import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr } from '../components/UI/alert'

export const fetchPaymentPurpose = createAsyncThunk(
  'paymentpurpose/fetchPaymentPurpose',
  async function (_, { rejectWithValue }) {

    try {
      // const response = await fetch(`http://mediarama-payment-app/paymentpurpose`, {
      const response = await fetch(`https://payment.mediarama.by/core/paymentpurpose/get`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const arr = []
      for (const key in data) {
        const newObject = {
          affiliation: data[key].affiliation,
          name: 'appoint-' + data[key].id,
          value: data[key].value,
          marker: data[key].marker,
          cod: data[key].cod
        }
        arr.push(newObject)
      }
      return arr
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const paymentPurpose = createSlice({
  name: 'paymentpurpose',
  initialState: {
    paymentpurpose: [],
    status: null,
    error: null,
  },
  extraReducers: {
    [fetchPaymentPurpose.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchPaymentPurpose.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.paymentpurpose = action.payload
    },
    [fetchPaymentPurpose.rejected]: setError,

  }
})
export default paymentPurpose.reducer