import React from "react";

import styles from './styles.module.sass'
function Th({ name, width, dataId, className }) {
  return (
    <th data-id={dataId} style={{ minWidth: width + "px", maxWidth: width + "px", width: width + "px" }} className={styles.th + " " + (className ? className : '')}>
      {name}
    </th>
  );
};
export default React.memo(Th)