import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchPostal = createAsyncThunk(
  'postals/fetchPostal',
  async function (_, { rejectWithValue }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/postals/get`, {
        method: 'GET'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const createPostal = createAsyncThunk(
  'postals/createPostal',
  async function (formData, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/postals/create`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()
      dispatch(addPostal(data.data))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const editPostal = createAsyncThunk(
  'postals/editPostal',
  async function ({ formData, idx }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/postals/edit/${idx}`, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()
      dispatch(modifyPostal(data.data))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const removePostal = createAsyncThunk(
  'postals/removePostal',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/postals/delete/${id}`, {
        method: 'POST',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');
      dispatch(deletePostal(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const postalsSlice = createSlice({
  name: 'postals',
  initialState: {
    postals: [],
    status: null,
    error: null,
  },
  reducers: {
    addPostal(state, action) {
      state.postals.unshift(action.payload)
    },
    deletePostal(state, action) {
      state.postals = state.postals.filter(postal => postal.id !== action.payload)
      success.fire({
        text: "Запись удалена!",
      });
    },
    modifyPostal(state, action) {
      const inx = state.postals.findIndex(postal => postal.id === action.payload.id)
      state.postals.splice(inx, 0, action.payload)
      success.fire({
        text: "Изменение сохранено!",
      });
    },
  },
  extraReducers: {
    [fetchPostal.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [createPostal.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editPostal.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchPostal.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.postals = action.payload
    },
    [fetchPostal.rejected]: setError,
    [createPostal.rejected]: setError,
    [editPostal.rejected]: setError,
    [removePostal.rejected]: setError,
  }
})
const { addPostal, deletePostal, modifyPostal } = postalsSlice.actions
export default postalsSlice.reducer