import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Textarea from "../components/UI/Textarea"
import { useSelector, useDispatch } from 'react-redux';
import "../components/Briefs/styles.sass"
import SelectSearch from '../components/UI/SelectSearch/SelectSearch';
import { createBriefs, fetchBriefs } from "../store/BriefSlice"
import style from '../components/Briefs/style.module.sass'
import { useParams, useLocation } from "react-router-dom";
import { success } from "../components/UI/alert";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFComponentBrief from "../components/Briefs/PDFComponentBrief"
import LoadServerData from '../components/LoadServerData'
import { alertErr } from '../components/UI/alert'
import { fetchContractsDetails, } from '../store/contractsDetailsSlice'

function BriefSiteEdit() {
  const dispatch = useDispatch()
  LoadServerData()

  const params = useParams()
  const { state } = useLocation();
  const { clients } = useSelector(state => state.clients)
  const { briefs } = useSelector(state => state.briefs)
  const [thisClient, setThisClient] = useState(null)
  const firmas = useSelector(state => state.firmas.firmas)
  const contractsDetails = useSelector(state => state.contractsdetails.contractsDetails)
  const [firmaName, setFirmaName] = useState('')
  const [contractsName, setContractsName] = useState('')
  useEffect(() => { dispatch(fetchContractsDetails(params.id)) }, [dispatch, params.id])
  useEffect(() => {
    const firmaArr = firmas.map(el => {
      return {
        name: 'firma-' + el.id,
        value: el.name,
        sing: el.sing
      }
    })
    setFirmaName(() => {
      return firmaArr
    })
  }, [firmas])
  useEffect(() => {
    const contractsArr = contractsDetails.map(el => {
      return {
        name: [el.contractNum, el.date],
        value: el.services,
      }
    })
    setContractsName(() => {
      return contractsArr
    })
  }, [contractsDetails])

  useEffect(() => {
    dispatch(fetchBriefs(params.id))
  }, [dispatch, params])

  useEffect(() => {
    setThisClient(clients[0]);
  }, [clients, params])

  function saveBrief(e) {
    e.preventDefault()
    const fields = e.target.querySelectorAll('.p-title')

    const object = {}

    fields.forEach(el => {
      const title = el.innerHTML
      const field = el.parentElement.nextElementSibling.querySelector('[data-name]')
      const fieldUl = el.parentElement.nextElementSibling.querySelectorAll('ul input')
      if (field.type === 'checkbox') {
        object[field.dataset.name] = { title: title, checked: field.checked, value: field.value }
      } else {
        object[field.dataset.name] = { style: field.style.height, title: title, value: field.value }
      }
      if (fieldUl.length > 0) {
        const result = {
          names: [],
          values: [],
          checked: []
        };
        fieldUl.forEach(input => {
          result.names.push(input.name);    // Добавляем name в массив names
          result.values.push(input.value);  // Добавляем value в массив values
          result.checked.push(input.checked); // Добавляем состояние checked в массив checked
        });
        object[field.dataset.name] = { title: title, fieldul: result }
      }
    });

    const brief = JSON.parse(briefs[state.key])
    brief.brief = object
    brief.editing = false
    dispatch(createBriefs(brief));
    success.fire({
      text: 'Сохранено!',
    })
  }

  const [thisBrief, setThisBrief] = useState({})
  const [showError, setShowError] = useState(false);
  const [titleName, setTitleName] = useState(false);
  useEffect(() => {
    if (state)
      if (briefs && briefs[state.key]) {
        const edit = JSON.parse(briefs[state.key]);
        if (edit.brief) setThisBrief(edit.brief);
        setTitleName(edit.name);
      } else {
        setTimeout(() => {
          setShowError(true)
        }, 1000);
      }
  }, [briefs, state]);

  const [dataPayment, setDataPayment] = useState([])
  const [sing, setSing] = useState('')
  const [contract, setContract] = useState('')
  function goSing(name) {
    const n = firmaName.filter(el => el.name === name)[0]
    setSing(n.sing);
  }
  function goContracts(params) {
    const newObj = {
      name: params.name,
      num: params.num.split(',')[0],
      date: params.num.split(',')[1],
    }
    setContract(newObj)
  }
  function ceratePDF(params) {
    if (sing && contract) {
      params["sing"] = { sing: sing };
      params["contract"] = { contract: contract };

      console.log(params);
      setDataPayment(Object.values(params))

    } else {
      alertErr.fire({
        text: 'Не выбран !',
      })
    }
  }

  return (
    <section className="pages pages-brief">
      {thisClient && Object.keys(briefs).length > 0 ?
        <Container>
          <h1 className='h1'>{titleName}</h1>
          <Form onSubmit={(e) => saveBrief(e)}>
            <Row>
              {
                Object.entries(thisBrief)?.map(([key, value]) => (
                  !value.contract && !value.sing &&
                  < React.Fragment key={key} >
                    <Col lg="4" className="mb-3">
                      <p className='p p-title' dangerouslySetInnerHTML={{ __html: value.title }}></p>
                    </Col>
                    <Col lg="8" className="mb-3">

                      {!value.fieldul && value.checked !== undefined ?
                        <label htmlFor={key}>
                          <p>Есть</p>
                          <input className='checkbox' type="checkbox" id={key} data-name={key} value={value.value} defaultChecked={value.checked} />
                        </label>
                        :
                        (!value.fieldul && <Textarea name={key} height={value.style} value={value.value} />)

                      }

                      {value.fieldul && <ul className={style.ul}>
                        {

                          value.fieldul && value.fieldul?.values.map((el, i) => (
                            <li key={el}>
                              <label htmlFor={value.fieldul?.names[i]}>
                                <input className='checkbox' type="checkbox" id={value.fieldul?.names[i]} name={value.fieldul?.names[i]} data-name={value.fieldul?.names[i]} value={el} defaultChecked={value.fieldul?.checked[i]} />
                                <p>{el}</p>
                              </label>
                            </li>
                          ))
                        }

                      </ul>}
                    </Col>
                  </React.Fragment>
                ))
              }
            </Row>
            <Row>
              <Col lg="3" className="mb-3">
                <Button className="button-submit" variant="success" type="submit">
                  Сохранить
                </Button>
              </Col>
              <Col lg="2" md="2" className="mb-3">
                <div className="input-style">
                  <label>Фирма</label>
                  <SelectSearch options={firmaName} onChange={(e) => goSing(e.name)} />
                </div>
                <div className="input-style">
                  <label>К договору</label>
                  <SelectSearch options={contractsName} onChange={(e) => goContracts({ num: (e.name), name: (e.value) })} />
                </div>
              </Col>
              <Col lg="3" className="mb-3">
                <Button className="button-submit" variant="danger" type="button" onClick={() => ceratePDF(thisBrief)}>
                  Распечатать в PDF
                </Button>
                {dataPayment.length > 0 && <PDFDownloadLink document={<PDFComponentBrief dataPayment={dataPayment} />} fileName={'Бриф.pdf'}>
                  <span onClick={() => setTimeout(() => setDataPayment(''), 1000)}>✅</span>
                </PDFDownloadLink>}
              </Col>
            </Row>
          </Form>
        </Container>
        :
        <Container>
          {showError && (
            <Row className='justify-content-center'>
              <Col lg="2" className="mb-3">
                <div className="error">
                  <div className="error-fon">404</div>
                  <div className="error-text-wrap">
                    <div className="error-text-top">404 Not Found</div>
                    <div className="error-test-separate"></div>
                    <div className="error-text-bottom">не найдено</div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      }
    </section >
  );
};
export default BriefSiteEdit;