import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchHosting = createAsyncThunk(
  'hosting/fetchHosting',
  async function (_, { rejectWithValue }) {

    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/hosting/get`, {
        method: 'GET'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const fetchOneHosting = createAsyncThunk(
  'hosting/fetchOneHosting',
  async function (id, { rejectWithValue }) {

    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/hosting/get/${id}`, {
        method: 'GET'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const searchHosting = createAsyncThunk(
  'hosting/searchHosting',
  async function (formData, { rejectWithValue }) {

    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/hosting/search`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const createHosting = createAsyncThunk(
  'hosting/createHosting',
  async function (formData, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/hosting/create`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()

      dispatch(addHosting(data.data))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const blockingHosting = createAsyncThunk(
  'hosting/blockingHosting',
  async function ({ id, field, reversFlag, domain }, { rejectWithValue, dispatch }) {
    const formData = new FormData()
    formData.append('field', field)
    formData.append('flag', reversFlag)
    formData.append('domain', domain)
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting/${id}`, {
      const response = await fetch(`https://payment.mediarama.by/core/hosting/block/${id}`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');
      const data = await response.json()
      dispatch(blockHosting(data.data))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Процедура не удалась.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const blockingSite = createAsyncThunk(
  'hosting/blockingSite',
  async function ({ url, formData }, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting/${id}`, {
      const response = await fetch(url, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось заблокировать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const editHosting = createAsyncThunk(
  'hosting/editHosting',
  async function ({ formData, idx }, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting/${idx}`, {
      const response = await fetch(`https://payment.mediarama.by/core/hosting/edit/${idx}`, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()
      dispatch(modifyHosting(data.data))

      const form = document.getElementById(idx)
      const button = form.querySelector('.button-save')
      button.setAttribute('disabled', 'disabled')
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не изменен сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const hostingSlice = createSlice({
  name: 'hosting',
  initialState: {
    hosting: [],
    hostingOne: {},
    status: null,
    error: null,
  },
  reducers: {
    addHosting(state, action) {
      state.hosting.push(action.payload)
    },
    blockHosting(state, action) {
      state.hostingOne = action.payload
      success.fire({
        text: "Готово!",
      });
    },
    modifyHosting(state, action) {
      const inx = state.hosting.findIndex(hosting => hosting.id === action.payload.id)
      state.hosting.splice(inx, 0, action.payload)
      success.fire({
        text: "Изменение сохранено!",
      });
    },
    removeHostingOne(state, action) {
      state.hostingOne.domain = action.payload
    },
  },
  extraReducers: {
    [fetchHosting.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchOneHosting.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [searchHosting.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    // [fetchOneTrail.pending]: (state) => {
    //   state.status = 'loading'
    //   state.error = null
    // },
    [createHosting.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editHosting.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchHosting.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.hosting = action.payload
    },
    [fetchOneHosting.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.hostingOne = action.payload
    },
    [searchHosting.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.hosting = action.payload
    },
    // [fetchOneTrail.fulfilled]: (state, action) => {
    //   state.status = 'resolved'
    //   state.trail = action.payload
    // },
    [fetchHosting.rejected]: setError,
    [fetchOneHosting.rejected]: setError,
    [searchHosting.rejected]: setError,
    // [fetchOneTrail.rejected]: setError,
    [createHosting.rejected]: setError,
    [editHosting.rejected]: setError,
    [blockingHosting.rejected]: setError,
    [blockingSite.rejected]: setError,
  }
})
export const { addHosting, blockHosting, modifyHosting, removeHostingOne } = hostingSlice.actions
export default hostingSlice.reducer