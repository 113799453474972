import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success, successMessage } from '../components/UI/alert'

export const fetchRecruits = createAsyncThunk(
  'recruits/fetchRecruits',
  async function (_, { rejectWithValue }) {

    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/recruit/get`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data
    } catch (errorTel) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(errorTel.message)
    }
  }
)

export const fetchRecruitsReport = createAsyncThunk(
  'recruits/fetchRecruitsReport',
  async function (id, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/recruit/get/${id}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data
    } catch (errorTel) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(errorTel.message)
    }
  }
)

export const editRecruits = createAsyncThunk(
  'recruits/editRecruits',
  async function ({ formData, idx }, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting/${idx}`, {
      const response = await fetch(`https://payment.mediarama.by/core/recruit/edit/${idx}`, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()
      dispatch(modifyRecruits(data.data))

      const form = document.getElementById(idx)
      const button = form.querySelector('.button-save')
      button.setAttribute('disabled', 'disabled')
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не изменен сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const editRecruitsHotLinks = createAsyncThunk(
  'recruits/editRecruitsHotLinks',
  async function ({ formData, id }, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting/${idx}`, {
      const response = await fetch(`https://payment.mediarama.by/core/recruit/edithotlinks/${id}`, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()
      if (typeof data === 'object') {
        successMessage.fire({
          text: `СОХРАНЕНО!`,
        })
      }
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не изменен сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const filterRecruitsHotLinks = createAsyncThunk(
  'parishes/filterRecruitsHotLinks',
  async function (formData, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/recruit/filter`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');

      const data = await response.json()
      return Object.values(data)
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось изменить поле. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.statusTel = 'rejected'
  state.errorTel = action.payload
}

const recruitsSlice = createSlice({
  name: 'recruits',
  initialState: {
    recruits: [],
    recruit: {},
    report: [],
    status: null,
    error: null,
  },
  reducers: {
    modifyRecruits(state, action) {
      if (action.payload.dismissed === 'true') {
        state.recruits = state.recruits.filter(recruit => recruit.id !== action.payload.id)
      } else {
        const indx = state.recruits.findIndex(recruit => recruit.id === action.payload.id)
        state.recruits.splice(indx, 0, action.payload)
      }
      success.fire({
        text: "Изменение сохранено!",
      });
    },
    getRecruit(state, action) {
      state.recruit = state.recruits.find(r => r.chat_id === action.payload)
    },
    // modifyHotLinks(state, action){
    //   const indx = state.report.findIndex(r => r.id === action.payload.id)
    //   state.report.splice(indx, 1, action.payload)
    // }
  },
  extraReducers: {
    [fetchRecruits.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchRecruitsReport.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [filterRecruitsHotLinks.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchRecruits.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.recruits = action.payload
    },
    [fetchRecruitsReport.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.report = action.payload
    },
    [filterRecruitsHotLinks.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.report = action.payload
    },
    [editRecruits.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [filterRecruitsHotLinks.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editRecruitsHotLinks.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchRecruitsReport.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchRecruits.rejected]: setError,
    [fetchRecruitsReport.rejected]: setError,
    [editRecruits.rejected]: setError,
    [editRecruitsHotLinks.rejected]: setError,
    [filterRecruitsHotLinks.rejected]: setError,
  }
})
export const { modifyRecruits, getRecruit } = recruitsSlice.actions
export default recruitsSlice.reducer