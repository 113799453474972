import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from "./PDFComponentStyle";

// Create Document Component
function PDFPoint({ commission }) {
  const cost = Number(commission).toFixed(2)
  return (
    <View style={styles.rowBody} wrap={false}>
      <View style={styles.row1}><Text>Комиссионное вознаграждение согласно Публичному договору возмездного оказания услуг</Text></View>
      <View style={[styles.row1, styles.row2]}><Text></Text></View>
      <View style={[styles.row1, styles.row2]}><Text></Text></View>
      <View style={[styles.row1, styles.row3]}><Text>{cost}</Text></View>
      <View style={[styles.row1, styles.row3]}><Text>{cost}</Text></View>
      <View style={[styles.row1, styles.row3]}><Text>Без НДС</Text></View>
      <View style={[styles.row1, styles.row3]}><Text></Text></View>
      <View style={[styles.row1, styles.row3]}><Text>{cost}</Text></View>
    </View>
  )
}
export default PDFPoint