import React, { useState } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useDispatch } from 'react-redux';

import { fetchAuth } from '../store/authSlice'

const Auth = () => {
  const dispatch = useDispatch()

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);
    dispatch(fetchAuth(formData))
  }
  return (
    <Container>
      <Row style={{ marginTop: '10%' }} className="justify-content-center align-items-center">
        <Col sm="8" md="6" lg="4">
          <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3">
              <Form.Label>Логин</Form.Label>
              <Form.Control value={login} onChange={(e) => setLogin(e.target.value)} type="text" placeholder="Логин" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Пароль</Form.Label>
              <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Пароль" />
            </Form.Group>

            <Button variant="primary" type="submit">
              Войти
            </Button>

          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Auth;