import React from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'

import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function RatioManagers({ indicators }) {
  const q = Object.entries(indicators.ratioManagersMonth[0])
  const q2 = Object.entries(indicators.ratioManagersMonth[1])
  q.forEach((el, i) => el.push(q2[i][1]));

  const barChartData = {
    labels: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    datasets: [
      {
        label: "Продажи " + q[0][0],
        data: q[0][1],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 144, 255, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        label: "Ведения " + q[0][0],
        data: q[0][2],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 100, 0, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      }
    ],
  };
  const barChartData4 = {
    labels: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    datasets: [
      {
        label: "Продажи " + q[4][0],
        data: q[4][1],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 144, 255, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        label: "Ведения " + q[4][0],
        data: q[4][2],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 100, 0, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      }
    ],
  };
  const barChartData5 = {
    labels: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    datasets: [
      {
        label: "Продажи " + q[5][0],
        data: q[5][1],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 144, 255, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        label: "Ведения " + q[5][0],
        data: q[5][2],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 100, 0, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      }
    ],
  };
  const barChartData6 = {
    labels: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    datasets: [
      {
        label: "Продажи " + q[6][0],
        data: q[6][1],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 144, 255, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        label: "Ведения " + q[6][0],
        data: q[6][2],
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 100, 0, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      }
    ],
  };
  return (
    <Row>
      <Col lg="6" className="mb-3">
        <Bar
          type="bar"
          width={130}
          height={50}

          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "Соотношения новых продаж и ведения",
              }
            },
          }}
          data={barChartData}
        />
      </Col>
      <Col lg="6" className="mb-3">
        <Bar
          type="bar"
          width={130}
          height={50}

          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "Соотношения новых продаж и ведения",
              }
            },
          }}
          data={barChartData4}
        />
      </Col>
      <Col lg="6" className="mb-3">
        <Bar
          type="bar"
          width={130}
          height={50}

          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "Соотношения новых продаж и ведения",
              }
            },
          }}
          data={barChartData5}
        />
      </Col>
      <Col lg="6" className="mb-3">
        <Bar
          type="bar"
          width={130}
          height={50}

          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "Соотношения новых продаж и ведения",
              }
            },
          }}
          data={barChartData6}
        />
      </Col>
    </Row>
  )
};

export default RatioManagers;