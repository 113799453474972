import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SimpleInput from '../../UI/SimpleInput';
import Textarea from '../../UI/Textarea';
import '../../../sass/style.sass'
import { useDispatch, useSelector } from 'react-redux';
import { editClient } from '../../../store/clientsSlice'
import { fetchSettings } from "../../../store/settingsSlice"
import stringify from 'json-stable-stringify';
import SelectSearch from '../../UI/SelectSearch/SelectSearch';

const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
  buttonDiv: {
    width: '100%',
    maxWidth: '80px',
    background: "blue",
    color: "#fff",
    textAlign: "center",
    padding: "0.375rem 0.75rem",
    borderRadius: "0.25rem",
    cursor: "pointer",
  }
}
function CommonData({ data }) {
  console.log(data);
  const dispatch = useDispatch()

  const saveButton = useRef(null)

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  const { spheres, managers } = useSelector(state => state.settings)

  const editClientSend = useCallback((e) => {
    e.preventDefault()
    const targets = e.target.querySelectorAll('input, textarea');
    const formData = new FormData()
    const originalArray = []
    targets.forEach(inp => {
      // Обрабатываем большинство полей формы
      if (inp.dataset.name === 'domain' || inp.dataset.name === 'topic' || inp.dataset.name === 'inform') {
        if (inp.value) originalArray.push({ [inp.dataset.name]: inp.value });
      } else {
        // Для чекбокса archive просто сохраняем его состояние
        if (inp.dataset.name === 'archive') {
          formData.append(inp.dataset.name, inp.checked);
        } else {
          // Для всех остальных полей добавляем их значения напрямую
          formData.append(inp.dataset.name, inp.value);
        }
      }
    });

    // После сбора данных проверяем условие для email и archive
    // Предполагается, что у вас есть доступ к значениям email и archive
    let email = formData.get('email');
    const isArchived = formData.get('archive') === 'true'; // или просто formData.get('archive'), если это булево значение

    // Проверяем, нужно ли добавить или удалить "-архив" из email
    if (isArchived && !email.endsWith("-архив")) {
      // Добавляем "-архив", если ещё не добавлен и archive === true
      email += "-архив";
    } else if (!isArchived && email.endsWith("-архив")) {
      // Убираем "-архив", если archive === false
      email = email.replace(/-архив$/, "");
    }

    // Обновляем значение email в formData
    formData.set('email', email);

    let idx = ''
    formData.forEach((value, key) => {
      if (key === 'id') idx = value
    });
    const resultArray = originalArray.reduce((acc, cur) => {
      if (cur.domain) {
        acc.push({ domain: cur.domain });
      } else {
        const obj = acc[acc.length - 1];
        const key = Object.keys(cur)[0];
        obj[key] = cur[key];
      }
      return acc;
    }, []).map((el, id) => ({ ...el, id }));
    formData.append('domains', JSON.stringify(resultArray))
    dispatch(editClient({ formData, idx }))
  }, [dispatch])

  const getFormData = useCallback((id) => {

    const form = document.getElementById(id)
    const formValues = form.querySelectorAll('input, textarea')

    const object = {
      id: '',
      name: '',
      footing: '',
      face: '',
      phone: '',
      email: '',
      telegram: '',
      viber: '',
      data: '',
      manager: '',
      memory: '',
      date: '',
      domains: "[]",
      archive: "false"
    }

    formValues.forEach((key) => {
      if (key.dataset.name === 'id') object.id = key.value
      if (key.dataset.name === 'name') object.name = key.value
      if (key.dataset.name === 'footing') object.footing = key.value
      if (key.dataset.name === 'face') object.face = key.value
      if (key.dataset.name === 'phone') object.phone = key.value
      if (key.dataset.name === 'email') object.email = key.value
      if (key.dataset.name === 'telegram') object.telegram = key.value
      if (key.dataset.name === 'viber') object.viber = key.value
      if (key.dataset.name === 'data') object.data = key.value.replace(/\n/g, '\r\n');
      if (key.dataset.name === 'manager') object.manager = key.value
      if (key.dataset.name === 'memory') object.memory = key.value
      if (key.dataset.name === 'date') object.date = key.value
      if (key.dataset.name === 'archive') object.archive = String(key.checked)

    });

    if (stringify(data) !== stringify(object)) {
      saveButton.current.removeAttribute('disabled')
    } else {
      saveButton.current.setAttribute('disabled', 'disabled')
    }
  }, [data])

  const clickForm = useCallback((id, event) => {
    // Проверяем, не является ли элемент, инициировавший событие, одним из тех, для которых мы игнорируем onKeyUp
    if (["domain", "topic", "inform"].includes(event.target.dataset.name)) {
      // Просто возвращаемся, не выполняя никаких действий
      return;
    }
    getFormData(id)
  }, [getFormData])

  function resetSaveButton() {
    saveButton.current.removeAttribute('disabled')
  }

  const [domain, setDomain] = useState([])

  useEffect(() => {
    setDomain([])
    if (data?.domains) setDomain(JSON.parse(data.domains));
  }, [data])


  function addDomainAndWay() {
    setDomain(prevArray => [...prevArray, { id: prevArray.length, domain: '', topic: '', inform: '' }]);
    saveButton.current.removeAttribute('disabled')
  }

  function delDomainAndWay(id) {
    const lastDomain = domain.filter(d => d.id !== id)
    setDomain(lastDomain);

  }
  function fieldDomain(e) {
    window.open('https://' + e.value, '_blank')
  };
  // Обработчик изменений чекбокса
  const [isArchived, setIsArchived] = useState(data?.archive === "true");
  useEffect(() => {
    setIsArchived(data?.archive === "true");

  }, [data?.archive]);
  const handleChange = (e) => {
    setIsArchived(e.target.checked);
    saveButton.current.removeAttribute('disabled')
  };


  return (
    <Form id={data?.id} onSubmit={(e) => editClientSend(e)} onKeyUp={(e) => clickForm(data?.id, e)} >
      <Container>
        <Row>
          <Col lg='4' className="mb-3">
            <input type="hidden" data-name="date" value={data?.date || ""} readOnly />
            <input type="hidden" data-name="id" value={data?.id || ""} readOnly />
            <SimpleInput title="Название" name="name" value={data?.name || ""} />
          </Col>
          <Col lg='2' className="mb-3">
            <SimpleInput title="На основании чего" name="footing" value={data?.footing || ""} />
          </Col>
          <Col lg='4' className="mb-3">
            <SimpleInput title="В лице кого" name="face" value={data?.face || ""} />
          </Col>
          <Col lg="2" md="6" className="mb-2">
            <label htmlFor="manager">Ответственный менедж</label>
            <SelectSearch onChange={resetSaveButton} name="manager" id="manager" options={[{ name: 'manager', value: "" }, ...managers]} defaultValue={{ name: 'manager', value: data?.manager }} />
          </Col>
        </Row>
        <Row>
          <Col lg='4' className="mb-3">
            <SimpleInput className="mb-3" title="Телефон" name="phone" value={data?.phone || ""} />
            <SimpleInput className="mb-3" title="email" name="email" value={data?.email || ""} />
            <SimpleInput className="mb-3" title="Telegram" name="telegram" type="number" value={data?.telegram || ""} />
            <SimpleInput className="mb-3" title="Viber" name="viber" type="text" value={data?.viber || ""} />
          </Col>
          <Col lg='4' className="mb-3">
            <Textarea height="280px" title="Реквизиты" name="data" value={data?.data || ""} />
          </Col>
          <Col lg='4' className="mb-3">
            <Textarea height="280px" title="Памятка" name="memory" value={data?.memory || ""} />
          </Col>
          {
            domain && domain.map(d =>
              <Col key={d.id} lg="11" md="5" className="mb-2">
                <Row>
                  <Col lg="4" md="3" className="mb-2">
                    <div className="input-style" >
                      <label>Домен*</label>
                      <input autoComplete="off" type="text" data-name="domain" defaultValue={d.domain} onDoubleClick={(e) => fieldDomain(e.target)} />
                    </div>
                  </Col>
                  <Col lg="4" md="3" className="mb-2">
                    <label htmlFor="topic">Тема сайта</label>
                    <SelectSearch onChange={resetSaveButton} name="topic" id="topic" options={[{ name: 'topic', value: "" }, ...spheres]} defaultValue={{ name: 'topic', value: d.topic }} />
                  </Col>
                  <Col lg="3" md="3" className="mb-2">
                    <div className="input-style" >
                      <label>Информация</label>
                      <input autoComplete="off" type="text" data-name="inform" defaultValue={d.inform} />
                    </div>
                  </Col>
                  <Col lg="1" md="1" className="mb-2">
                    <div className="input-style">
                      <label>Удалить</label>
                      <Button variant="danger" onClick={() => delDomainAndWay(d.id)}>-</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          <Col lg="1" md="1" className="mb-2">
            <div className="input-style">
              <label>Добавить</label>
              <div style={style.buttonDiv} onClick={addDomainAndWay}>Сайт</div>
            </div>
          </Col>
          <Col lg="1" md="2" sm="2" className="mb-3">
            <div className="input-style">
              <label>Сохранить</label>
              <Button disabled ref={saveButton} style={style.button} type="submit" variant="success">&#9998;</Button>
            </div>
          </Col>
          <Col lg="10" md="8" sm="8" className="mb-3">
            <Row className="justify-content-end">
              <Col lg="3" md="3" sm="3" className="mb-3">
                <div className="input-style">
                  <label>Отправить в архива</label>
                  <input type="checkbox" data-name="archive" name="archive" checked={isArchived} onChange={handleChange} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form >
  );
};
export default CommonData;