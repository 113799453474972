import React, { useEffect, useState, useCallback } from "react";
import styles from './styles.module.sass'
import SelectSearch from '../UI/SelectSearch/SelectSearch'

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import { useDispatch } from 'react-redux';
import { editParishes } from '../../store/parishesSlice'

import telegramMessages from '../UI/telegramMessages'
import { successMessage } from '../UI/alert'

const markerStyle = {
  background: 'green',
  color: "#fff"
}

function Td({ managers, width, calendar, client, code, balance, clientName, value, type = "text", name, id, marker, amount, datathid, mouseHover, className }) {

  const dispatch = useDispatch()

  let enterNumber = useCallback((id, name, value, mark = false) => {
    const formData = new FormData()
    const checkName = (n) => {
      return ["date", "client", "code", "manager"].find(el => el === n)
    }
    if (checkName(name)) formData.append(name, value)
    else formData.append(name, JSON.stringify({ value: value, marker: mark }))

    dispatch(editParishes({ id, formData }));
  }, [dispatch])

  const closeCalendar = useCallback((params) => {
    params.hide();
  }, [])

  const startCalendar = useCallback(() => {
    let butSeeCalendar = {
      content: "Закрыть",
      onClick: (dp) => {
        closeCalendar(dp);
      },
    };
    new AirDatepicker('.' + calendar, {
      dateFormat: "dd.MM.yyyy",
      autoClose: true,
      maxDate: new Date(),
      buttons: [butSeeCalendar],
      onSelect({ date, formattedDate, datepicker }) {
        const id = datepicker.$el.dataset.id;
        const name = datepicker.$el.dataset.name;

        enterNumber(id, name, formattedDate)
      },
    })
  }, [calendar, enterNumber, closeCalendar])

  useEffect(() => {
    if (calendar) startCalendar()
  }, [calendar, startCalendar])

  const debounce = (fn, ms) => {
    let timeout;
    return function () {
      const fnCall = () => { fn.apply(this, arguments) }
      clearTimeout(timeout)
      timeout = setTimeout(fnCall, ms);
    }
  }

  enterNumber = debounce(enterNumber, 500)

  const [thisClient, setThisClient] = useState({ value: "", name: "" })

  const enterClient = useCallback(({ id, _, name }) => {
    enterNumber(id, 'client', name);
  }, [enterNumber])

  const getClientFromBase = useCallback((clientNumb) => {
    const clnt = clientName.filter(cl => cl.name === clientNumb)
    setThisClient(...clnt)
  }, [clientName])

  useEffect(() => {
    if (name === "client") getClientFromBase(value)
  }, [client, value, name, getClientFromBase])

  const enterMarker = useCallback(() => {
    enterNumber(id, name, value, marker ? false : true)
  }, [enterNumber, id, name, value, marker])

  const enterCode = useCallback(({ id, value, name }) => {
    enterNumber(id, name, value);
  }, [enterNumber])

  const sendTelegramMessage = useCallback((e, value) => {
    const tr = e.target.closest('tr');
    const date = tr.querySelector('[data-name="date"]').value
    const client = tr.querySelector('[data-name^="client"]').value
    const code = tr.querySelector('[data-name^="code"]').value
    telegramMessages(`${value} р. - ${client} от ${date} - ${code}`)
    successMessage.fire({
      text: `ОТПРАВЛЕНО! «${client} - ${value} руб. от ${date} - ${code}»`,
    })
  }, [])

  let field;
  if (client) {
    field = <SelectSearch id={id} search={true} options={clientName} defaultValue={thisClient} onChange={enterClient} />
  } else if (code) {
    field = <SelectSearch id={id} options={[{ name: "code", value: "X" }, { name: "code", value: "M" }, { name: "code", value: "-" }]} defaultValue={{ name: name, value: value }} onChange={enterCode} />
  } else if (balance) {
    field = <input key={id} data-thid={datathid} onMouseMove={mouseHover} autoComplete="off" data-id={id} className={styles.input + " " + styles.simpleInput + " " + className} type={type} readOnly value={value} />
  } else if (managers) {
    field = <SelectSearch id={id} options={managers} defaultValue={{ name: name, value: value }} onChange={enterCode} />
  } else if (amount) {
    field = <>
      <input
        onWheel={e => e.target.blur()}
        key={id}
        data-thid={datathid}
        onMouseMove={mouseHover}
        autoComplete="off"
        style={marker ? markerStyle : null}
        onDoubleClick={(e) => sendTelegramMessage(e, e.target.value)}
        data-id={id}
        data-name={name}
        className={styles.input + " " + styles.simpleInput}
        type={type}
        defaultValue={value}
        onKeyUp={(e) => enterNumber(e.target.dataset.id, e.target.dataset.name, e.target.value)} />
      <span
        style={{ position: "absolute", bottom: "0px", right: "3px", cursor: "pointer", fontWeight: "bold" }}
        onDoubleClick={(e) => sendTelegramMessage(e, value)}>
        &#128386;</span>
    </>
  } else if (calendar) {
    field = <input key={id} autoComplete="off" data-id={id} data-name={name} className={styles.input + " " + calendar + " " + styles.simpleInput} type={type} defaultValue={value} readOnly />
  }
  else {
    field = <input onWheel={e => e.target.blur()} data-h="1" key={id} data-thid={datathid} onMouseMove={mouseHover} autoComplete="off" style={marker ? markerStyle : null} onDoubleClick={(e) => enterMarker(e.target.dataset.id, e.target.dataset.name, e.target.value)} data-id={id} data-name={name} className={styles.input + " " + styles.simpleInput} type={type} defaultValue={value} onKeyUp={(e) => enterNumber(e.target.dataset.id, e.target.dataset.name, e.target.value)} />
  }
  return (
    <td width={width} className={styles.td}>
      {field}
    </td>
  );
};

export default React.memo(Td)