import React, { useState, useMemo, useEffect } from "react";

import { Row, Col, Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import CreateClient from '../components/Clients/CreateClient';
import ClientsList from '../components/Clients/ClientsList';
import { useSelector, useDispatch } from 'react-redux';
import { fetchParishesAll } from '../store/parishesSlice'
import Loading from '../components/UI/Loading'
import { fetchClient } from '../store/clientsSlice'

function Сlients() {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchClient())
    dispatch(fetchParishesAll())
  }, [dispatch])
  const parishesAll = useSelector(state => state.parishes.parishesAll)

  const { clients, status, error } = useSelector(state => state.clients)
  let revers = [...clients].reverse()

  const [searchVal, setSearchVal] = useState('')


  const newArrClients = useMemo(() => {
    return revers.filter(el => {
      if (el.name.toLowerCase().includes(searchVal) || el.name.includes(searchVal)) return el
      if (el.manager.toLowerCase().includes(searchVal) || el.manager.includes(searchVal)) return el
      if (el.face.toLowerCase().includes(searchVal) || el.face.includes(searchVal)) return el
      if (el.email.toLowerCase().includes(searchVal) || el.email.includes(searchVal)) return el
      if (el.data.toLowerCase().includes(searchVal) || el.data.includes(searchVal)) return el
      if (el.telegram.includes(searchVal)) return el
      if (String(el.date).includes(searchVal)) return el
    });
  }, [revers, searchVal])

  //получаем архивных людей
  function getArchive() {
    dispatch(fetchClient({ archive: true }))
    setSearchVal('')
  }
  //получаем всех людей
  function getAllClients() {
    dispatch(fetchClient())
    setSearchVal('')
  }

  return (
    <section className="pages">
      <Container>
        <h1>Клиенты</h1>
        <CreateClient />
        <Row>
          <Col lg="8" className="mb-3">
            <div className="input-style" >
              <label>Поиск</label>
              <input type="text" placeholder="Поиск..." onChange={(e) => setSearchVal(e.target.value)} value={searchVal} />
            </div>
          </Col>
          <Col lg="2" className="mb-3">
            <div className="input-style" >
              <label>Все</label>
              <Button variant="success" onClick={getAllClients}>Показать всех</Button>
            </div>
          </Col>
          <Col lg="2" className="mb-3">
            <div className="input-style" >
              <label>Архив</label>
              <Button variant="warning" onClick={getArchive}>Показать архив</Button>
            </div>
          </Col>
        </Row>

      </Container>
      <Container fluid className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        <ClientsList data={newArrClients} rating={parishesAll} />
      </Container>
    </section >
  );
};

export default Сlients;