import React from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from 'react-chartjs-2'
function Managers({ indicators }) {
  const thisMonth = new Date().getMonth() + 1
  const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"].splice(0, thisMonth)
  const money = Object.values(indicators.managers)
  const manager = Object.keys(indicators.managers)
  const barChartData = {
    labels: months,
    datasets: [
      ...Object.entries(money).map((el, i) => {
        let m = el[1].reduce((a, c) => a + c)
        if (m > 0) {
          return {
            data: el[1],
            label: manager[i],
            borderColor: `rgb(${[1, 2, 3].map(x => Math.random() * 256 | 0)})`,
            backgroundColor: `rgba(255, 0, 0, .0)`,
            fill: true
          }
        }
      }).filter(el => el !== undefined)
    ]
  };

  return (
    <Line
      type="Line"
      width={130}
      height={50}

      options={{
        plugins: {
          title: {
            display: true,
            text: "Помесячный отчет по менеджерам",
          }
        },
      }}
      data={barChartData}
    />
  )
};

export default Managers;