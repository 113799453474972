import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchViberBase = createAsyncThunk(
  'viber/fetchViberBase',
  async function (_, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/viberbase/get`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data
    } catch (errorTel) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(errorTel.message)
    }
  }
)
export const removeClient = createAsyncThunk(
  'viber/removeClient',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients/${id}`, {
      const response = await fetch(`https://payment.mediarama.by/core/viberbase/delete/${id}`, {
        method: 'POST',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');
      dispatch(deleteClient(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.statusTel = 'rejected'
  state.errorTel = action.payload
}

const viberBaseSlice = createSlice({
  name: 'viberBase',
  initialState: {
    viberBase: [],
    statusTel: null,
    errorTel: null,
  },
  reducers: {
    deleteClient(state, action) {
      state.viberBase = state.viberBase.filter(client => client.id !== action.payload)
      success.fire({
        text: "Клиент удален!",
      });
    },
  },
  extraReducers: {
    [fetchViberBase.pending]: (state) => {
      state.statusTel = 'loading'
      state.errorTel = null
    },
    [fetchViberBase.fulfilled]: (state, action) => {
      state.statusTel = 'resolved'
      state.viberBase = action.payload
    },
    [fetchViberBase.rejected]: setError,
  }
})
const { deleteClient } = viberBaseSlice.actions
export default viberBaseSlice.reducer