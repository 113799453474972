import React from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
function RatioQuarter({ indicators }) {
  const barChartData = {
    labels: ["1й квартал", "2й квартал", "3й квартал", "4й квартал"],
    datasets: [
      {
        data: indicators.ratioQuarter[0],
        label: "Продажи новых продуктов",
        borderColor: "#3333ff",
        backgroundColor: "rgba(100, 100, 100, 0.5)",
        fill: true
      },
      {
        data: indicators.ratioQuarter[1],
        label: "Ведение и обслуживание",
        borderColor: "#ff3333",
        backgroundColor: "rgba(23, 144, 255, 0.5)",
        fill: true
      }
    ]
  };
  return (
    <Bar
      type="bar"
      width={130}
      height={50}

      options={{
        plugins: {
          title: {
            display: true,
            text: "Поквартальный отчет соотношения новых продаж и ведения",
          }
        },
      }}
      data={barChartData}
    />
  )
};

export default RatioQuarter;