import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from "./PDFComponentStyle";

// Create Document Component
function PDFPoint({ appoint, price }) {
  const cost = Number(price).toFixed(2)
  return (
    <View style={styles.rowBody} wrap={false}>
      <View style={styles.row1}><Text>{appoint}</Text></View>
      <View style={[styles.row1, styles.row2]}><Text>шт</Text></View>
      <View style={[styles.row1, styles.row2]}><Text>1</Text></View>
      <View style={[styles.row1, styles.row3]}><Text>{cost}</Text></View>
      <View style={[styles.row1, styles.row3]}><Text>{cost}</Text></View>
      <View style={[styles.row1, styles.row3]}><Text>Без НДС.</Text></View>
      <View style={[styles.row1, styles.row3]}><Text></Text></View>
      <View style={[styles.row1, styles.row3]}><Text>{cost}</Text></View>
    </View>
  )
}
export default PDFPoint