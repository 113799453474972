import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";

import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import CreateInvoice from '../components/CreateInvoice';

import LoadServerData from '../components/LoadServerData'

import { alertErr } from '../components/UI/alert'

import { useSelector, useDispatch } from 'react-redux';

import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFComponentContractContext from '../components/Contracts/PDFComponentContractContext';
import PDFComponentContractSite from '../components/Contracts/PDFComponentContractSite';
import PDFComponentContractAddProduct from '../components/Contracts/PDFComponentContractAddProduct';
import PDFComponentContractDevSiteDesign from '../components/Contracts/PDFComponentContractDevSiteDesign';
import PDFComponentContractMap from '../components/Contracts/PDFComponentContractMap';
import PDFComponentContractContextService from '../components/Contracts/PDFComponentContractContextService';
import PDFComponentContractSiteModification from '../components/Contracts/PDFComponentContractSiteModification';
import PDFComponentContractHostingServices from '../components/Contracts/PDFComponentContractHostingServices';
import PDFComponentContractInstagramAccount from '../components/Contracts/PDFComponentContractInstagramAccount';
import PDFComponentContractInstagramAccountDesign from '../components/Contracts/PDFComponentContractInstagramAccountDesign';
import PDFComponentContractSEO from '../components/Contracts/PDFComponentContractSEO';
import PDFComponentContractTargetedAdvertising from '../components/Contracts/PDFComponentContractTargetedAdvertising';
import PDFComponentContractComplexMaint from '../components/Contracts/PDFComponentContractComplexMaint';
import PDFComponentContractProgramming from '../components/Contracts/PDFComponentContractProgramming';

import { createContractsDetails } from '../store/contractsDetailsSlice'

import load from '../images/1.svg'
import loadOk from '../images/2.svg'

import Input from '../components/UI/Input';
import SelectSearch from '../components/UI/SelectSearch/SelectSearch';

import styles from '../components/Contracts/styles.module.sass'
import { fetchContractsDetails } from '../store/contractsDetailsSlice'
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import { fetchSettings } from "../store/settingsSlice"
const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
  buttonMin: {
    width: '100%',
    maxWidth: '55px'
  },
  buttonSend: {
    width: '200px'
  },
  img: {
    marginRight: '5px'
  },
}

const calendarOptions = {
  autoClose: true,
  view: "days",
  minView: "months",
  dateFormat: "dd.MM.yyyy",
  multipleDatesSeparator: " - ",
  buttons: ['clear'],
  // selectedDates: new Date() 
}

function Contracts() {
  const authName = useSelector(state => state.auth.name)
  const dispatch = useDispatch()
  LoadServerData()
  const contractsDetails = useSelector(state => state.contractsdetails.contractsDetails)
  const { clients } = useSelector(state => state.clients)
  const { firmas } = useSelector(state => state.firmas)

  const [firmaName, setFirmaName] = useState('')
  const { managers } = useSelector(state => state.settings)
  // useEffect(() => { dispatch(fetchContractsDetails()) }, [dispatch])

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  useMemo(() => {
    const firmaArr = firmas.map(el => {
      return {
        name: 'firma-' + el.id,
        value: el.name
      }
    })
    setFirmaName(() => {
      return firmaArr
    })
  }, [firmas])

  const [clientName, setClientName] = useState('')

  useMemo(() => {
    const clientArr = clients.map(el => {
      return {
        name: 'client-' + el.id,
        value: el.name
      }
    })
    setClientName(clientArr)
  }, [clients])

  const calendar = useRef(null)
  useEffect(() => {
    new AirDatepicker(calendar.current, calendarOptions)
  }, [calendar])

  const [paymentMethod, setPaymentMethod] = useState([])

  const newPDF = useCallback(() => {
    setTimeout(() => {
      setDataPayment('')
    }, 1000)
  }, [])

  const [paymentMethodSelected, setPaymentMethodSelected] = useState('')
  function selectPaymentMethod(params) {
    setPaymentMethodSelected(params.name)
    newPDF()

    if (params.name === "payment") {
      setPaymentMethod([{ id: 0, date: '', sum: '' }, { id: 1, date: '', sum: '' }])
    }
    if (params.name === "hosting") {
      setPaymentMethod([{ id: 0, date: '', sum: '' }])
    }
    if (params.name === "100") setPaymentMethod([{ id: 0, date: '', sum: '' }])
    if (params.name === "50") setPaymentMethod([{ id: 0, date: '', sum: '' }, { id: 1, date: '', sum: '' }])

  }

  function addPaymentFields() {
    setPaymentMethod([...paymentMethod, { id: paymentMethod.length, date: '', sum: '' }])
  }

  function removePaymentFields(id) {
    const newPaymentMethod = paymentMethod.filter(el => el.id !== id)
    setPaymentMethod(newPaymentMethod)
  }

  const [dataPayment, setDataPayment] = useState('')

  // useEffect(() => { console.log(dataPayment) }, [dataPayment])
  const [selectContract, setSelectContract] = useState("")
  const [paymentMethodSelectArray, setPaymentMethodSelectArray] = useState([
    { name: '100', value: '100% оплата' },
    { name: 'payment', value: 'Рассрочка' },
    { name: '50', value: '2 этапа 50%' },
  ])

  const [isClear, setIsClear] = useState(null)
  useEffect(() => {
    setIsClear(new Date());
    const paymentMethodSelectArrays = {
      "seo": [{ name: '100', value: '100% оплата' }],
      "contextServices": [{ name: '100', value: '100% оплата' }],
      "target": [{ name: '100', value: '100% оплата' }],
    }
    const findObjectByKey = (obj, key) => {
      if (obj.hasOwnProperty(key)) return obj[key];
      else return null;
    }
    const foundObject = findObjectByKey(paymentMethodSelectArrays, selectContract);
    // в selectContract находиться name предмета договоров
    if (foundObject) setPaymentMethodSelectArray(foundObject)
    if (selectContract === 'hostingServices') {
      setPaymentMethodSelectArray([
        { name: 'hosting', value: '6 мес' },
        { name: 'hosting', value: '12 мес' },
        { name: 'hosting', value: '24 мес' },
      ])
    }
    else setPaymentMethodSelectArray([
      { name: '100', value: '100% оплата' },
      { name: 'payment', value: 'Рассрочка' },
      { name: '50', value: '2 этапа 50%' },
    ])

    setPaymentMethod([])
    setPaymentMethodSelected("")
  }, [selectContract])

  const [targetForm, setTargetForm] = useState(null)

  async function send(e) {
    e.preventDefault()
    await dispatch(fetchContractsDetails()) // для расчета порядкового номера
    await setTargetForm(e)
  }

  const createPDF = useCallback((e, num) => {

    const object = {
      authName: authName,
      contractNum: num,
      client_id: '',
      date: '',
      requisites: '',
      clientName: '',
      clientInFace: '',
      clientFooting: '',
      services: '',
      servicesName: '',
      payment: [],
      price: [],
      firmaRequisites: '',
      firmaName: '',
      firmaInFace: '',
      firmaFooting: '',
      sing: '',
      hostingMonth: '',
      manager: '',
      pay: false,
    }

    const targets = e.target.querySelectorAll('input, textarea');

    const err = Array.from(targets).findIndex(inp => {
      if (!inp.value) {
        alertErr.fire({
          text: 'Нужно заполнить все поля!',
        })
        return inp.dataset.name
      }
      return null
    })

    if (err === -1) {
      Array.from(targets).forEach(inp => {
        if (inp.dataset.name === 'calendar-pay') object.payment.push(inp.value)
        if (inp.dataset.name === 'price') object.price.push(inp.value)
        if (inp.dataset.name.replace(/\d|(-)/g, '') === 'firma') {
          const firma = firmas.filter(f => f.id === inp.dataset.name.replace(/\D/g, ''))
          object.firmaRequisites = firma[0].data
          object.firmaName = firma[0].name
          object.firmaInFace = firma[0].face
          object.firmaFooting = firma[0].footing
          object.sing = firma[0].sing
        }
        if (inp.dataset.name.replace(/\d|(-)/g, '') === 'client') {
          const client = clients.filter(c => c.id === inp.dataset.name.replace(/\D/g, ''))
          object.clientFooting = client[0].footing
          object.clientInFace = client[0].face
          object.clientName = client[0].name
          object.requisites = client[0].data + '\r\n' + client[0].email + '\r\n' + client[0].phone
          object.client_id = client[0].id;
        }
        if (inp.dataset.name === 'date') object.date = inp.value
        if (inp.dataset.name === 'manager') object.manager = inp.value
        if (inp.dataset.name === 'domain') object.domain = inp.value
        if (inp.dataset.name === 'numproduct') object.numproduct = inp.value
        if (inp.dataset.name === 'srokday') object.srokday = inp.value
        if (inp.dataset.name === 'hosting') object.hostingMonth = parseInt(inp.value.replace(/\D/g, ''), 10)

        if (inp.dataset.id === 'contract') {
          object.servicesName = inp.dataset.name
          object.services = inp.value
        }
      })
      setDataPayment(object);
      dispatch(createContractsDetails(object))
    }

  }, [authName, clients, dispatch, firmas])

  useEffect(() => {
    async function sendRequest() {
      const id = await contractsDetails.map((obj) => obj.contractNum).pop()
      const num = await id ? +id + 1 : 1
      await createPDF(targetForm, num)
    }

    if (targetForm) sendRequest();
  }, [targetForm, contractsDetails, createPDF]);

  return (
    <section className="pages">
      <Container>
        <h1>Создать договор</h1>
      </Container>
      <Container className="edit-box">
        <Form onSubmit={(e) => send(e)}>
          <Row>
            <Col lg="2" md="2" className="mb-3">
              <div className="input-style">
                <label>Дата</label>
                <input readOnly autoComplete="off" type="text" data-name="date" ref={calendar} />
              </div>
            </Col>
            <Col lg="4" md="6" className="mb-3">
              <label className={styles.label} htmlFor="clients">Клиент</label>
              <SelectSearch id="clients" search={true} options={clientName} onChange={newPDF} />
            </Col>
            <Col lg="2" md="2" className="mb-3">
              <div className="input-style">
                <label>Фирма</label>
                <SelectSearch options={firmaName} onChange={newPDF} />
              </div>
            </Col>
            <Col lg="2" md="2" className="mb-3">
              <div className="input-style">
                <label>Чья продажа</label>
                <SelectSearch onChange={newPDF} options={[{ name: 'manager', value: "" }, ...managers]} />
              </div>
            </Col>
            <Col lg="6" md="6" className="mb-3">
              <label className={styles.label} htmlFor="contract">Предмет договора</label>
              <SelectSearch id="contract" options={[
                { name: 'contextG', value: '«Google Ads»' },
                { name: 'contextY', value: '«Яндекс.Директ»' },
                { name: 'contextAll', value: '«Google Ads» и «Яндекс.Директ»' },
                { name: 'site', value: '«Создание сайта»' },
                { name: 'addProduct', value: '«Услуги по наполнение сайта товарными позициями»' },
                { name: 'devSiteDesign', value: '«Создание дизайна и(или) верстки сайта»' },
                { name: 'map', value: '«Google и Яндекс карты»' },
                { name: 'contextServices', value: '«Обслуживанию контекстной рекламы»' },
                { name: 'siteModification', value: '«Доработка сайта»' },
                { name: 'hostingServices', value: '«Хостинг»' },
                { name: 'instagramAccount', value: '«Таргетированной рекламы и оформление Аккаунта Instagram»' },
                { name: 'instagramAccountDesign', value: '«Оформление Аккаунта Instagram»' },
                { name: 'target', value: '«Услуги по размещению таргетированной рекламы»' },
                { name: 'seo', value: '«SEO»', },
                { name: 'complexMaint', value: '«Услуги по комплексному обслуживанию»', },
                { name: 'programming', value: '«Услуги в области программирования»', },
              ]} onChange={(e) => setSelectContract(e.name)} />
            </Col>
            <Col lg="2" md="2" className="mb-3">
              <label className={styles.label} htmlFor="paymentMethod">Способ оплаты</label>
              <SelectSearch clear={isClear} id="paymentMethod" options={paymentMethodSelectArray} onChange={selectPaymentMethod} />
            </Col>

            {selectContract === "seo" || selectContract === "siteModification" || selectContract === "complexMaint" || selectContract === "addProduct" ?
              <Col lg="2" md="2" className="mb-3">
                <div className="input-style">
                  <label>Домен(ы)</label>
                  <input autoComplete="off" type="text" data-name="domain" />
                </div>
              </Col>
              :
              <></>
            }
            {selectContract === "addProduct" ?
              <Col lg="1" md="1" className="mb-3">
                <div className="input-style">
                  <label>Кол-во</label>
                  <input autoComplete="off" type="number" data-name="numproduct" />
                </div>
              </Col>
              :
              <></>
            }
            {selectContract === "addProduct" ?
              <Col lg="1" md="1" className="mb-3">
                <div className="input-style">
                  <label>Срок</label>
                  <input autoComplete="off" type="number" data-name="srokday" />
                </div>
              </Col>
              :
              <></>
            }
            <Row>
              {paymentMethodSelected === "payment" &&
                <>
                  <Col lg="2" md="2" className="mb-3 d-flex align-items-center">
                    <span>Количество платежей {paymentMethod.length}</span>
                  </Col>
                  <Col lg="1" md="1" className="mb-3">
                    <Button style={style.buttonMin} type="button" variant="secondary" onClick={addPaymentFields}>+</Button>
                  </Col>
                </>
              }

              {paymentMethod.map((el, i) =>
                <Row key={el.id}>
                  <Col lg="1" md="1" className="mb-3">
                    <div className="input-style">
                      <label>Сумма</label>
                      <input autoComplete="off" type="number" data-name="price" step="0.01" />
                    </div>
                  </Col>

                  {(i !== 1 || paymentMethodSelected === "payment") && (paymentMethodSelected !== "hosting") ?
                    <Col lg="2" md="2" className="mb-3">
                      <div className="input-style">
                        <label>Дата</label>
                        <Input name={'calendar-pay'} className={'calendar-' + el.id} calendarOptions={calendarOptions} />
                      </div>
                    </Col>
                    : ''}

                  {paymentMethodSelected === "payment" &&
                    <Col lg="1" md="1" className="mb-3">
                      <div className="input-style">
                        <label>Убрать</label>
                        <Button style={style.buttonMin} type="button" variant="danger" onClick={() => removePaymentFields(el.id)}>-</Button>
                      </div>
                    </Col>
                  }
                </Row>
              )}
            </Row>
            <Col lg="12" md="12" className="mb-3">
              {paymentMethod.length > 0 &&
                <>
                  {
                    dataPayment ?
                      <PDFDownloadLink document=
                        {selectContract === 'contextG' || selectContract === 'contextY' || selectContract === 'contextAll' ? (
                          <PDFComponentContractContext dataPayment={dataPayment} />
                        ) : selectContract === 'site' ? (
                          <PDFComponentContractSite dataPayment={dataPayment} />
                        ) : selectContract === 'map' ? (
                          <PDFComponentContractMap dataPayment={dataPayment} />
                        ) : selectContract === 'contextServices' ? (
                          <PDFComponentContractContextService dataPayment={dataPayment} />
                        ) : selectContract === 'siteModification' ? (
                          <PDFComponentContractSiteModification dataPayment={dataPayment} />
                        ) : selectContract === 'hostingServices' ? (
                          <PDFComponentContractHostingServices dataPayment={dataPayment} />
                        ) : selectContract === 'instagramAccount' ? (
                          <PDFComponentContractInstagramAccount dataPayment={dataPayment} />
                        ) : selectContract === 'instagramAccountDesign' ? (
                          <PDFComponentContractInstagramAccountDesign dataPayment={dataPayment} />
                        ) : selectContract === 'seo' ? (
                          <PDFComponentContractSEO dataPayment={dataPayment} />
                        ) : selectContract === 'target' ? (
                          <PDFComponentContractTargetedAdvertising dataPayment={dataPayment} />
                        ) : selectContract === 'devSiteDesign' ? (
                          <PDFComponentContractDevSiteDesign dataPayment={dataPayment} />
                        ) : selectContract === 'complexMaint' ? (
                          <PDFComponentContractComplexMaint dataPayment={dataPayment} />
                        ) : selectContract === 'addProduct' ? (
                          <PDFComponentContractAddProduct dataPayment={dataPayment} />
                        ) : selectContract === 'programming' ? (
                          <PDFComponentContractProgramming dataPayment={dataPayment} />
                        ) : ''}
                        fileName={"договор - " + dataPayment.clientName + ' - ' + dataPayment.date + '.pdf'}>
                        {({ loading }) => (loading ? <Button className="buttonAnimation" style={style.buttonSend} type="submit"><img style={style.img} src={load} alt="" />Загрузка.....</Button> : <Button onClick={newPDF} style={style.buttonSend} variant="success"><img style={style.img} src={loadOk} alt="" />Скачать</Button>)}
                      </PDFDownloadLink>
                      : <Button key={'but-create'} style={style.buttonSend} type="submit"><img style={style.img} src={load} alt="" />Создать Договор</Button>
                  }
                </>
              }
            </Col>

          </Row>
        </Form>
      </Container>
    </section >
  );
};

export default Contracts;