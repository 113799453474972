import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr } from '../components/UI/alert'

export const fetchParishes = createAsyncThunk(
  'parishes/fetchParishes',
  async function (_, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/parishes/get`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return Object.values(data)
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const fetchParishesAll = createAsyncThunk(
  'parishes/fetchParishesAll',
  async function (_, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/parishes/getall`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return Object.values(data)
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const createParishes = createAsyncThunk(
  'parishes/createParishes',
  async function (formData, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/parishes/create`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()
      dispatch(createAddParishes(data.data));
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось создать запись. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const editParishes = createAsyncThunk(
  'parishes/editParishes',
  async function ({ id, formData }, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/parishes/edit/${id}`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()
      dispatch(editFieldParishes(data.data));
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось изменить поле. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const filterParishes = createAsyncThunk(
  'parishes/filterParishes',
  async function (object, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/filter/`, {
        method: 'POST',
        body: JSON.stringify(object)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');

      const data = await response.json()

      return Object.values(data)
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось изменить поле. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const parishesSlice = createSlice({
  name: 'parishes',
  initialState: {
    parishes: [],
    parishesAll: [],
    status: null,
    error: null,
  },
  reducers: {
    createAddParishes(state, action) {
      state.parishes.push(action.payload)
    },
    editFieldParishes(state, action) {
      state.parishes.forEach((el, i) => {
        if (el.id === action.payload.id) state.parishes.splice(i, 1, action.payload)
      });
    },
    // filterAddParishes(state, action){
    //   state.parishes = action.payload
    //   // console.log(JSON.parse(JSON.stringify(state.parishes)));
    // }
  },
  extraReducers: {
    [fetchParishes.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchParishesAll.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [createParishes.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editParishes.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [filterParishes.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [filterParishes.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.parishes = action.payload
    },
    [fetchParishes.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.parishes = action.payload
    },
    [fetchParishesAll.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.parishesAll = action.payload
    },
    [fetchParishes.rejected]: setError,
    [fetchParishesAll.rejected]: setError,
    [createParishes.rejected]: setError,
    [editParishes.rejected]: setError,
    [filterParishes.rejected]: setError,
  }
})
const { createAddParishes, editFieldParishes } = parishesSlice.actions
export default parishesSlice.reducer