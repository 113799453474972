import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import { rubles } from "rubles";
import logo from '../../images/logo.png'
import { toFixed, thisDateYers } from "../functions";

import PDFFooterSingFull from "./PDFFooterSingFull"
import PDFFooterSing from "./PDFFooterSing"

function PDFComponentContractInstagramAccount({ dataPayment }) {
  const found = dataPayment.firmaName.includes("ХитГрупп");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Договор № И-{dataPayment.contractNum}</Text>
          <Text style={[styles.headTitle, styles.mb]}>Услуги таргетированной рекламы и оформление Аккаунта Instagram</Text>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>
          <Text style={styles.text}>{dataPayment.firmaName.replace(/\sRU$/, "")}, именуемый в дальнейшем «Исполнитель», в лице {dataPayment.firmaInFace}, {dataPayment.firmaFooting} с одной стороны, и {dataPayment.clientName}, именуемый в дальнейшем «Заказчик», в лице {dataPayment.clientInFace}, действующего на основании {dataPayment.clientFooting}, с другой стороны, именуемые в дальнейшем Стороны, заключили настоящий Договор (далее Договор) о нижеследующем:</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>1. Предмет договора</Text>

          <Text style={styles.text}>1.1. По заданию Заказчика Исполнитель принимает на себя обязательства оказать услугу по настройке таргетированной рекламы в системе «Facebook» и/или «Instagram», а так же по разработке и оформлению визуала Аккаунта в Instagram Заказчика на основании брифа, заполненного Заказчиком. Услуга оказывается Исполнителем в течение 30 дней, т.к. представляет собой комплекс взаимосвязанных мероприятий, распределенных на протяжении всего периода работ.</Text>
          <Text style={styles.text}>Заказчик обязуется принять и оплатить оказанную услугу.</Text>
          <Text style={styles.text}>Услуга включает в себя:</Text>
          <Text style={styles.text}>1.1.1. Создание таргетированной рекламы:</Text>
          <Text style={styles.text}>— проверка статуса Заказчика в сети «Facebook» на наличие блокировки; </Text>
          <Text style={styles.text}>— анализ спроса по тематике деятельности, указанной Заказчиком;</Text>
          <Text style={styles.text}>— анализ целевого рынка и конкурентов в системе «Facebook» и «Instagram»;</Text>
          <Text style={styles.text}>— создание и заполнение Бизнес страницы (БС) на «Facebook» (при ее отсутствии);</Text>
          <Text style={styles.text}>— создание  Бизнес менеджера (БМ) (при его отсутствии), в том числе привязка к нему  БС  и  аккаунта  «Instagram», платежной  системы (возможна проверка системой «Facebook» до 90 дней);</Text>
          <Text style={styles.text}>— создание Пикселя, настройка событий;</Text>
          <Text style={styles.text}>— создание Рекламного аккаунта (РА) и рекламной компании в AdsManager;</Text>
          <Text style={styles.text}>— проработка и создание целевых аудиторий;</Text>
          <Text style={styles.text}>— выбор цели и стратегии рекламы, площадок размещения (плейсментов);</Text>
          <Text style={styles.text}>— создание рекламных баннеров и составление текстов, на основе информационных материалов, предоставленных заказчиком (если не были согласованы иные условия);</Text>
          <Text style={styles.text}>— запуск рекламных компаний и прохождение модерации;</Text>
          <Text style={styles.text}>— общее количество рекламных компаний - 1, групп объявлений до 3-х. Каждая последующая группа объявлений оценивается дополнительно. Дополнительные работы и услуги оплачиваются Заказчиком отдельно, на основании выставленных счет-фактур, являющихся неотъемлемой частью Договора.</Text>
          <Text style={styles.text}>Результатом вышеперечисленных работ будет являться предложение продукции или услуги Заказчика, представленной в выгодном свете, подобранному кругу лиц, наиболее заинтересованных в её покупке в поисковых системах «Facebook» и «Instagram». С ним будут ознакомлены покупатели из указанных Заказчиком регионов.</Text>
          <Text style={styles.text}>1.1.2. Обслуживание таргетированной рекламы:</Text>
          <Text style={styles.text}>— мониторинг изменения цены клика;</Text>
          <Text style={styles.text}>— мониторинг эффективности объявлений;</Text>
          <Text style={styles.text}>— мониторинг эффективности таргетированных настроек;</Text>
          <Text style={styles.text}>— мониторинг эффективности выбранной целевой аудитории;</Text>
          <Text style={styles.text}>— мониторинг эффективности площадок размещения рекламных материалов;</Text>
          <Text style={styles.text}>— мониторинг бюджета рекламной компании.</Text>
          <Text style={styles.text}>1.1.3. Разработка и оформление визуала Аккаунта в Instagram:</Text>
          <Text style={styles.text}>— анализ текущего Аккаунта Заказчика (если Аккаунт уже создан);</Text>
          <Text style={styles.text}>— оформление Аккаунта (подбор логотипа/аватара, разработка логотипа дизайнером, составление оптимального названия и имени Аккаунта, создание ёмкого описания в шапке профиля Аккаунта);</Text>
          <Text style={styles.text}>— изучение и анализ Аккаунтов основных конкурентов Заказчика;</Text>
          <Text style={styles.text}>— разработка дизайнером единого стиля в оформлении Аккаунта;</Text>
          <Text style={styles.text}>— полное ежедневное сопровождение Аккаунта: публикации до 12 за весь отчетный период, с постепенной загрузкой в Аккаунт, сторис; использование таких инструментов привлечения внимания у ЦА как сториз,  хештеги, оформление и размещение важной информации в актуальном (вечные сториз) до 6 штук;</Text>
          <Text style={styles.text}>— подбор и/или создание подходящего визуального (фото, видео) и уникального текстового контента, разработка тематических рубрик в соответствии с тем либо иным видом размещаемого контента (развлекательный, экспертный, репутационный, продающий, обучающий и пр.).</Text>

          <Text style={styles.text}>В случае необходимости дальнейшего обслуживания рекламных кампаний, Аккаунта по истечении срока оказания услуги указанной в п.1.1, между Исполнителем и Заказчиком заключается дополнительное соглашение. В Приложении №1 указан глоссарий специализированных терминов данной тематики, которые могут использоваться в данном Договоре и любых приложениях или в дополнениях к нему.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>2. Срок действия договора</Text>

          <Text style={styles.text}>2.1. Договор вступает в силу с момента его подписания Сторонами и действует до 31.12.{thisDateYers()} г., а в части взаиморасчетов до исполнения сторонами своих обязательств. Договор считается пролонгированным на следующий календарный год, если ни одна из сторон договора не заявила расторжении договора за один месяц до окончания срока договора.</Text>
          <Text style={styles.text}>2.2. Досрочное расторжение Договора возможно только по взаимному согласию Сторон.</Text>
          <Text style={styles.text}>2.3. Заказчик безоговорочно соглашается с тем, что в случае одностороннего отказа от настоящего Договора по собственной инициативе либо в случае одностороннего отказа по инициативе Исполнителя, вызванного нарушением Заказчиком условий настоящего Договора или действующего законодательства, возврат денежных средств, уплаченных Заказчиком в счет оплаты/предоплаты услуг, не производится, даже в случае, если срок оказания оплаченных услуг не окончен. В этом случае договор считается исполненным на последний день месяца, в котором поступила оплата/предоплата либо односторонний отказ от настоящего договора Исполнителя, либо Заказчика.</Text>
          <Text style={styles.text}>2.4. Сумма исковых претензий не может превышать половины стоимости настоящего Договора.</Text>
          <Text style={styles.text}>2.5. Все изменения и дополнения настоящего Договора оформляются в виде дополнительных соглашений, которые подписываются сторонами и являются неотъемлемой часть Договора.</Text>
          <Text style={styles.text}>2.6.  Настоящий Договор составлен в двух экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру на каждую из сторон.</Text>
          <Text style={styles.text}>2.7. Стороны признают юридическую силу за электронными письмами – документами, направленными по электронной почте (e-mail), и признают их равнозначными документами на бумажных носителях, подписанным собственноручной подписью, т.к. только сами Стороны и уполномоченные ими лица имеют доступ к соответствующим адресам электронной почты, указанным в Договоре в реквизитах Сторон и являющимся электронной подписью соответствующей Стороны. Доступ к электронной почте каждая Сторона осуществляет по паролю и обязуется сохранять его конфиденциальность.</Text>
          <Text style={styles.text}>2.8. В случае если подписанный договор не возвращен Исполнителю, то фактом подписания договора считается оплата Заказчиком услуг. Оплата услуг свидетельствует о том, что Заказчик ознакомлен с условиями договора и полностью их принимает.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>3. Права и обязанности сторон</Text>

          <Text style={styles.text}>3.1. Исполнитель обязан:</Text>
          <Text style={styles.text}>3.1.1. Оказывать Услуги по настройке таргетированной рекламы в сети «Facebook», оформлению Аккаунта в соответствии с п.1.1 настоящего Договора, проводить консультации по вопросам, возникающим у Заказчика в связи с оказанием ему услуги путем телефонных переговоров или переписки по электронной почте.</Text>
          <Text style={styles.text}>3.1.2. Приступить к оказанию Услуги в течение пяти рабочих дней с момента поступления денежных средств в размере, указанном в п.4 настоящего Договора.</Text>
          <Text style={styles.text}>3.1.3. В течении пяти рабочих дней после окончания отчетного периода представлять Заказчику акт выполненных работ с указанием фактической стоимости оказанной Услуги за прошедший отчетный период.</Text>
          <Text style={styles.text}>3.1.4. Незамедлительно уведомлять Заказчика обо всех обстоятельствах, которые могут повлечь задержку в оказании Услуги.</Text>
          <Text style={styles.text}>3.1.5. Сохранять конфиденциальность условий настоящего Договора, а также информации, полученной от Заказчика в связи с исполнением настоящего Договора, в соответствии с разделом 7 настоящего Договора.</Text>
          <Text style={styles.text}>3.1.6. Гарантировать своевременное и точное исполнение работ, указанных в п.1.1 настоящего Договора, не учитывая время модерации и проверки аккаунта самой системой «Facebook». В противном случае Исполнитель обязан вернуть выплаченные заказчиком средства за отчетный период, не закрытый актом выполненных работ.</Text>
          <Text style={styles.text}>3.1.7. Предоставлять Заказчику информацию, необходимую для оценки результатов работ по настоящему Договору.</Text>
          <Text style={styles.text}>3.1.8. Сообщать Заказчику статистические данные по размещению рекламных материалов на соответствующем Интернет-ресурсе по окончании рекламного периода.</Text>
          <Text style={styles.text}>3.1.9. Оказывать Заказчику услуги по обслуживанию Рекламных кампаний в соответствии с предоставленными Заказчиком требованиями к условиям размещения.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3.2. Исполнитель вправе:</Text>

          <Text style={styles.text}>3.2.1. Требовать от Заказчика предоставления необходимой информации для надлежащего оказания Услуги, в том числе для составления объявлений, оформления Аккаунта в Instagram.</Text>
          <Text style={styles.text}>3.2.2. В случае нарушения Заказчиком срока и/или размера оплаты стоимости Услуги, указанной в п.4 настоящего Договора, Исполнитель вправе требовать компенсации убытков, понесенных им, в результате этого нарушения.</Text>
          <Text style={styles.text}>3.2.3. Самостоятельно определять состав персонала, обеспечивающего надлежащее оказание Услуги, при необходимости привлекать третьих лиц.</Text>
          <Text style={styles.text}>3.2.4. Не предоставлять гарантию Заказчику на какое-либо конкретное количество звонков поступаемых к Заказчику от возможных покупателей. Ввиду того, что, обращаясь к опубликованному объявлению Заказчика, покупатель принимает решение о звонке, основываясь на выгодности этого предложения для него. Исполнитель не может влиять на цену и качество продаваемого Заказчиком товара или услуги, а, следовательно, и на выгодность предложения Заказчика.</Text>
          <Text style={styles.text}>3.2.5. Не предоставлять гарантию Заказчику на количество продаж, совершаемых Заказчиком возможным покупателям, привлеченным Исполнителем, в рамках работ, определенных в п.1.1 настоящего Договора. Количество продаж определяется профессиональным уровнем персонала Заказчика. Исполнитель не может влиять на профессиональный уровень персонала Заказчика, следовательно, и на количество продаж.</Text>
          <Text style={styles.text}>3.2.6. Если Аккаунт Заказчика на момент подписания Договора был подключён к сервисам по накрутке лайков и/или подписчиков, Заказчик должен проинформировать об этом Исполнителя, а затем удалить свой Аккаунт из подобных сервисов.</Text>
          <Text style={styles.text}>3.2.7. Аккаунт может модифицироваться по усмотрению Исполнителя для достижения цели согласно Договора. Исполнитель вправе не приступать к оказанию услуг или приостановить начатый процесс оказания услуг в случаях, когда Заказчик препятствует оказанию услуг (изменил пароль и не предупредил Исполнителя, нарушает законодательство РБ, а также права и обязанности, указанные в настоящем Договоре, использует сервисы по накрутке лайков и/или подписчиков).</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3.3. Заказчик обязан:</Text>

          <Text style={styles.text}>3.3.1. Выделить ответственного сотрудника для оперативного взаимодействия с сотрудником Исполнителя.</Text>
          <Text style={styles.text}>3.3.2. Предоставить для деловой переписки, в рамках настоящего Договора, электронный адрес, телефон достоверно принадлежащий Заказчику, и указать его в реквизитах настоящего Договора.</Text>
          <Text style={styles.text}>3.3.3. Предоставлять Исполнителю информацию, необходимую для оказания Исполнителем Услуги.</Text>
          <Text style={styles.text}>3.3.4. Оплатить Услугу в сроки и в порядке, установленные настоящим Договором. После поступления денежных средств на расчетный счет Исполнителя, Заказчик не вправе отменять исполнение услуги.</Text>
          <Text style={styles.text}>3.3.5. В течение 3-х рабочих дней после предоставления Исполнителем Акта выполненных работ, подписать его, либо направить мотивированную претензию по Услуге в тот же срок. В случае просрочки Заказчиком сроков подписания акта или не предоставления претензий со стороны Заказчика к качеству выполненных Исполнителем работ, услуга считается оказанной надлежащим образом и принятой Заказчиком в полном объеме.</Text>
          <Text style={styles.text}>3.3.6. Предоставить Исполнителю свои требования к условиям размещения рекламных материалов в рекламной системе.</Text>
          <Text style={styles.text}>3.3.7. Ни при каких условиях не совершать никаких действий (смена пароля, проставление лайков, подписки, отписки, самостоятельные публикации постов и т.д.) от имени Аккаунта без согласования с Исполнителем в течение всего срока действия настоящего Договора.</Text>
          <Text style={styles.text}>3.3.8. В случае такой необходимости и по требованию Исполнителя своевременно предоставлять Исполнителю фотоматериалы, видеоматериалы и/или иную информацию по рекламируемым товарам/услугам.</Text>
          <Text style={styles.text}>3.3.9. Изменить пароль Аккаунта после окончания оказания услуг по продвижению Аккаунта в Instagram, а также уволить Исполнителя из БМ.</Text>
          <Text style={styles.text}>3.3.10. Принять по окончании отчетного периода услуги, подписать и передать Исполнителю Акт выполненных работ.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3.4. Заказчик вправе:</Text>

          <Text style={styles.text}>3.4.1. Проверять ход и качество работы, выполняемой Исполнителем, не вмешиваясь в его деятельность.</Text>
          <Text style={styles.text}>3.4.2. Предоставлять Исполнителю рекомендации по оказанию услуги, необходимые для повышения эффективности рекламной кампании.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>4. Порядок расчетов</Text>

          {/* <Text style={styles.text}>4.1. Оплата услуг указанных в п.1.1. настоящего Договора осуществляется Заказчиком следующим образом:</Text> */}

          {dataPayment.price.length === 1 &&
            <>
              <Text style={styles.text}>4.1. Стоимость услуг указанных в п.1.1. составляет
                {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                {dataPayment.price.reduce((akk, p) => +akk + +p)} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
              {

                dataPayment.price.map((p, i) =>
                  <Text key={i} style={styles.text}>{i + 1} Оплата {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)})  до {dataPayment.payment[i]} года</Text>
                )
              }
            </>
          }
          {dataPayment.price.length >= 2 && dataPayment.payment.length >= 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>4.1. Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) до {dataPayment.payment[i]} года</Text>
                  )}
                </>
              }
            </>
          }
          {dataPayment.price.length === 2 && dataPayment.payment.length !== 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>4.1. Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {i === 1 && 'Оплата в размере '} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) {i === 1 ? 'по завершению работ, что подтверждается актом выполненных работ и оплата производится в течение 5-ти банковских дней с момента подписания акта.' : 'до ' + dataPayment.payment[i] + ' года'}  </Text>
                  )}
                </>
              }
            </>
          }


          <Text style={styles.text}>Проценты на предоплату не начисляются. Предоплата не возвращается в случае, если работы, заявленные п.1.1, выполнены в полном объеме. </Text>
          <Text style={styles.text}>4.2. Днем оплаты считается день поступления денежных средств на расчетный счет Исполнителя.</Text>
          <Text style={styles.text}>4.3. Дополнительные услуги Исполнителя, их стоимость, а также любые изменения в ранее согласованных и утвержденных этапах работ для Заказчика согласуются отдельно. Дополнительные работы и услуги, оплачиваются Заказчиком отдельно, на основании выставленных счет-фактур, являющихся неотъемлемой частью Договора.</Text>
          <Text style={styles.text}>4.4. Все расчеты по Договору осуществляются в рублях без НДС.</Text>
          <Text style={styles.text}>4.5. Оплата счета Заказчиком означает согласие Заказчика с условиями настоящего договора.</Text>
          <Text style={styles.text}>4.6. В случае немотивированного отказа или уклонения Заказчика от подписания Акта, услуги считаются принятыми без возражений и замечаний по истечении 3 (трех) рабочих дней с момента выставления Акта.</Text>
          <Text style={styles.text}>4.7. В случае несвоевременной оплаты Заказчиком оказанных Исполнителем услуг (выполненных работ) в соответствии с п.4.1. настоящего Договора Исполнитель вправе взыскать с Заказчика пеню в размере 0,5% (ноль целых пять десятых процента) от стоимости услуги (выполненных работ) за каждый день просрочки.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>5. Порядок сдачи-приемки услуг </Text>

          <Text style={styles.text}>5.1. Исполнитель после окончания оказания рекламных услуг, указанных в счет – фактуре, подтверждает Актом об оказании услуг, который направляется Исполнителем Заказчику посредством электронных каналов связи в виде сканированной копии с подписью и печатью Исполнителя. В случае необходимости Исполнитель обязан выслать Заказчику оригинал Акта об оказанных услугах в течение 3 (трех) рабочих дней после получения соответствующего требования от Заказчика.</Text>
          <Text style={styles.text}>5.2. При направлении Исполнителем Акта об оказанных услугах Заказчик обязуется рассмотреть Акт, подписать его и направить Исполнителю в течение 3 (трех) рабочих дней либо в тот же срок предоставить мотивированные возражения от подписания Акта.</Text>
          <Text style={styles.text}>5.3. Заказчик безоговорочно соглашается с тем, что если он в течение 3 (трех) рабочих дней с момента получения Акта об оказании услуг не заявит каких-либо письменных претензий по оказанным Исполнителем услугам, то это будет рассматриваться как однозначное согласие Заказчика с тем, что услуги оказаны Исполнителем своевременно, в полном объеме и надлежащим образом.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>6. Особые условия и ответственность Сторон</Text>

          <Text style={styles.text}>6.1. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору Стороны несут ответственность в соответствии с действующим законодательством РБ и настоящим Договором.</Text>
          <Text style={styles.text}>6.2. Исполнитель не гарантирует Заказчику достижение каких-либо показателей в хозяйственной деятельности Заказчика и/или изменения его деловой репутации в связи с оказанием услуг по Договору. </Text>
          <Text style={styles.text}>6.3. Если Заказчик не предоставил Исполнителю свои требования к условиям размещения рекламных материалов в системе «Facebook» и «Instagram», а так же по оформлению Аккаунта условия размещения определяет Исполнитель исходя из своего опыта. При этом Заказчик лишается права предъявлять претензии Исполнителю за выбранные условия размещения. </Text>
          <Text style={styles.text}>6.4. Заказчик самостоятельно в полном объеме несет ответственность за соответствие содержания и формы рекламных материалов требованиям законодательства, юридическую правомерность использования логотипов, фирменных наименований и прочих объектов интеллектуальной собственности и средств индивидуализации в рекламных материалах, а равно за отсутствие в рекламных материалах обязательной информации, предусмотренной законодательством РБ. </Text>
          <Text style={styles.text}>6.5. В случае если содержание и форма рекламных материалов Заказчика не соответствует требованиям к рекламным материалам «Facebook» и «Instagram», запуск рекламной кампании откладывается до момента предоставления Заказчиком рекламных материалов, соответствующих требованиям, предъявляемым к рекламным материалам «Facebook» и «Instagram».</Text>
          <Text style={styles.text}>6.6. В случае если размещение рекламных материалов по Договору явилось основанием для предъявления к Исполнителю претензий, исков и/или предписаний по уплате штрафных санкций со стороны государственных органов и/или третьих лиц, Заказчик обязуется незамедлительно, по требованию Исполнителя, предоставить ему всю запрашиваемую информацию, касающуюся размещения и содержания рекламных материалов, содействовать Исполнителю в урегулировании таких претензий и исков, а также возместить все убытки (включая судебные расходы, расходы по уплате штрафов), причиненные Исполнителю вследствие предъявления ему таких претензий, исков, предписаний в связи с нарушением прав третьих лиц и/или действующего законодательства РБ в результате размещения материалов. </Text>
          <Text style={styles.text}>6.7. В случае изменения условий «Facebook» оказания услуг «AdsManager», Стороны вносят соответствующие изменения в условия Договора. </Text>
          <Text style={styles.text}>6.8. Заказчик признает, что в целях Договора, в частности для определения количества показов, количества кликов, стоимости услуг, используются исключительно данные статистики «Facebook», формируемой по результатам обработки запросов пользователей. </Text>
          <Text style={styles.text}>6.9. Все спорные вопросы разрешаются путем переговоров, а при невозможности разрешения ― в Экономическом суде г. Гомеля. Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй Стороне. Претензия предъявлена надлежащим образом, если направлена другой Стороне любым способом, позволяющим установить личность отправителя и факт вручения корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй Стороны, не является обязательным.</Text>
          <Text style={styles.text}>Сторона, получившая претензию, в течение 10 (десяти) рабочих дней со дня ее получения письменно уведомляет вторую Сторону о результатах рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией и изложенными в ней обстоятельствами.</Text>
          <Text style={styles.text}>6.10. Исполнитель не несет ответственности за какую-либо часть услуг, выполняемых Заказчиком самостоятельно, а также за какой-либо ущерб, причиненный Заказчику, явившийся следствием нарушения условий Договора Заказчиком. </Text>
          <Text style={styles.text}>6.11. Исполнитель не несёт ответственность за технические сбои социальной сети Instagram, за действия (в том числе бездействие) пользователей социальной сети Instagram, владельцев пабликов, а также любых третьих лиц.</Text>
          <Text style={styles.text}>6.12. В случае если Аккаунт был взломан либо проводились действия со стороны Заказчика, повлекшие за собой утерю Аккаунта, Исполнитель не несет никакой материальной, моральной или иной ответственности и не возмещает Заказчику никаких убытков или упущенную выгоду.</Text>
          <Text style={styles.text}>6.13. Заказчик признаётся единственным владельцем Аккаунта в Instagram и несёт всю ответственность за содержание Аккаунта, соблюдение законодательства страны на территории, которой осуществляет свою деятельность  при продаже товаров, оказании услуг, распространении информации, данных, фотографий, рисунков, изображений и любых иных объектов авторского права.</Text>
          <Text style={styles.text}>6.14. Исполнитель не несет ответственность за информацию, данные, фотографии и иные изображения, предоставленные Заказчиком для постинга в Аккаунте, а также за услуги и товары Заказчика, указанные или прорекламированные в Аккаунте Instagram.</Text>
          <Text style={styles.text}>6.15. Заказчик уведомлён и соглашается, что поведение живых людей в социальной сети Instagram может быть непредсказуемо, поскольку зависит от множества самых разнообразных факторов: от сферы деятельности Заказчика, от личных убеждений и привычек относительно того, каким образом следует взаимодействовать с другими аккаунтами в Instagram и т.д.</Text>
          <Text style={styles.text}>6.16. Заказчик уведомлён и соглашается, что продвижение в Instagram является одним из методов Интернет-маркетинга, суть которого заключается в привлечении внимания ЦА из конкретной социальной сети Instagram к товарам/услугам Заказчика, при этом ЦА – это пока ещё не клиенты Заказчика, но которые могут ими стать в перспективе.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>7. Конфиденциальность. </Text>

          <Text style={styles.text}>7.1. Любая информация, полученная Сторонами в ходе исполнения Договора, рассматривается, как конфиденциальная и не может быть раскрыта третьим лицам, за исключением запросов уполномоченных государственных органов и других случаев, предусмотренных действующим законодательством РБ. </Text>
          <Text style={styles.text}>7.2. Конфиденциальная информация всегда остается собственностью передающей эту информацию Стороны и не должна воспроизводиться без предварительного письменного согласия такой передающей Стороны. </Text>
          <Text style={styles.text}>7.3. Обязательство сохранять в тайне Конфиденциальную Информацию в соответствии с условиями настоящей статьи вступает в силу с момента подписания Договора обеими Сторонами и остается в силе в течение 3 (трех) лет по окончании срока действия Договора или его прекращения действия по какой-либо причине. </Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>8. Обстоятельства непреодолимой силы.</Text>

          <Text style={styles.text}>8.1 Ни одна из Сторон не несет ответственности за полное или частичное неисполнение обязательств по настоящему Договору в случае пожара, наводнения, землетрясения и других стихийных бедствий, возникших после заключения настоящего Договора, а также обстоятельств неопределимой силы в результате событий чрезвычайного характера, которые Сторона не могла ни предвидеть, ни предотвратить разумными методами (форс-мажорные обстоятельства).</Text>
          <Text style={styles.text}>8.2. В случае наступления обстоятельств непреодолимой силы срок исполнения Сторонами своих обязанностей по Договору отодвигается соразмерно времени, в течение которого действуют обстоятельства непреодолимой силы или их последствия. В случае если события непреодолимой силы превышают 2 (два) месяца, любая из Сторон вправе в одностороннем порядке отказаться от исполнения Договора. В этом случае стороны производят окончательные взаиморасчеты и ни одна из Сторон не вправе требовать от другой Стороны возмещения убытков</Text>

          <PDFFooterSingFull data={dataPayment} />
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <Text style={[styles.headTitle, styles.mt]}>Приложение № 1</Text>
          <Text style={styles.headTitle}>к договору № И-{dataPayment.contractNum}</Text>

          <View style={[styles.row]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>

          <View style={[styles.row]}>
            <View><Text style={styles.text}>Исполнитель: {dataPayment.firmaName.replace(/\sRU$/, "")}</Text>
              <Text style={[styles.text]}>Заказчик: {dataPayment.clientName}</Text></View>
          </View>

          <Text style={[styles.headTitle2, styles.mb]}>Глоссарий</Text>


          <Text style={styles.text}><Text style={styles.textBold}>Пользователь </Text>– посетитель информационных ресурсов в сети Интернет.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Сеть Интернет </Text>- всемирная глобальная компьютерная сеть общего доступа. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Интернет-ресурс </Text>– совокупность интегрированных программно-аппаратных средств и информации, предназначенной для публикации в сети Интернет и отображаемой в определенной текстовой, графической или звуковой формах. Интернет-ресурс доступен для пользователей сети Интернет посредством доменного имени - уникального электронного адреса, позволяющего осуществлять доступ к информации и программно-аппаратному комплексу. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Интернет-страница </Text>- страница (HTML-документ) Интернет-ресурса. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Рекламные материалы </Text>– текстовая, графическая, аудио или видеоинформация рекламного характера на интернет-странице, содержащие информацию об объекте рекламирования, которая предназначена для неопределенного круга лиц и призвана формировать или поддерживать интерес к товарам (работам, услугам) Заказчика и способствовать реализации этих товаров (работ, услуг). </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Размещение рекламных материалов </Text>- техническое размещение рекламных материалов Заказчика в системах таргетированной рекламы Facebook, Instagram посредством сервиса управления рекламой в указанных системах. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Переход </Text>– перенаправление HTTP-запроса из браузера пользователя сети Интернет с рекламного материала, разработанного Заказчиком и размещенного с использованием Сервиса управления рекламой, по гиперссылке, указанной Заказчиком, произошедшее после клика (нажатия) на рекламный материал Заказчика. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Бриф </Text>– документ, разработанный Исполнителем, который заполняется Заказчиком. Бриф, заполненный Заказчиком и согласованный Сторонами, является обязательным для начала оказания услуг по Договору. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Таргетированная реклама </Text>- вид рекламы, основанный на показе рекламного предложения только пользователям, удовлетворяющим заданным критериям (целевой аудитории).</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Таргетинг </Text>- механизм, позволяющий выделить из всей аудитории группу пользователей интернет-страницы, которая удовлетворяет заданным критериям (возраст, пол, место и др. критерии), и показывать информацию только ей. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Аккаунт </Text>– учетная запись, созданная Исполнителем, содержащая сведения, необходимые для идентификации Заказчика при подключении к сервису управления рекламой рекламных систем (индивидуальный номер, имя пользователя и пароль), и позволяющая Заказчику самостоятельно осуществлять управление процессом размещения рекламных материалов Заказчика (в случае самостоятельного ведения Рекламных кампаний), знакомиться с данными статистики по оказанным услугам, а также контролировать состояние своего лицевого счета. </Text>
          <Text style={styles.text}><Text style={styles.textBold}>Плейсмент </Text>– это место размещения рекламы, запущенной в Ads Manager. Все плейсменты, доступные в настройках кампании, относятся к четырем платформам: Facebook, Instagram, Audience Network или Messenger.  Плейсмент определяет не только то, где будут показываться объявления, но и формат рекламы.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Магазин </Text>- страница Интернет-ресурса, содержащая товарные предложения Заказчика в виде текстовых, графических и/или смешанных материалов информационного характера, которые могут включать изображения, иную информацию (наименование товара, наименование магазина Заказчика, описание (характеристику) товара Заказчика и др.), ссылку, переадресующую пользователя сети к информационному ресурсу (сайту) в сети Интернет (сайт Заказчика/интернет-магазин Заказчика), адрес (URL) которого указан Заказчиком.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Рекламное объявление </Text>– рекламный текстовый блок, содержащий рекламную информацию и ссылку, предоставленные Рекламодателем в рамках определенной рекламной кампании.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Рекламная кампания </Text>– совокупность заказанных Заказчиком рекламных объявлений, в отношении которых осуществляется оказание Услуги в соответствии с определенными Заказчиком условиями размещения. Каждая Рекламная кампания может содержать одно или несколько рекламных объявлений.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Рекламный бюджет </Text>- бюджет рекламного аккаунта (в валюте аккаунта), используемый для размещения рекламных материалов Заказчика в системах таргетированной рекламы.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Показ </Text>– размещение/отображение рекламного объявления в специально отведенной для показа рекламных объявлений области, которое отвечает таргетированным настройкам, указанных Заказчиком в соответствующей рекламной кампании в качестве критерия Показа для данного рекламного объявления.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Клик </Text>– обращение пользователя по содержащейся в Рекламном объявлении Ссылке после показа такому пользователю этого Рекламного объявления.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Социальная сеть</Text> — платформа, онлайн-сервис и веб-сайт, предназначенные для построения, отражения и организации социальных взаимоотношений в Интернете.</Text>
          <Text style={styles.text}><Text style={styles.textBold}></Text>Instagram – приложение для обмена фотографиями и видеозаписями с элементами социальной сети, позволяющее снимать и оценивать фотографии, видео, текстовое содержание постов, а
            также распространять их через свой сервис и ряд других социальных сетей.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Аккаунт</Text> — это учётная запись пользователя, которая содержит некоторые сведения о нём. Для однозначной идентификации владельца и входа в свой аккаунт используется логин и пароль.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Аккаунт Instagram</Text> - личный кабинет пользователя в Instagram, который включает в себя информацию о владельце аккаунта, размещённые посты, внутренние сообщения, статистику и настройки.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Пост</Text> – запись, отдельно взятое сообщение в блоге, на форуме, в социальной сети (в том числе с использованием изображений или видео).</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Постинг</Text> - процесс размещения постов на каком-либо социальном сервисе или в блоге.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Пользователь</Text> - любое лицо, имеющее аккаунт в Instagram.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Целевая аудитория (ЦА)</Text> – это группа пользователей, на которую направлены некоторые рекламные мероприятия, в которой заинтересованы рекламодатели и/или которая сама заинтересована в какой-либо информации, товаре либо услуге.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Лайк</Text> — это функция в коммуникационном программном обеспечении, которая предназначена для социальных сетей, блогов, форумов. Используется как условное выражение одобрения к тому либо иному контенту.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Подписчики аккаунта Instagram </Text>— это активные пользователи данной социальной сети, которым интересны посты и новости того, на кого они подписаны.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Показы </Text>– количество появлений публикаций и/или историй на экране у пользователей Instagram.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Охват</Text> – количество уникальных аккаунтов в Instagram, которые посмотрели публикацию и/или историю.</Text>
          <Text style={styles.text}><Text style={styles.textBold}>Хештег</Text> - ключевое слово или сочетание слов и символов, используемые в микроблогах и социальных сетях в качестве пометок (тегов) и облегчающие поиск сообщений по теме или содержанию. Начинаются со знака решётки (#).</Text>

          <PDFFooterSing data={dataPayment} />

        </View>
      </Page>
    </Document >
  )
}
export default PDFComponentContractInstagramAccount