import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import { rubles } from "rubles";
import logo from '../../images/logo.png'
import { toFixed, thisDateYers } from "../functions";

import PDFFooterSingFull from "./PDFFooterSingFull"
import PDFFooterSing from "./PDFFooterSing"

function PDFComponentContractSEO({ dataPayment }) {
  const found = dataPayment.firmaName.includes("ХитГрупп");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Договор № Р-{dataPayment.contractNum}</Text>
          <Text style={[styles.headTitle, styles.mb]}>Услуги по оптимизации сайта</Text>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>
          <Text style={styles.text}>{dataPayment.firmaName.replace(/\sRU$/, "")}, именуемый в дальнейшем «Исполнитель», в лице {dataPayment.firmaInFace}, {dataPayment.firmaFooting} с одной стороны, и {dataPayment.clientName}, именуемый в дальнейшем «Заказчик», в лице {dataPayment.clientInFace}, действующего на основании {dataPayment.clientFooting}, с другой стороны, именуемые в дальнейшем Стороны, заключили настоящий Договор (далее Договор) о нижеследующем:</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>1. Предмет договора</Text>

          <Text style={styles.text}>1.1	В соответствии с условиями настоящего договора Исполнитель выполняет по заданию Заказчика Работы и передает их результаты Заказчику, а Заказчик принимает результаты выполненных работ и оплачивает их.</Text>
          <Text style={styles.text}>1.2	Конкретный перечень работ указан в Приложении №1, которое является неотъемлемой частью настоящего Договора.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2.	Права и обязанности сторон</Text>

          <Text style={styles.text}>2.1 Исполнитель обязан:</Text>
          <Text style={styles.text}>2.1.1 Приступить к оказанию услуг в течение 3 (трёх) рабочих дней, следующих за днём поступления первой предоплаты от Заказчика, и получения от Заказчика электронной копии Договора, подписанной с его стороны. Обмен оригинальными экземплярами Договора осуществляется по почте.</Text>
          <Text style={styles.text}>2.1.2 Оказать Услуги в полном объеме, с надлежащим качеством, согласно условиям настоящего Договора и Приложению №1. Отступления по просьбе Заказчика от Договора и Приложения №1, приводящие к уменьшению или увеличению затрат Исполнителя, осуществляются на основании выставленных счетов, являющихся неотъемлемой частью Договора.</Text>
          <Text style={styles.text}>2.1.3 В рамках текущего Договора написать оптимизированные тексты для размещения на страницах Сайта Заказчика. При этом общее количество знаков, содержащееся в оптимизированных текстах, не должно превышать с пробелами 50 000 (пятидесяти тысяч). В случае превышения лимита, указанного в настоящем пункте, равно как и в случае необходимости написания текстов, не связанных с выполнением Услуг, Заказчик оплачивает отдельно Исполнителю данный вид услуг в рамках текущего Договора.</Text>
          <Text style={styles.text}>2.1.4 Приостановить или прекратить оказание услуг, либо расторгнуть настоящий Договор в одностороннем порядке, в случае неисполнения Заказчиком принятых на себя обязательств по данному Договору, в том числе просрочке оплаты Заказчиком услуг по настоящему Договору. Исполнитель уведомляет Заказчика о приостановке или прекращении оказания услуг путем направления сообщения по электронной почте. По истечении 2 (двух) рабочих дней с момента направления Заказчику сообщения о приостановке или прекращении оказания услуг.</Text>
          <Text style={styles.text}>2.1.5 Предоставлять Заказчику право знакомиться с ходом оказания услуг по настоящему Договору при условии предварительного согласования с Исполнителем. Ответы на электронные письма и консультации Заказчика по телефону или на встрече осуществляются в рабочие дни с 9.00 до 17.00.</Text>
          <Text style={styles.text}>2.1.6 По окончании выполнения Работ либо их отдельных этапов предоставляет Заказчику акты выполненных работ.</Text>
          <Text style={styles.text}>2.1.7 В случае, если предоставление данных по доступам к Сайту согласно п.2.2.2.1 невозможно, составить рекомендации и требования для Заказчика для самостоятельного выполнения. Все рекомендации и требования направляются Исполнителем Заказчику по электронной почте.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2.2 Заказчик обязан:</Text>

          <Text style={styles.text}>2.2.1 Своевременно предоставлять и (или) утверждать все материалы, необходимые для выполнения Работ, в течение 3-х рабочих дней с момента поступления заявки от Исполнителя по электронной почте. </Text>
          <Text style={styles.text}>2.2.2 Предоставить Исполнителю следующую информацию: </Text>
          <Text style={styles.text}>2.2.2.1 доступы к Сайту (FTP, CMS, MySQL) и право на внесение необходимых изменений в структуру и содержание Сайта (в том числе, на размещение и изменение текстов на страницах Сайта), доступы к панелям Яндекс.Вебмастер и Google Search Console, доступы к системам статистики Яндекс.Метрика и Google Analytics при их наличии на сайте. </Text>
          <Text style={styles.text}>В случаях изменения паролей доступов к Сайту Заказчик обязуется письменно уведомлять Исполнителя по электронной почте в течение 1 (одного) рабочего дня с даты введения таких изменений. </Text>
          <Text style={styles.text}>В случае, если предоставление данных невозможно, Заказчик самостоятельно обязуется выполнять все рекомендации и требования Исполнителя касательно заполнения Сайта текстами и внесения изменений на Сайт в течение 5 (Пяти) рабочих дней со дня предоставления указанных рекомендаций и требований Исполнителя, если иной срок не согласован сторонами путем заключения письменного дополнительного соглашения к настоящему Договору. Все рекомендации и требования направляются Исполнителем Заказчику по электронной почте. По окончании внесения рекомендаций и требований на Сайт Заказчик уведомляет об этом Исполнителя по электронной почте.</Text>
          <Text style={styles.text}>2.2.2.2 Полный список Сайтов-аффилиатов, Сайтов-зеркал. </Text>
          <Text style={styles.text}>2.2.2.3 Обо всех применявшихся к Сайту санкциях, когда-либо имевших место в случаях нарушения требований со стороны поисковых систем.</Text>
          <Text style={styles.text}>2.2.3 Обеспечивать круглосуточную работоспособность Сайта в Интернете в течение всего срока действия настоящего Договора (своевременно оплачивать хостинг и обслуживание доменного имени Сайта). Факты недоступности Сайта фиксируются через панель вебмастера поисковой системы Яндекс, расположенной по адресу http://webmaster.yandex.ru/wmconsole. В случае временного отсутствия доступа к Сайту, сроки оказания Услуг по Сайту могут быть смещены Исполнителем в одностороннем порядке, о чем Заказчик будет письменно уведомлен. Оплата Услуг в период отсутствия доступов оплачивается согласно условиям оплаты за Отчётный период.</Text>
          <Text style={styles.text}>По взаимному согласию Стороны могут продлить сроки оказания Услуг для решения, возникших в процессе выполнения технических и организационных проблем.</Text>
          <Text style={styles.text}>2.2.4 В случае, если на Сайте установлена система управления стороннего разработчика, качественно и в срок до 5 (пяти) рабочих дней с момента Даты уведомления выполнять задачи, поставленные Исполнителем по решению возможных технических недоработок Сайта. В случае выполнения задач в период больший, чем 5 (пять) рабочих дней с момента Даты уведомления, сроки оказания Услуг по Сайту могут быть смещены Исполнителем в одностороннем порядке, о чем Заказчик будет письменно уведомлен. </Text>
          <Text style={styles.text}>2.2.5 В случае предъявления Исполнителю претензий со стороны третьих лиц, представить Исполнителю документы и иные доказательства, подтверждающие авторское право Заказчика на материалы, предоставленные Заказчиком.</Text>
          <Text style={styles.text}>2.2.6 Заказчик самостоятельно несет полную ответственность за наличие у него свидетельства о государственной регистрации, всех необходимых в соответствии с законодательством лицензий, сертификатов, разрешений и согласований государственных органов.</Text>
          <Text style={styles.text}>2.2.7 С помощью возможностей интерфейса системы администрирования (управления) Сайтом для каждой отдельно взятой страницы Сайта обеспечить возможность независимого редактирования служебных полей, meta-tags, title, h1, ее содержания. В случае, если предоставление независимого редактирования посредством интерфейса системы управления невозможно, Заказчик обязуется выполнить необходимые изменения самостоятельно в течение 5 (пяти) рабочих дней с момента Даты уведомления.</Text>
          <Text style={styles.text}>2.2.8 Не вносить никаких изменений в код Сайта и не изменять URL-адреса его страниц без согласования с Исполнителем в течение всего срока действия настоящего Договора. В случае необходимости - уведомлять Исполнителя по электронной почте обо всех планируемых изменениях на Сайте в период действия Договора.</Text>
          <Text style={styles.text}>2.2.9 Не создавать помехи в оказании Услуг Исполнителя (помехами являются: неработоспособность Сайта; использование контента Сайта на других Web-сайтах; размещение ссылок на другие Web-сайты на Сайте Заказчика; удаление текстов и служебных данных Продвигаемых страниц и т.п.), за исключением случаев, когда Исполнитель явно нарушает положения настоящего Договора. За любые негативные последствия, ставшие результатом такого вмешательства, ответственность несет Заказчик. В случае возникновения таких помех, Исполнитель вправе приостановить оказание Услуг по Продвижению Сайта на время их действия, зафиксировать дату их возникновения и уведомить об этом Заказчика. При этом дни простоя, возникшие по вине Заказчика, а также дни, необходимые для устранения помех и восстановления работы, подлежат оплате в полном объеме в соответствии с условиями настоящего Договора.</Text>
          <Text style={styles.text}>2.2.10 В течение 6-ти (шести) Отчетных периодов не изменять и не удалять Ключевые запросы (при их наличии), согласованные в Приложении № 1 к настоящему договору, так  как указанные действия могут нарушить процесс Продвижения Сайта, а также увеличить срок достижения положительного результата в целом.</Text>
          <Text style={styles.text}>2.2.11 Не заключать трудовые и (или) гражданско-правовые договоры с сотрудниками Исполнителя, состоявшими в трудовых отношениях с Исполнителем на момент действия настоящего Договора, в течение срока действия Договора, а так же 6 (шести) месяцев после его прекращения.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2.3 Исполнитель имеет право: </Text>

          <Text style={styles.text}>2.3.1 Вносить изменения на Сайт, необходимые для качественного оказания Услуг по Договору.</Text>
          <Text style={styles.text}>2.3.2 Проводить дополнительные мероприятия, необходимые для выполнения условий Договора.</Text>
          <Text style={styles.text}>2.3.3 Привлекать третьих лиц для выполнения условий Договора </Text>
          <Text style={styles.text}>2.3.4 Если с момента подписания договора прошло более 30 (тридцати) календарных дней, и Заказчик не предоставил все необходимые информационные материалы для выполнения работ по настоящему Договору, Исполнитель имеет право расторгнуть договор в одностороннем порядке. В таком случае предоплата Заказчику не возвращается.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2.4 Заказчик имеет право: </Text>

          <Text style={styles.text}>2.4.1 В любое время проверять ход и качество оказания Услуг, не вмешиваясь в деятельность Исполнителя.</Text>
          <Text style={styles.text}>2.4.3 Заказчик вправе отказаться от исполнения Договора при условии оплаты Исполнителю всех оказанных Услуг до момента прекращения действия Договора и на условиях, указанных в п.3.5, письменно уведомив Исполнителя о своем желании прекратить действие Договора за 15 (пятнадцать) календарных дней до такого прекращения.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3.	Стоимость и порядок оплаты работ</Text>

          <Text style={styles.text}>3.1 Стоимость работ по настоящему Договору рассчитывается в порядке и размере, согласованными сторонами в Приложении №1, счетах и актах сдачи-приемки оказанных услуг к настоящему Договору, являющихся неотъемлемой частью настоящего Договора, и оплачивается за текущий отчётный период Заказчиком до 10 (десятого) календарного дня включительно того же отчётного периода на основании данного Договора (без НДС).</Text>
          <Text style={styles.text}>Отчётный период — установленный условиями Договора период оказания услуг по продвижению Сайта, равный 1 (одному) календарному месяцу. Первый Отчётный период отсчитывается со дня поступления на расчетный счет Исполнителя первой предоплаты по настоящему Договору. Каждый последующий Отчётный период начинается с такого же числа каждого календарного месяца, в который была произведена первая предоплата. Отчётный период заканчивается, соответственно, за день до начала следующего отчётного периода.</Text>
          <Text style={styles.text}>3.2	 Проценты на предоплату не начисляются.</Text>
          <Text style={styles.text}>3.3 Оплата по настоящему Договору производится путем перечисления денежных средств на указанный в п.9. расчётный счёт Исполнителя. Все банковские расходы по перечислению денежных средств несет Заказчик. </Text>
          <Text style={styles.text}>3.4 Датой оплаты считается фактическое поступление средств на указанный в п.9. расчётный счёт Исполнителя. </Text>
          <Text style={styles.text}>3.5 При прекращении обязательств по Договору по инициативе Заказчика либо в случае нарушения Заказчиком п.2.2. настоящего Договора, сумма, уплаченная им Исполнителю в соответствии с п.3.1. настоящего Договора, удерживается Исполнителем в качестве компенсации произведенных затрат.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>4.	Порядок сдачи-приемки оказанных услуг </Text>

          <Text style={styles.text}>4.1 Исполнитель в срок не позднее 10 (десяти) рабочих дней с момента окончания оказания услуг, указанных в счет – фактуре, подтверждает Актом об оказании услуг, который направляется Исполнителем Заказчику посредством электронных каналов связи в виде сканированной копии с подписью и печатью Исполнителя. В случае необходимости Исполнитель обязан выслать Заказчику оригинал Акта об оказанных услугах в течение 5 (пяти) рабочих дней после получения соответствующего требования от Заказчика.</Text>
          <Text style={styles.text}>В случае неоплаты в течении 30 (тридцати) календарных дней Договор считается расторгнутым, а предоплата не возвращается.</Text>
          <Text style={styles.text}>4.2 При направлении Исполнителем Акта об оказанных услугах Заказчик обязуется рассмотреть Акт, подписать его и направить Исполнителю в течение 5 (пяти) рабочих дней либо в тот же срок предоставить мотивированные возражения от подписания Акта.</Text>
          <Text style={styles.text}>4.3 Заказчик безоговорочно соглашается с тем, что если он в течение 5 (пяти) рабочих дней с момента получения Акта об оказании услуг не заявит каких-либо письменных претензий по оказанным Исполнителем услугам, то это будет рассматриваться как однозначное согласие Заказчика с тем, что услуги оказаны Исполнителем своевременно, в полном объеме и надлежащим образом.</Text>
          <Text style={styles.text}>4.4 Работы, выполненные Исполнителем в каждом Отчетном периоде, считаются надлежащим образом выполненными Исполнителем и принятыми Заказчиком на последнюю дату каждого Отчетного периода.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>5.	Разрешение споров и ответственность сторон</Text>

          <Text style={styles.text}>5.1 Все спорные вопросы разрешаются путем переговоров, а при невозможности разрешения ― в Экономическом суде г. Гомеля. Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй Стороне. Претензия предъявлена надлежащим образом, если направлена другой Стороне любым способом, позволяющим установить личность отправителя и факт вручения корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй Стороны, не является обязательным. Сторона, получившая претензию, в течение 5 (пяти) рабочих дней со дня ее получения письменно уведомляет вторую Сторону о результатах рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией и изложенными в ней обстоятельствами. </Text>
          <Text style={styles.text}>5.2. Доказательствами в суде могут являться факсимильные копии и распечатки электронных писем, если переписка осуществлялась с адресов электронной почты сторон.</Text>
          <Text style={styles.text}>5.3 Если договор расторгается по желанию Заказчика на любом этапе работ, Заказчик оплачивает фактически проделанную работу. В адрес Заказчика направляется акт выполненных работ посредством электронной связи. Заказчик обязан подписать и отправить в адрес Исполнителя один экземпляр акта об оказании услуг в течении 5 (пять) рабочих дней с момента получения акта. В случае, если в указанный выше срок Заказчик не отправит Исполнителю подписанный акт об оказании услуг и не отправит письменный мотивированный отказ от приемки оказанных услуг, то стороны считают, что услуги, указанные в соответствующем счет - фактуре, приняты Заказчиком без претензий и подлежат оплате. </Text>
          <Text style={styles.text}>5.4 Исполнитель не несет ответственности за несоответствие фактически предоставленных услуг, субъективным ожиданиям и представлениям Заказчика о таких услугах.</Text>
          <Text style={styles.text}>5.5 Переписка по электронной почте между Сторонами имеет силу при согласовании и утверждении на всех этапах выполнения работ.</Text>
          <Text style={styles.text}>5.6 Датой передачи соответствующего сообщения считается день отправления сообщения по электронной почте.</Text>
          <Text style={styles.text}>5.7 Во всем остальном, что не оговорено настоящим Договором, Стороны несут ответственность в соответствии с законодательством Республики Беларусь.</Text>
          <Text style={styles.text}>5.8 Заказчик самостоятельно в полном объеме несет ответственность за достоверность информации, размещенной на Сайте, юридическую правомерность использования логотипов, названий фирм и прочих объектов интеллектуальной собственности и средств индивидуализации в информации для наполнения, а также за отсутствие обязательной информации, предусмотренной законодательством Республики Беларусь, в т.ч. в случаях, когда рекламируемая Заказчиком продукция, товар или услуга подлежит обязательной сертификации или лицензированию. </Text>
          <Text style={styles.text}>5.9 В случае, если наполнение Сайта, доменное имя и прочее явилось основанием для предъявления к Исполнителю претензий, исков и/или предписаний по уплате штрафных санкций со стороны государственных органов и/или третьих лиц, Заказчик обязуется незамедлительно по требованию Исполнителя предоставить ему всю запрашиваемую информацию, касающуюся размещения и содержания наполнения, содействовать Исполнителю в урегулировании таких претензий и исков, а также возместить все убытки (включая судебные расходы, расходы по уплате штрафов и т.п.), причиненные Исполнителю вследствие предъявления ему таких претензий, исков, предписаний в связи с нарушением прав третьих лиц и/или действующего законодательства в результате размещения материалов. </Text>
          <Text style={styles.text}>5.10 Исполнитель не является ответчиком или соответчиком по любым обязательствам и убыткам, связанным с нарушением настоящего Договора Заказчиком или любыми другими лицами, использующими пароли Заказчика, за исключением случаев, когда пароли Заказчика стали известны другим лицам по вине Исполнителя. </Text>
          <Text style={styles.text}>5.11 Настоящим Заказчик соглашается с тем, что он не имеет право ссылаться на некачественное оказание Услуг со стороны Исполнителя, если причиной данного обстоятельства послужило невыполнение/не своевременное/не в полном объёме своих обязательств согласно Договору.</Text>
          <Text style={styles.text}>5.12 Исполнитель не несёт ответственность за возможные сбои в работе поисковых систем, сбои в работе Сайта, а также любые их последствия.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>6.	Конфиденциальность </Text>

          <Text style={styles.text}>6.1 Стороны обязуются не разглашать конфиденциальную информацию, под которой подразумевается содержание Договора и любые данные, предоставляемые каждой из сторон друг другу в связи с исполнением Договора, не разглашать в общем или в частности эту информацию какой-либо третьей стороне без предварительного письменного согласия другой стороны по Договору. </Text>
          <Text style={styles.text}>6.2 Информация, предоставляемая Заказчику в соответствии с Договором, предназначена исключительно для него и не может передаваться третьим лицам или использоваться каким-либо иным способом, с участием третьих лиц, в частности и полностью, без согласия Исполнителя. </Text>
          <Text style={styles.text}>6.3 Обязательства Сторон относительно неразглашения конфиденциальной информации и неиспользования её в ущерб другой стороне не распространяются на общедоступную информацию и информацию, указанную в законодательстве как не подлежащую засекречиванию. </Text>
          <Text style={styles.text}>6.4 Стороны достигли соглашения о размещении информации о предоставлении услуг Исполнителем Заказчику в портфолио и на WEB-сайте Исполнителя с указанием ссылки, ведущей на адрес Сайта, и Исполнителю указывать себя в качестве компании, оказывающей Услуги по Продвижению Сайта на сайте Заказчика.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>7.	Форс-мажор</Text>

          <Text style={styles.text}>7.1 Ни одна из Сторон не несет ответственности за полное или частичное неисполнение обязательств по настоящему Договору в случае пожара, наводнения, землетрясения и других стихийных бедствий, возникших после заключения настоящего Договора, а также обстоятельств неопределимой силы в результате событий чрезвычайного характера, которые Сторона не могла ни предвидеть, ни предотвратить разумными методами (форс-мажорные обстоятельства).</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>8.	Порядок изменения и срок действия договора</Text>

          <Text style={styles.text}>8.1 Договор вступает в силу с момента его подписания Сторонами и действует до 31.12.{thisDateYers()} г., а в части взаиморасчетов до исполнения сторонами своих обязательств. Договор считается пролонгированным на следующий календарный год, если ни одна из сторон договора не заявила о расторжении договора за один месяц до окончания срока договора.</Text>
          <Text style={styles.text}>8.2 Все изменения и дополнения настоящего Договора оформляются в виде дополнительных соглашений, которые подписываются сторонами и являются неотъемлемой часть Договора. </Text>
          <Text style={styles.text}>Договор, изменения и дополнения к нему, другие документы, заверенные подписью и печатью, переданные по факсимильной связи, имеют юридическую силу.</Text>
          <Text style={styles.text}>8.3 Настоящий Договор составлен в двух экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру на каждую из сторон.</Text>
          <Text style={styles.text}>8.4 Стороны признают юридическую силу за электронными письмами – документами, направленными по электронной почте (e-mail) или посредством мессенджеров, и признают их равнозначными документами на бумажных носителях, подписанным собственноручной подписью, т.к. только сами Стороны и уполномоченные ими лица имеют доступ к соответствующим адресам электронной почты, указанным в Договоре в реквизитах Сторон и являющимся электронной подписью соответствующей Стороны. Доступ к электронной почте каждая Сторона осуществляет по паролю и обязуется сохранять его конфиденциальность.</Text>
          <Text style={styles.text}>8.5 В случае, если подписанный договор не возвращен Исполнителю, то фактом подписания договора считается оплата Заказчиком услуг. Оплата услуг свидетельствует о том, что Заказчик ознакомлен с условиями договора и полностью их принимает.</Text>


          <PDFFooterSingFull data={dataPayment} />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <Text style={[styles.headTitle, styles.mt]}>Приложение № 1</Text>
          <Text style={styles.headTitle}>к договору № Р-{dataPayment.contractNum}</Text>

          <View style={[styles.row]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>

          <View style={[styles.row]}>
            <View><Text style={styles.text}>Исполнитель: {dataPayment.firmaName.replace(/\sRU$/, "")}</Text>
              <Text style={[styles.text]}>Заказчик: {dataPayment.clientName}</Text></View>
          </View>

          <Text style={[styles.text, styles.textBold, styles.mt]}>1.	Перечень сайтов заказчика: </Text>

          <Text style={styles.text}>{dataPayment.domain ? dataPayment.domain : ''}</Text>


          <Text style={[styles.text, styles.textBold, styles.mt]}>2.	Перечень основных услуг, выполняемых для Сайта: </Text>

          <Text style={styles.text}>Оптимизация Сайта Заказчика по ключевым фразам </Text>
          <Text style={styles.text}>Улучшение видимости ключевых фраз по позициям в выдаче поисковых систем </Text>
          <Text style={styles.text}>Регистрация Сайта в каталогах и рейтингах с учётом выбранных ключевых фраз </Text>
          <Text style={styles.text}>Проведение дополнительных мероприятий, необходимых для выполнения п.1 настоящего Договора. </Text>
          <Text style={styles.text}>Отслеживание позиций ключевых запросов в поисковых системах </Text>
          <Text style={styles.text}>Увеличение количества посетителей Сайта </Text>
          <Text style={styles.text}>Другие Услуги, согласованные с заказчиком и отражённые в счетах к настоящему Договору и актах оказанных услуг к настоящему Договору </Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3.	Порядок расчёта и условия оплаты. </Text>

          <Text style={styles.text}>3.1	Стоимость Работ по продвижению Сайта Заказчика рассчитывается: </Text>

          {dataPayment.price.length === 1 &&
            <>
              <Text style={styles.text}>3.1.1 Стоимость услуг составляет
                {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                {dataPayment.price.reduce((akk, p) => +akk + +p)} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
              {

                dataPayment.price.map((p, i) =>
                  <Text key={i} style={styles.text}>{i + 1} Оплата {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)})  до {dataPayment.payment[i]} года</Text>
                )
              }
            </>
          }
          {dataPayment.price.length >= 2 && dataPayment.payment.length >= 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>3.1.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>не менее пяти месяцев, далее на усмотрение  заказчика. Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) до {dataPayment.payment[i]} года</Text>
                  )}
                </>
              }
            </>
          }
          {dataPayment.price.length === 2 && dataPayment.payment.length !== 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>3.1.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>не менее пяти месяцев, далее на усмотрение  заказчика. Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {i === 1 && 'Оплата в размере '} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) {i === 1 ? 'по завершению работ, что подтверждается актом выполненных работ и оплата производится в течение 5-ти банковских дней с момента подписания акта.' : 'до ' + dataPayment.payment[i] + ' года'}  </Text>
                  )}
                </>
              }
            </>
          }

          <Text style={styles.text}>3.1.2 По согласованию Сторон (в любой удобной форме – устной, путём переписки по электронной почте, письменной и т.п.) бюджет может быть увеличен. Конечная стоимость отражается в счетах и актах оказанных услуг к настоящему договору.</Text>
          <Text style={styles.text}>3.1.3 Изменение количества ключевых запросов в п.4 Приложение 1 осуществляется путём составления дополнительного соглашения к текущему договору. </Text>
          <Text style={styles.text}>4.	Ключевые фразы (запросы) для Сайта согласовываются с Заказчиком после начала работ путём составления дополнительного соглашения. </Text>

          <Text style={styles.text}><Text style={styles.textBold}>Отчетный период </Text>– 30 календарных дней от числа заключения договора или окончания предыдущего периода.</Text>


          <PDFFooterSing data={dataPayment} />

        </View>
      </Page>

    </Document >
  )
}
export default PDFComponentContractSEO