import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { alertErr } from '../../UI/alert'
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../UI/Loading'
import SelectSearch from '../../UI/SelectSearch/SelectSearch';
import { fetchWidgets, createWidgets, removeWidgets } from "../../../store/widgetsSlice"

const style = {
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
  buttonMin: {
    width: '100%',
    maxWidth: '55px'
  },
  button: {
    width: '100%',
    maxWidth: '80px'
  },
}
function Widgets({ data }) {
  const { widgets, status, error } = useSelector(state => state.widgets)
  const dispatch = useDispatch()


  const [domain, setDomain] = useState([])
  const [widgetsMenu, setWidgets] = useState([])

  useEffect(() => {
    if (data?.domains) {
      const domainsArr = JSON.parse(data.domains).map(el => {
        return {
          name: "domain",
          value: el.domain
        }
      })
      setDomain([...domainsArr])
    }

  }, [data])


  useEffect(() => {
    dispatch(fetchWidgets(data.id))
  }, [dispatch, data.id])

  const [toggleForm, setToggleForm] = useState(false)

  function sendData(e) {
    e.preventDefault()

    const domain = e.target.querySelector('[data-name="domain"]').value;
    const widget = e.target.querySelector('[data-name="widget"]').value;
    if (!widget || !domain) {
      alertErr.fire({
        text: 'Заполните все обязательные поля!',
      });
      return
    }

    const links = (w, d = '') => {
      const getLink = [
        { widget: 'r-bot', link: `https://ramabot.mediarama.by/?id=_${d.replace(/\./g, "")}` },
        { widget: 'r-quiz', link: `https://rquiz.mediarama.by/?id=${d.replace(/\./g, "")}` },
        { widget: 'r-menu', link: `https://widgets.mediarama.by/r-menu/r-menu-active.js` },
        { widget: 'r-form', link: `https://lbox.mediarama.by/?id=_${d.replace(/\./g, "")}` },
      ]
      const thisLink = getLink.filter(el => el.widget === w)[0]
      return thisLink
    }

    const object = {
      client_id: data.id,
      data: { ...links(widget, domain), domain: domain },
      domain: domain
    };


    dispatch(createWidgets(object))
    setWidgets([])
    e.target.querySelectorAll('input,textarea').forEach(i => i.value = '');

  }

  function createWidgetMenu(params) {
    const widgetMenuArr = [{ name: 'widget', value: "r-bot" }, { name: 'widget', value: "r-quiz" }, { name: 'widget', value: "r-menu" }, { name: 'widget', value: "r-form" }]

    function getAvailableWidgetsForSelectedDomain(selectedDomainValue) {
      let usedWidgets = widgets
        .filter(w => w.domain === selectedDomainValue)
        .map(w => w.widget);

      let availableWidgets = widgetMenuArr.filter(wm => !usedWidgets.includes(wm.value));
      return availableWidgets;
    }

    // Пример использования функции
    let selectedDomain = params.value; // Здесь вы устанавливаете выбранный domain
    let availableWidgets = getAvailableWidgetsForSelectedDomain(selectedDomain);
    setWidgets(availableWidgets)

  }
  function deleteWidget(params) {

    Swal.fire({
      title: `Точно удалить ${params.widget} для ${params.domain}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData()
        formData.append("domain", params.domain)
        formData.append("widget", params.widget)
        const id = data.id
        dispatch(removeWidgets({ formData, id }))
      }
    })
  }
  return (
    <section className='pages'>
      <Container>
        <Row>
          <Col lg="2" md="2" sm="2" className="mb-2">
            <div className="input-style">
              <Button style={{ margin: '-20px 0 10px' }} type="button" variant="primary" className={'button-save'} onClick={() => setToggleForm(!toggleForm)}>{toggleForm ? 'Скрыть ↑' : 'Добавить ↓'}</Button>
            </div>
          </Col>
        </Row>
        {toggleForm &&
          <Form onSubmit={(e) => sendData(e)}>
            <Row>
              <Col lg='4' className='mb-3'>
                <label htmlFor="domain">Домен</label>
                <SelectSearch onChange={(p) => createWidgetMenu(p)} name="domain" id="domain" options={[{ name: 'domain', value: "" }, ...domain]} defaultValue={{ name: 'domain', value: "" }} />
              </Col>
              <Col lg='4' className='mb-3'>
                <label htmlFor="widget">Виджет</label>
                <SelectSearch name="widget" id="widget" options={widgetsMenu} defaultValue={{ name: 'widget', value: "" }} />
              </Col>
              <Col lg='1' className='mb-3'>
                <div className="input-style">
                  <label>Добавить</label>
                  <Button style={style.button} type="submit" variant="success" className={'button-save'}>&#9998;</Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      </Container>
      <div className="invoicesHead">

        <Row>
          <Col lg="4" className="mb-2">Виджет</Col>
          <Col lg="2" className="mb-2">Домен</Col>
          <Col lg="5" className="mb-2">Ссылка</Col>
          <Col lg="1" className="mb-2">Удалить</Col>
        </Row>
      </div>
      <div className="invoicesBody">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {widgets?.map((el, i) =>
          <div key={el.domain + '-' + i + '-' + el.widget} className="invoice">
            <Row>
              <Col lg="4">{el.widget}</Col>
              <Col lg="2">{el.domain}</Col>
              <Col lg="5" >{el.link}</Col>
              <Col lg="1"><span onClick={(e) => deleteWidget(el)} className="close-button">&times;</span></Col>
            </Row>
          </div>
        ).reverse()}
      </div>
    </section >
  );
};
export default Widgets;