import React, { useEffect, useRef, useState, useCallback } from "react";
import parse from 'html-react-parser';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from '../components/UI/Loading'
import { fetchClient } from '../store/clientsSlice'
import { fetchTelegramBase, removeClient } from '../store/telegramBaseSlice'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { dateTransform } from '../components/functions'

import { TelegramMessagesPhoto } from '../components/UI/telegramMessages'
import { alertErr, successMessage, alert } from '../components/UI/alert'

import styles from '../components/CreateMessagesTelegram/styles.module.sass'
function CreateMessagesTelegram() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchClient())
    dispatch(fetchTelegramBase())
  }, [dispatch])
  const { clients, status, error } = useSelector(state => state.clients)
  const { telegramBase, statusTel, errorTel } = useSelector(state => state.telegramBase)
  const clientsTelegram = clients.filter(el => el.telegram);
  const previewMessage = useRef(null)

  const [valueTextarea, setValueTextarea] = useState('');
  const handleChangeTextarea = (event) => {
    setValueTextarea(event.target.value);
  };

  const selectDocument = useRef(null)
  const noClientsCheck = useRef(null)
  const noClientsField = useRef(null)
  const inputContainer = useRef(null)
  function selectAll(e) {
    const inputs = inputContainer.current.querySelectorAll('.clientInput')
    if (e.checked) inputs.forEach(cl => cl.checked = true)
    else inputs.forEach(cl => cl.checked = false)
  }

  const inputContainerNewClient = useRef(null)
  function selectAllNewClient(e) {
    const inputs = inputContainerNewClient.current.querySelectorAll('.clientInput')
    if (e.checked) inputs.forEach(cl => cl.checked = true)
    else inputs.forEach(cl => cl.checked = false)
  }

  function telegramMessage(e) {
    e.preventDefault()
    const file = e.target.querySelector('#file').files[0]
    const mess = e.target.querySelector('textarea').value

    const messages = mess.split("\n").join("%0A")

    const inputs = inputContainer.current.querySelectorAll('.clientInput')
    const inputsNewClients = inputContainerNewClient.current.querySelectorAll('.clientInput')

    if (!file && !mess) {
      alertErr.fire({
        text: 'Не заполнены поля!',
      })
      return
    }

    let inputsChackedArr = [...inputs, ...inputsNewClients].filter(i => i.checked).map(v => v.dataset.telegram)
    if (noClientsCheck.current.checked) {
      const telegramArr = noClientsField.current.value.replace(/( )/g, '').split(',')
      inputsChackedArr = inputsChackedArr.concat(telegramArr);
    }

    if (!inputsChackedArr.length && !noClientsCheck.current.checked) {
      alertErr.fire({
        text: 'Не выбрано не одного клиента!',
      })
      return
    }

    alert.fire({
      title: `Точно отправить сообщение всем выбранным контактам?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        inputsChackedArr.forEach(el => {
          setTimeout(() => {
            if (selectDocument.current.checked) {
              TelegramMessagesPhoto(el, messages, file, ['sendDocument', 'document'])
            } else {
              TelegramMessagesPhoto(el, messages, file)
            }

          }, 200);
        })
        e.target.querySelector('textarea').value = ""
        e.target.querySelector('#file').value = ""
        const oldImg = previewMessage.current.querySelector('img')
        if (oldImg) oldImg.remove()
        setValueTextarea('')
        successMessage.fire({
          text: `Сообщение отправлено всем выбранным пользователям`,
        })
      }
    })
  }



  function getFile(event) {
    const oldImg = previewMessage.current.querySelector('img')
    if (oldImg) oldImg.remove()
    const files = Array.from(event.target.files);

    const img = document.createElement('img')


    files.forEach((file) => {
      if (!file.type.match("image")) return;

      const reader = new FileReader();
      reader.onload = (ev) => {
        img.setAttribute('src', ev.target.result)
        previewMessage.current.insertAdjacentElement('afterBegin', img)
      };

      reader.readAsDataURL(file);
    });
  }
  const deleteClient = useCallback((id, name, chat_id) => {
    Swal.fire({
      title: `Точно удалить ${name} - ${chat_id}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeClient(id))
      }
    })
  }, [dispatch])
  return (
    <section className="pages">
      <Container>
        <h1>Создать сообщение в Telegram</h1>
      </Container>
      <Container className="edit-box" fluid>
        <Row>
          <Col lg="4">
            <div className={styles.noClientsFieldBox}>
              <div className={styles.client2} >
                <input className={styles.input} type="checkbox" name="client" id="selectNoClients" ref={noClientsCheck} />
                <label className={styles.label2} htmlFor="selectNoClients">Отослать всем</label>
              </div>
              <div className="input-style">
                <label>Список id через запятую не клиентов</label>
                <textarea autoComplete="off" data-name="noClientsMessage" ref={noClientsField}></textarea>
              </div>
            </div>
            <Form onSubmit={(e) => telegramMessage(e)}>
              <div className="input-style">
                <label>Сообщение</label>
                <textarea className={styles.textarea} autoComplete="off" data-name="message" value={valueTextarea} onChange={handleChangeTextarea}></textarea>
              </div>
              <div className={styles.client2}>
                <input onChange={(e) => getFile(e)} type="file" name="file" id="file" accept=".jpg,.jpeg,.png,.pdf,.docx,.xlsx" />
                <div className={styles.client3} >
                  <input className={styles.input} type="checkbox" name="document" id="selectDocument" ref={selectDocument} />
                  <label className={styles.label3} htmlFor="selectDocument">Как документ</label>
                </div>
              </div>
              <div className={styles.previewMessage} ref={previewMessage}>
                <p className={styles.pMess}>{parse(valueTextarea.split("\n").join("<br>"))}</p>
              </div>
              <div className={[styles.buttonSend + " input-style"]}>
                <label>Отправить</label>
                <Button className={styles.button} type="submit">&#9998;</Button>
              </div>
            </Form>
          </Col>
          <Col lg="8" ref={inputContainer}>
            {status === 'loading' && <Loading />}
            {error && <h2>{error}</h2>}
            <div className={styles.client2}>
              <input className={styles.input} type="checkbox" name="client" id="selectAll" onChange={(e) => selectAll(e.target)} />
              <label className={styles.label2} htmlFor="selectAll">ВЫБРАТЬ / УБРАТЬ ВСЕХ</label>
            </div>
            <ul className={styles.ul}>
              {clientsTelegram.map(cl =>

                <li className={styles.li} key={cl.id}>
                  <div className={styles.client} >
                    <input className={[styles.input + ' clientInput']} type="checkbox" data-telegram={cl.telegram} name="client" id={cl.id} />
                    <label className={styles.label} htmlFor={cl.id}>{cl.name}</label>
                  </div>
                </li>

              )}
            </ul>
          </Col>
          <Col lg="12"><hr></hr></Col>
          <Col lg="12" ref={inputContainerNewClient}>
            {statusTel === 'loading' && <Loading />}
            {errorTel && <h2>{errorTel}</h2>}
            <div className={styles.client2}>
              <input className={styles.input} type="checkbox" name="client" id="selectAllNewClient" onChange={(e) => selectAllNewClient(e.target)} />
              <label className={styles.label2} htmlFor="selectAllNewClient">ВЫБРАТЬ / УБРАТЬ ВСЕХ НОВЫХ</label>
            </div>
            <ul className={styles.ul2}>
              {telegramBase.map(cl =>

                <li className={styles.li} key={cl.id}>
                  <span className={styles.closeButton + " close-button"} onClick={() => deleteClient(cl.id, cl.first_name, cl.chat_id)}>&times;</span>
                  <div className={styles.client} >
                    <input className={[styles.input + ' clientInput']} type="checkbox" data-telegram={cl.chat_id} name="client" id={cl.id} />
                    <label className={styles.label} htmlFor={cl.id}>{`${cl.user_name} (${cl.first_name} ${cl.last_name}) от ${dateTransform(cl.date, 'time')} | id: ${cl.chat_id}`}</label>
                  </div>
                </li>

              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </section >
  );
};

export default CreateMessagesTelegram;