import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { filterParishes } from "../../../store/parishesSlice"
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../UI/Loading'

import { dateUnTransform } from '../../functions'

function Payments({ data }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(filterParishes({ client: `client-${data.id}` }));
  }, [dispatch, data.id])

  const { parishes, status, error } = useSelector(state => state.parishes)

  function getAmount(amount) {
    return JSON.parse(amount).value + ' руб'
  }

  function sortDate(params) {
    const sortedArr = params.slice().sort((a, b) => dateUnTransform(a.date) - dateUnTransform(b.date));
    return sortedArr
  }

  useEffect(() => {
    sortDate(parishes)
  }, [parishes])

  function getAmountField(field) {
    const fieldArr = {
      refill_g: "Пополнение Google",
      refill_y: "Пополнение Яндекс",
      refill_i: "Пополнение Инстаграм",
      dev_site: "Разработка Сайт",
      dev_context: "Разработка Контекст",
      dev_complex: "Комплекс",
      dev_seo: "Seo",
      host: "Хостинг",
      map: "Карты",
      dev_i: "Разработка Инстаграм",
      maint_i: "Ведение Инстаграм",
      maint_context: "Контекст ведение",
      maint_site: "Сайт ведение",
      products: "Продукты",
      other: "Прочее",
    }
    const arr = []
    Object.keys(field).forEach((key, i) => {
      if (fieldArr.hasOwnProperty(key) && field[key]) {
        arr.push({ id: i + field.id, value: fieldArr[key], amount: JSON.parse(field[key]).value + ' руб' })

      }
    });
    return arr
  }
  return (
    <>
      <div className="invoicesHead">
        <Row>
          <Col lg="1" className="mb-2">Дата</Col>
          <Col lg="1" className="mb-2">Сумма</Col>
          <Col lg="8" className="mb-2">На что потрачено</Col>
          <Col lg="1" className="mb-2">Код</Col>
          <Col lg="1" className="mb-2">Менеджер</Col>
        </Row>
      </div>
      <div className="invoicesBody">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {sortDate(parishes)?.map(el =>
          <div key={el.id} className="invoice">
            <Row>

              <Col lg="1">{el.date}</Col>
              <Col lg="1">{getAmount(el.amount)}</Col>
              <Col lg="8">
                {getAmountField(el).map(a =>
                  <div key={a.id} lg="3">{a.value} {a.amount}🧾</div>
                )}
              </Col>
              <Col lg="1">{el.code}</Col>
              <Col lg="1">{el.manager}</Col>
            </Row>
          </div>
        ).reverse()}
      </div>
    </>
  );
};
export default Payments;