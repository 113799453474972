import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Textarea from "../UI/Textarea"
import style from './style.module.sass'
function BriefSiteForm({ thisClient }) {
  return (
    <Row className={"align-items-center"}>
      <Col lg="12" className="mb-3">
        <h2 className="title">1.	Информация о компании</h2>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.1 Название компании полное
          (и если отличается название бренда)
        </p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-1" height={"50px"} value={thisClient?.name} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.2 Реквизиты и УНП организации</p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-2" height={"140px"} value={thisClient?.data} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.3 Адрес организации, торговой точки, почтовый адрес</p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-3" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.4 Время работы / время приёма заявок
          <br /><span className='sub'>(укажите время работы и выходные дни)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-4" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.5 Контактные данные которые нужно указать на сайте например (Телефон, viber, whatsApp, telegram, email)
          <br /><span className='sub'>(номер телефона мы будем указывать в объявлениях, на баннерах и других рекламных материалах)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-5" height={"140px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.6 Email и Telegram для заявок с сайта</p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-6" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.7 Укажите существующий сайт, что не устраивает в старом сайте?
          <br /><span className='sub'>(если токовой есть) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-7" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.8 Доменное имя нового сайта и доступы от кабинета
          <br /><span className='sub'>(если токовое есть) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-8" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.9 Зарегистрированный хостинг и доступы от кабинета
          <br /><span className='sub'>(если токовой есть) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-1-9" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.1 Вид деятельности компании
          <br /><span className='sub'>(продажи/услуги/товар) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-1" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.2 Описание товара, услуги подробно
          <br /><span className='sub'>(цены, преимущества, отличия) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-2" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.3 Клиенты, целевая аудитория
          <br /><span className='sub'>(укажите кто чаще у Вас покупает
            укажите, какие слои населения являются вашей аудиторией, их половозрастную структуру, уровень дохода и т.д.
            ) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-3" height={"120px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.4 География реализации товаров, услуг
          <br /><span className='sub'>(подробно опишите какой регион Вам интересен. Это могут быть страны, области, города, районы, радиус вокруг точки и т.д.) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-4" height={"120px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.5 Внушительные цифры не менее 4
          <br /><span className='sub'>(«5 лет на рынке», «2032 выполненных заказа», «120 довольных клиентов» и т.д) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-5" height={"120px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.6 Преимуществ Вашей компании или товара/услуги Чем Вы отличаетесь от конкуренов</p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-6" height={"150px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.7 Как Вы работаете<br /><span className='sub'>(этапы работы, минимум 4) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-7" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.8 Какие акции есть(действуют) в данный<br /><span className='sub'>(подробнее) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-8" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.9 Имеются ли сертификаты, паспорта качества, свидетельства, др. официальные документы </p>
      </Col>
      <Col lg="8" className="mb-3">
        <label htmlFor="brief_site-2-9">
          <p>Есть</p>
          <input className='checkbox' type="checkbox" id="brief_site-2-9" data-name="brief_site-2-9" value="Имеются ли сертификаты" />
        </label>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.10 Информация о существующем фирменном стиле <br /><span className='sub'>(логотип, фирменные цвета, фирменный стиль, персонажи) </span> </p>
      </Col>
      <Col lg="8" className="mb-3">
        <label htmlFor="brief_site-2-10">
          <p>Есть</p>
          <input className='checkbox' type="checkbox" id="brief_site-2-10" name="brief_site-2-10" data-name="brief_site-2-10" value="Есть фирменный стиль" />
        </label>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.11 Гарантии, которые Вы готовы предоставить на ваши товары/услуги </p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-2-11" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.12 Фото товаров/примеры работ</p>
      </Col>
      <Col lg="8" className="mb-3">
        <label htmlFor="brief_site-2-12">
          <p>Есть</p>
          <input className='checkbox' type="checkbox" id="brief_site-2-12" name="brief_site-2-12" data-name="brief_site-2-12" value="Фото товаров/примеры работ" />
        </label>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.1 Цветовая гамма, не больше 2х цветов</p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-3-1" height={"40px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.2 Создание интероктавной карты с меткой торгового объекта и(или) организации</p>
      </Col>
      <Col lg="8" className="mb-3">
        <label htmlFor="brief_site-3-2">
          <p>Требуется</p>
          <input className='checkbox' type="checkbox" id="brief_site-3-2" name="brief_site-3-2" data-name="brief_site-3-2" value="Требуется создание интероктавной карты" />
        </label>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.3 Укажите адреса сайтов, которые Вам нравятся по дизайну либо по другим параметрам. Прокомментируйте каждый из них<br /><span className='sub'>(что нравится: дизайн, навигация, акции, структура и тд.) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_site-3-3" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.3 Вид рекламы<br /><span className='sub'>(Выберете места размещения)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <ul className={style.ul}>
          <li><label htmlFor="brief_map-1-3">
            <input className='checkbox' type="checkbox" id="brief_map-1-3" name="brief_map-1-3" data-name="brief_map-1-3" value="Яндекс" />
            <p>Яндекс</p>
          </label></li>
          <li><label htmlFor="brief_map-1-4">
            <input className='checkbox' type="checkbox" id="brief_map-1-4" name="brief_map-1-4" data-name="brief_map-1-4" value="Google" />
            <p>Google</p>
          </label></li>
          <li><label htmlFor="brief_map-1-5">
            <input className='checkbox' type="checkbox" id="brief_map-1-5" name="brief_map-1-5" data-name="brief_map-1-5" value="РСЯ" />
            <p>РСЯ</p>
          </label></li>
          <li><label htmlFor="brief_map-1-6">
            <input className='checkbox' type="checkbox" id="brief_map-1-6" name="brief_map-1-6" data-name="brief_map-1-6" value="КМС" />
            <p>КМС</p>
          </label></li>
          <li><label htmlFor="brief_map-1-7">
            <input className='checkbox' type="checkbox" id="brief_map-1-7" name="brief_map-1-7" data-name="brief_map-1-7" value="YouTube" />
            <p>YouTube</p>
          </label></li>
        </ul>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.4 Бюджет в день
          <br /><span className='sub'>(укажите желаемый дневной расход на рекламу, можно указать цифры «от-до»)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-8" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.6 Что рекламируем?
          <br /><span className='sub'>(Подробно опишите какой продукт более важен для Вас, а какой не интересен.)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-10" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.9 Время показа рекламы
          <br /><span className='sub'>(Рекомендуем показывать рекламу круглосуточно, или и с корректировкой по времени суток, например: «показываем круглосуточно, но ночью дешевле на 50%)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-13" height={"50px"} />
      </Col>

      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.4 Ссылка на Яндекс и Google карты
          <br /><span className='sub'>(Если ваша компания зарегистрирована в Яндекс и Google картах, укажите ссылки на страницы)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-4" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.8 Доп. Инфа <br /><span className='sub'>(тут можно указать любую информацию, которая по Вашему мнению важна для нас)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-7" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.9 Опишите вашего потенциального клиента <br /><span className='sub'>(пол, возраст, предпочтения…)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-8" height={"100px"} />
      </Col>
    </Row>
  );
};
export default BriefSiteForm;