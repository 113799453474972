import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchContractsDetails = createAsyncThunk(
  'contractsdetails/fetchContractsDetails',
  async function (id, { rejectWithValue }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/contractsdetails/get/${id ? id : ''}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const arr = []
      for (const key in data) {

        const newObject = {
          id_contract: data[key].id,
          date: data[key].date,
          ...JSON.parse(data[key].value)
        }
        arr.push(newObject)
      }
      return arr

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const createContractsDetails = createAsyncThunk(
  'contractsdetails/createContractsDetails',
  async function (object, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/contractsdetails/create`, {
        method: 'POST',
        body: JSON.stringify(object)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось создать запись о счете в базе. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const removeContractsDetails = createAsyncThunk(
  'contractsdetails/removeContractsDetails',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/contractsdetails/delete/${id}`, {
        method: 'POST',
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      dispatch(deletePayment(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось удалить счет в базе. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const editContractsDetails = createAsyncThunk(
  'contractsdetails/editContractsDetails',
  async function (object, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/contractsdetails/edit/${object.id_contract
        }`, {
        method: 'POST',
        body: JSON.stringify(object)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      dispatch(editPayment(object))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось удалить счет в базе. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const contractsDetailsSlice = createSlice({
  name: 'contractsdetails',
  initialState: {
    contractsDetails: [],
    status: null,
    error: null,
  },
  reducers: {
    clearContractsDetails(state) {
      state.contractsDetails = []
    },
    deletePayment(state, action) {
      state.contractsDetails = state.contractsDetails.filter(contractDetail => contractDetail.id_contract !== action.payload)
      success.fire({
        text: "Договор удален!",
      });
    },
    editPayment(state, action) {
      // Создаем новый массив на основе contractsDetails, в котором заменяем объект
      const updatedContractsDetails = state.contractsDetails.map((contractDetail) => {
        if (contractDetail.id_contract === action.payload.id_contract) {
          // Заменяем объект с заданным id_contract на новый объект (action.payload)
          return action.payload;
        }
        // Возвращаем неизмененные объекты
        return contractDetail;
      });

      // Присваиваем обновленный массив свойству contractsDetails
      state.contractsDetails = updatedContractsDetails;

      success.fire({
        text: "Изменения сохранены!",
      });
    },
  },
  extraReducers: {
    [fetchContractsDetails.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [editContractsDetails.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [createContractsDetails.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editContractsDetails.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchContractsDetails.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.contractsDetails = action.payload
    },
    [fetchContractsDetails.rejected]: setError,
    [editContractsDetails.rejected]: setError,
    [createContractsDetails.rejected]: setError,
  }
})
export const { deletePayment, editPayment, clearContractsDetails } = contractsDetailsSlice.actions
export default contractsDetailsSlice.reducer