import React from "react";

import Th from './Th';
import styles from './styles.module.sass'


export default function Payment({ theadArr, markerTh }) {

  return (
    <thead className={styles.thead}>
      <tr>
        {theadArr.map(th =>
          <Th className={markerTh === th.id ? styles.markerTh : ''} dataId={th.id} name={th.title} width={th.width} key={th.id} field={th.field} />
        )}
      </tr>
    </thead>
  );
};