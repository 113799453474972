import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Container, Button, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useSelector, useDispatch } from 'react-redux';

import { fetchClient } from '../store/clientsSlice'
import { fetchSettings } from '../store/settingsSlice'
import { filterParishes, createParishes } from '../store/parishesSlice'

import Thead from '../components/Table/Thead';
import Tbody from '../components/Table/Tbody';
import styles from '../components/Table/styles.module.sass'

import TableFilter from '../components/Table/TableFilter'
import TableColumnsSum from '../components/Table/TableColumnsSum'

import Loading from '../components/UI/Loading'

// import { Parishes } from '../context'

const theadArr = [
  { id: "1", title: "Дата", width: "100", calendar: true },
  { id: "2", title: "Клиент", width: "350", client: true },
  { id: "3", title: "Сумма", width: "76", field: "amount" },
  { id: "4", title: "Баланс", width: "60" },
  { id: "5", title: "Код", width: "70" },
  { id: "6", title: "Менеджер", width: "120" },
  { id: "7", title: "Пополнение Google", width: "76", field: "refill_g" },
  { id: "8", title: "Пополнение Яндекс", width: "76", field: "refill_y" },
  { id: "9", title: "Пополнение Инстаграм", width: "76", field: "refill_i" },
  { id: "10", title: "Разработка Сайт", width: "76", field: "dev_site" },
  { id: "11", title: "Разработка Контекст", width: "76", field: "dev_context" },
  { id: "12", title: "Seo", width: "76", field: "dev_seo" },
  { id: "13", title: "Программирование", width: "76", field: "programming" },
  { id: "14", title: "Хостинг", width: "76", field: "host" },
  { id: "15", title: "Карты", width: "76", field: "map" },
  { id: "16", title: "Разработка Инстаграм", width: "76", field: "dev_i" },
  { id: "17", title: "Ведение Инстаграм", width: "76", field: "maint_i" },
  { id: "18", title: "Контекст ведение", width: "76", field: "maint_context" },
  { id: "19", title: "Сайт ведение", width: "76", field: "maint_site" },
  { id: "20", title: "Продукты", width: "76", field: "products" },
  { id: "21", title: "Комплекс", width: "76", field: "dev_complex" },
  { id: "22", title: "Прочее", width: "100", field: "other" },
];
const buttonStyle = {
  margin: "auto",
  display: "block",
  marginTop: "10px",
  marginBottom: "300px",
}

function Parishes() {
  const { status, error } = useSelector(state => state.parishes)
  const dispatch = useDispatch()

  // Добавить строку
  const addRow = useCallback(() => {
    const date = new Date()
    const nowDate = ('0' + date.getDate()).slice(-2) + "." + ('0' + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear()
    const formData = new FormData()
    formData.append('date', nowDate)
    dispatch(createParishes(formData))
  }, [dispatch])
  //-----------
  useEffect(() => {
    dispatch(fetchClient())
    dispatch(filterParishes({
      client: '',
      dateFrom: '',
      dateTo: '',
      field: '',
      code: '',
      manager: '',
    }))
    dispatch(fetchSettings(1))
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  }, [])

  const clients = useSelector(state => state.clients.clients)
  const parishes = useSelector(state => state.parishes.parishes)
  const managers = useSelector(state => state.settings.managers)
  const [clientName, setClientName] = useState('')


  const [columnsSum, setColumnsSum] = useState([])

  const fieldSum = useCallback((field) => {
    const fieldValue = parishes.map(el => el[field]).filter(el => el).map(el => +JSON.parse(el).value.replace(/\D/gi, ''))
    return fieldValue.reduce((acc, val) => acc + val, 0)
  }, [parishes])

  useEffect(() => {
    setColumnsSum([
      { amount: fieldSum('amount') },
      { refill_g: fieldSum('refill_g') },
      { refill_y: fieldSum('refill_y') },
      { refill_i: fieldSum('refill_i') },
      { dev_site: fieldSum('dev_site') },
      { dev_context: fieldSum('dev_context') },
      { dev_seo: fieldSum('dev_seo') },
      { programming: fieldSum('programming') },
      { host: fieldSum('host') },
      { map: fieldSum('map') },
      { dev_i: fieldSum('dev_i') },
      { maint_i: fieldSum('maint_i') },
      { maint_context: fieldSum('maint_context') },
      { maint_site: fieldSum('maint_site') },
      { products: fieldSum('products') },
      { dev_complex: fieldSum('dev_complex') },
      { other: fieldSum('other') },
    ]);
  }, [parishes, fieldSum])

  useMemo(() => {
    const clientArr = clients.map(el => {
      return {
        name: 'client-' + el.id,
        value: el.name
      }
    })
    setClientName([{ value: '', name: '' }, ...clientArr])
  }, [clients])

  const [markerTh, setMarkerTh] = useState('')

  const mouseHover = useCallback((params) => {
    setMarkerTh(params.target.dataset.thid);
  }, [])

  const [columnsInputChecked, setColumnsInputChecked] = useState(0)
  const inputSum = useRef(null);

  const getColumnSum = useCallback(() => {
    const inputsArr = inputSum.current.querySelectorAll('input');
    const inputsArrSum = [...inputsArr].filter(inp => inp.checked).map(inp => inp.value.replace(/(,)/gi, ""));
    const sum = inputsArrSum.reduce((acc, val) => +acc + +val, 0)
    setColumnsInputChecked(sum);
  }, [inputSum])

  return (
    // <Parishes.Provider value={{ parishes, theadArr }}>
    <>
      <Container fluid>
        <div className={styles.tableBox}>
          {status === 'loading' && <Loading />}
          {error && <h2>{error}</h2>}
          <table className={styles.table} >
            <Thead theadArr={theadArr} markerTh={markerTh} />
            {parishes.map(el =>
              <Tbody key={el.id} clientName={clientName} parishes={el} mouseHover={mouseHover} managers={managers} />
            )}
            <TableColumnsSum ref={inputSum} theadArr={theadArr} mouseHover={mouseHover} columnsSum={columnsSum} getColumnSum={getColumnSum} />
          </table>
        </div>
        <Button style={buttonStyle} onClick={addRow}>+</Button>
      </Container>
      <TableFilter options={clientName} managers={managers} />
      <div className={columnsInputChecked ? styles.filterSumm + " " + styles.filterSummOpen : styles.filterSumm} >{columnsInputChecked}</div>
    </>
    // </Parishes.Provider>
  );
};

export default Parishes;