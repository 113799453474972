import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr } from '../components/UI/alert'

export const fetchFirma = createAsyncThunk(
  'firmas/fetchFirma',
  async function (_, { rejectWithValue }) {

    try {
      // const response = await fetch(`http://mediarama-payment-app/firmas`, {
      const response = await fetch(`https://payment.mediarama.by/core/firmas/get`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data.reverse()

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const firmasSlice = createSlice({
  name: 'firmas',
  initialState: {
    firmas: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchFirma.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchFirma.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.firmas = action.payload
    },
    [fetchFirma.rejected]: setError,

  }
})
// const {} = firmasSlice.actions
export default firmasSlice.reducer