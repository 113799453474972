import React, { useEffect, useState } from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'

function RevenueMonth({ indicators }) {
  const year = new Date().getFullYear()
  const month = new Date().getMonth()
  const allDays = new Date(year, month + 1, 0).getDate()
  const thisDay = new Date().getDate()
  const [newIndicatorsArr, setNewIndicatorsArr] = useState([])
  const [colorsArr, setColorsArr] = useState([])

  const plans = [...Array(12)].map(e => 26000)

  useEffect(() => {

    if (indicators.yearMonth) {
      const nowSum = indicators.yearMonth[month]
      const newArr = indicators.yearMonth.map(el => el * 0)
      const forecastSum = (nowSum / thisDay) * allDays
      newArr.splice(month, 1, forecastSum)
      setNewIndicatorsArr(newArr)

      const colors = indicators.yearMonth.map((el, i) => {
        const p1 = plans[i] / 3
        const p2 = plans[i] / 2
        const p3 = plans[i] / 1.2
        if (el < p1) return "rgba(233, 0, 0, 1)"
        if (el > p1 && el < p2) return "rgba(233, 140, 0, 1)"
        if (el > p2 && el < p3) return "rgba(233, 190, 0, 1)"
        if (el > p3) return "rgba(5, 233, 0, 1)"
        return "rgba(233, 0, 0, 1)"
      })
      setColorsArr(colors)
    }
  }, [indicators])

  const barChartData = {
    labels: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    datasets: [
      {
        data: indicators.yearMonth,
        label: "Выручка",
        borderColor: "#3333ff",
        backgroundColor: colorsArr,
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: plans,
        label: "План",
        borderColor: "#ff3333",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: newIndicatorsArr,
        label: "Прогноз",
        borderColor: "#ff3333",
        backgroundColor: "rgba(124, 185, 232, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      }
    ]
  };
  return (
    <Bar
      type="bar"
      width={130}
      height={50}

      options={{
        plugins: {
          title: {
            display: true,
            text: "Помесячный отчет за год",
          }
        },
      }}
      data={barChartData}
    />
  )
};

export default RevenueMonth;