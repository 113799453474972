import createInvoice from '../pages/CreateInvoice'
import clients from '../pages/Сlients'
import clientspayments from '../pages/ClientsCommonDataPage'
import login from '../pages/Login'
import parishes from '../pages/Parishes'
import hostings from '../pages/Hostings'
import settings from '../pages/Settings'
import indicators from '../pages/Indicators'
import createContract from '../pages/CreateContract'
import messagest from '../pages/CreateMessagesTelegram'
import messagesv from '../pages/CreateMessagesViber'
// import recruit from '../pages/RecruitManagers'
// import recruitreport from '../pages/RecruitManagerReport'
import allcontracts from '../pages/AllContracts'
// import openaigenerateimg from '../pages/OpenAiGenerateImg'
import openai from '../pages/OpenAi'
import briefclient from '../pages/BriefClient'
import briefsiteedit from '../pages/BriefEdit'
import seo from '../pages/Seo'
import siteslist from '../pages/SitesList'
import postalItems from '../pages/PostalItems'


export const authAdmin = [
  { path: '/createInvoice', element: createInvoice, exact: true },
  { path: '/clients', element: clients, exact: true },
  { path: '/clients/:id', element: clientspayments, exact: true },
  { path: '/createContract', element: createContract, exact: true },
  { path: '/parishes', element: parishes, exact: true },
  { path: '/hostings', element: hostings, exact: true },
  { path: '/settings', element: settings, exact: true },
  { path: '/indicators', element: indicators, exact: true },
  { path: '/messagest', element: messagest, exact: true },
  { path: '/messagesv', element: messagesv, exact: true },
  // { path: '/recruit', element: recruit, exact: true },
  // { path: '/recruit/:id', element: recruitreport, exact: true },
  { path: '/allcontracts', element: allcontracts, exact: true },
  // { path: '/openaigenerateimg', element: openaigenerateimg, exact: true },
  { path: '/openai', element: openai, exact: true },
  { path: '/briefsiteedit/:id', element: briefsiteedit, exact: true },
  { path: '/seo', element: seo, exact: true },
  { path: '/siteslist', element: siteslist, exact: true },
  { path: '/postalitems', element: postalItems, exact: true },
]
export const authRop = [
  { path: '/createInvoice', element: createInvoice, exact: true },
  { path: '/clients', element: clients, exact: true },
  { path: '/clients/:id', element: clientspayments, exact: true },
  { path: '/createContract', element: createContract, exact: true },
  { path: '/indicators', element: indicators, exact: true },
  { path: '/messagest', element: messagest, exact: true },
  { path: '/messagesv', element: messagesv, exact: true },
  // { path: '/recruit', element: recruit, exact: true },
  // { path: '/recruit/:id', element: recruitreport, exact: true },
  { path: '/briefsiteedit/:id', element: briefsiteedit, exact: true },
  { path: '/allcontracts', element: allcontracts, exact: true },
  { path: '/seo', element: seo, exact: true },
  { path: '/siteslist', element: siteslist, exact: true },

]
export const authManager = [
  { path: '/createInvoice', element: createInvoice, exact: true },
  { path: '/createContract', element: createContract, exact: true },
  { path: '/indicators', element: indicators, exact: true },
  // { path: '/recruit/:id', element: recruitreport, exact: true },
  { path: '/siteslist', element: siteslist, exact: true },
  { path: '/clients', element: clients, exact: true },
  { path: '/clients/:id', element: clientspayments, exact: true },
]
export const all = [
  { path: '/siteslist', element: siteslist, exact: true },
]

export const publicRoutes = [
  { path: '/login', element: login, exact: true },
  { path: '/briefclient', element: briefclient, exact: true },
]