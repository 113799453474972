import React, { useEffect, useState } from "react";

import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";

import CommonData from "../components/Clients/ClientCard/CommonData";
import Invoices from "../components/Clients/ClientCard/Invoices";
import Contracts from "../components/Clients/ClientCard/Contracts";
import Hosting from "../components/Clients/ClientCard/Hosting";
import SEO from "../components/Clients/ClientCard/SEO";
import PurchasedServices from "../components/Clients/ClientCard/PurchasedServices";
import Widgets from "../components/Clients/ClientCard/Widgets";
import Payments from "../components/Clients/ClientCard/Payments";
import Briefs from "../components/Clients/ClientCard/Briefs";
import { fetchClient, clearClients } from '../store/clientsSlice'
import { fetchFirma } from '../store/firmasSlice'
import { fetchPaymentPurpose } from '../store/paymentPurposeSlice'
import '../sass/style.sass'

import { dateTransform } from "../components/functions";
const style = {
  tabBut: {
    background: "none",
    border: "none",
  },
  p: {
    marginTop: "-30px"
  }
}

function ClientsPayments() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabId = searchParams.get('tabId');
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearClients());
    dispatch(fetchClient({ id: params.id }))
  }, [dispatch, params.id])

  useEffect(() => {
    dispatch(fetchFirma())
    dispatch(fetchPaymentPurpose())
  }, [dispatch])
  const authName = useSelector(state => state.auth.name)
  const paymentpurpose = useSelector(state => state.paymentpurpose.paymentpurpose)
  const firmas = useSelector(state => state.firmas.firmas)

  const { clients } = useSelector(state => state.clients)
  const [activeTab, setActiveTab] = useState(0);
  const [client, setClient] = useState(null);
  useEffect(() => {
    setClient(clients[0])
  }, [clients])

  const tabs = [
    { id: 1, name: "Общие данные", page: <CommonData data={client} /> },
    { id: 2, name: "Хостинг", page: <Hosting client={client} authName={authName} paymentpurpose={paymentpurpose} firmas={firmas} /> },
    { id: 3, name: "SEO", page: <SEO data={client} /> },
    { id: 4, name: "Приобретённые услуги", page: <PurchasedServices data={client} /> },
    { id: 5, name: "Виджеты", page: <Widgets data={client} /> },
    { id: 6, name: "История", page: <Payments data={client} /> },
    { id: 7, name: "Счета", page: <Invoices /> },
    { id: 8, name: "Договора", page: <Contracts /> },
    { id: 9, name: "Брифы", page: <Briefs data={client} authName={authName} /> },
  ]

  function clickTabBut(i) {
    setActiveTab(i)
  }

  useEffect(() => {
    if (tabId) clickTabBut(+tabId)
  }, [tabId])

  return (
    <section className="pages">
      <Container>
        <h1>{client?.name}</h1>
        <p style={style.p}>{"Дата создания: " + dateTransform(client?.date) + " / id: " + client?.id} </p>
      </Container>
      <Container className="edit-box">
        <div className="tabs-container">
          <div className="tab-buts">
            {tabs.map((t, i) =>
              <button style={style.tabBut} key={t.id} className={activeTab === i ? 'tab-but-active tab-but' : 'tab-but'} onClick={() => clickTabBut(i)}>{t.name}</button>
            )}
          </div>
          <div className="tab-pages">
            {tabs.map((t, i) =>
              activeTab === i ? <div key={t.id} className={activeTab === i ? 'tab-page-active tab-page' : 'tab-page'}>{t.page}</div> : ''
            )}
          </div>
        </div>

      </Container>
    </section >
  );
};

export default ClientsPayments;