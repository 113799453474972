import React, { useState } from "react";

import { Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import SelectSearch from '../UI/SelectSearch/SelectSearch'
const style = {
  label: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    position: 'relative',
    margin: 0,
    top: '1px',
    marginRight: '5px',
    color: '#a7a7a7'
  }
}
// Модуль назначения платежа
function Appoinment({ options, id, deleteAppoint, search = false }) {

  const [value, setValue] = useState('')

  return (
    <>
      <Col lg="7" md="7" className="mb-3">
        <div className="input-style" >
          <label style={style.label}>{id > 0 && <span onClick={() => deleteAppoint(id)} style={style.close} className="close-button">&times;</span>}Назначение платежа</label>
          <SelectSearch options={options} search={search} />
        </div>
      </Col>
      <Col lg="2" md="2" className="mb-3">
        <div className="input-style">
          <label>Сумма</label>
          <input type="number" data-name={'price-' + id} autoComplete="off" min="1" value={value && Math.max(1, value)}
            onChange={e => setValue(e.target.value ? Number(e.target.value) : e.target.value)} />
        </div>
      </Col>
    </>
  );
};

export default Appoinment;