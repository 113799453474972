import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { styles } from "./PDFComponentStyle";
import logo from '../../images/logo.png'

function PDFHeader({ firma, client, clientNumber, date, email, name }) {
  return (
    <>
      <View>
        <Image
          style={styles.logo}
          src={logo}
        />
      </View>
      <View style={styles.rowHeadRec}>
        <View>
          <Text style={styles.recTitle}>Исполнитель и его адрес:</Text>
          <Text style={styles.recText}>{firma}</Text>
        </View>
        <View>
          <Text style={styles.recTitle}>Заказчик и его адрес:</Text>
          <Text style={styles.recText}>{name}</Text>
          <Text style={styles.recText}>{client}</Text>
          <Text style={styles.recText}>{email}</Text>
        </View>
      </View>
      <Text style={[styles.headTitle, styles.mt]}>Счет № {clientNumber} от {date} г.</Text>
    </>
  )
}
export default PDFHeader