import React from 'react';
import { Document, Page, Text, View, Image, } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import logo from '../../images/logo.png'
// Создание стилей
function removeHtmlTags(str) {
  if (!str) return
  return str.replace(/<[^>]*>/g, '');
}
function PDFComponentBrief({ dataPayment }) {

  const sing = require(`../../images/${dataPayment.find(item => item.sing !== undefined).sing}`);
  const contract = dataPayment.find(item => item.contract !== undefined).contract;
  const restElements = dataPayment.filter(item => item.sing === undefined && item.contract === undefined);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Бриф (техническое задание)</Text>
          <Text style={styles.headTitle}>приложение к договору № {contract.num} от {contract.date}</Text>
          {/* <Text style={[styles.headTitle, styles.mb]}>Услуги по настройке контекстной рекламы</Text> */}
          <Text style={[styles.headTitle, styles.mb]}>{contract.name}</Text>
          {restElements.map(el =>
            el.style ?
              <View style={[styles.rowColumn, styles.mb]} key={el.title}>
                {!/^1\.2/.test(el.title) &&
                  <>

                    <Text style={styles.textBold2}>{removeHtmlTags(el.title)}</Text>
                    <Text style={styles.text2}>{el.value !== "" ? el.value : '-'}</Text>
                  </>
                }
              </View> :
              <View style={[styles.rowColumn, styles.mb]} key={el.title + 1}>
                {!/^1\.2/.test(el.title) &&
                  <>
                    <Text style={styles.textBold2}>{removeHtmlTags(el.title)}</Text>
                    <View style={[styles.rowWrap]}>
                      {el.fieldul?.checked.map((ul, i) =>
                        <Text key={el.title + ul + i} style={[styles.textMin, styles.mb]}>{ul ? el.fieldul?.values[i] : '-'}</Text>
                      )}
                    </View>
                  </>
                }
              </View>
          )}
          <Text style={[styles.headTitle, styles.mt]}>ПОДПИСИ СТОРОН:</Text>
          <View style={[styles.row, styles.mt40, styles.mb]}>
            <View>
              <Text style={[styles.recTitle]}>Исполнитель:</Text>
              <Image
                style={styles.image2}
                src={sing}
              />
              <Text style={[styles.recText, styles.mt]}>М.П. ____________________</Text>
            </View>
            <View>
              <Text style={[styles.recTitle]}>Заказчик:</Text>
              <Text style={[styles.recText, styles.mt]}>М.П. ____________________</Text>
            </View>
          </View>
        </View>
      </Page>


    </Document >
  )
}
export default PDFComponentBrief