import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../Seo/styles.module.sass'
import { createEditor, fetchEditor, editEditor, deleteEditors, seoActive, fetchAllEditors } from '../../../store/clientsSEOSlice'
import Loading from '../../../components/UI/Loading'
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SimpleInput from '../../UI/SimpleInput';
import pdfIco from '../../../images/pdf-ico.svg'
import { dateTransformNewDate, getNowDate, sortFromDate } from "../../../components/functions";
const style = {
  buttonSave: {
    marginTop: '20px',
  },
  check: {
    width: '25px',
    height: '25px',
  },
  label: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
  },
  p: {
    marginBottom: "0",
    marginLeft: '10px',
  },
  pdfIco: {
    width: '21px',
  },
  center: {
    textAlign: 'center',
  },
}

function SEO({ data }) {
  const dispatch = useDispatch()
  const seo = useSelector(state => state.seo.editor)
  const seoClients = useSelector(state => state.seo.seoClients)
  const { status, error } = useSelector(state => state.seo)
  const editorRef = useRef()
  const checkId = useRef()
  const authName = useSelector(state => state.auth.name)
  useEffect(() => {
    if (data && data.id) {
      dispatch(fetchEditor(data.id));
      dispatch(fetchAllEditors())
    }
  }, [data, dispatch]);

  const [checkActive, setCheckActive] = useState('false');
  useEffect(() => {
    if (seoClients.length && data) {
      const active = seoClients.find(item => item.id === data.id)
      if (!active) setCheckActive('false');
      if (active) setCheckActive(active.active)
    }

  }, [seoClients, data]);



  const calendar = useRef(null)
  const [toggleEditor, setToggleEditor] = useState(false);

  const [id, setId] = useState('')

  const saveNewReport = useCallback((e) => {
    e.preventDefault()

    const targets = e.target.querySelectorAll('input, textarea');
    const formData = new FormData()

    targets.forEach(inp => {
      if (!inp.dataset.name) formData.append('editor', inp.value)
      else if (checkId.current.checked && inp.dataset.name === 'date') {
        formData.append('date', getNowDate())
      }
      else formData.append(inp.dataset.name, inp.value)
    });
    formData.append('authName', authName)

    if (!id) {
      dispatch(createEditor(formData))
    } else if (checkId.current.checked && id) {
      dispatch(createEditor(formData))
    }
    else {
      formData.append('id', id)
      dispatch(editEditor(formData))
    }

  }, [dispatch, id, authName])


  const [editVal, setEditVal] = useState('')
  const [editDescriptions, setEditDescriptions] = useState('')
  const [editDate, setEditDate] = useState('')

  useEffect(() => {
    new AirDatepicker(calendar.current, {
      dateFormat: "dd.MM.yyyy",
      autoClose: true,
      buttons: ['clear'],
      selectedDates: editDate ? dateTransformNewDate(editDate) : new Date(),
    })
  }, [calendar, toggleEditor, editDate])

  function editEditorFync(e, edit, descriptions, date, idx) {
    e.preventDefault()
    setEditDescriptions(descriptions)
    setEditVal(edit)
    setEditDate(date)
    setToggleEditor(true)
    setId(idx)
  }

  useEffect(() => {
    if (!toggleEditor) {
      setEditVal('')
      setId('')
      setEditDescriptions('')
      setEditDate('')
    }
  }, [toggleEditor])

  async function deleteEditor(id) {
    const { value: password } = await Swal.fire({
      title: 'Чтобы удалить введите пароль',
      input: 'password',
      inputLabel: 'Введите пароль',
      inputPlaceholder: 'Пароль',
      inputAttributes: {
        maxlength: 10,
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    })
    const realPass = '54321'
    if (password) {
      if (realPass === password) {
        dispatch(deleteEditors(id))
      }
    }

  }

  function downloadFDF(e, editor, date) {
    e.preventDefault()
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const pdfMakeContent = htmlToPdfmake(editor);

    const pdfDocumentDefinition = {
      content: [
        pdfMakeContent
      ]
    };
    // pdfMake.createPdf(pdfDocumentDefinition).open();
    pdfMake.createPdf(pdfDocumentDefinition).download(`Отчет-от-${date}-${JSON.parse(data.domains)[0]}.pdf`);
  }
  function changeSeoActive(params) {
    const formData = new FormData()
    formData.append('active', String(params.checked))
    const id = data.id
    dispatch(seoActive({ id, formData }))
  }
  return (
    <>
      <div className="invoicesHead">
        <Row>
          <Col lg="1" className="mb-2" ><Button onClick={() => setToggleEditor(true)}>+</Button></Col>
          <Col lg="3" className="mb-2 d-flex align-items-center" >
            <label style={style.label}>
              <input ref={checkId} style={style.check} type="checkbox" onChange={(e) => changeSeoActive(e.target)} checked={checkActive === 'true' ? true : false} />
              <p style={style.p}>SEO АКТИВНО</p>
            </label>
          </Col>
        </Row>
        {toggleEditor && <Form onSubmit={saveNewReport}><Row className={styles.addReport}>
          <div className={styles.closeAddReport} onClick={() => setToggleEditor(false)}> <span>❌</span>  </div>
          <input type="hidden" data-name="client_id" value={data.id} readOnly />
          <Col lg="2" md="6" className="mb-2">
            <label className={styles.label} htmlFor="calendar">Дата*</label>
            <input id="calendar" className={styles.input + " " + styles.simpleInput} autoComplete="off" ref={calendar} data-name="date" readOnly />
          </Col>
          <Col lg="2" md="4" className="mb-2">
            <div className="input-style">
              <Button style={style.buttonSave} type="submit" variant="success" >Сохранить</Button>
            </div>
          </Col>
          <Col lg="3" md="4" className="mb-2 d-flex align-items-center">
            <label style={style.label}>
              <input ref={checkId} style={style.check} type="checkbox" />
              <p style={style.p}>Сохранить как копию</p>
            </label>
          </Col>
          <Col lg="12" md="4" className='mb-2'>
            <SimpleInput title="Краткое описание" type="text" name="descriptions" value={editDescriptions} />
          </Col>
          <Col lg='12' className='mb-3'>
            {status === 'loading' && <Loading />}
            {error && <h2>{error}</h2>}
            <Editor
              apiKey="j7u6oqk3jari9o9bzic4l46ah72923kq64lrr1a318ibb2hy"
              initialValue={editVal}
              init={{
                language: 'ru',
                selector: 'textarea',
                height: '90vh',
                plugins: [
                  'advlist autolink lists link image charmap preview anchor pagebreak',
                  'searchreplace wordcount visualblocks visualchars code fullscreen',
                  'insertdatetime nonbreaking save table directionality',
                  'emoticons template'
                ].join(' '),
                toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor emoticons | codesample | preview',
                paste_data_images: true,
                automatic_uploads: false,
                file_picker_types: 'image',
                file_picker_callback: function (callback, value, meta) {
                  var input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.onchange = function () {
                    var file = this.files[0];
                    var reader = new FileReader();
                    reader.onload = function (e) {
                      callback(e.target.result, {
                        alt: file.name
                      });
                    };
                    reader.readAsDataURL(file);
                  };
                  input.click();
                },
              }}

              onInit={(evt, editor) => editorRef.current = editor}
            />
          </Col>
          <Col lg="2" md="4" className="mb-2">
            <div className="input-style">
              <Button type="submit" variant="success" >Сохранить</Button>
            </div>
          </Col>
        </Row></Form>}
        <Row>
          <Col lg="1" className="mb-2">Дата</Col>
          <Col lg="7" className="mb-2">Краткое описание</Col>
          <Col lg="2" className="mb-2">Автор</Col>
          <Col lg="1" className="mb-2" style={style.center}>Скачать</Col>
          <Col lg="1" className="mb-2"></Col>
        </Row>
      </div>
      <div className="invoicesBody">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {seo.length ? sortFromDate(seo).map(el =>
          <div key={el.id} className="invoice">
            <Row>
              <Col lg="1">{el.date}</Col>
              <Col lg="7"><a href="!" onClick={(e) => editEditorFync(e, el.editor, el.descriptions, el.date, el.id)}>{el.descriptions}</a></Col>
              <Col lg="2">{el.authName}</Col>
              <Col lg="1" className="justify-content-center"><a href="!" onClick={(e) => downloadFDF(e, el.editor, el.date)}><img style={style.pdfIco} src={pdfIco} alt="" /></a></Col>
              <Col lg="1"><span onClick={() => deleteEditor(el.id)} className="close-button">&times;</span></Col>
            </Row>
          </div>
        ).reverse() :
          <div className="invoice">
            <Row>
              <Col className="justify-content-center" lg="12"><h2>Пока нет не одного отчета</h2></Col>
            </Row>
          </div>
        }
      </div>
    </>
  );
};
export default SEO;