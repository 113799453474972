import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from '../UI/Loading'
import { createSettings, fetchSettings } from "../../store/settingsSlice"

import { alertErr, alert } from "../UI/alert"
import { sortName } from "../functions"
const style = {
  item: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: "12px",
    marginTop: "24px",
  },
  itemManagers: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  managersNameBox: {
    marginTop: "20px",
    // maxWidth: "230px",
    p: {
      marginBottom: "0px"
    }
  }
}

function OurServicesList() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  const { ourServices, status, error } = useSelector(state => state.settings)

  const [valueInputSpheres, getValueInputSpheres] = useState('');
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    valueInputSpheres ? setIsDisabled(false) : setIsDisabled(true)
  }, [valueInputSpheres])

  const saveManager = useCallback(() => {
    const checkNameManager = ourServices.find(n => n.value.toLowerCase() === valueInputSpheres.toLowerCase())
    if (checkNameManager) {
      alertErr.fire({
        text: 'Такая услуга уже есть!',
      })
      getValueInputSpheres('')
      return
    }
    dispatch(createSettings({ services: [...ourServices, { name: "services", value: valueInputSpheres }] }))
    getValueInputSpheres('')
  }, [dispatch, ourServices, valueInputSpheres])

  function deleteManagers(params) {
    alert.fire({
      title: `Точно удалить услугу ${params}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        const newArr = ourServices.filter(m => m.value !== params)
        dispatch(createSettings({ services: newArr }))
      }
    })

  }
  return (
    <Container>
      <Row>
        <Col lg="5" md="6" sm="12">
          <div className="item" style={style.item}>
            <div className="input-style">
              <label>Введите услугу</label>
              <input autoComplete="off" type="text" name="manager" value={valueInputSpheres} onChange={(e) => { getValueInputSpheres(e.target.value) }} />
            </div>
            <Button disabled={isDisabled ? isDisabled : ''} style={style.button} onClick={saveManager}>+</Button>
          </div>
          <div style={style.managersNameBox}>
            {status === 'loading' && <Loading />}
            {error && <h2>{error}</h2>}
            {sortName(ourServices)?.map((m, i) =>
              <div key={m.value + i} style={style.itemManagers}>
                <p style={style.managersNameBox.p}>{m.value}</p>
                <span onClick={() => deleteManagers(m.value)} className="close-button">&times;</span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default OurServicesList;