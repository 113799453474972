import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { fetchClient } from '../store/clientsSlice'
import { fetchFirma } from '../store/firmasSlice'
import { fetchPaymentPurpose } from '../store/paymentPurposeSlice'
function LoadServerData() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFirma())
    dispatch(fetchClient())
    dispatch(fetchPaymentPurpose())
  }, [dispatch])

}

export default LoadServerData