import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link } from 'react-router-dom'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { editContractsDetails, removeContractsDetails } from '../../store/contractsDetailsSlice'
import styles from '../../components/CreateMessagesTelegram/styles.module.sass'
import SelectSearch from '../UI/SelectSearch/SelectSearch';
import { fetchSettings } from "../../store/settingsSlice"
const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
  buttonMin: {
    width: '100%',
    minWidth: '55px'
  },
  a: {
    color: '#000',
    textDecoration: "none"
  },
  span: {
    paddingLeft: "5px"
  },
  center: {
    textAlign: 'center',
  },
}

function ContractList({ contractsDetails }) {

  const { spheres, managers } = useSelector(state => state.settings)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  function activePay(e, contractNum, price, el) {
    const newEl = { ...el }
    function goContact(params) {
      dispatch(editContractsDetails(params));
    }
    if (!e.checked) {
      Swal.fire({
        title: `Точно убрать галочку "ПРЕДОПЛАТА" с договора №${contractNum} на сумму ${price.reduce((accumulator, currentValue) =>
          +accumulator + +currentValue, 0)}  (оплата ${price[0]})?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          e.checked = false
          newEl.pay = false
          goContact(newEl);
        } else {
          e.checked = true
          newEl.pay = true
        }

      })

    }
    if (e.checked) {
      Swal.fire({
        title: `Точно пометить как оплачен по договору №${contractNum} на сумму ${price.reduce((accumulator, currentValue) => +accumulator + +currentValue, 0)} (оплата ${price[0]})?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          e.checked = true
          newEl.pay = true
          goContact(newEl);
        } else {
          e.checked = false
          newEl.pay = false
        }
      })
    }

  }
  function deletePayment(id_contract, contractNum, date) {
    Swal.fire({
      title: `Точно удалить договор № ${contractNum} от ${date}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeContractsDetails(id_contract))
      }
    })

  }
  function saveButtonManager(params, manager) {
    Swal.fire({
      title: `Точно продавец ${manager}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        const newEl = { ...params }
        newEl.manager = manager
        dispatch(editContractsDetails(newEl));
      }
    })
  }

  const [ruCurs, setRuCurs] = useState('')


  useEffect(() => {
    fetch('https://api.nbrb.by/exrates/rates/456?ondate=2023-01-10')
      .then(response => response.json())
      .then(res => {
        setRuCurs(res.Cur_OfficialRate)
      })
  }, [])


  function ruConvert(params) {
    if (params.indexOf("RU") !== -1) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <div className="listHead">
        <Row>
          <Col lg="1" className="mb-2">№</Col>
          <Col lg="4" className="mb-2">Название</Col>
          <Col lg="1" className="mb-2">Сумма</Col>
          <Col lg="1" className="mb-2">1 платеж</Col>
          <Col lg="1" className="mb-2">Дата</Col>
          <Col lg="2" className="mb-2">Продал</Col>
          <Col lg="1" className="mb-2">Оплачен</Col>
        </Row>
      </div>
      <TransitionGroup>
        {contractsDetails.map((elm, i) =>
          <CSSTransition
            key={elm.id_contract}
            timeout={500}
            classNames="formanimation"
          >
            <div className={"listBody"}>
              <Row>
                <Col lg="1">№ {elm.contractNum}</Col>
                <Col lg="4"><Link to={{
                  pathname: '/clients/' + elm.client_id,
                  search: '?tabId=6',
                }}>{elm.clientName}</Link></Col>
                <Col lg="1">{ruConvert(elm.firmaName) ? (+elm.price.reduce((akk, p) => +akk + +p) * +ruCurs / 100).toFixed(0) : elm.price.reduce((akk, p) => +akk + +p)}</Col>
                <Col lg="1">{ruConvert(elm.firmaName) ? (+elm.price[0] * +ruCurs / 100).toFixed(0) : elm.price[0]}</Col>
                <Col lg="1">{elm.date}</Col>
                <Col lg="2"><SelectSearch onChange={(e) => saveButtonManager(elm, e.value)} name="manager" id="manager" options={[{ name: 'manager', value: "" }, ...managers]} defaultValue={{ name: 'manager', value: elm?.manager }} /></Col>
                <Col lg="1"><input title="Была ли предоплата" onChange={(e) => activePay(e.target, elm.contractNum, elm.price, elm)} className={styles.input} type="checkbox" data-name="pay" defaultChecked={elm.pay} value={elm.pay} /></Col>
                <Col lg="1"><span onClick={() => deletePayment(elm.id_contract, elm.contractNum, elm.date)} className="close-button">&times;</span></Col>
              </Row>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup >
    </>
  );
};

export default React.memo(ContractList);