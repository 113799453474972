import React, { useEffect, useState } from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'

function getNumberOfDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

function getQuarterBounds() {
  const date = new Date();
  return getNumberOfDays(new Date().setMonth(date.getMonth() - date.getMonth() % 3, 1), new Date().setMonth(date.getMonth() + 3 - date.getMonth() % 3, 0))
}
//------------------------------------------------
function RevenueQuarter({ indicators }) {

  const quarter = Math.floor(new Date().getMonth() / 3)
  const remainingВays = getNumberOfDays(new Date(new Date().setMonth(new Date().getMonth() - new Date().getMonth() % 3, 1)), new Date())
  const [newIndicatorsArr, setNewIndicatorsArr] = useState([])
  const [colorsArr, setColorsArr] = useState([])

  const plans = [...Array(4)].map(e => 72000)

  useEffect(() => {
    if (indicators.yearQuarter) {
      const nowSum = indicators.yearQuarter[quarter]
      const newArr = indicators.yearQuarter.map(el => el * 0)
      const forecastSum = (nowSum / remainingВays) * getQuarterBounds()
      newArr.splice(quarter, 1, forecastSum)
      setNewIndicatorsArr(newArr)

      const colors = indicators.yearQuarter.map((el, i) => {
        const p1 = plans[i] / 3
        const p2 = plans[i] / 2
        const p3 = plans[i] / 1.2
        if (el < p1) return "rgba(233, 0, 0, 1)"
        if (el > p1 && el < p2) return "rgba(233, 140, 0, 1)"
        if (el > p2 && el < p3) return "rgba(233, 190, 0, 1)"
        if (el > p3) return "rgba(5, 233, 0, 1)"
        return "rgba(233, 0, 0, 1)"
      })
      setColorsArr(colors)
    }
  }, [indicators])




  const barChartData = {
    labels: ["1й квартал", "2й квартал", "3й квартал", "4й квартал"],
    datasets: [
      {
        data: indicators.yearQuarter,
        label: "Выручка",
        borderColor: "#3333ff",
        backgroundColor: colorsArr,
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: plans,
        label: "План",
        borderColor: "#ff3333",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: newIndicatorsArr,
        label: "Прогноз",
        borderColor: "#ff3333",
        backgroundColor: "rgba(124, 185, 232, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      }
    ]
  };
  return (
    <Bar
      type="bar"
      width={130}
      height={50}

      options={{
        plugins: {
          title: {
            display: true,
            text: "Поквартальный отчет за год",
          }
        },
      }}
      data={barChartData}
    />
  )
};

export default RevenueQuarter;