import React, { useCallback } from "react";
import styles from "./SelectSearch.module.sass"

function SelectSearch({ clear = false, id, defaultValue = { value: '', name: 'appoint-default' }, search = false, options, noOptionsMessage = "Данных не найдено!", isDisabled = false, placeholder, onChange }) {

  const [getList, setGetList] = React.useState(options)
  const [inputVal, setInputVal] = React.useState('')
  const [selectLists, setSelectLists] = React.useState([])
  const [openLI, setOpenLI] = React.useState(false)
  const refInput = React.useRef('')
  const refSelectBox = React.useRef('')

  React.useEffect(() => {
    setInputVal(defaultValue.value)
  }, [defaultValue.value])

  React.useEffect(() => {
    if (clear) refInput.current.value = ''
  }, [clear])

  React.useEffect(() => {
    setGetList(options)
    setSelectLists(getList)
    // if (search) refInput.current.value = ''
  }, [options, getList])

  React.useEffect(() => {
    if (search) {
      const fil = getList.filter(v => v.value && v.value.toLowerCase().includes(inputVal.toLowerCase()))
      setSelectLists(fil)
    }
  }, [inputVal, getList, search])

  function toggleSelect() {
    if (!isDisabled) setOpenLI((openLI) => !openLI)
  }

  const getUL = useCallback((val, id = "", name = "", marker = "", cod = "") => {
    refInput.current.value = val
    refInput.current.dataset.id = id
    refInput.current.dataset.name = name
    refInput.current.dataset.marker = marker
    refInput.current.dataset.cod = cod
    if (onChange) onChange({ id: id, value: val, name: name, marker: marker, cod: cod })
    setOpenLI(false)
  }, [onChange, refInput, setOpenLI])

  document.addEventListener('click', (e) => {
    if (refSelectBox.current !== e.target.closest('.input-select')) setOpenLI(false)
  }, true)

  return (
    <div ref={refSelectBox} className={styles.inputSelect + ' input-select ' + (isDisabled ? 'isDisabled' : '')}>
      <input
        className={styles.input}
        disabled={isDisabled}
        placeholder={placeholder}
        ref={refInput}
        type="text"
        defaultValue={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        data-id={id}
        onClick={() => setOpenLI(true)}
        data-name={defaultValue.name}
        data-marker={defaultValue.marker}
        data-cod={defaultValue.cod}
        readOnly={!search ? 'readonly' : ''}
        autoComplete="off"
      />
      <div onClick={toggleSelect} className={styles.inputSelectBut} >
        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="35" fill="white" />
          <path d="M0 5L1.04907e-06 29" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15 15L19.5 19L24 15" stroke="#848484" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <defs>
            <rect width="36" height="35" fill="white" />
          </defs>
        </svg>
      </div>
      {openLI &&
        <ul className={styles.ul}>
          {selectLists.map((li, i) =>
            <li
              className={styles.li + " " + (refInput.current.value === li.value ? styles.active : '')}
              key={li.id + '-' + i}
              onClick={(e) => getUL(e.target.dataset.value, e.target.dataset.id, e.target.dataset.name, e.target.dataset.marker, e.target.dataset.cod)}
              data-name={li.name}
              data-value={li.value}
              data-marker={li.marker}
              data-cod={li.cod}
              data-id={id}
            >{li.value}</li>
          )}
          {!selectLists.length && <li className={styles.noSearch + " " + styles.li}>{noOptionsMessage}</li>}
        </ul>
      }
    </div >
  )
}
export default React.memo(SelectSearch)