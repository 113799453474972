import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchWidgets = createAsyncThunk(
  'widgets/fetchWidgets',
  async function (id, { rejectWithValue }) {

    try {
      const response = await fetch(`https://payment.mediarama.by/core/widgets/get/${id}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const createWidgets = createAsyncThunk(
  'widgets/createWidgets',
  async function (object, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/widgets/create`, {
        method: 'POST',
        body: JSON.stringify(object)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()

      dispatch(addWidgets(JSON.parse(data.data.data)))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось создать запись о счете в базе. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)


export const removeWidgets = createAsyncThunk(
  'widgets/removeWidgets',
  async function ({ formData, id }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/widgets/delete/${id}`, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      let domain = formData.get("domain");
      let widget = formData.get("widget");

      // Создаем новый объект с этими ключами и значениями
      let extractedObject = {
        domain: domain,
        widget: widget
      };

      dispatch(deleteWidgets(extractedObject))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось удалить счет в базе. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const widgetsSlice = createSlice({
  name: 'widgets',
  initialState: {
    widgets: [],
    status: null,
    error: null,
  },
  reducers: {
    addWidgets(state, action) {
      action.payload.forEach(objToAdd => {
        const exists = state.widgets.some(obj => obj.widget === objToAdd.widget && obj.link === objToAdd.link && obj.domain === objToAdd.domain);
        if (!exists) {
          state.widgets.push(objToAdd);
        }
      });
      success.fire({
        text: "Виджет добавлен!",
      });
    },
    deleteWidgets(state, action) {
      state.widgets = state.widgets.filter(item =>
        !(item.domain === action.payload.domain && item.widget === action.payload.widget)
      );
      success.fire({
        text: "Виджет удален!",
      });
    },
  },
  extraReducers: {
    [fetchWidgets.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchWidgets.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.widgets = action.payload
    },
    [createWidgets.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchWidgets.rejected]: setError,
    [createWidgets.rejected]: setError,
    [removeWidgets.rejected]: setError,
  }
})
const { deleteWidgets, addWidgets } = widgetsSlice.actions
export default widgetsSlice.reducer