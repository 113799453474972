import React, { useState } from "react";

import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateInvoice from '../components/Invoice/CreateInvoice';

import Loading from '../components/UI/Loading'
import LoadServerData from '../components/LoadServerData'

import { useSelector } from 'react-redux';
import SelectSearch from '../components/UI/SelectSearch/SelectSearch'


function AddInvoice() {
  const { status, error } = useSelector(state => state.clients)
  const [invoice, setInvoice] = useState("1")

  LoadServerData()

  return (
    <section className="pages">
      <Container>
        <h1>Создать счет</h1>
        <Row>
          <Col lg="2" className="mb-3">
            <div className="input-style" >
              <label>Тип счета</label>
              <SelectSearch options={[
                { value: 'Обычный', name: '1' }, { value: 'Пополнение Я/G', name: '2' },
                { value: 'Пополнение Instagram', name: '3' },
                { value: 'Оплата RBot, RQuiz', name: '4' },
                { value: 'Оплата хостинга', name: '5' },
              ]} defaultValue={{ value: 'Обычный', name: '1' }} onChange={(e) => setInvoice(e.name)} />
            </div>
          </Col>
        </Row>

      </Container>
      <Container className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {invoice === '1' && <CreateInvoice page={"1"} />}
        {invoice === '2' && <CreateInvoice page={"2"} />}
        {invoice === '3' && <CreateInvoice page={"3"} />}
        {invoice === '4' && <CreateInvoice page={"4"} />}
        {invoice === '5' && <CreateInvoice page={"5"} />}
      </Container>
    </section >
  );
};

export default AddInvoice;