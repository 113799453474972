import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Textarea from "../UI/Textarea"
import style from './style.module.sass'

function BriefMapForm({ thisClient }) {
  return (
    <Row className={"align-items-center"}>
      <Col lg="12" className="mb-3">
        <h2 className="title">1.	Информация о компании</h2>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.1 Название компании полное
          <br /><span className='sub'>(название бренда мы будем использовать в объявлениях, на баннерах и других рекламных материалах. Если у Вас есть логотип, обязательно отправьте его нам)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-1" height={"50px"} value={thisClient?.name} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.2 Реквизиты и УНП организации<br /><span className='sub'>(все официальные документы мы будем выписывать, указывая данное название/имя)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-2" height={"140px"} value={thisClient?.data} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.3 Вид рекламы<br /><span className='sub'>(Выберете места размещения)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <ul className={style.ul}>
          <li><label htmlFor="brief_map-1-3">
            <input className='checkbox' type="checkbox" id="brief_map-1-3" name="brief_map-1-3" data-name="brief_map-1-3" value="Яндекс" />
            <p>Яндекс</p>
          </label></li>
          <li><label htmlFor="brief_map-1-4">
            <input className='checkbox' type="checkbox" id="brief_map-1-4" name="brief_map-1-4" data-name="brief_map-1-4" value="Google" />
            <p>Google</p>
          </label></li>
          <li><label htmlFor="brief_map-1-5">
            <input className='checkbox' type="checkbox" id="brief_map-1-5" name="brief_map-1-5" data-name="brief_map-1-5" value="РСЯ" />
            <p>РСЯ</p>
          </label></li>
          <li><label htmlFor="brief_map-1-6">
            <input className='checkbox' type="checkbox" id="brief_map-1-6" name="brief_map-1-6" data-name="brief_map-1-6" value="КМС" />
            <p>КМС</p>
          </label></li>
          <li><label htmlFor="brief_map-1-7">
            <input className='checkbox' type="checkbox" id="brief_map-1-7" name="brief_map-1-7" data-name="brief_map-1-7" value="YouTube" />
            <p>YouTube</p>
          </label></li>
        </ul>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.4 Бюджет в день
          <br /><span className='sub'>(укажите желаемый дневной расход на рекламу, можно указать цифры «от-до»)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-8" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.5 Адрес сайта
          <br /><span className='sub'>(Доменное имя)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-9" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.6 Что рекламируем?
          <br /><span className='sub'>(Подробно опишите какой продукт более важен для Вас, а какой не интересен.)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-10" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.7 Регионы показа
          <br /><span className='sub'>(Подробно опишите какой регион Вам интересен. Это могут быть страны, области, города, районы, радиус вокруг точки и т.д.)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-11" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.8 Время работы
          <br /><span className='sub'>(Укажите время работы и выходные дни)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-12" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.9 Время показа рекламы
          <br /><span className='sub'>(Рекомендуем показывать рекламу круглосуточно, или и с корректировкой по времени суток, например: «показываем круглосуточно, но ночью дешевле на 50%)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-13" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.0 E-mail для оповещений
          <br /><span className='sub'>(Укажите почту, на которую нам стоит отправлять уведомления о пополнении кабинетов, расходе средств, и другую техническую информацию)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-0" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.1 E-mail для клиентов
          <br /><span className='sub'>(Этот E-mail мы будем указывать в объявлениях, на баннерах и других рекламных материалах)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-1" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.2 Телефон для клиентов
          <br /><span className='sub'>(Этот номер телефона мы будем указывать в объявлениях, на баннерах и других рекламных материалах)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-2" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.3 Адрес торговой точки/офиса/объекта
          <br /><span className='sub'>(Этот адрес мы будем указывать в объявлениях, на баннерах и других рекламных материалах)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-3" height={"120px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.4 Ссылка на Яндекс и Google карты
          <br /><span className='sub'>(Если ваша компания зарегистрирована в Яндекс и Google картах, укажите ссылки на страницы)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-4" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.5 Уникальное торговое предложение <br /><span className='sub'>(Укажите акции, скидки, спецпредложения, удобства, дополнительный сервис, которые отличают Вас от конкурентов. Ответьте на вопрос: почему я должен купить у Вас?)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-5" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.6 На вашем сайте установлена Яндекс Метрика и/или Google Analytics? Есть ли от них доступы?</p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-6" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.7 Был ли у вас опыт работы с Яндекс Директ и/или Google ADS? <br /><span className='sub'>(если Вы уже рекламировались в Яндекс Директ или Google ADS, мы можем провести аудит рекламных кабинетов, чтобы выявить возможные проблемы и учесть прежний опыт. Вы можете указать здесь логин и пароль Яндекс Директ или дать доступ к кабинету Google ADS для логина max.media.rama@gmail.com)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-6" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.8 Доп. Инфа <br /><span className='sub'>(тут можно указать любую информацию, которая по Вашему мнению важна для нас)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-7" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.9 Опишите вашего потенциального клиента <br /><span className='sub'>(пол, возраст, предпочтения…)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-8" height={"100px"} />
      </Col>
    </Row>
  );
};
export default BriefMapForm;