import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from "./PDFComponentStyle";

function PDFPointHead() {
  return (
    <View style={[styles.rowHead, styles.bold, styles.header]}>
      <View style={styles.rowHeader1}><Text>Наименование услуги</Text></View>
      <View style={[styles.rowHeader1, styles.rowHeader2]}><Text>Ед.изм.</Text></View>
      <View style={[styles.rowHeader1, styles.rowHeader2]}><Text>Кол-во</Text></View>
      <View style={[styles.rowHeader1, styles.rowHeader3]}><Text>Цена</Text></View>
      <View style={[styles.rowHeader1, styles.rowHeader3]}><Text>Сумма без НДС, руб.</Text></View>
      <View style={[styles.rowHeader1, styles.rowHeader3]}><Text>Ставка НДС, %</Text></View>
      <View style={[styles.rowHeader1, styles.rowHeader3]}><Text>Сумма НДС, руб.</Text></View>
      <View style={[styles.rowHeader1, styles.rowHeader3]}><Text>Сумма с НДС, руб.</Text></View>
    </View>
  )
}
export default PDFPointHead