import React from "react";

import { Link } from 'react-router-dom'

import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { dateTransform } from '../functions'

const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
  buttonMin: {
    width: '100%',
    minWidth: '55px'
  },
  a: {
    color: '#000',
    textDecoration: "none"
  },
  span: {
    paddingLeft: "5px"
  }
}

function HostingsList({ clients, hosting, }) {

  // Создаем объект для быстрого доступа к архивному статусу клиентов по их ID
  const clientArchiveStatus = clients.reduce((acc, client) => {
    acc[client.id] = client.archive;
    return acc;
  }, {});

  // Фильтруем массив hostingRevers, исключая записи с client_id соответствующими архивным клиентам
  const filteredHostingRevers = hosting.filter(hosting => {
    // Исключаем записи, где client_id соответствует клиенту с archive === "false"
    return clientArchiveStatus[hosting.client_id] === "false" || clientArchiveStatus[hosting.client_id] === "";
  });

  function getNameClients(id) {
    return clients.filter(cl => cl.id === id)
  }
  function getObject(json) {
    let domains = ''
    JSON.parse(json).forEach(el => {
      domains += el.domain + ' '
    });
    return domains
  }
  function fieldDomain(dom) {
    window.open('https://' + dom, '_blank')
  };
  return (
    <>
      <div className="listHead">
        <Row>
          <Col lg="1" className="mb-2">Дата</Col>
          <Col lg="3" className="mb-2">Название</Col>
          <Col lg="2" className="mb-2">Домен</Col>
          <Col lg="2" className="mb-2">Телефон</Col>
          <Col lg="2" className="mb-2">email</Col>
          <Col lg="1" className="mb-2">Сумма</Col>
        </Row>
      </div>
      <TransitionGroup>
        {filteredHostingRevers.map((elm, i) =>
          <CSSTransition
            key={elm.id}
            timeout={500}
            classNames="formanimation"
          >
            <div className={"listBody"}>
              <Row>
                <Col lg="1"><span style={style.span}>{dateTransform(elm.unix)}</span></Col>
                <Col lg="3"><Link to={{
                  pathname: '/clients/' + elm.client_id,
                  search: '?tabId=1',
                }}>{getNameClients(elm.client_id)[0]?.name}</Link></Col>
                <Col lg="2" onDoubleClick={() => fieldDomain(getObject(elm.domain))}>{getObject(elm.domain)}</Col>
                <Col lg="2"> <a style={style.a} href={'tel:+' + getNameClients(elm.client_id)[0]?.phone.replace(/[-() +.]/g, '')} target="_blank" rel="noopener noreferrer">{getNameClients(elm.client_id)[0]?.phone}</a></Col>
                <Col lg="2">{getNameClients(elm.client_id)[0]?.email}</Col>
                <Col lg="1">{elm.price} руб</Col>
                <Col lg="1">{elm.del === "true" ? "💣" : (elm.block === "true" ? "🔒" : "") + (elm.debt === "true" ? "❗️" : "")}</Col>
              </Row>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup >
    </>
  );
};

export default React.memo(HostingsList);