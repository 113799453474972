import React, { useCallback, useEffect } from "react";

import { alertErr } from '../UI/alert'

import SimpleInput from '../UI/SimpleInput';
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import SelectSearch from '../UI/SelectSearch/SelectSearch';
import { createClient, fetchClient } from '../../store/clientsSlice'
import { fetchSettings } from "../../store/settingsSlice"

const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
}

function CreateClient() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])
  const managers = useSelector(state => state.settings.managers)

  const addClientSend = useCallback((e) => {
    e.preventDefault()

    const targets = e.target.querySelectorAll('input, textarea');
    const formData = new FormData()
    const err = new Set()
    targets.forEach(inp => {
      if (inp.dataset.name !== 'telegram') if (!inp.value) err.add(inp.dataset.name)
      formData.append(inp.dataset.name, inp.value)
    });
    if (err.size > 0) {
      alertErr.fire({
        text: 'Заполните все обязательные поля!',
      })
      return
    }
    dispatch(createClient(formData))
    dispatch(fetchClient())
    e.target.querySelectorAll('input,textarea').forEach(i => i.value = '');
  }, [dispatch])

  return (
    <Form onSubmit={(e) => addClientSend(e)}>
      <Row>
        <Col lg="3" md="6" className="mb-3">
          <SimpleInput title="Название" name="name" />
        </Col>
        <Col lg="2" md="6" className="mb-3">
          <SimpleInput title="email" type="email" name="email" />
        </Col>
        <Col lg="2" md="6" className="mb-3">
          <div className="input-style">
            <label>Телефон</label>
            <InputMask mask="+375 99 999 99 99" data-name={'phone'} />
          </div>
        </Col>
        <Col lg="2" md="6" className="mb-3">
          <SimpleInput title="Telegram" type="number" name="telegram" placeholder="telegram id" />
        </Col>
        <Col lg="2" md="6" className="mb-2">
          <label htmlFor="manager">Продал менеджер</label>
          <SelectSearch name="manager" id="manager" options={managers} />
        </Col>
        <Col sm="2" lg="1" md="1" className="mb-3">
          <div className="input-style">
            <label>Добавить</label>
            <Button style={style.button} type="submit">+</Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(CreateClient);