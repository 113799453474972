import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import { all, publicRoutes, authAdmin, authRop, authManager } from '../routes'

import Layout from '../pages/Layout'
import { useSelector } from 'react-redux';
import Menu from '../components/Menu/Menu';
export function AppRouter() {
  const { auth, privat } = useSelector(state => state.auth)
  const location = useLocation();
  const [thisPage, setThisPage] = useState("/indicators")

  useEffect(() => {
    localStorage.setItem('page', location.pathname)
    if (auth) setThisPage(localStorage.getItem('page'))
  }, [location, auth])

  const routersNav = () => {
    if (auth && privat === "admin") {
      return (
        <Routes>
          <Route element={<Layout />}>
            {authAdmin.map(route =>
              <Route
                path={route.path}
                element={<route.element />}
                exact={route.exact}
                key={route.path}
              />
            )}
            <Route path='*' element={<Navigate to={thisPage} replace />} />
          </Route>
        </Routes>
      )
    } else if (auth && privat === "rop") {
      return (
        <Routes>
          <Route element={<Layout />}>
            {authRop.map(route =>
              <Route
                path={route.path}
                element={<route.element />}
                exact={route.exact}
                key={route.path}
              />
            )}
            <Route path='*' element={<Navigate to={thisPage} replace />} />
          </Route>
        </Routes>
      )
    }
    else if (auth && privat === "manager") {
      return (
        <Routes>
          <Route element={<Layout />}>
            {authManager.map(route =>
              <Route
                path={route.path}
                element={<route.element />}
                exact={route.exact}
                key={route.path}
              />
            )}
            <Route path='*' element={<Navigate to={thisPage} replace />} />
          </Route>
        </Routes>
      )
    } else if (auth && privat === "all") {
      return (
        <Routes>
          <Route element={<Layout />}>
            {all.map(route =>
              <Route
                path={route.path}
                element={<route.element />}
                exact={route.exact}
                key={route.path}
              />
            )}
            <Route path='*' element={<Navigate to="/siteslist" replace />} />
          </Route>
        </Routes>
      )
    } else {
      return (
        <Routes>
          <Route element={<Layout />}>
            {publicRoutes.map(route =>
              <Route
                path={route.path}
                element={<route.element />}
                exact={route.exact}
                key={route.path}
              />
            )}
            <Route path='*' element={<Navigate to="/login" replace />} />
          </Route>
        </Routes>
      )
    }
  }


  return (
    <>
      <Menu />
      {routersNav()}
    </>
  );
}
