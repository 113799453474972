import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";

import Appoinment from './Appoinment';

import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { alertErr } from '../UI/alert'

import { useSelector, useDispatch } from 'react-redux';
import SelectSearch from '../UI/SelectSearch/SelectSearch'


import PDFComponent from '../PDFComponentsInvoice/PDFComponent'
import { PDFDownloadLink } from '@react-pdf/renderer';

import { createInvoicesDetails } from '../../store/invoicesDetailsSlice'

import load from '../../images/1.svg'
import loadOk from '../../images/2.svg'

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

const style = {
  button: {
    width: '80px'
  },
  buttonSend: {
    width: '157px'
  },
  img: {
    marginRight: '5px'
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
  },
  check: {
    width: "25px",
    height: "25px",
    cursor: "pointer",
    marginRight: "6px",
  },
  checkTitle: {
    fontSize: "10px",
  }

}

const calendarOptions = {
  autoClose: true,
  view: "days",
  minView: "months",
  dateFormat: "dd.MM.yyyy",
  multipleDatesSeparator: " - ",
  buttons: ['clear'],
  selectedDates: new Date()
}
function CreateInvoice({ page }) {

  const dispatch = useDispatch()
  const authName = useSelector(state => state.auth.name)
  const clients = useSelector(state => state.clients.clients)
  const firmas = useSelector(state => state.firmas.firmas)
  const paymentpurpose = useSelector(state => state.paymentpurpose.paymentpurpose)
  const [paymentPurp, setPaymentPurp] = useState([])
  const [activeSing, setActiveSing] = useState(false)
  useEffect(() => {
    if (paymentpurpose.length) {
      setPaymentPurp(() => paymentpurpose.filter(el => el.affiliation === page))
    }
  }, [paymentpurpose, page])

  const [clientName, setClientName] = useState('')

  useMemo(() => {
    const clientArr = clients.map(el => {
      return {
        name: 'client-' + el.id,
        value: el.name
      }
    })
    setClientName(clientArr)
  }, [clients])

  const [firmaName, setFirmaName] = useState('')
  useMemo(() => {
    const firmaArr = firmas.map(el => {
      return {
        name: 'firma-' + el.id,
        value: el.name
      }
    })
    setFirmaName(() => {
      if (page === "2" || page === "3") return [firmaArr[0]]
      if (page === "4" || page === "5") return [firmaArr[1]]
      else return firmaArr
    })
  }, [firmas, page])

  const [addAppoinment, setAddAppoinment] = useState([{ id: 0 }])

  function addAppoinmentFunc() {
    setAddAppoinment([...addAppoinment, {
      id: addAppoinment.length
    }])
  }

  const [dataPayment, setDataPayment] = useState('')

  const createPDF = useCallback((e) => {
    e.preventDefault()
    const targets = e.target.querySelectorAll('input');
    const formData = new FormData()
    targets.forEach(inp => {
      formData.append(inp.dataset.name, inp.value)
      if (inp.dataset.marker) formData.append('marker', inp.dataset.marker)
      if (inp.dataset.cod) formData.append('cod', inp.dataset.cod)
    });
    const object = {
      page: page,
      authName: authName,
      client_id: '',
      cod: '',
      marker: '',
      name: '',
      client: '',
      firmaName: '',
      firma: '',
      date: '',
      email: '',
      sing: '',
      clientNumber: '',
      appoint: [],
      price: [],
      activeSing: activeSing
    }

    for (let [name, value] of formData) {
      if (!value) {
        alertErr.fire({
          text: 'Нужно заполнить все поля!',
        })
        return
      }
      const n = name.split("-")[0]
      if (n === 'client') {

        const nameClient = clients.filter(el => el.id === name.split("-")[1]).map(el => el.name)
        const client = clients.filter(el => el.id === name.split("-")[1]).map(el => el.data)
        const email = clients.filter(el => el.id === name.split("-")[1]).map(el => el.email)

        const clientNumber = clients.filter(el => el.id === name.split("-")[1]).map(el => el.date)
        object.client = client.join()
        object.name = nameClient.join()
        object.email = email.join()
        object.client_id = name.split("-")[1]
        object.clientNumber = clientNumber.join()
      }
      if (n === 'firma') {
        const firma = firmas.filter(el => el.id === name.split("-")[1]).map(el => el.data)
        const firmaName = firmas.filter(el => el.id === name.split("-")[1]).map(el => el.name)
        const sing = firmas.filter(el => el.id === name.split("-")[1]).map(el => el.sing)
        object.firma = firma.join()
        object.firmaName = firmaName.join()
        object.sing = sing.join()
      }
      if (n === 'appoint') object.appoint.push(value)
      if (n === 'price') object.price.push(value)
      if (name === 'date') object.date = value
      if (name === 'marker') object.marker = value
      if (name === 'cod') object.cod = value
    }
    setDataPayment(object)
    dispatch(createInvoicesDetails(object))
  }, [authName, activeSing, clients, firmas, page])



  const deleteAppoint = useCallback((id) => {
    const newArrAppoint = addAppoinment.filter(el => el.id !== id)
    setAddAppoinment(newArrAppoint)
  }, [addAppoinment])

  const newPDF = useCallback(() => {
    setTimeout(() => {
      setDataPayment('')
    }, 1000)
  }, [])

  const calendar = useRef(null)
  useEffect(() => {
    new AirDatepicker(calendar.current, calendarOptions)
  }, [calendar])



  return (
    <Form onSubmit={(e) => createPDF(e)}>
      <Row>
        <Col lg="4" md="4" className="mb-3">
          <div className="input-style">
            <label>Фирма</label>
            <SelectSearch options={firmaName} defaultValue={page === "2" || page === "3" || page === "4" || page === "5" ? firmaName[0] : ""} />
          </div>
        </Col>
        <Col lg="4" md="4" className="mb-3">
          <div className="input-style">
            <label>Клиент</label>
            <SelectSearch options={clientName} search={true} />
          </div>
        </Col>
        <Col lg="2" md="2" className="mb-3">
          <div className="input-style">
            <label>Дата</label>
            <input readOnly autoComplete="off" type="text" data-name="date" ref={calendar} />
          </div>
        </Col>
        {addAppoinment.map(el =>
          <Appoinment options={paymentPurp} key={el.id} id={el.id} deleteAppoint={deleteAppoint} search={true} />
        )}
        <Col lg="2" md="2" className="mb-3">
          {page !== "3"
            &&
            <div className="input-style">
              <label>Добавить</label>
              <Button style={style.button} onClick={addAppoinmentFunc}>+</Button>
            </div>

          }
          {page === "1"
            &&
            <div style={style.checkBox}>
              <input style={style.check} type="checkbox" onChange={e => setActiveSing(e.target.checked)} />
              <label style={style.checkTitle}>Ссылка на договор</label>
            </div>
          }
        </Col>
        <Col lg="12" md="12" className="mb-3">
          {
            dataPayment ?
              <PDFDownloadLink document={<PDFComponent data={dataPayment} page={page} activeSing={activeSing} />} fileName={"Счет-" + dataPayment.name + '-' + dataPayment.date + '.pdf'}>
                {({ loading }) => (loading ? <Button className="buttonAnimation" style={style.buttonSend} type="submit"><img style={style.img} src={load} alt="" />Загрузка.....</Button> : <Button style={style.buttonSend} onClick={newPDF} variant="success"><img style={style.img} src={loadOk} alt="" />Скачать</Button>)}
              </PDFDownloadLink>
              : <Button style={style.buttonSend} type="submit"><img style={style.img} src={load} alt="" />Создать счет</Button>
          }
        </Col>
      </Row>
    </Form >
  );
};

export default CreateInvoice;