import React, { useEffect } from "react";
import { Chart as ChartJS } from 'chart.js/auto'
import { Container, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useSelector, useDispatch } from 'react-redux';
import Loading from '../components/UI/Loading'

import RevenueMonth from '../components/Indicators/RevenueMonth'
import RevenueQuarter from '../components/Indicators/RevenueQuarter'
import Managers from '../components/Indicators/Managers'
import RatioMonth from '../components/Indicators/RatioMonth'
import RatioManagers from '../components/Indicators/RatioManagers'
import RatioManagersPrognose from '../components/Indicators/RatioManagersPrognose'
import RatioQuarter from '../components/Indicators/RatioQuarter'
import TotalVolume from '../components/Indicators/TotalVolume'
import TotalVolumeDev from '../components/Indicators/TotalVolumeDev'
import TotalVolumeDevMonth from '../components/Indicators/TotalVolumeDevMonth'
import TotalVolumeRefill from '../components/Indicators/TotalVolumeRefill'
import TotalVolumeThisMonth from '../components/Indicators/TotalVolumeThisMonth'
import ContractsAnalytics from '../components/Indicators/ContractsAnalytics'
import Products from '../components/Indicators/Products'
import { fetchIndicators } from '../store/indicatorsSlice'

function Indicators() {
  const dispatch = useDispatch()
  const { indicators, status, error } = useSelector(state => state.indicators)
  useEffect(() => {
    dispatch(fetchIndicators())
  }, [dispatch])
  return (
    <section className="pages">
      <Container>
        <h1>Показатели</h1>
      </Container>
      <Container className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        <Row>
          <Col lg="12" className="mb-5">
            <ContractsAnalytics />
          </Col>
          <Col lg="12" className="mb-5">
            <RevenueMonth indicators={indicators} />
          </Col>
          <Col lg="12" className="mb-5">
            <RevenueQuarter indicators={indicators} />
          </Col>
          <Col lg="12" className="mb-5">
            {indicators.fieldsReports && <Products indicators={indicators} />}
          </Col>
          <Col lg="12" className="mb-5">
            {indicators.managers && <Managers indicators={indicators} />}
          </Col>
          <Col lg="12" className="mb-5">
            {indicators.managers && <RatioMonth indicators={indicators} />}
          </Col>
          <Col lg="12" className="mb-5">
            {indicators.managers && <RatioQuarter indicators={indicators} />}
          </Col>
          <Col lg="12" className="mb-5">
            {indicators.managers && <RatioManagers indicators={indicators} />}
          </Col>
          <Col lg="12" className="mb-5">
            {indicators.totalVolumeThisMonth && <RatioManagersPrognose indicators={indicators} />}
          </Col>
          <Col lg="6" className="mb-5">
            {indicators.managers && <TotalVolume indicators={indicators} />}
          </Col>
          <Col lg="6" className="mb-5">
            {indicators.managers && <TotalVolumeThisMonth indicators={indicators} />}
          </Col>
          <Col lg="6" className="mb-5">
            {indicators.fieldsReports && <TotalVolumeDev indicators={indicators} />}
          </Col>
          <Col lg="6" className="mb-5">
            {indicators.fieldsReports && <TotalVolumeDevMonth indicators={indicators} />}
          </Col>
          <Col lg="6" className="mb-5">
            {indicators.fieldsReports && <TotalVolumeRefill indicators={indicators} />}
          </Col>
        </Row>
      </Container>
    </section >
  );
};

export default Indicators;