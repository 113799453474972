export default function TelegramMessages(mess) {
  const token = process.env.REACT_APP_TELEGRAM_TOKEN
  const chat_id = process.env.REACT_APP_CHAT_ID_PAYMENT_GROUP;
  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${mess}`;
  const oReq = new XMLHttpRequest();
  oReq.open("GET", url, true);
  oReq.send();
}

export function TelegramMessagesPhoto(chat_id, mess = '', file = '', document = ['sendPhoto', 'photo']) {
  const token = process.env.REACT_APP_TELEGRAM_TOKEN;
  const sendFile = () =>{
    const url_photo = `https://api.telegram.org/bot${token}/${document[0]}`
    const formData = new FormData()
    formData.append('chat_id', chat_id)
    formData.append(document[1], file)
    fetch(url_photo, {
      method: 'POST',
      body: formData
    })
    .then((res) => {
      console.log('Send ok!');
    })
    .catch((err) => {
      console.log('Send error! ' + err);
    })
  }
  const sendText = () =>{
    const url_text = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${mess}&parse_mode=html`;
    const oReq = new XMLHttpRequest();
    oReq.open("GET", url_text, true);
    oReq.send();
  }
  if(!file){
    sendText()
  }else if(mess && file){
    sendFile()
    setTimeout(() => {
      sendText()
    }, 500);
    
  }else{
    sendFile()
  } 
}