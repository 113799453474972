import React, { useState, useMemo, useEffect } from "react";

import { Row, Col, Container, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import ContractList from '../components/Contracts/ContractList';

import Loading from '../components/UI/Loading'

import { useSelector, useDispatch } from 'react-redux';

import { fetchAllEditors } from '../store/clientsSEOSlice'
import { fetchClient } from '../store/clientsSlice'

import 'air-datepicker/air-datepicker.css';

import { fetchContractsDetails } from '../store/contractsDetailsSlice'

const style = {
  button: {
    width: '100%',
    maxWidth: '80px',
  },
  buttonMin: {
    width: '100%',
    minWidth: '55px',
  },
  form: {
    background: 'rgba(7, 187, 255, .1)',
    padding: "10px 10px 5px 10px",
    marginBottom: "5px",
  },
  h2: {
    textAlign: "center",
    marginTop: "74px",
    color: "#999",
  },
  clearInputSearch: {
    position: 'absolute',
    right: '5px',
    top: '21px',
    bottom: '0',
    margin: 'auto',
    height: '32px',
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

  }
}
function AllContracts() {
  const dispatch = useDispatch()
  const { clients } = useSelector(state => state.clients)
  const { contractsDetails, status, error } = useSelector(state => state.contractsdetails)
  let revers = [...contractsDetails].reverse()
  useEffect(() => {
    dispatch(fetchClient())
    dispatch(fetchAllEditors())
    dispatch(fetchContractsDetails())
  }, [dispatch])

  const [searchVal, setSearchVal] = useState('')

  const contractsDetailsArr = useMemo(() => {
    return revers.filter(el => {
      if (el.clientName.toLowerCase().includes(searchVal) || el.clientName.includes(searchVal)) return el
      if (el.firmaName.toLowerCase().includes(searchVal) || el.firmaName.includes(searchVal)) return el
      if (el.firmaInFace.toLowerCase().includes(searchVal) || el.firmaInFace.includes(searchVal)) return el
      if (el.services.toLowerCase().includes(searchVal) || el.services.includes(searchVal)) return el
      if (el.date.toLowerCase().includes(searchVal) || el.date.includes(searchVal)) return el
      if (String(el.date).includes(searchVal)) return el
    });
  }, [revers, searchVal])

  function clearInputSearch() {
    setSearchVal('')
  }

  return (
    <section className="pages">
      <Container>
        <h1>Все договора</h1>

        <Form style={style.form}>
          <Row>
            <Col lg="12" className="mb-3">
              <div className="input-style" >
                <label>Поиск</label>
                <input type="text" placeholder="Поиск..." name="field"
                  onChange={(e) => setSearchVal(e.target.value)} value={searchVal}
                />
                <div style={style.clearInputSearch} onClick={clearInputSearch}>&#10006;</div>
              </div>
            </Col>
          </Row>
        </Form>

      </Container>
      <Container className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {
          contractsDetailsArr.length ?
            <ContractList contractsDetails={contractsDetailsArr} />
            : (<h2 style={style.h2}>По данному запросу ничего не найдено!</h2>)
        }
      </Container>

    </section >
  );
};

export default AllContracts;