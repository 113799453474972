import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import { rubles } from "rubles";
import logo from '../../images/logo.png'
import { toFixed, thisDateYers } from "../functions";

import PDFFooterSingFull from "./PDFFooterSingFull"

function PDFComponentContractProgramming({ dataPayment }) {
  const found = dataPayment.firmaName.includes("ХитГрупп");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Договор № С-{dataPayment.contractNum}</Text>
          <Text style={[styles.headTitle, styles.mb]}>На предоставление услуг в области программирования</Text>
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>
          <Text style={styles.text}>{dataPayment.firmaName.replace(/\sRU$/, "")}, именуемый в дальнейшем «Исполнитель», в лице {dataPayment.firmaInFace}, {dataPayment.firmaFooting} с одной стороны, и {dataPayment.clientName}, именуемый в дальнейшем «Заказчик», в лице {dataPayment.clientInFace}, действующего на основании {dataPayment.clientFooting}, с другой стороны, именуемые в дальнейшем Стороны, заключили настоящий Договор (далее Договор) о нижеследующем:</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>1. Предмет договора</Text>

          <Text style={styles.text}>1.1 В соответствии с условиями Договора Исполнитель выполняет по заданию Заказчика Работы и передает их результаты Заказчику, а Заказчик принимает результаты выполненных работ и оплачивает их.</Text>
          <Text style={styles.text}>1.2	Перечень Работ, который может быть выполнен по Договору:</Text>
          <Text style={styles.text}>1.2.1	работы связанные с интеграцией, настройкой, разработкой интерактивных виджетов;</Text>
          <Text style={styles.text}>1.2.2	работы связанные с поддержкой и сопровождением сайтов и веб-приложений;</Text>
          <Text style={styles.text}>1.2.3	работы связанные с интеграцией разработанных ООО «ХитГрупп» собственных решений на сайты и веб-приложения;</Text>
          <Text style={styles.text}>1.2.4	работы связанные с повышением конверсии сайтов, сбор данных, формирование аналитики;</Text>
          <Text style={styles.text}>1.2.5	работы связанные с SEO-продвижением сайтов;</Text>
          <Text style={styles.text}>1.2.6	работы связанные с внутренней оптимизацией сайтов;</Text>
          <Text style={styles.text}>1.2.7	работы связанные с дизайном и версткой сайтов и веб-приложений;</Text>
          <Text style={styles.text}>1.3	Конкретный перечень работ, составляющих задание Заказчика, согласовывается сторонами в приложениях к настоящему договору, являющихся его неотъемлемой частью, которыми, в частности, могут быть счета.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2. Права и обязанности сторон</Text>

          <Text style={styles.text}>2.1	ИСПОЛНИТЕЛЬ:</Text>
          <Text style={styles.text}>2.1.1	обязуется качественно и в срок оказывать услуги, являющиеся предметом Договора;</Text>
          <Text style={styles.text}>2.1.2	вправе отказаться от выполнения Работ, если предоставленные Заказчиком материалы необходимые для   выполнения работ по Договору, по мнению Исполнителя, не соответствуют требованиям законодательства Республики Беларусь при условии письменного обоснования Исполнителем такого отказа;</Text>
          <Text style={styles.text}>2.1.3	обязуется предоставлять Заказчику право знакомиться с ходом оказания услуг по настоящему Договору при условии предварительного согласования с Исполнителем. Ответы на электронные письма и консультации Заказчика по телефону или на встрече осуществляются в рабочие дни с 9.00 до 17.00.</Text>
          <Text style={styles.text}>2.2 ЗАКАЗЧИК:</Text>
          <Text style={styles.text}>2.2.1	своевременно предоставляет и утверждает все материалы, необходимые для выполнения Работ по договору, в течение 3-х рабочих дней с момента поступления заявки от Исполнителя по электронной почте. Если с момента подписания Договора прошло более 30 (тридцати) календарных дней и Заказчик не предоставил все необходимые материалы, Исполнитель имеет право расторгнуть Договор в одностороннем порядке. В таком случае предоплата Заказчику не возвращается;</Text>
          <Text style={styles.text}>2.2.2	в течение 3-х рабочих дней с момента поступления заявки от Исполнителя по электронной почте принимает и оплачивает услуги в порядке и сроки, предусмотренные Договором;</Text>
          <Text style={styles.text}>2.2.3	предоставляет все доступы и права необходимые для выполнения работ по Договору (доступы FTP, CMS, MySQL. Права на внесение изменений в структуру. доступы к панелям Яндекс.Вебмастер и Google Search Console, доступы к системам статистики Яндекс.Метрика и Google Analytics);</Text>
          <Text style={styles.text}>2.2.4	вправе проверять ход и качество оказания услуг, не вмешиваясь в деятельность Исполнителя.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3. Стоимость, порядок оплаты, порядок сдачи-приемки услуг.</Text>

          <Text style={styles.text}>3.1 Стоимость работ и сроки их выполнения согласовывается с Заказчиком и указываются в счетах на оплату.</Text>

          {dataPayment.price.length === 1 &&
            <>
              <Text style={styles.text}>3.2 Стоимость услуг составляет {' '}
                {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                {dataPayment.price.reduce((akk, p) => +akk + +p)} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
              <Text style={styles.text}>3.2 Оплата производится Заказчиком следующим образом:</Text>
              {

                dataPayment.price.map((p, i) =>
                  <Text key={i} style={styles.text}>{i + 1} составляет {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)})  до {dataPayment.payment[i]} года</Text>
                )
              }
            </>
          }
          {dataPayment.price.length >= 2 && dataPayment.payment.length >= 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>3.2 Стоимость услуг составляет {' '}
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями). '}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) до {dataPayment.payment[i]} года</Text>
                  )}
                </>
              }
            </>
          }
          {dataPayment.price.length === 2 && dataPayment.payment.length !== 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>3.2 Стоимость услуг составляет {' '}
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями). '}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {i === 1 && 'Оплата в размере '} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) {i === 1 ? 'по завершению работ, что подтверждается актом выполненных работ и оплата производится в течение 5-ти банковских дней с момента подписания акта.' : 'до ' + dataPayment.payment[i] + ' года'}  </Text>
                  )}
                </>
              }
            </>
          }

          <Text style={styles.text}>3.3 Проценты на предоплату не начисляются. Предоплата не возвращается в случае, если работы, заявленные в задании Заказчика, выполнены в полном объеме или частично, и это подтверждено актом выполненных работ.</Text>
          <Text style={styles.text}>3.4 Расчеты по договору проводятся в безналичной форме в виде банковского перевода на основании счетов.</Text>
          <Text style={styles.text}>3.5 Исполнитель после окончания оказания услуг, указанных в счет – фактуре, подтверждает Актом об оказании услуг, который направляется Исполнителем Заказчику посредством электронных каналов связи в виде сканированной копии с подписью и печатью Исполнителя. В случае необходимости Исполнитель обязан выслать Заказчику оригинал Акта об оказанных услугах в течение 3 (трех) рабочих дней после получения соответствующего требования от Заказчика.</Text>
          <Text style={styles.text}>3.6. При направлении Исполнителем Акта об оказанных услугах Заказчик обязуется рассмотреть Акт, подписать его и направить Исполнителю в течение 3 (трех) рабочих дней либо в тот же срок предоставить мотивированные письменные возражения от подписания Акта.</Text>
          <Text style={styles.text}>3.7. Заказчик безоговорочно соглашается с тем, что если он в течение 3 (трех) рабочих дней с момента получения Акта об оказании услуг не заявит каких-либо письменных претензий по оказанным Исполнителем услугам, то это будет рассматриваться как однозначное согласие Заказчика с тем, что услуги оказаны Исполнителем своевременно, в полном объеме и надлежащим образом.</Text>


          <Text style={[styles.text, styles.textBold, styles.mt]}>4. Разрешение споров и ответственность сторон.</Text>

          <Text style={styles.text}>4.1 Все спорные вопросы разрешаются путем переговоров, а при невозможности разрешения ― в Экономическом суде г. Гомеля. Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй Стороне. Претензия предъявлена надлежащим образом, если направлена другой Стороне любым способом, позволяющим установить личность отправителя и факт вручения корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй Стороны, не является обязательным.</Text>
          <Text style={styles.text}>Сторона, получившая претензию, в течение 10 (десяти) рабочих дней с момента ее получения письменно уведомляет вторую Сторону о результатах рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией и изложенными в ней обстоятельствами.</Text>
          <Text style={styles.text}>4.2 Доказательствами в суде могут являться факсимильные копии и распечатки электронных писем, если переписка осуществлялась с адресов электронной почты сторон.</Text>
          <Text style={styles.text}>4.3 Если Договор расторгается по желанию Заказчика на любом этапе работ, Заказчик оплачивает фактически проделанную работу. В адрес Заказчика направляется акт выполненных работ посредством электронной связи. Заказчик обязан подписать и отправить в адрес Исполнителя один экземпляр акта об оказании услуг в течение 5 (пяти) рабочих дней с момента получения акта. В случае, если в указанный выше срок Заказчик не отправит Исполнителю подписанный акт об оказании услуг и не отправит письменный мотивированный отказ от приемки оказанных услуг, то стороны считают, что услуги, указанные в соответствующей счет - фактуре, приняты Заказчиком без претензий и подлежат оплате.</Text>
          <Text style={styles.text}>4.5 Исполнитель не несет ответственности за несоответствие фактически предоставленных услуг, субъективным ожиданиям и представлениям Заказчика о таких услугах;</Text>
          <Text style={styles.text}>4.6 Во всем остальном, что не оговорено Договором, Стороны несут ответственность в соответствии с законодательством Республики Беларусь.</Text>


          <Text style={[styles.text, styles.textBold, styles.mt]}>5. Форс-мажор.</Text>

          <Text style={styles.text}>5.1 Ни одна из Сторон не несет ответственности за полное или частичное неисполнение обязательств по Договору в случае пожара, наводнения, землетрясения и других стихийных бедствий, возникших после заключения Договора, а также обстоятельств неопределимой силы в результате событий чрезвычайного характера, которые Сторона не могла ни предвидеть, ни предотвратить разумными методами (форс-мажорные обстоятельства).</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>6. Порядок изменения и срок действия договора.</Text>

          <Text style={styles.text}>6.1 Договор вступает в силу с момента его подписания Сторонами и действует до 31.12.{thisDateYers()} г., а в части взаиморасчетов до исполнения сторонами своих обязательств. Договор считается пролонгированным на следующий календарный год на тех же условиях, если ни одна из сторон Договора не заявила о расторжении договора за один месяц до окончания срока Договора.</Text>
          <Text style={styles.text}>6.2 Досрочное расторжение Договора возможно по согласию сторон.</Text>
          <Text style={styles.text}>6.3 Заказчик безоговорочно соглашается с тем, что в случае одностороннего отказа от настоящего Договора по собственной инициативе либо в случае одностороннего отказа по инициативе Исполнителя, вызванного нарушением Заказчиком условий настоящего Договора или действующего законодательства, возврат денежных средств, уплаченных Заказчиком в счет оплаты услуг, не производится, даже в случае, если срок оказания оплаченных услуг не окончен.</Text>
          <Text style={styles.text}>6.4   Все изменения и дополнения настоящего Договора оформляются в виде дополнительных соглашений, которые подписываются сторонами и являются неотъемлемой часть Договора.</Text>
          <Text style={styles.text}>6.5 Договор, изменения и дополнения к нему, другие документы, заверенные подписью и печатью, переданные по факсимильной связи, имеют юридическую силу.</Text>
          <Text style={styles.text}>6.6 Настоящий Договор составлен в двух экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру на каждую из сторон.</Text>
          <Text style={styles.text}>6.7 Стороны признают юридическую силу за электронными письмами – документами, направленными по электронной почте (e-mail) или посредством мессенджеров, и признают их равнозначными документам на бумажных носителях, подписанным собственноручной подписью, т.к. только сами Стороны и уполномоченные ими лица имеют доступ к соответствующим адресам электронной почты, указанным в Договоре в реквизитах Сторон и являющимся электронной подписью соответствующей Стороны. Доступ к электронной почте каждая Сторона осуществляет по паролю и обязуется сохранять его конфиденциальность.</Text>
          <Text style={styles.text}>6.8 В случае, если подписанный Договор не возвращен Исполнителю, то фактом подписания Договора считается оплата Заказчиком услуг. Оплата услуг свидетельствует о том, что Заказчик ознакомлен с условиями Договора и полностью их принимает.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>7.	Конфиденциальность.</Text>

          <Text style={styles.text}>7.1 Стороны обязуются не разглашать конфиденциальную информацию, под которой подразумевается содержание Договора и любые данные, предоставляемые каждой из сторон друг другу в связи с исполнением Договора, не разглашать в общем или в частности эту информацию какой-либо третьей стороне без предварительного письменного согласия другой стороны по Договору.</Text>
          <Text style={styles.text}>7.2 Информация, предоставляемая Заказчику в соответствии с Договором, предназначена исключительно для него и не может передаваться третьим лицам или использоваться каким-либо иным способом, с участием третьих лиц, в частности и полностью, без согласия Исполнителя.</Text>
          <Text style={styles.text}>7.3 Обязательства Сторон относительно неразглашения конфиденциальной информации и неиспользования её в ущерб другой стороне не распространяются на общедоступную информацию и информацию, указанную в законодательстве как не подлежащую засекречиванию.</Text>
          <Text style={styles.text}>7.4 Стороны достигли соглашения о размещении информации о предоставлении услуг Исполнителем Заказчику в портфолио и на WEB-сайте Исполнителя с указанием ссылки, ведущей на адрес сайта или веб-приложения, и Исполнителю указывать себя в качестве компании, оказывающей услуги по на сайте Заказчика.</Text>
          <Text style={styles.text}>7.5 Стороны обязаны принимать все разумные меры для защиты конфиденциальной информации.</Text>


          <PDFFooterSingFull data={dataPayment} />
        </View>
      </Page>
    </Document >
  )
}
export default PDFComponentContractProgramming