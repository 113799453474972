import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";
import { cutFromAString } from "../functions";
function PDFFooterSingFull({ data }) {
  const sing = require(`../../images/${data.sing}`)

  return (
    <View style={[styles.row, styles.mt]}>
      <View>
        <Text style={[styles.recTitle]}>Исполнитель:</Text>
        <Text style={[styles.recText]}>{data.firmaRequisites}</Text>
        <Image
          style={styles.image}
          src={sing}
        />
        <Text style={[styles.recText, styles.mt]}>М.П. ____________________</Text>
        <Text style={[styles.recText]}>({cutFromAString(data.firmaInFace, "директора ")})</Text>
      </View>
      <View>
        <Text style={[styles.recTitle]}>Заказчик:</Text>
        <Text style={[styles.recText]}>{data.clientName}</Text>
        <Text style={[styles.recText]}>{data.requisites}</Text>
        <Text style={[styles.recText, styles.mt]}>М.П. ____________________</Text>
      </View>
    </View>
  )
}
export default PDFFooterSingFull