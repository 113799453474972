
import React from 'react'
import { Link } from 'react-router-dom'

import { Container, Navbar, Offcanvas, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menu.sass';
import { useSelector } from 'react-redux';
const settingsStyle = {
  marginTop: "50px"
}

function Menu() {
  const { privat } = useSelector(state => state.auth)
  const authName = useSelector(state => state.auth.name)
  return (
    <Navbar collapseOnSelect className="align-items-start" variant="light" bg="white" expand={false} >
      <Container fluid>
        {privat === "admin" || privat === "rop" || privat === "manager" ? <Navbar.Toggle aria-controls="offcanvasNavbar" fixed="top" /> : ''}
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          fixed="top"
        >
          <Offcanvas.Header closeButton >
            <Offcanvas.Title id="offcanvasNavbarLabel">МЕНЮ</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-start flex-grow-1 pe-3">
              {privat === "admin" || privat === "rop" || privat === "manager" ? <Nav.Link eventKey="0" as={Link} className="btn btn-primary" to="/createInvoice">✎ Создать счет</Nav.Link> : ''}
              {privat === "admin" || privat === "rop" ? <Nav.Link eventKey="1" as={Link} className="btn btn-primary" to="/CreateContract">✎ Создать договор</Nav.Link> : ''}
              {privat === "admin" || privat === "rop" ? <Nav.Link eventKey="2" as={Link} className="btn btn-primary" to="/allcontracts">Все договора</Nav.Link> : ''}
              {privat === "admin" || privat === "rop" ? <Nav.Link eventKey="3" as={Link} className="btn btn-primary" to="/postalitems">✉ Письма</Nav.Link> : ''}
              {privat === "admin" || privat === "rop" || authName === "Павел Гусарев" ? <Nav.Link eventKey="4" as={Link} style={settingsStyle} className="btn btn-primary" to="/clients">👥 Клиенты</Nav.Link> : ''}
              {privat === "admin" && <Nav.Link eventKey="5" as={Link} className="btn btn-primary" to="/parishes">💲Приходы</Nav.Link>}
              {privat === "admin" && <Nav.Link eventKey="6" as={Link} className="btn btn-primary" to="/hostings">💾 Хостинг</Nav.Link>}
              {privat === "admin" || privat === "rop" ? <Nav.Link eventKey="7" as={Link} className="btn btn-primary" to="/seo">SEO</Nav.Link> : ''}
              <Nav.Link eventKey="8" as={Link} className="btn btn-primary" to="/siteslist">Список сайтов</Nav.Link>
              {privat === "admin" || privat === "rop" || privat === "manager" ? <Nav.Link eventKey="9" as={Link} className="btn btn-primary" to="/indicators">Показатели</Nav.Link> : ''}

              {privat === "admin" || privat === "rop" ? <Nav.Link eventKey="10" as={Link} style={settingsStyle} className="btn btn-primary" to="/messagest">Сообщение Telegram</Nav.Link> : ''}
              {privat === "admin" || privat === "rop" ? <Nav.Link eventKey="11" as={Link} className="btn btn-primary" to="/messagesv">Сообщение Viber</Nav.Link> : ''}
              {/* {privat === "admin" || privat === "rop" ? <Nav.Link eventKey="9" as={Link} className="btn btn-primary" to="/recruit">Найм лид генераторов</Nav.Link> : ''} */}
              {privat === "admin" && <Nav.Link eventKey="12" as={Link} style={settingsStyle} className="btn btn-primary" to="/settings">Настройки</Nav.Link>}
              {/* {privat === "admin" && <Nav.Link eventKey="11" as={Link} style={settingsStyle} className="btn btn-primary" to="/openaigenerateimg">Изображения OpenAi</Nav.Link>} */}
              {/* {privat === "admin" && <Nav.Link eventKey="12" as={Link} style={settingsStyle} className="btn btn-primary" to="/openai">OpenAi</Nav.Link>} */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default React.memo(Menu);
