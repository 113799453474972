import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr } from '../components/UI/alert'

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async function (id, { rejectWithValue }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/settings/get/${id ? id : ''}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const createSettings = createAsyncThunk(
  'settings/createSettings',
  async function (newArr, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/settings/create`, {
        method: 'POST',
        body: JSON.stringify(newArr)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()

      if ('managers' in newArr) dispatch(createAddManagers(data.data.managers));
      if ('spheres' in newArr) dispatch(createAddSpheres(data.data.spheres));
      if ('services' in newArr) dispatch(createAddOurServices(data.data.services));

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось создать запись. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    managers: [],
    spheres: [],
    ourServices: [],
    status: null,
    error: null,
  },
  reducers: {
    createAddManagers(state, action) {
      state.managers = JSON.parse(action.payload)
    },
    createAddSpheres(state, action) {
      state.spheres = JSON.parse(action.payload)
    },
    createAddOurServices(state, action) {
      state.ourServices = JSON.parse(action.payload)
    },
  },
  extraReducers: {
    [fetchSettings.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchSettings.fulfilled]: (state, action) => {
      state.status = 'resolved'
      Object.keys(action.payload).forEach(key => {
        if (key === "managers") state.managers = JSON.parse(action.payload.managers)
        if (key === "spheres") state.spheres = JSON.parse(action.payload.spheres)
        if (key === "services") state.ourServices = JSON.parse(action.payload.services)
      });

    },
    [fetchSettings.rejected]: setError,

    [createSettings.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [createSettings.rejected]: setError,
  }
})
const { createAddManagers, createAddSpheres, createAddOurServices } = settingsSlice.actions
export default settingsSlice.reducer