import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchPurchasedServices = createAsyncThunk(
  'purchasedservices/fetchPurchasedServices',
  async function (id, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients`, {
      const response = await fetch(`https://payment.mediarama.by/core/purchasedservices/get/${id}`, {
        method: 'GET'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()

      const dataArray = Object.values(data).map(obj => {
        const { data, ...rest } = obj;
        return { ...JSON.parse(data), ...rest };
      });

      return dataArray

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const createPurchasedServices = createAsyncThunk(
  'purchasedservices/createPurchasedServices',
  async function (object, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients`, {
      const response = await fetch(`https://payment.mediarama.by/core/purchasedservices/create`, {
        method: 'POST',
        body: JSON.stringify(object)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()
      const newData = JSON.parse(data.data.data);
      const newObj = {
        ...data.data,
        ...newData
      };
      delete newObj.data;

      dispatch(addService(newObj))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const removePurchasedServices = createAsyncThunk(
  'purchasedservices/removePurchasedServices',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients/${id}`, {
      const response = await fetch(`https://payment.mediarama.by/core/purchasedservices/delete/${id}`, {
        method: 'POST',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');
      dispatch(deleteService(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    services: [],
    status: null,
    error: null,
  },
  reducers: {
    addService(state, action) {
      state.services.push(action.payload)
    },
    deleteService(state, action) {
      state.services = state.services.filter(service => service.id !== action.payload)
      success.fire({
        text: "Услуга удалена!",
      });
    },
    modifyService(state, action) {
      const inx = state.services.findIndex(service => service.id === action.payload.id)
      state.services.splice(inx, 0, action.payload)
      success.fire({
        text: "Изменение сохранено!",
      });
    },
  },
  extraReducers: {
    [fetchPurchasedServices.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },

    [createPurchasedServices.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchPurchasedServices.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.services = action.payload
    },

    [fetchPurchasedServices.rejected]: setError,
    [createPurchasedServices.rejected]: setError,
    [removePurchasedServices.rejected]: setError,
  }
})
const { addService, deleteService, modifyService } = servicesSlice.actions
export default servicesSlice.reducer