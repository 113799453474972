import React, { useCallback, useRef, useEffect, useState, useMemo } from "react";

import { alertErr } from '../UI/alert'

import Textarea from '../UI/Textarea';
import SimpleInput from '../UI/SimpleInput';
import SelectSearch from '../UI/SelectSearch/SelectSearch';
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './styles.module.sass'
import { useDispatch } from 'react-redux';

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import { createHosting } from '../../store/clientsHostingSlice'
import { removeHostingOne } from '../../store/clientsHostingSlice'
const style = {
  button: {
    width: '100%',
    maxWidth: '100px'
  },
}

function CreateClientHosting({ clients }) {

  const dispatch = useDispatch()
  const [clientName, setClientName] = useState('')
  useMemo(() => {
    const clientArr = clients.map(el => {
      return {
        name: 'client-' + el.id,
        value: el.name
      }
    })
    setClientName(clientArr)
  }, [clients])


  const [domain, setDomain] = useState([{ id: 0, domain: "", way: "" }])
  function addDomainAndWay() {
    setDomain(prevArray => [...prevArray, { id: prevArray.length, domain: '', way: '' }]);
  }
  function fieldDomain(e) {
    window.open('https://' + e.value, '_blank')
  };
  function delDomainAndWay(id) {
    const lastDomain = domain.filter(d => d.id !== id)
    setDomain(lastDomain);
    dispatch(removeHostingOne(JSON.stringify(lastDomain)));
  }
  const addClientSend = useCallback((e) => {
    e.preventDefault()

    const targets = e.target.querySelectorAll('input, textarea');
    const formData = new FormData()
    const err = new Set()
    const originalArray = []
    targets.forEach(inp => {
      if (inp.dataset.name !== 'access' && inp.dataset.name !== 'info') {
        if (!inp.value) {
          err.add(inp.dataset.name)
          return
        }
      }
      if (inp.dataset.name.includes('date')) {
        const d = inp.value.split('.')
        const newDate = Math.floor(new Date(d[2] + '.' + d[1] + '.' + d[0]).getTime() / 1000)
        formData.append('unix', newDate)
        formData.append(inp.dataset.name, inp.value)
      } else
        if (inp.dataset.name === 'domain' || inp.dataset.name === 'way') {
          if (inp.value) originalArray.push({ [inp.dataset.name]: inp.value })
        } else
          if (inp.dataset.name.includes('date')) {
            formData.append(inp.dataset.name, inp.value)
          } else
            // if (!inp.dataset.name.includes('client-') && !inp.dataset.name.includes('date')) formData.append(inp.dataset.name, inp.value)
            if (inp.dataset.name.includes('client-') && !inp.dataset.name.includes('date')) {
              formData.append('client_id', inp.dataset.name.replace(/\D/gi, ''))
            } else {
              formData.append(inp.dataset.name, inp.value)
            }
    });
    if (err.size > 0) {
      alertErr.fire({
        text: 'Заполните все обязательные поля!',
      })
      return
    }

    const resultArray = originalArray.reduce((acc, cur) => {
      if (cur.domain) {
        acc.push({ domain: cur.domain });
      } else {
        const obj = acc[acc.length - 1];
        const key = Object.keys(cur)[0];
        obj[key] = cur[key];
      }
      return acc;
    }, []).map((el, id) => ({ ...el, id }));

    formData.append('domain', JSON.stringify(resultArray))

    dispatch(createHosting(formData))
    e.target.querySelectorAll('input,textarea').forEach(i => i.value = '');
  }, [dispatch])

  const calendar = useRef(null)
  useEffect(() => {
    new AirDatepicker(calendar.current, {
      dateFormat: "dd.MM.yyyy",
      autoClose: true,
      buttons: ['clear'],
    })
  }, [calendar])

  return (
    <Form onSubmit={(e) => addClientSend(e)}>
      <Row>
        <Col lg="2" md="6" className="mb-2">
          <label className={styles.label} htmlFor="calendar">Дата*</label>
          <input id="calendar" className={styles.input + " " + styles.simpleInput} autoComplete="off" ref={calendar} data-name="date" readOnly />
        </Col>
        <Col lg="4" md="6" className="mb-2">
          <label className={styles.label} htmlFor="clients">Клиент*</label>
          <SelectSearch id="clients" search={true} options={clientName} />
        </Col>
        <Col lg="1" md="6" className="mb-2">
          <SimpleInput title="Цена*" type="number" name="price" />
        </Col>
        <Col lg="2" md="6" className="mb-2">
          <label className={styles.label} htmlFor="wher">Где*</label>
          <SelectSearch id="wher" options={[
            { name: 'wher', value: 'domain.by' },
            { name: 'wher', value: 'hoster.by' },
          ]} />
        </Col>
        <Col lg="2" md="6" className="mb-2">
          <label className={styles.label} htmlFor="firma">Фирма*</label>
          <SelectSearch id="firma" options={[
            { name: "firma", value: "ООО «ХитГрупп»" },
            { name: "firma", value: "ИП Холопов М.С." },
          ]} />
        </Col>
        {
          domain && domain.map(d =>
            <Col key={d.id} lg="11" md="5" className="mb-2">
              <Row>
                <Col lg="5" md="5" className="mb-2">
                  <div className="input-style" >
                    <label>Домен*</label>
                    <input autoComplete="off" type="text" data-name="domain" defaultValue={d.domain} onDoubleClick={(e) => fieldDomain(e.target)} />
                  </div>
                </Col>
                <Col lg="5" md="5" className="mb-2">
                  <div className="input-style" >
                    <label>Путь*</label>
                    <input autoComplete="off" type="text" data-name="way" defaultValue={d.way} />
                  </div>
                </Col>
                <Col lg="1" md="1" className="mb-2">
                  <div className="input-style">
                    <label>Удалить</label>
                    <Button variant="danger" style={style.buttonMin} onClick={() => delDomainAndWay(d.id)}>-</Button>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        <Col lg="1" md="1" className="mb-2">
          <div className="input-style">
            <label>Добавить</label>
            <Button style={style.buttonMin} onClick={addDomainAndWay}>Домен</Button>
          </div>
        </Col>
        <Col lg="3" md="6" className="mb-2">
          <Textarea title="Доступы" name="access" />
        </Col>
        <Col lg="3" md="6" className="mb-2">
          <Textarea title="Информация" name="info" />
        </Col>
        <Col sm="2" lg="1" md="1" className="mb-2">
          <div className="input-style">
            <label>Сохранить</label>
            <Button style={style.button} type="submit" variant="success">Хостинг</Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(CreateClientHosting);