import React, { useEffect, useState, useMemo } from "react";

import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import CreatePostal from '../components/Postals/CreatePostal';
import PostalsList from '../components/Postals/PostalsList';

import Loading from '../components/UI/Loading'
import LoadServerData from '../components/LoadServerData'

import { useSelector, useDispatch } from 'react-redux';

import { fetchPostal, createPostal } from '../store/postalItemsSlice'

import { dateTransform } from '../components/functions'

const style = {}
function PostalItems() {
  LoadServerData()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchPostal())
  }, [dispatch])


  const { clients } = useSelector(state => state.clients)
  const { postals, status, error } = useSelector(state => state.postalitems)


  const [searchVal, setSearchVal] = useState('')
  const newArrPostals = useMemo(() => {
    return postals.filter(el => {
      if (el.client.toLowerCase().includes(searchVal) || el.client.includes(searchVal)) return el
      if (el.what.toLowerCase().includes(searchVal) || el.what.includes(searchVal)) return el
      if (el.status.toLowerCase().includes(searchVal) || el.status.includes(searchVal)) return el
      if (dateTransform(el.date).includes(searchVal)) return el
    });
  }, [postals, searchVal])
  console.log(postals);
  return (
    <section className="pages">
      <Container>
        <h1>Почтовые отправления</h1>
        <CreatePostal clients={clients} createPostal={createPostal} />
        <Row>
          <Col lg="12" className="mb-3">
            <div className="input-style" >
              <label>Поиск</label>
              <input type="text" placeholder="Поиск..." onChange={(e) => setSearchVal(e.target.value)} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {
          postals.length ?
            <PostalsList postals={newArrPostals} />
            : (<h2 style={style.h2}>По данному запросу ничего не найдено!</h2>)
        }
      </Container>

    </section >
  );
};

export default PostalItems;