import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import { rubles } from "rubles";
import logo from '../../images/logo.png'
import { toFixed, thisDateYers } from "../functions";

import PDFFooterSingFull from "./PDFFooterSingFull"

function PDFComponentContractComplexMaint({ dataPayment }) {
  const found = dataPayment.firmaName.includes("ХитГрупп");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Договор № С-{dataPayment.contractNum}</Text>
          <Text style={[styles.headTitle, styles.mb]}>Услуги по комплексному ведению</Text>
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>
          <Text style={styles.text}>{dataPayment.firmaName.replace(/\sRU$/, "")}, именуемый в дальнейшем «Исполнитель», в лице {dataPayment.firmaInFace}, {dataPayment.firmaFooting} с одной стороны, и {dataPayment.clientName}, именуемый в дальнейшем «Заказчик», в лице {dataPayment.clientInFace}, действующего на основании {dataPayment.clientFooting}, с другой стороны, именуемые в дальнейшем Стороны, заключили настоящий Договор (далее Договор) о нижеследующем:</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>1. Предмет договора</Text>

          <Text style={styles.text}>1.1 В соответствии с условиями настоящего договора Исполнитель выполняет по заданию Заказчика работы по продвижению и обслуживанию сайта {dataPayment.domain}, далее именуемого «веб-сайт», в сети Интернет. Заказчик принимает результаты выполненных работ и оплачивает их.</Text>
          <Text style={styles.text}>1.2 Конкретный перечень работ, составляющих задание Заказчика, а также порядок и сроки их выполнения, могут согласовываться сторонами в приложениях к настоящему договору, являющихся его неотъемлемой частью, которыми, в частности, могут быть счет – фактуры.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2. Права и обязанности сторон</Text>

          <Text style={styles.text}>2.1 Исполнитель обязуется: </Text>
          <Text style={styles.text}>2.1.1 Обслуживание веб-сайта. Внесение изменений, которые заказчик не в состоянии внести самостоятельно на веб-сайт. Не связанных с изменением его конструкции.</Text>
          <Text style={styles.text}>2.2 Заказчик обязуется:</Text>
          <Text style={styles.text}>2.2.1 Своевременно предоставляет Исполнителю все исходные материалы, необходимые для выполнения Работ.</Text>
          <Text style={styles.text}>2.2.2 Предоставить доступ к веб-сайту для своевременного внесения изменений и модификации веб-сайта.</Text>
          <Text style={styles.text}>2.2.3 Своевременно производить оплату за выполненные услуги.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3. Порядок выполнения работ и отчетность по договору.</Text>

          <Text style={styles.text}>3.1 Исполнитель начинает выполнение работ по настоящему Договору в течении одного рабочего дня с момента поступления денежных средств на расчетный счет Исполнителя.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>4.Порядок сдачи и приемки выполненных работ.</Text>

          <Text style={styles.text}>4.1 О завершении работ (этапа работ) предоставляет Заказчику акт сдачи-приемки выполненных работ, в котором указывается перечень выполненных работ и их стоимость. В акт сдачи-приемки выполненных поэтапно работ (работ в целом) может быть включена другая информация, которую стороны сочтут необходимой указать.</Text>
          <Text style={styles.text}>4.2 Принятые и одобренные Заказчиком работы по каждому из этапов (работы в целом) считаются выполненными с момента подписания акта сдачи-приемки работ обеими Сторонами.</Text>
          <Text style={styles.text}>4.3 Исполнитель в срок не позднее 3 (трех) рабочих дней с момента окончания этапов (работы в целом), указанных в счет – фактуре, направляет в адрес Заказчика два экземпляра акта выполненных работ . Заказчик обязан подписать и отправить в адрес Исполнителя один экземпляр акта в в течение 5 (пяти) рабочих дне с момента получения акта либо в тот же срок направить в адрес Исполнителя письменный мотивированный отказ от приемки этапов (работы в целом) с указанием перечня выявленных недостатков, порядка и сроков их устранения Исполнителем. В случае, если в указанный выше срок Заказчик не отправит Исполнителю подписанный акт выполненных работ и не отправит письменный мотивированный отказ от приемки этапов (работы в целом), то стороны считают, что этапы (работы в целом), указанные в соответствующем счет - фактуре, приняты Заказчиком без претензий и подлежат оплате. </Text>



          <Text style={[styles.text, styles.textBold, styles.mt]}>5. Размер и порядок оплаты услуг.</Text>

          {dataPayment.price.length === 1 &&
            <>
              <Text style={styles.text}>5.1 Стоимость услуг составляет
                {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                {dataPayment.price.reduce((akk, p) => +akk + +p)} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
              <Text style={styles.text}>5.2 Оплата производится Заказчиком следующим образом:</Text>
              {

                dataPayment.price.map((p, i) =>
                  <Text key={i} style={styles.text}>{i + 1} составляет {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)})  до {dataPayment.payment[i]} года</Text>
                )
              }
            </>
          }
          {dataPayment.price.length >= 2 && dataPayment.payment.length >= 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>5.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) до {dataPayment.payment[i]} года</Text>
                  )}
                </>
              }
            </>
          }
          {dataPayment.price.length === 2 && dataPayment.payment.length !== 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>5.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {i === 1 && 'Оплата в размере '} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) {i === 1 ? 'по завершению работ, что подтверждается актом выполненных работ и оплата производится в течение 5-ти банковских дней с момента подписания акта.' : 'до ' + dataPayment.payment[i] + ' года'}  </Text>
                  )}
                </>
              }
            </>
          }

          <Text style={[styles.text, styles.textBold, styles.mt]}>6. Ответственность сторон</Text>

          <Text style={styles.text}>6.1 За нарушения условий настоящего договора стороны несут ответственность в соответствии с действующим законодательством РБ. </Text>
          <Text style={styles.text}>6.2 При нарушение Заказчиком сроков исполнения обязательств по оплате услуг Исполнителя согласно п.5  настоящего Договора Исполнитель не гарантирует выполнение своих обязательств в срок и с надлежащим качеством.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>7. Обстоятельства непреодолимой силы (форс-мажор).</Text>

          <Text style={styles.text}>7.1 Ни одна из Сторон не несет ответственности за полное или частичное неисполнение обязательств по настоящему Договору в случае пожара, наводнения, землетрясения и других стихийных бедствий, возникших после заключения настоящего Договора, а также обстоятельств неопределимой силы в результате событий чрезвычайного характера, которые Сторона не могла ни предвидеть, ни предотвратить разумными методами (форс-мажорные обстоятельства).</Text>
          <Text style={styles.text}>7.2 Под форс-мажорными обстоятельствами в работе провайдера услуг по хостингу веб-сайта или форс-мажорными обстоятельствами в работе провайдера Интернет-услуг понимаются следующие обстоятельства:</Text>
          <Text style={styles.text}>7.2.1 Выход по независящим от действий Заказчика и/или Исполнителя причинам из строя и/или временная частичная или полная неработоспособность сервера на территории провайдера услуг по хостингу, содержащего файлы или программное обеспечение для работы веб-сайта, любых его аппаратных и программных компонентов и/или периферийного оборудования, обеспечивающих нормальную работоспособность веб-сайта; </Text>
          <Text style={styles.text}>7.2.2 Выход по независящим от действий Заказчика и/или Исполнителя причинам из строя и/или временная частичная или полная неработоспособность коммуникационных каналов, обеспечивающих связь между сервером на территории провайдера услуг по хостингу и любых других компьютеров в сети Интернет.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>8. Прочие условия</Text>

          <Text style={styles.text}>8.1. В случае изменения законодательных и нормативных актов, тарифов и сборов, цен на услуги, предоставляемые в сети Интернет, введения иных обязательных платежей, распространяемых на Исполнителя, а также изменения индекса цен на территории РБ, Исполнитель вправе пересмотреть условия настоящего Договора с обязательным уведомлением Заказчика.</Text>
          <Text style={styles.text}>8.2. Все изменения и дополнения к настоящему Договору действительны только в случае подписания их обеими сторонами. </Text>
          <Text style={styles.text}>8.3. Настоящий Договор составлен в двух экземплярах, имеющих равную юридическую силу, законодательством РБ. Договор вступает в силу с момента его подписания Сторонами и действует до 31.12.{thisDateYers()} г., а в части взаиморасчетов до исполнения сторонами своих обязательств. Договор считается пролонгированным на следующий календарный год, если ни одна из сторон договора не заявила о расторжении договора за один месяц до окончания срока договора.</Text>


          <PDFFooterSingFull data={dataPayment} />
        </View>
      </Page>
    </Document >
  )
}
export default PDFComponentContractComplexMaint