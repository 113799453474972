import React, { forwardRef } from "react";

import styles from './styles.module.sass'

const trStyle = {
  background: "#fff",
  tbody: {
    opacity: ".4",
  },
  th: {
    border: "1px solid rgba(0,0,0,.05)",
  },
  p: {
    margin: "0px",
    fontSize: "10px"
  },
  inputStyle: {
    cursor: "pointer"
  }
}
const TableColumnsSum = forwardRef(({ theadArr, getColumnSum, columnsSum }, inputSum) => {
  return (
    <tbody className={styles.tbody} style={trStyle.tbody}>
      <tr style={trStyle} ref={inputSum}>
        {theadArr.map(th =>
          <th key={th.id} className={styles.th} style={trStyle.th}>
            {th.field && <p data-field={th.field} style={trStyle.p}>{columnsSum.map(el => el[th.field])}</p>}
            {th.field && <input style={trStyle.inputStyle} type="checkbox" value={columnsSum.map(el => el[th.field])} name={th.field} onChange={getColumnSum} />}
          </th>
        )}
      </tr>
    </tbody>
  );
});
export default TableColumnsSum;