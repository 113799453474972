import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputMask from 'react-input-mask';
import SimpleInput from '../../UI/SimpleInput'
import Textarea from '../../UI/Textarea'
import { alertErr } from '../../UI/alert'
import { useSelector, useDispatch } from 'react-redux';
import { createPurchasedServices, fetchPurchasedServices, removePurchasedServices } from "../../../store/purchasedServicesSlice"
import Loading from '../../UI/Loading'
import SelectSearch from '../../UI/SelectSearch/SelectSearch';
import { fetchSettings } from "../../../store/settingsSlice"
const style = {
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
  buttonMin: {
    width: '100%',
    maxWidth: '55px'
  },
  button: {
    width: '100%',
    maxWidth: '80px'
  },
}
function PurchasedServices({ data }) {
  const { services, status, error } = useSelector(state => state.purchasedservices)
  const { ourServices } = useSelector(state => state.settings)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPurchasedServices(data.id))
    dispatch(fetchSettings())
  }, [dispatch, data.id])

  const [toggleForm, settToggleForm] = useState(false)

  function sendData(e) {
    e.preventDefault()
    const targets = e.target.querySelectorAll('input, textarea');

    const object = {}
    object["client_id"] = data.id;

    const err = new Set()
    targets.forEach(inp => {
      if (inp.dataset.name !== 'info') if (!inp.value) err.add(inp.dataset.name)
      object[inp.dataset.name] = inp.value;
    });
    if (err.size > 0) {
      alertErr.fire({
        text: 'Заполните все обязательные поля!',
      })
      return
    }

    // console.log(object);
    dispatch(createPurchasedServices(object))
    e.target.querySelectorAll('input,textarea').forEach(i => i.value = '');
  }

  function deletePayment(id, name) {
    Swal.fire({
      title: `Точно удалить услугу ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removePurchasedServices(id))
      }
    })

  }
  return (
    <section className='pages'>
      <Container>
        <Row>
          <Col lg="2" md="2" sm="2" className="mb-2">
            <div className="input-style">
              <Button style={{ margin: '-20px 0 10px' }} type="button" variant="primary" className={'button-save'} onClick={() => settToggleForm(!toggleForm)}>{toggleForm ? 'Скрыть ↑' : 'Добавить ↓'}</Button>
            </div>
          </Col>
        </Row>
        {toggleForm &&
          <Form onSubmit={(e) => sendData(e)}>
            <Row>
              <Col lg='4' className='mb-3'>
                <label htmlFor="services">Услуга</label>
                <SelectSearch name="services" id="service" options={[{ name: 'services', value: "" }, ...ourServices]} />
              </Col>
              <Col lg='1' className='mb-3'>
                <SimpleInput type="number" title="Стоимость" name={'price'} />
              </Col>
              <Col lg="2" md="6" className="mb-2">
                <label style={style.label} htmlFor="calendar">Дата*</label>
                <div className="input-style" >
                  <InputMask mask="99.99.9999" maskChar=" " data-name={'date'} />
                </div>
              </Col>
              <Col lg='4' className='mb-3'>
                <Textarea title="Информация" name={'info'} />
              </Col>
              <Col lg="1" md="2" sm="2" className="mb-2">
                <div className="input-style">
                  <label>Сохранить</label>
                  <Button style={style.button} type="submit" variant="success" className={'button-save'}>&#9998;</Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      </Container>
      <div className="invoicesHead">

        <Row>
          <Col lg="4" className="mb-2">Услуга</Col>
          <Col lg="1" className="mb-2">Стоимость</Col>
          <Col lg="2" className="mb-2">Дата</Col>
          <Col lg="4" className="mb-2">Информация</Col>
          <Col lg="1"></Col>
        </Row>
      </div>
      <div className="invoicesBody">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {services?.map(el =>
          <div key={el.id} className="invoice">
            <Row>
              <Col lg="4">{el.services}</Col>
              <Col lg="1">{el.price}</Col>
              <Col lg="2">{el.date}</Col>
              <Col lg="4">{el.info}</Col>
              <Col lg="1"><span onClick={() => deletePayment(el.id, el.services)} className="close-button">&times;</span></Col>
            </Row>
          </div>
        ).reverse()}
      </div>
    </section >
  );
};
export default PurchasedServices;