import React from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from 'react-chartjs-2'
function Products({ indicators }) {
  const thisMonth = new Date().getMonth() + 1
  const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"].splice(0, thisMonth)
  const money = Object.values(indicators.fieldsReports['Продукты']).splice(0, thisMonth)

  const barChartData = {
    labels: months,
    datasets: [
      {
        data: money,
        label: 'Продукты',
        borderColor: `rgb(${[1, 2, 3].map(x => Math.random() * 256 | 0)})`,
        backgroundColor: `rgba(255, 0, 0, .0)`,
        fill: true
      }
    ]
  };

  return (
    <Line
      type="Line"
      width={130}
      height={50}

      options={{
        plugins: {
          title: {
            display: true,
            text: "Помесячный отчет по продаже продуктов",
          }
        },
      }}
      data={barChartData}
    />
  )
};

export default Products;