import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import { rubles } from "rubles";
import logo from '../../images/logo.png'
import { toFixed, thisDateYers } from "../functions";

import PDFFooterSingFull from "./PDFFooterSingFull"

function PDFComponentContractContextService({ dataPayment }) {
  const found = dataPayment.firmaName.includes("ХитГрупп");

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Договор № В-{dataPayment.contractNum}</Text>
          <Text style={[styles.headTitle, styles.mb]}>Услуги по обслуживанию кампаний контекстной рекламы</Text>

          <View style={[styles.row, styles.mb]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>
          <Text style={styles.text}>{dataPayment.firmaName.replace(/\sRU$/, "")}, именуемый в дальнейшем «Исполнитель», в лице {dataPayment.firmaInFace}, {dataPayment.firmaFooting} с одной стороны, и {dataPayment.clientName}, именуемый в дальнейшем «Заказчик», в лице {dataPayment.clientInFace}, действующего на основании {dataPayment.clientFooting}, с другой стороны, именуемые в дальнейшем Стороны, заключили настоящий Договор (далее Договор) о нижеследующем:</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>1. Предмет договора</Text>

          <Text style={styles.text}>1.1 В соответствии с условиями настоящего договора Исполнитель выполняет по заданию Заказчика работы по обслуживанию компаний контекстной рекламы в системах Яндекс.Директ и Google.ADS. Заказчик принимает результаты выполненных работ и оплачивает их.</Text>
          <Text style={styles.text}>1.2 Конкретный перечень работ, составляющих задание Заказчика, а также порядок и сроки их выполнения, могут согласовываться сторонами в приложениях к настоящему договору, являющихся его неотъемлемой частью, которыми, в частности, могут быть счет – фактуры.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>2. Права и обязанности сторон.</Text>

          <Text style={styles.text}>2.1 Исполнитель обязуется производить обслуживание кампании контекстной рекламы:</Text>
          <Text style={styles.text}>-	управление ценой клика кампаний;</Text>
          <Text style={styles.text}>-	мониторинг изменений позиций объявлений;</Text>
          <Text style={styles.text}>-	мониторинг эффективности текстовых блоков объявлений;</Text>
          <Text style={styles.text}>-	мониторинг статуса ключевых запросов;</Text>
          <Text style={styles.text}>-	мониторинг CTR кампаний;</Text>
          <Text style={styles.text}>-	анализ поискового спроса по тематике деятельности, указанной Заказчиком;</Text>
          <Text style={styles.text}>-	составление текстовых блоков объявлений, на основе спроса по тематике;</Text>
          <Text style={styles.text}>-	подготовка ключевых запросов на основе спроса по тематике;</Text>
          <Text style={styles.text}>-	создание и редактирование ключевых запросов, текстовых блоков. </Text>

          <Text style={styles.text}>2.2. Исполнитель вправе:</Text>
          <Text style={styles.text}>2.2.1. Требовать от Заказчика предоставления необходимой информации для надлежащего оказания Услуги, в том числе для составления объявлений.</Text>
          <Text style={styles.text}>2.2.2. В случае нарушения Заказчиком срока и/или размера оплаты стоимости Услуги, указанной в п.4 настоящего Договора, Исполнитель вправе требовать компенсации убытков, понесенных им, в результате этого нарушения.</Text>
          <Text style={styles.text}>2.2.3. Самостоятельно определять состав персонала, обеспечивающего надлежащее оказание Услуги, при необходимости привлекать третьих лиц.</Text>
          <Text style={styles.text}>2.2.4. Не предоставлять гарантию Заказчику на какое-либо конкретное количество звонков поступаемых к Заказчику от возможных покупателей. Ввиду того, что, обращаясь к опубликованному объявлению Заказчика, покупатель принимает решение о звонке, основываясь на выгодности этого предложения для него. Исполнитель не может влиять на цену и качество продаваемого Заказчиком товара или услуги, а, следовательно, и на выгодность предложения Заказчика.</Text>
          <Text style={styles.text}>2.2.5. Не предоставлять гарантию Заказчику на количество продаж, совершаемых Заказчиком возможным покупателям, привлеченным Исполнителем, в рамках работ, определенных в п.1.1 настоящего Договора. Количество продаж определяется профессиональным уровнем персонала Заказчика. Исполнитель не может влиять на профессиональный уровень персонала Заказчика, следовательно, и на количество продаж</Text>


          <Text style={[styles.text, styles.textBold, styles.mt]}>2.3 Заказчик обязуется:</Text>

          <Text style={styles.text}>2.3.1 Своевременно предоставляет Исполнителю все исходные материалы, необходимые для выполнения Работ.</Text>
          <Text style={styles.text}>2.3.2 Своевременно производить оплату за выполненные услуги.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2.4. Заказчик вправе:</Text>

          <Text style={styles.text}>2.4.1. Проверять ход и качество работы, выполняемой Исполнителем, не вмешиваясь в его деятельность.</Text>
          <Text style={styles.text}>3.4.2. Предоставлять Исполнителю рекомендации по оказанию услуги, необходимые для повышения эффективности рекламной кампании.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>3. Порядок выполнения работ и отчетность по договору.</Text>

          <Text style={styles.text}>3.1 Исполнитель начинает выполнение работ по настоящему Договору в течении одного рабочего дня с момента поступления денежных средств на расчетный счет Исполнителя. Ежемесячное предоставление отчетов о ходе рекламной кампании.</Text>
          <Text style={styles.text}>3.2. Исполнитель не предоставляет гарантию Заказчику на какое-либо конкретное количество звонков, поступаемых к Заказчику от возможных покупателей. Ввиду того, что обращаясь к опубликованному объявлению Заказчика, покупатель принимает решение о звонке, основываясь на выгодности этого предложения для него. Исполнитель не может влиять на цену и качество продаваемого Заказчиком товара, а следовательно, и на выгодность предложения Заказчика.</Text>
          <Text style={styles.text}>3.3. Не предоставлять гарантию Заказчику на количество продаж, совершаемых Заказчиком возможным покупателям, привлеченным Исполнителем, в рамках работ определенных в п.2.1 настоящего Договора. Количество продаж определяется профессиональным уровнем персонала Заказчика. Исполнитель не может влиять на профессиональный уровень персонала Заказчика, следовательно, и на количество продаж.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>4. Порядок сдачи-приемки услуг </Text>

          <Text style={styles.text}>4.1. Исполнитель после окончания оказания рекламных услуг, указанных в счет – фактуре, подтверждает Актом об оказании услуг, который направляется Исполнителем Заказчику посредством электронных каналов связи в виде сканированной копии с подписью и печатью Исполнителя. В случае необходимости Исполнитель обязан выслать Заказчику оригинал Акта об оказанных услугах в течение 3 (трех) рабочих дней после получения соответствующего требования от Заказчика.</Text>
          <Text style={styles.text}>4.2. При направлении Исполнителем Акта об оказанных услугах Заказчик обязуется рассмотреть Акт, подписать его и направить Исполнителю в течение 3 (трех) рабочих дней либо в тот же срок предоставить мотивированные возражения от подписания Акта.</Text>
          <Text style={styles.text}>4.3. Заказчик безоговорочно соглашается с тем, что если он в течение 3 (трех) рабочих дней с момента получения Акта об оказании услуг не заявит каких-либо письменных претензий по оказанным Исполнителем услугам, то это будет рассматриваться как однозначное согласие Заказчика с тем, что услуги оказаны Исполнителем своевременно, в полном объеме и надлежащим образом.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>5. Размер и порядок оплаты услуг.</Text>

          {dataPayment.price.length === 1 &&
            <>
              <Text style={styles.text}>5.1 Стоимость услуг составляет
                {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                {dataPayment.price.reduce((akk, p) => +akk + +p)} руб ежемесячно ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
              {

                dataPayment.price.map((p, i) =>
                  <Text key={i} style={styles.text}>{i + 1} Оплата {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)})  до {dataPayment.payment[i]} года</Text>
                )
              }
            </>
          }
          {dataPayment.price.length >= 2 && dataPayment.payment.length >= 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>5.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) до {dataPayment.payment[i]} года</Text>
                  )}
                </>
              }
            </>
          }
          {dataPayment.price.length === 2 && dataPayment.payment.length !== 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>5.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {i === 1 && 'Оплата в размере '} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) {i === 1 ? 'по завершению работ, что подтверждается актом выполненных работ и оплата производится в течение 5-ти банковских дней с момента подписания акта.' : 'до ' + dataPayment.payment[i] + ' года'}  </Text>
                  )}
                </>
              }
            </>
          }


          <Text style={styles.text}>5.2. Дополнительные услуги Исполнителя, их стоимость, а также любые изменения в ранее согласованных и утвержденных этапах работ для Заказчика согласуются отдельно. Дополнительные работы и услуги, оплачиваются Заказчиком отдельно, на основании выставленных счет-фактур, являющихся неотъемлемой частью Договора.</Text>
          <Text style={styles.text}>5.3. Все расчеты по Договору осуществляются в рублях без НДС.</Text>
          <Text style={styles.text}>5.4. Оплата счета Заказчиком означает согласие Заказчика с условиями настоящего договора.</Text>
          <Text style={styles.text}>5.5. Оплата услуг по обслуживанию рекламной компании производится ежемесячно до 5 числа.</Text>
          <Text style={styles.text}>5.6. Днем оплаты считается день поступления денежных средств на расчетный счет Исполнителя.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>6. Ответственность сторон.</Text>

          <Text style={styles.text}>6.1 За нарушения условий настоящего договора стороны несут ответственность в соответствии с действующим законодательством РБ. </Text>
          <Text style={styles.text}>6.2 При нарушение Заказчиком сроков исполнения обязательств по оплате услуг Исполнителя согласно п.5 настоящего Договора Исполнитель не гарантирует выполнение своих обязательств в срок и с надлежащим качеством.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>7. Обстоятельства непреодолимой силы (форс-мажор).</Text>

          <Text style={styles.text}>7.1. Заказчик предупрежден о том, что использование не принадлежащих ему зарегистрированных товарных знаков, логотипов, наименований, слоганов, изображений, фотографий является нарушением законодательства РБ. Заказчик обязуется не размещать материалы, запрещенные законодательством РБ и нарушающие нормы сетевого этикета. Исполнитель не несет ответственности за указанные выше нарушения.</Text>
          <Text style={styles.text}>7.2. Исполнитель не несет ответственности по претензиям Заказчика к качеству доступа к информации Заказчика, связанных с качеством функционирования сетей Интернет – провайдеров, городской телефонной сети, с функционированием абонентского оборудования и другими обстоятельствами, находящимися вне компетенции Исполнителя.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>8. Конфиденциальность.</Text>

          <Text style={styles.text}>8.1. Любая информация, полученная Сторонами в ходе исполнения Договора, рассматривается, как конфиденциальная и не может быть раскрыта третьим лицам, за исключением запросов уполномоченных государственных органов и других случаев, предусмотренных действующим законодательством РБ. </Text>
          <Text style={styles.text}>8.2. Конфиденциальная информация всегда остается собственностью передающей эту информацию Стороны и не должна воспроизводиться без предварительного письменного согласия такой передающей Стороны. </Text>
          <Text style={styles.text}>8.3. Обязательство сохранять в тайне Конфиденциальную Информацию в соответствии с условиями настоящей статьи вступает в силу с момента подписания Договора обеими Сторонами и остается в силе в течение 3 (трех) лет по окончании срока действия Договора или его прекращения действия по какой-либо причине.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>9. Срок действия договора</Text>

          <Text style={styles.text}>9.1. Договор вступает в силу с момента его подписания Сторонами и действует до 31.12.{thisDateYers()} г., а в части взаиморасчетов до исполнения сторонами своих обязательств. Договор считается пролонгированным на следующий календарный год, если ни одна из сторон договора не заявила расторжении договора за один месяц до окончания срока договора.</Text>
          <Text style={styles.text}>9.2. Досрочное расторжение Договора возможно только по взаимному согласию Сторон.</Text>
          <Text style={styles.text}>9.3. Заказчик безоговорочно соглашается с тем, что в случае одностороннего отказа от настоящего Договора по собственной инициативе либо в случае одностороннего отказа по инициативе Исполнителя, вызванного нарушением Заказчиком условий настоящего Договора или действующего законодательства, возврат денежных средств, уплаченных Заказчиком в счет оплаты/предоплаты услуг, не производится, даже в случае, если срок оказания оплаченных услуг не окончен. В этом случае договор считается исполненным на последний день месяца, в котором поступила оплата/предоплата либо односторонний отказ от настоящего договора Исполнителя, либо Заказчика.</Text>
          <Text style={styles.text}>9.4. Сумма исковых претензий не может превышать половины стоимости настоящего Договора.</Text>
          <Text style={styles.text}>9.5. Все изменения и дополнения настоящего Договора оформляются в виде дополнительных соглашений, которые подписываются сторонами и являются неотъемлемой часть Договора.</Text>
          <Text style={styles.text}>9.6.  Настоящий Договор составлен в двух экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру на каждую из сторон.</Text>
          <Text style={styles.text}>9.7. Стороны признают юридическую силу за электронными письмами – документами, направленными по электронной почте (e-mail), и признают их равнозначными документами на бумажных носителях, подписанным собственноручной подписью, т.к. только сами Стороны и уполномоченные ими лица имеют доступ к соответствующим адресам электронной почты, указанным в Договоре в реквизитах Сторон и являющимся электронной подписью соответствующей Стороны. Доступ к электронной почте каждая Сторона осуществляет по паролю и обязуется сохранять его конфиденциальность.</Text>
          <Text style={styles.text}>9.8. В случае если подписанный договор не возвращен Исполнителю, то фактом подписания договора считается оплата Заказчиком услуг. Оплата услуг свидетельствует о том, что Заказчик ознакомлен с условиями договора и полностью их принимает.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt, styles.mb]}>10. Прочие условия.</Text>

          <Text style={styles.text}>10.1. В случае изменения законодательных и нормативных актов, тарифов и сборов, цен на услуги, предоставляемые в сети Интернет, введения иных обязательных платежей, распространяемых на Исполнителя, а также изменения индекса цен на территории РБ, Исполнитель вправе пересмотреть условия настоящего Договора с обязательным уведомлением Заказчика.</Text>

          <PDFFooterSingFull data={dataPayment} />
        </View>
      </Page>
    </Document >
  )
}
export default PDFComponentContractContextService