import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr } from '../components/UI/alert'

export const fetchAuth = createAsyncThunk(
  'auth/fetchAuth',
  async function (formData, { rejectWithValue }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/getAuth.php`, {
        // const response = await fetch(`http://localhost:5000/api/login`, {
        // headers: {
        //   'Content-Type': 'application/json;charset=utf-8'
        // },
        method: "POST",
        body: formData,
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      if (!data.status) {
        alertErr.fire({
          text: 'Неверный логин или пароль!',
        })
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("privat");
        sessionStorage.removeItem("name");
        return
      }
      sessionStorage.setItem("auth", data.status);
      sessionStorage.setItem("privat", data.privat);
      sessionStorage.setItem("name", data.name);
      

      return data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: sessionStorage.getItem("auth"),
    privat: sessionStorage.getItem("privat"),
    name: sessionStorage.getItem("name"),
    status: null,
    error: null,
  }, 
  reducers: {
    toggleAuth(state, action){
      state.auth = action.payload
    }
  },
  extraReducers: {
    [fetchAuth.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchAuth.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.auth = action.payload.status
      state.privat = action.payload.privat
      state.name = action.payload.name
    },
    [fetchAuth.rejected]: setError,

  }
})
export const { toggleAuth } = authSlice.actions
export default authSlice.reducer