import React from "react";

import { Link } from 'react-router-dom'

import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
  buttonMin: {
    width: '100%',
    minWidth: '55px'
  },
  a: {
    color: '#000',
    textDecoration: "none"
  },
  span: {
    paddingLeft: "5px"
  },
  center: {
    textAlign: 'center',
  },
}

function HostingsList({ seoClientsArr }) {
  function getObject(json) {

    let domains = ''
    JSON.parse(json).forEach(el => {
      domains += el.domain + ' '
    });
    return domains
  }
  function fieldDomain(dom) {
    window.open('https://' + dom, '_blank')
  };
  return (
    <>
      <div className="listHead">
        <Row>
          <Col lg="1" className="mb-2">№</Col>
          <Col lg="4" className="mb-2">Название</Col>
          <Col lg="2" className="mb-2">Домен</Col>
          <Col lg="2" className="mb-2">Телефон</Col>
          <Col lg="2" className="mb-2">email</Col>
          <Col lg="1" className="mb-2">Менеджер</Col>
        </Row>
      </div>
      <TransitionGroup>
        {seoClientsArr.map((elm, i) =>
          <CSSTransition
            key={elm.id}
            timeout={500}
            classNames="formanimation"
          >
            <div className={"listBody"}>
              <Row>
                <Col lg="1"><Link to={{
                  pathname: '/clients/' + elm.id,
                  search: '?tabId=2',
                }}>№ {i + 1}</Link></Col>
                <Col lg="4"><Link to={{
                  pathname: '/clients/' + elm.id,
                  search: '?tabId=2',
                }}>{elm.name}</Link></Col>
                <Col lg="2" onDoubleClick={() => fieldDomain(getObject(elm.domains))}>{getObject(elm.domains)}</Col>
                <Col lg="2"> <a style={style.a} href={'tel:+' + elm.phone.replace(/[-() +.]/g, '')} target="_blank" rel="noopener noreferrer">{elm.phone}</a></Col>
                <Col lg="2"> <a style={style.a} href={'mailto:' + elm.email} target="_blank" rel="noopener noreferrer">{elm.email}</a></Col>
                <Col lg="1">{elm.manager}</Col>
              </Row>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup >
    </>
  );
};

export default React.memo(HostingsList);