import React from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'

import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function RatioManagersPrognose({ indicators }) {
  const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1
  const allDays = new Date(year, month, 0).getDate()
  const thisDay = new Date().getDate()

  function compareName(name) {
    return Object.keys(indicators.managersPlan).find(n => n === name)
  }

  function getValumeManagers() {
    const newArr = []
    for (const key in indicators.totalVolumeThisMonth) {
      if (compareName(key) === key) newArr.push(indicators.totalVolumeThisMonth[key].join())
    }
    return newArr
  }

  const managersName = Object.keys(indicators.totalVolumeThisMonth).filter(el => el === compareName(el))
  const managersVolume = getValumeManagers()

  const plans = managersName.map(el => indicators.managersPlan[el])

  const colors = managersVolume.map((el, i) => {
    const p1 = plans[i] / 3
    const p2 = plans[i] / 2
    const p3 = plans[i] / 1.2
    if (el < p1) return "rgba(233, 0, 0, 1)"
    if (el > p1 && el < p2) return "rgba(233, 140, 0, 1)"
    if (el > p2 && el < p3) return "rgba(233, 190, 0, 1)"
    if (el > p3) return "rgba(5, 233, 0, 1)"
    return "rgba(233, 0, 0, 1)"
  })

  const barChartData = {
    labels: managersName,
    datasets: [
      {
        label: months[month],
        data: managersVolume,
        borderColor: "#ff3333",
        backgroundColor: colors,
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: plans,
        label: "План",
        borderColor: "#ff3333",
        backgroundColor: "rgba(30, 212, 0, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: managersVolume.map(elm => (+elm / thisDay) * allDays),
        label: "Прогноз",
        borderColor: "#ff3333",
        backgroundColor: "rgba(124, 185, 232, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      },
    ],
  };

  return (
    <Row>
      <Col lg="12" className="mb-3">
        <Bar
          type="bar"
          width={130}
          height={50}

          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "Отчет по менеджерам за месяц с планом прогнозом",
              }
            },
          }}
          data={barChartData}
        />
      </Col>
    </Row>
  )
};

export default RatioManagersPrognose;