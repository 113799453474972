import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import { rubles } from "rubles";
import logo from '../../images/logo.png'
import { toFixed, thisDateYers } from "../functions";

import PDFFooterSingFull from "./PDFFooterSingFull"

function PDFComponentContractDevSiteDesign({ dataPayment }) {
  const found = dataPayment.firmaName.includes("ХитГрупп");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Договор № С-{dataPayment.contractNum}</Text>
          <Text style={[styles.headTitle, styles.mb]}>Услуги по разработке дизайна и(или) верстки сайта</Text>
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>
          <Text style={styles.text}>{dataPayment.firmaName.replace(/\sRU$/, "")}, именуемый в дальнейшем «Исполнитель», в лице {dataPayment.firmaInFace}, {dataPayment.firmaFooting} с одной стороны, и {dataPayment.clientName}, именуемый в дальнейшем «Заказчик», в лице {dataPayment.clientInFace}, действующего на основании {dataPayment.clientFooting}, с другой стороны, именуемые в дальнейшем Стороны, заключили настоящий Договор (далее Договор) о нижеследующем:</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>1. Предмет договора</Text>

          <Text style={styles.text}>1.1	В соответствии с условиями настоящего договора Исполнитель выполняет по заданию Заказчика Работы и передает их результаты Заказчику. Заказчик обязуется принять и оплатить оказанную услугу.</Text>
          <Text style={styles.text}>1.2 Конкретный перечень работ, составляющих задание Заказчика, оговариваются в техническом задании.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2. Права и обязанности сторон</Text>

          <Text style={styles.text}>2.1 ИСПОЛНИТЕЛЬ:</Text>
          <Text style={styles.text}>2.1.1 обязан разработать дизайн и(или) верстку сайта;</Text>
          <Text style={styles.text}>2.1.2 знакомит Заказчика по его требованию с ходом Работ;</Text>
          <Text style={styles.text}>2.1.3 вправе отказаться от выполнения Работ, если исходные информационные материалы, по мнению Исполнителя, не соответствуют требованиям законодательства Республики Беларусь при условии письменного обоснования Исполнителем такого отказа;</Text>
          <Text style={styles.text}>2.1.4 вправе приостановить или прекратить оказание услуг, либо расторгнуть настоящий Договор в одностороннем порядке, в случае неисполнения Заказчиком принятых на себя обязательств по данному Договору, в том числе просрочке оплаты Заказчиком услуг по настоящему Договору. Исполнитель уведомляет Заказчика о приостановке или прекращении оказания услуг путем направления сообщения по электронной почте.</Text>
          <Text style={styles.text}>2.1.5 в процессе разработки дизайна сайта, все изменения вносятся на этапе разработки макета, и по окончанию работы согласовываются с заказчиком путем подтверждения по средствам электронной почты словами «макет согласован» или подписью на распечатанном макете с подписью и/или печатью заказчика. Все изменения в наверстанном сайте, которые не были согласованы, на этапе разработки макета осуществляются на платной основе путем выставления дополнительных счет - фактур.</Text>
          <Text style={styles.text}>2.1.6 по окончании выполнения Работ либо их отдельных этапов предоставляет Заказчику акты выполненных работ.</Text>
          <Text style={styles.text}>2.1.7 общий срок выполнения работ, обусловленных настоящим договором, составляет до 30 (Шестьдесят) календарных дней после получения предоплаты.</Text>

          <Text style={styles.text}>2.2 ЗАКАЗЧИК:</Text>
          <Text style={styles.text}>2.2.1 своевременно предоставляет Исполнителю все исходные информационные материалы, необходимые для выполнения Работ, составляющих задание Заказчика;</Text>
          <Text style={styles.text}>Если с момента подписания договора прошло более 30 (тридцати) календарных дней и Заказчик не предоставил все необходимые информационные материалы для разработки дизайна сайта, Исполнитель имеет право расторгнуть договор в одностороннем порядке. В таком случае предоплата Заказчику не возвращается;</Text>
          <Text style={styles.text}>2.2.2 информирует Исполнителя о том, что деятельность Заказчика (товары, работы, услуги, игры) подлежат лицензированию либо специальной регистрации, и предъявляет копии лицензий, свидетельство о регистрации, копии сертификатов, если рекламируются товары, которые подлежат сертификации, а также положительные заключения уполномоченных органов, в случае размещения товаров (работ, услуг), на которые необходимо получение соответствующих разрешений (лекарства, медицинские услуги, трудоустройство за границей и др.);</Text>
          <Text style={styles.text}>2.2.3 согласно ст.10 Закона Республики Беларусь «О рекламе» предоставляет информацию о наименовании Заказчика, его учетный номер плательщика, а также, если заказчиком является гражданин, паспортные данные.</Text>
          <Text style={styles.text}>2.2.4 при наличии претензий по оказанным Исполнителем услугам письменно или путем электронной связи (е-mail) уведомить об этом Исполнителя в течение 24 (двадцати четырех) часов с момента, когда Заказчику стало известно или должно было стать известным о неисполнении или ненадлежащем исполнении Исполнителем принятых на себя обязательств по настоящему Договору;</Text>
          <Text style={styles.text}>2.2.5 принимает и оплачивает услуги в порядке и сроки, предусмотренные настоящим договором;</Text>
          <Text style={styles.text}>2.2.6. в случае предъявления Исполнителю претензий со стороны третьих лиц, представить Исполнителю документы и иные доказательства, подтверждающие авторское право Заказчика на материалы, предоставленные Заказчиком;</Text>
          <Text style={styles.text}>2.2.7. Заказчик самостоятельно несет полную ответственность за наличие у него свидетельства о государственной регистрации, всех необходимых в соответствии с законодательством лицензий, сертификатов, разрешений и согласований государственных органов.</Text>



          {/* Дополнения */}
          <Text style={styles.text}>2.2.8. В случае досрочного выполнения работ Заказчик вправе досрочно принять и оплатить работы по договорной цене.</Text>

          <Text style={styles.text}>2.2.9. Заказчик имеет право на внесение не более двух бесплатных правок после представления прототипа сайта и(или) дизайн-макета. Любые дополнительные изменения после утвержденного количества бесплатных правок будут считаться дополнительными услугами. Стоимость таких услуг будет определяться исходя из объема выполненных правок, их сложности и затраченного на них времени Исполнителя. Исполнитель предоставит Заказчику счет за выполнение этих дополнительных работ.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3. Стоимость и порядок оплаты работ</Text>

          {dataPayment.price.length === 1 &&
            <>
              <Text style={styles.text}>3.1 Стоимость услуг составляет
                {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                {dataPayment.price.reduce((akk, p) => +akk + +p)} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
              <Text style={styles.text}>3.2 Оплата производится Заказчиком следующим образом:</Text>
              {

                dataPayment.price.map((p, i) =>
                  <Text key={i} style={styles.text}>{i + 1} составляет {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)})  до {dataPayment.payment[i]} года</Text>
                )
              }
            </>
          }
          {dataPayment.price.length >= 2 && dataPayment.payment.length >= 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>3.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) до {dataPayment.payment[i]} года</Text>
                  )}
                </>
              }
            </>
          }
          {dataPayment.price.length === 2 && dataPayment.payment.length !== 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>3.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {i === 1 && 'Оплата в размере '} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) {i === 1 ? 'по завершению работ, что подтверждается актом выполненных работ и оплата производится в течение 5-ти банковских дней с момента подписания акта.' : 'до ' + dataPayment.payment[i] + ' года'}  </Text>
                  )}
                </>
              }
            </>
          }

          <Text style={styles.text}>Проценты на предоплату не начисляются. Предоплата не возвращается в случае, если работы, заявленные в техническом задании, выполнены в полном объеме и согласованны согласно пункту 2.1.5., но Заказчик требует дополнительных работ, которые должны оплачиваться отдельно. Работы по изготовлению дизайна и(или) верстки сайта считаются выполненными в полном объеме.</Text>
          <Text style={styles.text}>Дополнительные работы и услуги, не включенные в стоимость разработки, оплачиваются Заказчиком отдельно, на основании выставленных счет-фактур, являющихся неотъемлемой частью Договора.</Text>
          <Text style={styles.text}>3.2. Исполнитель в срок не позднее 5 (пяти) рабочих дней с момента окончания оказания услуг, указанных в счет – фактуре, подтверждает Актом об оказании услуг, который направляется Исполнителем Заказчику посредством электронных каналов связи в виде сканированной копии с подписью и печатью Исполнителя. В случае необходимости Исполнитель обязан выслать Заказчику оригинал Акта об оказанных услугах в течение 3 (трех) рабочих дней после получения соответствующего требования от Заказчика.</Text>
          <Text style={styles.text}>3.3. При направлении Исполнителем Акта об оказанных услугах Заказчик обязуется рассмотреть Акт, подписать его и направить Исполнителю в течение 3 (трех) рабочих дней либо в тот же срок предоставить мотивированные возражения от подписания Акта.</Text>
          <Text style={styles.text}>3.4. Заказчик безоговорочно соглашается с тем, что если он в течение 3 (трех) рабочих дней с момента получения Акта об оказании услуг не заявит каких-либо письменных претензий по оказанным Исполнителем услугам, то это будет рассматриваться как однозначное согласие Заказчика с тем, что услуги оказаны Исполнителем своевременно, в полном объеме и надлежащим образом.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>4. Разрешение споров и ответственность сторон</Text>

          <Text style={styles.text}>4.1. Все спорные вопросы разрешаются путем переговоров, а при невозможности разрешения ― в Экономическом суде г. Гомеля. Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй Стороне. Претензия предъявлена надлежащим образом, если направлена другой Стороне любым способом, позволяющим установить личность отправителя и факт вручения корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй Стороны, не является обязательным.</Text>
          <Text style={styles.text}>Сторона, получившая претензию, в течение 10 (десяти) рабочих дней со дня ее получения письменно уведомляет вторую Сторону о результатах рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией и изложенными в ней обстоятельствами.</Text>
          <Text style={styles.text}>4.2. Доказательствами в суде могут являться факсимильные копии и распечатки электронных писем, если переписка осуществлялась с адресов электронной почты сторон.</Text>
          <Text style={styles.text}>4.3 Если договор расторгается по желанию Заказчика на любом этапе работ, Заказчик оплачивает фактически проделанную работу. В адрес Заказчика направляется акт выполненных работ посредством электронной связи. Заказчик обязан подписать и отправить в адрес Исполнителя один экземпляр акта об оказании услуг в течении 5 (пять) рабочих дней с момента получения акта. В случае, если в указанный выше срок Заказчик не отправит Исполнителю подписанный акт об оказании услуг и не отправит письменный мотивированный отказ от приемки оказанных услуг, то стороны считают, что услуги, указанные в соответствующем счет - фактуре, приняты Заказчиком без претензий и подлежат оплате.</Text>
          <Text style={styles.text}>4.4 В случае несвоевременной оплаты Заказчиком оказанных Исполнителем услуг (выполненных работ) в соответствии с п.3.2 настоящего Договора Исполнитель вправе взыскать с Заказчика пеню в размере 0,5% (ноль целых пять десятых процента) от стоимости услуги (выполненных работ) за каждый день просрочки.</Text>
          <Text style={styles.text}>4.5. Исполнитель не несет ответственности за несоответствие фактически предоставленных Услуг, субъективным ожиданиям и представлениям Заказчика о таких Услугах;</Text>
          <Text style={styles.text}>4.6. Во всем остальном, что не оговорено настоящим Договором, Стороны несут ответственность в соответствии с законодательством Республики Беларусь.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>5. Форс-мажор</Text>

          <Text style={styles.text}>5.1. Ни одна из Сторон не несет ответственности за полное или частичное неисполнение обязательств по настоящему Договору в случае пожара, наводнения, землетрясения и других стихийных бедствий, возникших после заключения настоящего Договора, а также обстоятельств неопределимой силы в результате событий чрезвычайного характера, которые Сторона не могла ни предвидеть, ни предотвратить разумными методами (форс-мажорные обстоятельства).</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>6. Порядок изменения и срок действия договора</Text>

          <Text style={styles.text}>6.1. Договор вступает в силу с момента его подписания Сторонами и действует до 31.12.{thisDateYers()} г., а в части взаиморасчетов до исполнения сторонами своих обязательств. Договор считается пролонгированным на следующий календарный год, если ни одна из сторон договора не заявила о расторжении договора за один месяц до окончания срока договора.</Text>
          <Text style={styles.text}>6.2. Досрочное расторжение договора возможно по согласию сторон.</Text>
          <Text style={styles.text}>6.3. Заказчик безоговорочно соглашается с тем, что в случае одностороннего отказа от настоящего Договора по собственной инициативе либо в случае одностороннего отказа по инициативе Исполнителя, вызванного нарушением Заказчиком условий настоящего Договора или действующего законодательства, возврат денежных средств, уплаченных Заказчиком в счет оплаты услуг, не производится, даже в случае, если срока оказания оплаченных услуг не окончен.</Text>
          <Text style={styles.text}>6.4.   Все изменения и дополнения настоящего Договора оформляются в виде дополнительных соглашений, которые подписываются сторонами и являются неотъемлемой часть Договора.</Text>
          <Text style={styles.text}>Договор, изменения и дополнения к нему, другие документы, заверенные подписью и печатью, переданные по факсимильной связи, имеют юридическую силу.</Text>
          <Text style={styles.text}>6.5.  Настоящий Договор составлен в двух экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру на каждую из сторон.</Text>
          <Text style={styles.text}>6.6. Стороны признают юридическую силу за электронными письмами – документами, направленными по электронной почте (e-mail) или посредством мессенджеров, и признают их равнозначными документами на бумажных носителях, подписанным собственноручной подписью, т.к. только сами Стороны и уполномоченные ими лица имеют доступ к соответствующим адресам электронной почты, указанным в Договоре в реквизитах Сторон и являющимся электронной подписью соответствующей Стороны. Доступ к электронной почте каждая Сторона осуществляет по паролю и обязуется сохранять его конфиденциальность.</Text>
          <Text style={styles.text}>6.7. В случае если подписанный договор не возвращен Исполнителю, то фактом подписания договора считается оплата Заказчиком услуг. Оплата услуг свидетельствует о том, что Заказчик ознакомлен с условиями договора и полностью их принимает.</Text>


          <PDFFooterSingFull data={dataPayment} />
        </View>
      </Page>
    </Document >
  )
}
export default PDFComponentContractDevSiteDesign