import React, { useCallback, useEffect, useState } from "react";

// import { useSelector, useDispatch } from 'react-redux';
// import { Parishes } from '../../context'
import Td from './Td';
import styles from './styles.module.sass'

function Payment({ managers, clientName, parishes, mouseHover }) {
  // const { parishes, theadArr } = useContext(Parishes)
  const getValueBase = useCallback((params) => {
    if (parishes[params]) return JSON.parse(parishes[params]).value
    else return ''
  }, [parishes])
  const getMarkerBase = useCallback((params) => {
    if (parishes[params]) return JSON.parse(parishes[params]).marker
    else return false
  }, [parishes])

  const [sum, setSum] = useState([]);
  const [sumMarker, setSumMarker] = useState('');
  // Расчет баланса
  const priceStringSum = useCallback(() => {
    const arrSum = []
    let amount = ''
    const arrFields = (key) => {
      return ["refill_g", "refill_y", "refill_i", "dev_site", "dev_context", "programming", "dev_seo", "host", "map", "dev_i", "maint_i", "maint_context", "maint_site", "products", "dev_complex", "other"].find(el => el === key)
    }
    for (const key in parishes) {
      if (arrFields(key) && parishes[key]) {
        arrSum.push(JSON.parse(parishes[key]).value.replace(/\D/gi, ''));
      }
      if (key === "amount" && parishes[key]) amount = JSON.parse(parishes[key]).value.replace(/\D/gi, '')
    }
    const fulSum = arrSum.reduce((acc, el) => {
      return +acc + +el
    }, 0);
    const total = +amount - +fulSum
    setSum(total);
    if (total < 0) setSumMarker('red')
    else if (total > 0) setSumMarker('blue')
    else setSumMarker('')
  }, [parishes])

  useEffect(() => {
    priceStringSum()
  }, [priceStringSum])

  return (
    <tbody className={styles.tbody}>
      <tr>
        <Td width="100" datathid={1} id={parishes.id} calendar={'calendar-' + parishes.id} name={"date"} value={parishes.date} />
        <Td width="390" datathid={2} id={parishes.id} value={parishes.client} client={true} clientName={clientName} name={"client"} />
        <Td width="76" mouseHover={mouseHover} datathid={3} id={parishes.id} value={getValueBase("amount")} type="text" name={"amount"} amount={true} />
        <Td width="60" mouseHover={mouseHover} datathid={4} id={parishes.id} name={"balance"} balance={true} type="number" value={sum} className={sumMarker ? sumMarker : ''} />
        <Td width="70" datathid={5} id={parishes.id} value={parishes.code} name={"code"} code={true} />
        <Td width="90" datathid={6} id={parishes.id} value={parishes.manager} name={"manager"} managers={managers} />
        <Td width="76" mouseHover={mouseHover} datathid={7} id={parishes.id} marker={getMarkerBase("refill_g")} value={getValueBase("refill_g")} type="number" name={"refill_g"} />
        <Td width="76" mouseHover={mouseHover} datathid={8} id={parishes.id} marker={getMarkerBase("refill_y")} value={getValueBase("refill_y")} type="number" name={"refill_y"} />
        <Td width="76" mouseHover={mouseHover} datathid={9} id={parishes.id} marker={getMarkerBase("refill_i")} value={getValueBase("refill_i")} type="number" name={"refill_i"} />
        <Td width="76" mouseHover={mouseHover} datathid={10} id={parishes.id} marker={getMarkerBase("dev_site")} value={getValueBase("dev_site")} type="number" name={"dev_site"} />
        <Td width="76" mouseHover={mouseHover} datathid={11} id={parishes.id} marker={getMarkerBase("dev_context")} value={getValueBase("dev_context")} type="number" name={"dev_context"} />
        <Td width="76" mouseHover={mouseHover} datathid={12} id={parishes.id} marker={getMarkerBase("dev_seo")} value={getValueBase("dev_seo")} type="number" name={"dev_seo"} />
        <Td width="76" mouseHover={mouseHover} datathid={13} id={parishes.id} marker={getMarkerBase("programming")} value={getValueBase("programming")} type="number" name={"programming"} />
        <Td width="76" mouseHover={mouseHover} datathid={14} id={parishes.id} marker={getMarkerBase("host")} value={getValueBase("host")} type="text" name={"host"} />
        <Td width="76" mouseHover={mouseHover} datathid={15} id={parishes.id} marker={getMarkerBase("map")} value={getValueBase("map")} type="number" name={"map"} />
        <Td width="76" mouseHover={mouseHover} datathid={16} id={parishes.id} marker={getMarkerBase("dev_i")} value={getValueBase("dev_i")} type="number" name={"dev_i"} />
        <Td width="76" mouseHover={mouseHover} datathid={17} id={parishes.id} marker={getMarkerBase("maint_i")} value={getValueBase("maint_i")} type="number" name={"maint_i"} />
        <Td width="76" mouseHover={mouseHover} datathid={18} id={parishes.id} marker={getMarkerBase("maint_context")} value={getValueBase("maint_context")} type="number" name={"maint_context"} />
        <Td width="76" mouseHover={mouseHover} datathid={19} id={parishes.id} marker={getMarkerBase("maint_site")} value={getValueBase("maint_site")} type="number" name={"maint_site"} />
        <Td width="76" mouseHover={mouseHover} datathid={20} id={parishes.id} marker={getMarkerBase("products")} value={getValueBase("products")} type="number" name={"products"} />
        <Td width="76" mouseHover={mouseHover} datathid={21} id={parishes.id} marker={getMarkerBase("dev_complex")} value={getValueBase("dev_complex")} type="number" name={"dev_complex"} />
        <Td width="100" mouseHover={mouseHover} datathid={22} id={parishes.id} marker={getMarkerBase("other")} value={getValueBase("other")} name={"other"} />
      </tr>
    </tbody>
  );
};
export default React.memo(Payment)