import React from "react";
// import { Chart as ChartJS } from 'chart.js/auto'
import { Pie } from 'react-chartjs-2'
function TotalVolumeDev({ indicators }) {
  const q = Object.entries(indicators.refill);
  const qArr = q.map(el => el[1].reduce((acc, el) => +acc + +el, 0));
  const qName = q.map(el => el[0]);
  const qColor = q.map(el => `rgb(${[1, 2, 3].map(x => Math.random() * 256 | 0)})`);

  const barChartData = {
    labels: qName,
    datasets: [
      {
        data: qArr,
        borderColor: '#fff',
        backgroundColor: qColor,
        fill: true
      },
    ]
  };
  return (
    <Pie
      type="Pie"
      width={130}
      height={50}

      options={{
        plugins: {
          title: {
            display: true,
            text: "Общий отчет по объему пополнений с начала года",
          },
          legend: {
            display: true, //Is the legend shown?
            position: "top" //Position of the legend.
          }
        },
      }}
      data={barChartData}
    />
  )
};

export default TotalVolumeDev;