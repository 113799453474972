import React, {useEffect} from 'react'
import { AppRouter } from './components/AppRouter'


import { toggleAuth } from './store/authSlice';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch()
  useEffect(()=>{
    if(sessionStorage.getItem('auth')) dispatch(toggleAuth(sessionStorage.getItem('auth')))
  },[dispatch])
  return (
    <AppRouter />
  );

}

export default App;
