import React from 'react';
import { styles } from "./PDFComponentStyle";
import { Image, Text, View } from '@react-pdf/renderer';

function PDFFooter({ stamp }) {
  const sing = require(`../../images/${stamp}`)
  return (
    <>
      <Text style={[styles.recTextM, styles.mt]}>Срок оплаты - 5 дней.</Text>
      <Text style={styles.recTextM}>Счет-фактура является протоколом согласования цен.</Text>
      <Text style={styles.recTextM}>Цены: договорные.</Text>

      <View style={[styles.rowSing]}>
        <View>
          <Text style={[styles.recTitle]}>Исполнитель:</Text>
          <Image
            style={styles.image}
            src={sing}
          />
          <Text style={[styles.recText]}>М.П.</Text>
        </View>
        <View>
          <Text style={[styles.recTitle]}>Заказчик:</Text>
          <Text style={[styles.recText]}>М.П.</Text>
        </View>
      </View>
    </>
  )
}
export default PDFFooter