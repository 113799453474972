import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Textarea from "../UI/Textarea"
import style from './style.module.sass'

function BriefMapForm({ thisClient }) {
  return (
    <Row className={"align-items-center"}>
      <Col lg="12" className="mb-3">
        <h2 className="title">1.	Информация о компании</h2>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.1 Название компании полное
          (и если отличается название бренда)
        </p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-1" height={"50px"} value={thisClient?.name} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.2 Реквизиты и УНП организации</p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-2" height={"140px"} value={thisClient?.data} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.3 На каких картах нужна регистрация?</p>
      </Col>
      <Col lg="8" className="mb-3">
        <ul className={style.ul}>
          <li><label htmlFor="brief_map-1-3">
            <input className='checkbox' type="checkbox" id="brief_map-1-3" name="brief_map-1-3" data-name="brief_map-1-3" value="Яндекс" />
            <p>Яндекс</p>
          </label></li>
          <li><label htmlFor="brief_map-1-4">
            <input className='checkbox' type="checkbox" id="brief_map-1-4" name="brief_map-1-4" data-name="brief_map-1-4" value="Google" />
            <p>Google</p>
          </label></li>
        </ul>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.4 Была ли зарегистрирована организация на картах ранее?
          <br /><span className='sub'>(Если да укажите ссылку)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-5" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.5 Есть доступ в кабинет?
          <br /><span className='sub'>(Если «да», то дать доступ администратора (представителя) для Яндекс MediaRama.Oksana@yandex.ru Google  hittoksana@gmail.com)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-6" height={"140px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.6 Укажите Торговый бренд / Название бренда
          <br /><span className='sub'>(название бренда мы будем использовать на картах, в текстах)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-7" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.7 Укажите Виды деятельности
          <br /><span className='sub'>(на картах будет подобран наиболее соответствующий вид деятельности) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-8" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.8 Укажите Телефон
          <br /><span className='sub'>(будет указано на картах, при авторизации на него будет приходить код от Goоgle и Яндекс. Пропишите все мессенджеры, которые есть на этом номере телефона.) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-9" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>1.9 Укажите Телефон администратора
          <br /><span className='sub'>(администратора или лица, у которого можно будет уточнить особенности торговой точки) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-1-10" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.0 Адрес расположения офиса, торгового объекта (подробно) и т.д. + индекс
          <br /><span className='sub'>(по этому адресу будет зарегистрирована организация, установлена геометка, на него будет приходить код подтверждения от Google) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-0" height={"150px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.1 Территория обслуживания
          <br /><span className='sub'>(для Goоgle и онлайн карт Яндекс Написать: город, район, область, страна) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-1" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.2 Укажите E-mail
          <br /><span className='sub'>(будет указан на картах для клиентов) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-2" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.3 Укажите E-mail
          <br /><span className='sub'>(на указанный е-mail будет выслан доступ в аккаунт на картах для управления. Для Яндекс подходит только логин Яндекс. От Google указать логин и пароль.) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-3" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.4 Время работы
          <br /><span className='sub'>(укажите время работы и выходные дни. Будет опубликовано на картах) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-4" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.5 Сайт
          <br /><span className='sub'>(доменное имя. Будет указан на картах) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-5" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.6 Ссылка для записи онлайн<br /><span className='sub'>(если есть) </span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-6" height={"100px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.7 Логотип<br /><span className='sub'>(Если «нет», нужно отрисовать. (оплата производится отдельно))</span></p>
      </Col>
      <Col lg="8" className="mb-3">

        <ul className={style.ul}>
          <li><label htmlFor="brief_map-2-8">
            <input className='checkbox' type="checkbox" id="brief_map-2-8" name="brief_map-2-8" data-name="brief_map-2-8" value="Есть" />
            <p>Есть</p>
          </label></li>
          <li><label htmlFor="brief_map-2-9">
            <input className='checkbox' type="checkbox" id="brief_map-2-9" name="brief_map-2-9" data-name="brief_map-2-9" value="Нет нужно разработать" />
            <p>Нет нужно разработать</p>
          </label></li>
        </ul>

      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.10 Дата открытия организации <br /><span className='sub'>(будет указана на картах)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-10" height={"50px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.11 Есть ли подарочные сертификаты?</p>
      </Col>
      <Col lg="8" className="mb-3">
        <ul className={style.ul}>
          <li><label htmlFor="brief_map-2-11">
            <input className='checkbox' type="checkbox" id="brief_map-2-11" name="brief_map-2-11" data-name="brief_map-2-11" value="Есть" />
            <p>Есть</p>
          </label></li>
        </ul>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.12 Есть ли парковка?</p>
      </Col>
      <Col lg="8" className="mb-3">
        <ul className={style.ul}>
          <li><label htmlFor="brief_map-2-12">
            <input className='checkbox' type="checkbox" id="brief_map-2-12" name="brief_map-2-12" data-name="brief_map-2-12" value="Есть" />
            <p>Есть</p>
          </label></li>
        </ul>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>2.13 Группы в соцсетях <br /><span className='sub'>(указать все ссылки)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-2-13" height={"300px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.0 Способы оплаты</p>
      </Col>

      <Col lg="8" className="mb-3">
        <ul className={style.ul}>
          <li><label htmlFor="brief_map-3-0">
            <input className='checkbox' type="checkbox" id="brief_map-3-0" name="brief_map-3-0" data-name="brief_map-3-0" value="Наличными" />
            <p>Наличными</p>
          </label></li>
          <li><label htmlFor="brief_map-3-1">
            <input className='checkbox' type="checkbox" id="brief_map-3-1" name="brief_map-3-1" data-name="brief_map-3-1" value="Электронными деньгами" />
            <p>Электронными деньгами</p>
          </label></li>
          <li><label htmlFor="brief_map-3-2">
            <input className='checkbox' type="checkbox" id="brief_map-3-2" name="brief_map-3-2" data-name="brief_map-3-2" value="Sms-платеж" />
            <p>Sms-платеж</p>
          </label></li>
          <li><label htmlFor="brief_map-3-3">
            <input className='checkbox' type="checkbox" id="brief_map-3-3" name="brief_map-3-3" data-name="brief_map-3-3" value="Скидки на услуги льготным категориям" />
            <p>Скидки на услуги льготным категориям</p>
          </label></li>
          <li><label htmlFor="brief_map-3-4">
            <input className='checkbox' type="checkbox" id="brief_map-3-4" name="brief_map-3-4" data-name="brief_map-3-4" value="Предоплата" />
            <p>Предоплата</p>
          </label></li>
          <li><label htmlFor="brief_map-3-5">
            <input className='checkbox' type="checkbox" id="brief_map-3-5" name="brief_map-3-5" data-name="brief_map-3-5" value="Рассрочка" />
            <p>Рассрочка</p>
          </label></li>
          <li><label htmlFor="brief_map-3-6">
            <input className='checkbox' type="checkbox" id="brief_map-3-6" name="brief_map-3-6" data-name="brief_map-3-6" value="Онлайн" />
            <p>Онлайн</p>
          </label></li>
          <li><label htmlFor="brief_map-3-7">
            <input className='checkbox' type="checkbox" id="brief_map-3-7" name="brief_map-3-7" data-name="brief_map-3-7" value="Оплата картой" />
            <p>Оплата картой</p>
          </label></li>
          <li><label htmlFor="brief_map-3-8">
            <input className='checkbox' type="checkbox" id="brief_map-3-8" name="brief_map-3-8" data-name="brief_map-3-8" value="Банковским переводом" />
            <p>Банковским переводом</p>
          </label></li>
          <li><label htmlFor="brief_map-3-9">
            <input className='checkbox' type="checkbox" id="brief_map-3-9" name="brief_map-3-9" data-name="brief_map-3-9" value="Наложенным платежом" />
            <p>Наложенным платежом</p>
          </label></li>
          <li><label htmlFor="brief_map-3-10">
            <input className='checkbox' type="checkbox" id="brief_map-3-10" name="brief_map-3-10" data-name="brief_map-3-10" value="Дисконстная система скидок" />
            <p>Дисконтная система скидок</p>
          </label></li>
          <li><label htmlFor="brief_map-3-11">
            <input className='checkbox' type="checkbox" id="brief_map-3-11" name="brief_map-3-11" data-name="brief_map-3-11" value="Постоплата" />
            <p>Постоплата</p>
          </label></li>
          <li><label htmlFor="brief_map-3-12">
            <input className='checkbox' type="checkbox" id="brief_map-3-12" name="brief_map-3-12" data-name="brief_map-3-12" value="Безналичная" />
            <p>Безналичная</p>
          </label></li>
        </ul>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.1 Перечислить услуги/товары и цены на них, которые будут размещены на картах (прайс)<br /><span className='sub'>(в стоимость входит заполнение 3-х карточек с услугами. Более 3-х оплачивается отдельно)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-3-13" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.2 Укажите какие есть Бонусы</p>
      </Col>
      <Col lg="8" className="mb-3">
        <ul className={style.ul}>
          <li><label htmlFor="brief_map-3-14">
            <input className='checkbox' type="checkbox" id="brief_map-3-14" name="brief_map-3-14" data-name="brief_map-3-14" value="Скидки" />
            <p>Скидки</p>
          </label></li>
          <li><label htmlFor="brief_map-3-15">
            <input className='checkbox' type="checkbox" id="brief_map-3-15" name="brief_map-3-15" data-name="brief_map-3-15" value="Спецпредложения" />
            <p>Спецпредложения</p>
          </label></li>
          <li><label htmlFor="brief_map-3-16">
            <input className='checkbox' type="checkbox" id="brief_map-3-16" name="brief_map-3-16" data-name="brief_map-3-16" value="Подарки" />
            <p>Подарки</p>
          </label></li>
          <li><label htmlFor="brief_map-3-17">
            <input className='checkbox' type="checkbox" id="brief_map-3-17" name="brief_map-3-17" data-name="brief_map-3-17" value="Акции" />
            <p>Акции</p>
          </label></li>
          <li><label htmlFor="brief_map-3-18">
            <input className='checkbox' type="checkbox" id="brief_map-3-18" name="brief_map-3-18" data-name="brief_map-3-18" value="Бонусы" />
            <p>Бонусы</p>
          </label></li>
        </ul>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.3 Доставка<br /><span className='sub'>(нужное удалить лишнее и(или) заполнить поля)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-3-19" height={"700px"} value="1. Доставка
1.1. Стоимость: 
- бесплатно
- бесплатно от определенной суммы
  Если заказ меньше ___р., то доставка стоит ___ р.
- диапазон цен
  Если заказ меньше ___р., то доставка стоит ___ р.
  Если больше, доставка стоит ___ р.
- фиксированная цена
  Доставка всех заказов стоит____р.
1.2. Описать условия доставки: ¬¬¬¬  
          
1.3. Срок: от ____   до___
1.4. Территория доставки: 

2. Самовывоз
2.2. Стоимость: 
- бесплатно
- бесплатно от определенной суммы
  Если заказ меньше ___р., то доставка стоит ___ р.
- диапазон цен
  Если заказ меньше ___р., то доставка стоит ___ р.
  Если больше, доставка стоит ___ р.
- фиксированная цена
  Доставка всех заказов стоит____р.
2.2. Описать условия доставки: ¬¬¬¬     
       
2.3. Срок: от ____   до___
2.4. Территория доставки: 
"/>
      </Col>
      <Col lg="4" className="mb-3">
        <p className='p p-title'>3.4 Акции, новости<br /><span className='sub'>(Будет опубликовано в разделе публикаций на картах
          Если есть готовые баннера, можно предоставить отдельным файлом)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-3-20" height={"200px"} />
      </Col>
      <Col lg="4" className="mb-3 mt-5">
        <p className='p p-title'>3.5 Фото <br /><span className='sub'>(обязательно! Минимум 5 фото по каждой категории)</span></p>
      </Col>
      <Col lg="8" className="mb-3">
        <Textarea name="brief_map-3-21" height={"300px"} value="Предоставляется отдельным файлом, с подписью каждой фотографии: панорама, здание, крыльцо и т.д.

5 фото входа (панорама, здание с улицей, здание вблизи,  крыльцо (несколько ракурсов), вход в кабинет, вывеска)
5 фото внутреннего интерьера
5 фото рабочего процесса или услуг
5 фото готового продукта
5 фото коллектива
5 фото «рядом с работой»
"/>
      </Col>
    </Row>
  );
};
export default BriefMapForm;