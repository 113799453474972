import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { alertErr } from '../UI/alert'

import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useDispatch } from 'react-redux';
import SelectSearch from '../UI/SelectSearch/SelectSearch';
import styles from '../../components/Contracts/styles.module.sass'

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import { dateUnTransform } from '../../components/functions'

const calendarOptions = {
  autoClose: true,
  view: "days",
  minView: "months",
  dateFormat: "dd.MM.yyyy",
  multipleDatesSeparator: " - ",
  buttons: ['clear'],
  // selectedDates: new Date() 
}
function CreatePostal({ clients, createPostal }) {

  const dispatch = useDispatch()

  const addPostalSend = useCallback((e) => {
    e.preventDefault()

    const targets = e.target.querySelectorAll('input, textarea');
    const formData = new FormData()
    const err = new Set()
    targets.forEach(inp => {
      if (!inp.value) err.add(inp.dataset.name)
      if (inp.dataset.name === 'date') {
        formData.append(inp.dataset.name, dateUnTransform(inp.value))
      } else {
        formData.append(inp.dataset.name, inp.value)
      }
    });
    if (err.size > 0) {
      alertErr.fire({
        text: 'Заполните все обязательные поля!',
      })
      return
    }
    dispatch(createPostal(formData))

    e.target.querySelectorAll('input,textarea').forEach(i => i.value = '');
  }, [dispatch])
  const [clientName, setClientName] = useState('')

  useMemo(() => {
    const clientArr = clients.map(el => {
      return {
        name: 'client',
        value: el.name
      }
    })
    setClientName(clientArr)
  }, [clients])

  const calendar = useRef(null)
  useEffect(() => {
    new AirDatepicker(calendar.current, calendarOptions)
  }, [calendar])
  return (
    <Form onSubmit={(e) => addPostalSend(e)}>
      <Row>
        <Col lg="4" md="6" className="mb-3">
          <label className={styles.label} htmlFor="clients">Клиент</label>
          <SelectSearch id="clients" search={true} options={clientName} />
        </Col>
        <Col lg="2" md="2" className="mb-3">
          <div className="input-style">
            <label>Дата отправки</label>
            <input readOnly autoComplete="off" type="text" data-name="date" ref={calendar} />
          </div>
        </Col>
        <Col lg="2" md="6" className="mb-2">
          <label htmlFor="what">Что там</label>
          <SelectSearch name="what" id="what" options={[
            { name: 'what', value: 'АКТ' },
            { name: 'what', value: 'АКТ сверки' },
            { name: 'what', value: 'АКТ заказной' },
            { name: 'what', value: 'Договор' },
            { name: 'what', value: 'Договор + АКТ' },
          ]} />
        </Col>
        <Col lg="2" md="6" className="mb-2">
          <label htmlFor="manager">Добавить запись</label>
          <Button type="submit">Добавить запись</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(CreatePostal);