import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from '../UI/Loading'
import { createSettings, fetchSettings } from "../../store/settingsSlice"

import { alertErr, alert } from "../UI/alert"
import { sortName } from "../functions"
const style = {
  item: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: "12px",
    marginTop: "24px",
  },
  itemManagers: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  managersNameBox: {
    marginTop: "20px",
    maxWidth: "230px",
    p: {
      marginBottom: "0px"
    }
  }
}

function ManagersLists() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  const { managers, status, error } = useSelector(state => state.settings)
  const [valueInputManager, getValueInputManager] = useState('');
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    valueInputManager ? setIsDisabled(false) : setIsDisabled(true)
  }, [valueInputManager])

  const saveManager = useCallback(() => {
    const checkNameManager = managers.find(n => n.value.toLowerCase() === valueInputManager.toLowerCase())
    if (checkNameManager) {
      alertErr.fire({
        text: 'Такое имя уже есть!',
      })
      getValueInputManager('')
      return
    }
    dispatch(createSettings({ managers: [...managers, { name: "manager", value: valueInputManager }] }))
    getValueInputManager('')
  }, [dispatch, managers, valueInputManager])

  function deleteManagers(params) {
    alert.fire({
      title: `Точно удалить менеджера на ${params}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        const newArr = managers.filter(m => m.value !== params)
        dispatch(createSettings({ managers: newArr }))
      }
    })

  }
  return (
    <Container>
      <Row>
        <Col lg="3" md="6" sm="12">
          <div className="item" style={style.item}>
            <div className="input-style">
              <label>Введите имя менеджера</label>
              <input autoComplete="off" type="text" name="manager" value={valueInputManager} onChange={(e) => { getValueInputManager(e.target.value.replace(/[^а-я]|(\d)/gi, "")) }} />
            </div>
            <Button disabled={isDisabled ? isDisabled : ''} style={style.button} onClick={saveManager}>+</Button>
          </div>
          <div style={style.managersNameBox}>
            {status === 'loading' && <Loading />}
            {error && <h2>{error}</h2>}
            {sortName(managers)?.map((m, i) =>
              <div key={m.value + i} style={style.itemManagers}>
                <p style={style.managersNameBox.p}>{m.value}</p>
                <span onClick={() => deleteManagers(m.value)} className="close-button">&times;</span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ManagersLists;