import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";

import { rubles } from "rubles";
import logo from '../../images/logo.png'
import { toFixed } from "../functions";

import PDFFooterSingFull from "./PDFFooterSingFull"

function PDFComponentContractAddProduct({ dataPayment }) {
  const found = dataPayment.firmaName.includes("ХитГрупп");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <View>
            <Image
              style={styles.logo}
              src={logo}
            />
          </View>
          <Text style={styles.headTitle}>Договор № С-{dataPayment.contractNum}</Text>
          <Text style={[styles.headTitle, styles.mb]}>Услуги по наполнение сайта товарными позициями</Text>
          <View style={[styles.row, styles.mb]}>
            <Text style={styles.text}>г.Гомель</Text>
            <Text style={styles.text}>{dataPayment.date} г.</Text>
          </View>
          <Text style={styles.text}>{dataPayment.firmaName.replace(/\sRU$/, "")}, именуемый в дальнейшем «Исполнитель», в лице {dataPayment.firmaInFace}, {dataPayment.firmaFooting} с одной стороны, и {dataPayment.clientName}, именуемый в дальнейшем «Заказчик», в лице {dataPayment.clientInFace}, действующего на основании {dataPayment.clientFooting}, с другой стороны, именуемые в дальнейшем Стороны, заключили настоящий Договор (далее Договор) о нижеследующем:</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>1. Предмет договора</Text>

          <Text style={styles.text}>1.1 Исполнитель обязуется выполнить работы по наполнению сайта Заказчика товарными позициями, включая описание, фотографии и цены.</Text>
          <Text style={styles.text}>1.2 Заказчик обязуется предоставить необходимую информацию и материалы для наполнения сайта.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>2. Обязанности Исполнителя</Text>

          <Text style={styles.text}>2.1 Исполнитель обязан соблюдать сроки и качество работы в соответствии с техническим заданием. Однако, сроки выполнения работ могут быть скорректированы в случае:</Text>
          <Text style={styles.text}>2.1.1 Задержек со стороны Заказчика в предоставлении необходимой и структурированной информации.</Text>
          <Text style={styles.text}>2.1.2 Если предоставленная Заказчиком информация требует значительного дополнительного времени на разбор и структурирование, что не было предусмотрено в первоначальном обсуждении.</Text>
          <Text style={styles.text}>2.1.3 В таких случаях Исполнитель имеет право запросить пересмотр сроков выполнения работ и/или дополнительную оплату за сверхурочные работы, связанные с необходимостью дополнительного анализа и структурирования предоставленных материалов.</Text>
          <Text style={styles.text}>2.2 Исполнитель имеет право на отказ от выполнения работ, не предусмотренных договором без дополнительной оплаты.</Text>
          <Text style={styles.text}>2.3 Если с момента подписания договора прошло более 30 (тридцати) календарных дней и Заказчик не предоставил все необходимые информационные материалы в полном объеме, и(или) затягивает сроки предоставления необходимой информации. Исполнитель имеет право расторгнуть договор в одностороннем порядке. В таком случае предоплата Заказчику не возвращается;</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>3. Обязанности Заказчика</Text>
          <Text style={styles.text}>3.1 Заказчик обязуется предоставить все необходимые материалы для выполнения работ в цифровом формате, в структурированном виде, не требующие дополнительной расшифровки. Это может включать ссылки с сайтов на фотографии и описания товаров. В частности:</Text>

          <Text style={styles.text}>3.1.1 Фотографии товаров должны быть подписаны или организованы таким образом, чтобы легко можно было соотнести каждую фотографию с соответствующим описанием товара. Фотографии должны быть разложены по папкам с четким указанием наименования товара.</Text>
          <Text style={styles.text}>3.1.2 Разрешение фотографий должно быть не менее 1080 на 1080 пикселей. Заказчик несет ответственность за качество предоставленных изображений, включая их пригодность для отображения на сайте. ( Фотографии с низким разрешением могут быть не четкие )</Text>
          <Text style={styles.text}>3.1.3 Описания товаров и информация о ценах должны быть предоставлены в формате документов Microsoft Word или таблиц Microsoft Excel.</Text>

          <Text style={styles.text}>3.2 Заказчик несет полную ответственность за законность использования предоставленных материалов, включая соблюдение авторских прав и прав на интеллектуальную собственность. В случае предъявления третьими лицами претензий, связанных с нарушением их прав в результате использования предоставленных Заказчиком материалов, ответственность несет Заказчик.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>4. Сроки выполнения работ</Text>

          <Text style={styles.text}>4.1 Сроки выполнения работ определяются в соответствии с количеством товарных позиций и могут быть изменены по согласованию сторон.</Text>
          <Text style={styles.text}>4.2 Сроки выполнения работ и количество товарных позиций, подлежащих размещению на сайте {dataPayment.domain}, устанавливаются следующим образом: </Text>
          <Text style={styles.text}>4.2.1 Исполнитель обязуется разместить на сайте Заказчика  {dataPayment.numproduct} товарных позиций.</Text>
          <Text style={styles.text}>4.2.2 Работа по размещению каждой товарной позиции включает добавление описаний, фотографий, цен.</Text>
          <Text style={styles.text}>4.2.3 Все работы должны быть выполнены в течение {dataPayment.srokday} рабочих дней с момента предоставления Заказчиком полного пакета информации и материалов для каждой товарной позиции.</Text>
          <Text style={styles.text}>4.3 Сроки начинают исчисляться с даты получения Исполнителем последнего необходимого материала/информации от Заказчика. В случае поэтапного предоставления материалов, сроки могут быть скорректированы соответственно.</Text>
          <Text style={styles.text}>4.4 В случае изменения количества товарных позиций или условий работы, стороны договорились согласовывать новые сроки и объемы работ в письменной форме. Согласно пункту 5.1</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>5. Способы коммуникации и передачи информации.</Text>
          <Text style={styles.text}>5.1 Для передачи информации и материалов, необходимых для выполнения работ, стороны договорились использовать следующие каналы связи: электронная почта, Viber, Telegram, WhatsApp.</Text>
          <Text style={styles.text}>5.2 Вся информация, переданная через указанные каналы, считается официально переданной и может быть использована в качестве доказательств при необходимости решения спорных вопросов.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>6. Размер и порядок оплаты услуг.</Text>

          {dataPayment.price.length === 1 &&
            <>
              <Text style={styles.text}>6.1 Стоимость услуг составляет
                {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                {dataPayment.price.reduce((akk, p) => +akk + +p)} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
              <Text style={styles.text}>6.2 Оплата производится Заказчиком следующим образом:</Text>
              {

                dataPayment.price.map((p, i) =>
                  <Text key={i} style={styles.text}>{i + 1} составляет {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)})  до {dataPayment.payment[i]} года</Text>
                )
              }
            </>
          }
          {dataPayment.price.length >= 2 && dataPayment.payment.length >= 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>6.1. Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>6.2 Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) до {dataPayment.payment[i]} года</Text>
                  )}
                </>
              }
            </>
          }
          {dataPayment.price.length === 2 && dataPayment.payment.length !== 2 &&
            <>
              {
                <>
                  <Text style={styles.text}>6.1 Стоимость услуг составляет
                    {found && 'по настоящему договору выражена в белорусских рублях без НДС, на основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).'}
                    {toFixed(dataPayment.price.reduce((akk, p) => +akk + +p))} руб ({rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).charAt(0).toUpperCase() + rubles(dataPayment.price.reduce((akk, p) => +akk + +p)).slice(1)})</Text>
                  <Text style={styles.text}>6.2 Оплата настоящего Договора осуществляется Заказчиком следующим образом:</Text>
                  {dataPayment.price.map((p, i) =>
                    <Text key={i} style={styles.text}>{i + 1} {i === 0 && 'Предоплата'} {i === 1 && 'Оплата в размере '} {toFixed(p)} руб ({rubles(p).charAt(0).toUpperCase() + rubles(p).slice(1)}) {i === 1 ? 'по завершению работ, что подтверждается актом выполненных работ и оплата производится в течение 5-ти банковских дней с момента подписания акта.' : 'до ' + dataPayment.payment[i] + ' года'}  </Text>
                  )}
                </>
              }
            </>
          }
          <Text style={styles.text}>6.3 Проценты на предоплату не начисляются. Предоплата не возвращается в случае, если работы, заявленные в техническом задании, выполнены частично и немогут быть выполнены в полном объеме по вине Заказчика. Если Заказчик требует дополнительных работ, которые должны оплачиваться отдельно.</Text>
          <Text style={styles.text}>6.4 Дополнительные работы и услуги, не включенные в стоимость, оплачиваются Заказчиком отдельно, на основании выставленных счет-фактур, являющихся неотъемлемой частью Договора.</Text>
          <Text style={styles.text}>6.5  Исполнитель в срок не позднее 5 (пяти) рабочих дней с момента окончания оказания услуг, указанных в счет – фактуре, подтверждает Актом об оказании услуг, который направляется Исполнителем Заказчику посредством электронных каналов связи в виде сканированной копии с подписью и печатью Исполнителя. В случае необходимости Исполнитель обязан выслать Заказчику оригинал Акта об оказанных услугах в течение 3 (трех) рабочих дней после получения соответствующего требования от Заказчика.</Text>
          <Text style={styles.text}>6.6  При направлении Исполнителем Акта об оказанных услугах Заказчик обязуется рассмотреть Акт, подписать его и направить Исполнителю в течение 3 (трех) рабочих дней либо в тот же срок предоставить мотивированные возражения от подписания Акта.</Text>
          <Text style={styles.text}>6.7  Заказчик безоговорочно соглашается с тем, что если он в течение 3 (трех) рабочих дней с момента получения Акта об оказании услуг не заявит каких-либо письменных претензий по оказанным Исполнителем услугам, то это будет рассматриваться как однозначное согласие Заказчика с тем, что услуги оказаны Исполнителем своевременно, в полном объеме и надлежащим образом.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>7. Разрешение споров и ответственность сторон</Text>

          <Text style={styles.text}>7.1 Все спорные вопросы разрешаются путем переговоров, а при невозможности разрешения ― в Экономическом суде г. Гомеля. Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй Стороне. Претензия предъявлена надлежащим образом, если направлена другой Стороне любым способом, позволяющим установить личность отправителя и факт вручения корреспонденции получателю. Факт возврата корреспонденции с указанием на отсутствие получателя не препятствует обращению в суд. Приложение к претензии копий документов, обосновывающих и подтверждающих предъявленные требования, если данные документы имеются в наличии у второй Стороны, не является обязательным.</Text>
          <Text style={styles.text}>7.2 Сторона, получившая претензию, в течение 10 (десяти) рабочих дней со дня ее получения письменно уведомляет вторую Сторону о результатах рассмотрения. Неполучение ответа либо мотивированных возражений на претензию в установленный срок рассматривается как согласие с претензией и изложенными в ней обстоятельствами.</Text>
          <Text style={styles.text}>7.3  Доказательствами в суде могут являться скриншоты и распечатки переписки. Согласно оговоренным каналам связи в пункте 5.1</Text>
          <Text style={styles.text}>7.4 Если договор расторгается по желанию Заказчика на любом этапе работ, Заказчик оплачивает фактически проделанную работу. В адрес Заказчика направляется акт выполненных работ посредством электронной связи. Заказчик обязан подписать и отправить в адрес Исполнителя один экземпляр акта об оказании услуг в течении 3 (трех) рабочих дней с момента получения акта. В случае, если в указанный выше срок Заказчик не отправит Исполнителю подписанный акт об оказании услуг и не отправит письменный мотивированный отказ от приемки оказанных услуг, то стороны считают, что услуги, указанные в соответствующем счет - фактуре, приняты Заказчиком без претензий и подлежат оплате.</Text>
          <Text style={styles.text}>7.5 В случае несвоевременной оплаты Заказчиком оказанных Исполнителем услуг (выполненных работ) настоящего Договора Исполнитель вправе взыскать с Заказчика пеню в размере 0,1% (ноль целых пять десятых процента) от стоимости услуги (выполненных работ) за каждый день просрочки.</Text>
          <Text style={styles.text}>7.6 Исполнитель не несет ответственности за несоответствие фактически предоставленных Услуг, субъективным ожиданиям и представлениям Заказчика о таких Услугах.</Text>
          <Text style={styles.text}>7.7 Во всем остальном, что не оговорено настоящим Договором, Стороны несут ответственность в соответствии с законодательством Республики Беларусь.</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>8. Форс-мажор</Text>

          <Text style={styles.text}>8.1 Ни одна из Сторон не несет ответственности за полное или частичное неисполнение обязательств по настоящему Договору в случае пожара, наводнения, землетрясения и других стихийных бедствий, возникших после заключения настоящего Договора, а также обстоятельств неопределимой силы в результате событий чрезвычайного характера, которые Сторона не могла ни предвидеть, ни предотвратить разумными методами (форс-мажорные обстоятельства).</Text>

          <Text style={[styles.text, styles.textBold, styles.mt]}>9. Срок действия договора</Text>
          <Text style={styles.text}>9.1 Договор вступает в силу с момента его подписания и действует до полного выполнения сторонами своих обязательств.</Text>
          <Text style={styles.text}>9.2. Досрочное расторжение договора возможно по согласию сторон.</Text>
          <Text style={styles.text}>9.3. Заказчик безоговорочно соглашается с тем, что в случае одностороннего отказа от настоящего Договора по собственной инициативе либо в случае одностороннего отказа по инициативе Исполнителя, вызванного нарушением Заказчиком условий настоящего Договора или действующего законодательства, возврат денежных средств, уплаченных Заказчиком в счет оплаты услуг, не производится, даже в случае, если срока оказания оплаченных услуг не окончен.</Text>
          <Text style={styles.text}>9.4 Все изменения и дополнения настоящего Договора оформляются в виде дополнительных соглашений, которые подписываются сторонами и являются неотъемлемой часть Договора.</Text>
          <Text style={styles.text}>9.5 Договор, изменения и дополнения к нему, другие документы, заверенные подписью и печатью, переданные по оговоренным каналам связи в пункте 5.1, имеют юридическую силу.</Text>
          <Text style={styles.text}>9.6  Настоящий Договор составлен в двух экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру на каждую из сторон.</Text>
          <Text style={styles.text}>9.7 В случае если подписанный договор не возвращен Исполнителю, то фактом подписания договора считается оплата Заказчиком услуг. Оплата услуг свидетельствует о том, что Заказчик ознакомлен с условиями договора и полностью их принимает.</Text>

          <PDFFooterSingFull data={dataPayment} />

        </View>
      </Page>
    </Document >
  )
}
export default PDFComponentContractAddProduct