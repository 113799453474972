import React, { useState, useEffect, useMemo } from "react";

import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import CreateClient from '../components/Clients/CreateClient';
import ClientsList from '../components/Clients/ClientsList';
import { useSelector } from 'react-redux';

import Loading from '../components/UI/Loading'
import LoadServerData from '../components/LoadServerData'

const style = {
  col: {
    padding: '5px 0 5px 20px',
  }
}

function SitesList() {
  LoadServerData()
  const { clients, status, error } = useSelector(state => state.clients)
  const [newArrClients, setNewArrClients] = useState([])
  useEffect(() => {
    let clientsNewArr = [...clients].filter(el => {
      try {
        let parsedJSON = JSON.parse(el.domains);
        return parsedJSON.length > 0;
      } catch (e) {
        return false;
      }
    }).map(el => {
      let domains = JSON.parse(el.domains);
      return domains.map(domain => ({ ...domain, id: el.id }));
    }).flat();
    let joinArr = clientsNewArr.flat()
    setNewArrClients(joinArr)
  }, [clients]);
  const [searchVal, setSearchVal] = useState('')

  const newArrSearch = useMemo(() => {
    return newArrClients.filter(el => {
      if (el.domain.toLowerCase().includes(searchVal) || el.domain.includes(searchVal)) return el
      if (el.topic && (el.topic.toLowerCase().includes(searchVal) || el.topic.includes(searchVal))) return el
      if (el.inform && (el.inform.toLowerCase().includes(searchVal) || el.inform.includes(searchVal))) return el
    });
  }, [newArrClients, searchVal])

  return (
    <section className="pages">
      <Container>
        <h1>Список сайтов</h1>
        <Row>
          <Col lg="12" className="mb-3">
            <div className="input-style" >
              <label>Поиск</label>
              <input type="text" placeholder="Поиск..." onChange={(e) => setSearchVal(e.target.value)} />
            </div>
          </Col>
        </Row>
        <div className="listHead">
          <Row>
            <Row>
              <Col style={style.col} lg="3">Сайт</Col>
              <Col style={style.col} lg="4">Тематика</Col>
              <Col style={style.col} lg="5">Пометка</Col>
            </Row>
          </Row>
        </div>
      </Container>
      <Container className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}

        {newArrSearch.map(el =>
          <div className="listBody" key={el.id + '-' + el.domain}>
            <Row>
              <Col style={style.col} lg="3"><a href={"https://" + el.domain} target="_blank">{el.domain} </a></Col>
              <Col style={style.col} lg="4">{el.topic}</Col>
              <Col style={style.col} lg="5">{el.inform}</Col>
            </Row>
          </div>
        )}
      </Container>
    </section >
  );
};

export default SitesList;