import React, { useState, useMemo, useEffect } from "react";

import { Row, Col, Container, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import SeoList from '../components/Seo/SeoList';

import Loading from '../components/UI/Loading'

import { useSelector, useDispatch } from 'react-redux';

import { fetchAllEditors } from '../store/clientsSEOSlice'
import { fetchClient } from '../store/clientsSlice'

import 'air-datepicker/air-datepicker.css';

const style = {
  button: {
    width: '100%',
    maxWidth: '80px',
  },
  buttonMin: {
    width: '100%',
    minWidth: '55px',
  },
  form: {
    background: 'rgba(7, 187, 255, .1)',
    padding: "10px 10px 5px 10px",
    marginBottom: "5px",
  },
  h2: {
    textAlign: "center",
    marginTop: "74px",
    color: "#999",
  },
  clearInputSearch: {
    position: 'absolute',
    right: '5px',
    top: '21px',
    bottom: '0',
    margin: 'auto',
    height: '32px',
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

  }
}
function Seo() {
  const dispatch = useDispatch()
  const { clients } = useSelector(state => state.clients)
  const { seoClients, status, error } = useSelector(state => state.seo)

  useEffect(() => {
    dispatch(fetchClient())
    dispatch(fetchAllEditors())
  }, [dispatch])


  const [searchVal, setSearchVal] = useState('')

  const seoClientsArr = useMemo(() => {
    if (!seoClients) return [];
    const client = clients.filter(item => seoClients.some(seoClient => seoClient.id === item.id && seoClient.active === 'true'));
    const name = client.filter(c => c.name.toLowerCase().includes(searchVal) || c.name.includes(searchVal));
    const email = client.filter(c => c.email.toLowerCase().includes(searchVal) || c.email.includes(searchVal));
    const phone = client.filter(c => c.phone.toLowerCase().includes(searchVal) || c.phone.includes(searchVal));
    const domains = client.filter(c => JSON.parse(c.domains)[0].domain.toLowerCase().includes(searchVal) || JSON.parse(c.domains)[0].domain.includes(searchVal));

    if (name.length > 0) return name;
    if (domains.length > 0) return domains;
    if (email.length > 0) return email;
    if (phone.length > 0) return phone;
    return client;
  }, [clients, seoClients, searchVal]);





  function clearInputSearch() {
    setSearchVal('')
  }

  return (
    <section className="pages">
      <Container>
        <h1>SEO</h1>

        <Form style={style.form}>
          <Row>
            <Col lg="12" className="mb-3">
              <div className="input-style" >
                <label>Поиск</label>
                <input type="text" placeholder="Поиск..." name="field"
                  onChange={(e) => setSearchVal(e.target.value)} value={searchVal}
                />
                <div style={style.clearInputSearch} onClick={clearInputSearch}>&#10006;</div>
              </div>
            </Col>
          </Row>
        </Form>

      </Container>
      <Container className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {
          seoClientsArr.length ?
            <SeoList seoClientsArr={seoClientsArr} />
            : (<h2 style={style.h2}>По данному запросу ничего не найдено!</h2>)
        }
      </Container>

    </section >
  );
};

export default Seo;