import React, { useEffect, useState } from "react";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { editContractsDetails, fetchContractsDetails, removeContractsDetails, clearContractsDetails } from '../../../store/contractsDetailsSlice'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Loading from '../../UI/Loading'

import PDFComponentContractAddProduct from '../../../components/Contracts/PDFComponentContractAddProduct' // addProduct
import PDFComponentContractComplexMaint from '../../../components/Contracts/PDFComponentContractComplexMaint' // complexMaint
import PDFComponentContractContext from '../../../components/Contracts/PDFComponentContractContext' // contextG, contextY, contextAll
import PDFComponentContractContextService from '../../../components/Contracts/PDFComponentContractContextService' // contextServices
import PDFComponentContractDevSiteDesign from '../../../components/Contracts/PDFComponentContractDevSiteDesign' // devSiteDesign
import PDFComponentContractHostingServices from '../../../components/Contracts/PDFComponentContractHostingServices' // hostingServices
import PDFComponentContractInstagramAccount from '../../../components/Contracts/PDFComponentContractInstagramAccount' // instagramAccount
import PDFComponentContractInstagramAccountDesign from '../../../components/Contracts/PDFComponentContractInstagramAccountDesign' // instagramAccountDesign
import PDFComponentContractMap from '../../../components/Contracts/PDFComponentContractMap' // map
import PDFComponentContractSEO from '../../../components/Contracts/PDFComponentContractSEO' // seo
import PDFComponentContractSite from '../../../components/Contracts/PDFComponentContractSite' // site
import PDFComponentContractSiteModification from '../../../components/Contracts/PDFComponentContractSiteModification' // siteModification
import PDFComponentContractTargetedAdvertising from '../../../components/Contracts/PDFComponentContractTargetedAdvertising' // target

import styles from '../../../components/CreateMessagesTelegram/styles.module.sass'
const style = {
  input: {
    border: 'none',
    width: '100%',
  },
  invoiceBut: {
    cursor: "pointer"
  }
}

function truncateString(str, num) {
  if (str.length > num) return str.slice(0, num) + "...";
  else return str;
}
function Contracts() {
  const dispatch = useDispatch()
  const { status, error } = useSelector(state => state.contractsdetails)
  const contractsDetails = useSelector(state => state.contractsdetails.contractsDetails)
  const [dataPayment, setDataPayment] = useState('')
  const params = useParams()

  useEffect(() => { dispatch(clearContractsDetails()) }, [dispatch])
  useEffect(() => { dispatch(fetchContractsDetails(params.id)) }, [dispatch, params.id])

  function deletePayment(id_contract, contractNum, date) {
    Swal.fire({
      title: `Точно удалить договор № ${contractNum} от ${date}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeContractsDetails(id_contract))
      }
    })

  }

  function activePay(e, contractNum, price, el) {
    const newEl = { ...el }
    function goContact(params) {
      dispatch(editContractsDetails(params));
    }
    if (!e.checked) {
      Swal.fire({
        title: `Точно убрать галочку "ПРЕДОПЛАТА" с договора №${contractNum} на сумму ${price.reduce((accumulator, currentValue) =>
          +accumulator + +currentValue, 0)}  (оплата ${price[0]})?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          e.checked = false
          newEl.pay = false
          goContact(newEl);
        } else {
          e.checked = true
          newEl.pay = true
        }

      })

    }
    if (e.checked) {
      Swal.fire({
        title: `Точно пометить как оплачен по договору №${contractNum} на сумму ${price.reduce((accumulator, currentValue) => +accumulator + +currentValue, 0)} (оплата ${price[0]})?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then((result) => {
        if (result.isConfirmed) {
          e.checked = true
          newEl.pay = true
          goContact(newEl);
        } else {
          e.checked = false
          newEl.pay = false
        }
      })
    }

  }

  return (
    <>
      <div className="invoicesHead">
        <Row>
          <Col lg="1" className="mb-2">Номер</Col>
          <Col lg="3" className="mb-2">Предмет</Col>
          <Col lg="2" className="mb-2">Фирма</Col>
          <Col lg="1" className="mb-2">Дата</Col>
          <Col lg="1" className="mb-2">Сумма</Col>
          <Col lg="2" className="mb-2">Выписал</Col>
          <Col lg="1" className="mb-2">Продал</Col>
          <Col lg="1" className="mb-2">Оплата</Col>
          <Col lg="1" className="mb-2"></Col>
        </Row>
      </div>
      <div className="invoicesBody">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {contractsDetails?.map(el =>

          <div key={el.contractNum} className="invoice">
            <Row>
              {el.contractNum === dataPayment.contractNum ?
                <Col lg="1"><PDFDownloadLink document=
                  {el.servicesName === 'contextG' || el.servicesName === 'contextY' || el.servicesName === 'contextAll' ? (
                    <PDFComponentContractContext dataPayment={dataPayment} />
                  ) : el.servicesName === 'site' ? (
                    <PDFComponentContractSite dataPayment={dataPayment} />
                  ) : el.servicesName === 'map' ? (
                    <PDFComponentContractMap dataPayment={dataPayment} />
                  ) : el.servicesName === 'contextServices' ? (
                    <PDFComponentContractContextService dataPayment={dataPayment} />
                  ) : el.servicesName === 'siteModification' ? (
                    <PDFComponentContractSiteModification dataPayment={dataPayment} />
                  ) : el.servicesName === 'hostingServices' ? (
                    <PDFComponentContractHostingServices dataPayment={dataPayment} />
                  ) : el.servicesName === 'instagramAccount' ? (
                    <PDFComponentContractInstagramAccount dataPayment={dataPayment} />
                  ) : el.servicesName === 'seo' ? (
                    <PDFComponentContractSEO dataPayment={dataPayment} />
                  ) : el.servicesName === 'addProduct' ? (
                    <PDFComponentContractAddProduct dataPayment={dataPayment} />
                  ) : el.servicesName === 'complexMaint' ? (
                    <PDFComponentContractComplexMaint dataPayment={dataPayment} />
                  ) : el.servicesName === 'instagramAccountDesign' ? (
                    <PDFComponentContractInstagramAccountDesign dataPayment={dataPayment} />
                  ) : el.servicesName === 'devSiteDesign' ? (
                    <PDFComponentContractDevSiteDesign dataPayment={dataPayment} />
                  ) : el.servicesName === 'target' ? (
                    <PDFComponentContractTargetedAdvertising dataPayment={dataPayment} />
                  ) : ''} fileName={"Договор " + dataPayment.clientName + ' ' + dataPayment.date + '.pdf'}>
                  <span onClick={() => setTimeout(() => setDataPayment(''), 1000)}>№ {el.contractNum} ✅</span>
                </PDFDownloadLink> </Col>
                :
                <Col style={style.invoiceBut} lg="1" onClick={() => setDataPayment(el)}>№{el.contractNum} 💾</Col>
              }
              <Col lg="3"><input style={style.input} type="text" value={el.services} readOnly /></Col>
              <Col lg="2">{truncateString(el.firmaName || el.firma, 15)}</Col>
              <Col lg="1">{el.date}</Col>
              <Col lg="1">{el.price.reduce((akk, p) => +akk + +p)} р</Col>
              <Col lg="2">{el.authName}</Col>
              <Col lg="1">{el.manager}</Col>
              <Col lg="1"><input title="Была ли предоплата" onChange={(e) => activePay(e.target, el.contractNum, el.price, el)} className={styles.input} type="checkbox" data-name="pay" defaultChecked={el.pay} value={el.pay} /></Col>
              <Col lg="1"><span onClick={() => deletePayment(el.id_contract, el.contractNum, el.date)} className="close-button">&times;</span></Col>
            </Row>
          </div>
        ).reverse()}
      </div>
    </>
  );
};
export default Contracts;