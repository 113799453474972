import React, { useState } from "react";

import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import ManagersLists from '../components/Settings/ManagersList'
import SpheresList from '../components/Settings/SpheresList'
import OurServicesList from '../components/Settings/OurServicesList'
const style = {
  tabBut: {
    background: "none",
    border: "none",
  }
}

function Settings() {

  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { id: 1, name: "Менеджеры", page: <ManagersLists /> },
    { id: 2, name: "Сферы деятельности", page: <SpheresList /> },
    { id: 3, name: "Список наших услуг", page: <OurServicesList /> },

  ]
  function clickTabBut(i) {
    setActiveTab(i)
  }

  return (
    <section className="pages">
      <Container>
        <h1>Настройки</h1>
      </Container>
      <Container className="edit-box">
        <div className="tabs-container">
          <div className="tab-buts">
            {tabs.map((t, i) =>
              <button style={style.tabBut} key={t.id} className={activeTab === i ? 'tab-but-active tab-but' : 'tab-but'} onClick={() => clickTabBut(i)}>{t.name}</button>
            )}
          </div>
          <div className="tab-pages">
            {tabs.map((t, i) =>
              activeTab === i ? <div key={t.id} className={activeTab === i ? 'tab-page-active tab-page' : 'tab-page'}>{t.page}</div> : ''
            )}
          </div>
        </div>
      </Container>
    </section >
  );
};

export default Settings;