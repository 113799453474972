import React, { useCallback } from "react";
import { Link } from 'react-router-dom'

import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { removeClient, editClient } from '../../store/clientsSlice'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
  span: {
    color: '#8d8d8d',
    fontSize: '10px',
    transform: 'translateY(-40%) translateX(3px)',
  },
  span2: {
    minWidth: 'max-content',
    fontSize: '12px',
    color: '#000',
  },
  div: {
    overflow: 'auto',
  },
}

function ClientLists({ data, rating }) {

  const dispatch = useDispatch()

  const deleteClient = useCallback((id, name) => {
    Swal.fire({
      title: `Точно удалить ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeClient(id))
      }
    })
  }, [dispatch])

  const getRating = useCallback((client) => {
    const r = rating.filter(el => el.client === 'client-' + client);


    // Получаем текущую дату
    const currentDate = new Date();
    // Установка даты в начало текущего месяца
    //currentDate.setDate(1); // Устанавливаем день в 1 (первый день месяца)
    // Установка даты на начало следующего месяца
    currentDate.setMonth(currentDate.getMonth() + 1, 1);
    currentDate.setHours(0, 0, 0, 0);

    // Вычитаем 1 миллисекунду, чтобы получить конец текущего месяца
    currentDate.setMilliseconds(currentDate.getMilliseconds() - 1);

    // Вычисляем дату один месяца назад
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 1);

    // Вычисляем дату два месяца назад
    const twoMonthsAgo = new Date(currentDate);
    twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

    // Устанавливаем максимальную сумму
    const maxTotal = 2900 * 2;

    // Инициализируем сумму за последние два месяца
    let totalLastOneMonths = 0;
    let totalLastTwoMonths = 0;

    // Перебираем элементы массива и проверяем условия
    r[0]?.data.forEach(item => {
      const itemDate = new Date(item.unix_time * 1000);

      if (itemDate >= oneMonthsAgo && itemDate <= currentDate) {
        totalLastOneMonths += parseInt(item.value);
      }
      if (itemDate >= twoMonthsAgo && itemDate <= currentDate) {
        totalLastTwoMonths += parseInt(item.value);
      }

    });

    // Вычисляем процент от максимума
    const sumLastMoth = totalLastOneMonths;
    const percentage = (totalLastTwoMonths / maxTotal) * 100;

    return [percentage, sumLastMoth];

  }, [rating])

  const getDomain = (arr) => {
    if (!arr) return null
    const domains = JSON.parse(arr).map(item => item.domain);
    return domains.join(', ')
  }
  return (
    <>
      <div className="listHead">
        <Row>
          <Col lg="3" className="mb-2">Название</Col>
          <Col lg="2" className="mb-2">Сайт</Col>
          <Col lg="2" className="mb-2">email</Col>
          <Col lg="2" className="mb-2">Телефон</Col>
          <Col lg="1" className="mb-2">Telegram</Col>
          <Col lg="1" className="mb-2">Рейтинг</Col>
          <Col lg="1" className="mb-2">Менеджер</Col>
          <Col lg="1" className="mb-2"></Col>
        </Row>
      </div>
      <TransitionGroup>
        {data.sort((a, b) => getRating(b.id)[0] - getRating(a.id)[0]).map((elm, i) => (
          <CSSTransition
            key={elm.id}
            timeout={500}
            classNames="formanimation"
          >
            <div className="listBody">
              <Row>
                <Col lg="3">№ {i + 1}<Link to={'/clients/' + elm.id}>{elm.name}</Link></Col>
                <Col lg="2" style={style.div}><span style={style.span2}> {getDomain(elm.domains)}</span> </Col>
                <Col lg="2">{elm.email}</Col>
                <Col lg="2" style={style.span2}>{elm.phone}</Col>
                <Col lg="1" style={style.span2}>{elm.telegram}</Col>
                <Col lg="1" title="Считается за последних два месяца">{getRating(elm.id)[0].toFixed(0)} <span title="Сумма в этом месяце" style={style.span}>{getRating(elm.id)[1]}</span></Col>
                <Col lg="1" style={style.span2}>{elm.manager}</Col>
                <Col lg="1">
                  <span onClick={() => deleteClient(elm.id, elm.name)} className="close-button">&times;</span>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        ))}

      </TransitionGroup >
    </>
  );
};

export default React.memo(ClientLists);