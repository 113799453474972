import React from 'react'
function Loading() {
  return (
    <div id="preloader">
      <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#146c43">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18"></path>
          </g>
        </g>
      </svg>
      <h2>Loading...</h2>
    </div>
  )
}
export default React.memo(Loading)