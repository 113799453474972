import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr } from '../components/UI/alert'

export const fetchBriefs = createAsyncThunk(
  'briefs/fetchBriefs',
  async function (id, { rejectWithValue }) {

    try {
      const response = await fetch(`https://payment.mediarama.by/core/briefs/get/${id ? id : ''}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()

      return data
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const createBriefs = createAsyncThunk(
  'briefs/createBriefs',
  async function (newArr, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/briefs/create`, {
        method: 'POST',
        body: JSON.stringify(newArr)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()
      dispatch(createAddBriefs(data.data));
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось создать запись. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const removeBriefs = createAsyncThunk(
  'briefs/removeBriefs',
  async function (object, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/briefs/delete`, {
        method: 'POST',
        body: JSON.stringify(object)
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');
      const data = await response.json()
      dispatch(deleteBriefs(data.data))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const briefsSlice = createSlice({
  name: 'briefs',
  initialState: {
    briefs: {},
    status: null,
    error: null,
  },
  reducers: {
    createAddBriefs(state, action) {
      state.briefs = action.payload
    },
    deleteBriefs(state, action) {
      state.briefs = action.payload
    },
  },
  extraReducers: {
    [fetchBriefs.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchBriefs.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.briefs = action.payload ? action.payload : {}
    },
    [fetchBriefs.rejected]: setError,
    [removeBriefs.rejected]: setError,

    [removeBriefs.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [createBriefs.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [createBriefs.rejected]: setError,
  }
})
const { createAddBriefs, deleteBriefs } = briefsSlice.actions
export default briefsSlice.reducer