import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export const alert = Swal.mixin({
    icon: "warning",
    iconHtml: "!",
    confirmButtonText: "Да я уверен!",
    cancelButtonText: "Отмена",
    showCancelButton: true,
    showCloseButton: true,
});

export const alertErr = Swal.mixin({
    icon: 'error',
    iconHtml: "!",
    confirmButtonText: "ОК!",
});

export const alertOk = Swal.mixin({
    icon: 'success',
    confirmButtonText: "ОК!",
});

export const success = Swal.mixin({
    icon: 'success',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
export const successMessage = Swal.mixin({
    icon: 'success',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});