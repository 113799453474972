import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";

import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import HostingList from '../components/Hostings/HostingList';

import Loading from '../components/UI/Loading'
import LoadServerData from '../components/LoadServerData'

import { useSelector, useDispatch } from 'react-redux';

import { fetchHosting, searchHosting } from '../store/clientsHostingSlice'
import { fetchClient } from '../store/clientsSlice'
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import { dateUnTransform } from '../components/functions'
import CreateClientHosting from '../components/Hostings/CreateClientHosting'

const style = {
  button: {
    width: '100%',
    maxWidth: '80px',
  },
  buttonMin: {
    width: '100%',
    minWidth: '55px',
  },
  form: {
    background: 'rgba(7, 187, 255, .1)',
    padding: "10px 10px 5px 10px",
    marginBottom: "5px",
  },
  h2: {
    textAlign: "center",
    marginTop: "74px",
    color: "#999",
  },
  clearInputSearch: {
    position: 'absolute',
    right: '5px',
    top: '21px',
    bottom: '0',
    margin: 'auto',
    height: '32px',
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

  }
}
function Hosting() {
  const dispatch = useDispatch()

  // LoadServerData()
  const { clients } = useSelector(state => state.clients)
  const { hosting, status, error } = useSelector(state => state.hosting)
  const [hostingForm, setHostingForm] = useState(false)
  const hostingRevers = [...hosting].reverse()

  useEffect(() => {
    dispatch(fetchClient())
    dispatch(fetchHosting())
  }, [dispatch])


  const [searchVal, setSearchVal] = useState('')

  const newArrHosting = useMemo(() => {
    return hostingRevers.filter(el => {
      const client = clients.filter(c => c.id === el.client_id)
      if (client[0]?.name.toLowerCase().includes(searchVal) || client[0]?.name.includes(searchVal)) return el
      if (el.domain.toLowerCase().includes(searchVal) || el.domain.includes(searchVal)) return el
      if (el.info.toLowerCase().includes(searchVal) || el.info.includes(searchVal)) return el
      if (el.access.toLowerCase().includes(searchVal) || el.access.includes(searchVal)) return el
      if (el.date.toLowerCase().includes(searchVal) || el.date.includes(searchVal)) return el
    });
  }, [hostingRevers, clients, searchVal])

  const calendarSearch = useRef(null)
  function closeCalendar(params) {
    params.hide();
  }

  const startCalendar = useCallback((clear) => {
    let butSeeCalendar = {
      content: "Выбрать",
      onClick: (dp) => {
        closeCalendar(dp);
      },
    };
    const datepicker = new AirDatepicker(calendarSearch.current, {
      dateFormat: "dd.MM.yyyy",
      autoClose: true,
      range: true,
      buttons: ['clear', butSeeCalendar],
      multipleDatesSeparator: ' - ',
    })
    if (clear) datepicker.clear();
  }, [])

  useEffect(() => {
    startCalendar()
  }, [calendarSearch, startCalendar])

  function search(e) {
    e.preventDefault()
    const inputs = e.target.querySelectorAll('input')
    const formData = new FormData()
    let field = ''
    let dateFrom = ''
    let dateTo = ''
    inputs.forEach(inp => {
      if (inp.name === 'field') {
        if (inp.value) field = inp.value
      }
      if (inp.name === 'date') {
        if (inp.value) {
          const date = inp.value.split(" - ")
          dateFrom = date[0] ? dateUnTransform(date[0]) : ''
          dateTo = date[1] ? dateUnTransform(date[1]) : ''
        }
      }
    });
    formData.append('field', field)
    formData.append('dateFrom', dateFrom)
    formData.append('dateTo', dateTo)
    dispatch(searchHosting(formData))
  }
  function getNowMonth() {
    const formData = new FormData()
    formData.append('nowmonth', true)
    dispatch(searchHosting(formData))
  }
  function getBlock() {
    const formData = new FormData()
    formData.append('block', true)
    dispatch(searchHosting(formData))
  }
  function getDelete() {
    const formData = new FormData()
    formData.append('delete', true)
    dispatch(searchHosting(formData))
  }
  function clearInputSearch() {
    setSearchVal('')
  }
  function addHostingForm() {
    setHostingForm(hostingForm => !hostingForm);
  }
  return (
    <section className="pages">
      <Container>
        <h1>Хостинг</h1>
        {hostingForm ?
          <Row>
            <Col lg="12" className="mb-3">
              <CreateClientHosting clients={clients} />
            </Col>
          </Row>
          : ""}
        <Form onSubmit={(e) => search(e)} style={style.form}>
          <Row>
            <Col lg="5" className="mb-3">
              <div className="input-style" >
                <label>Поиск</label>
                <input type="text" placeholder="Поиск..." name="field"
                  onChange={(e) => setSearchVal(e.target.value)} value={searchVal}
                />
                <div style={style.clearInputSearch} onClick={clearInputSearch}>&#10006;</div>
              </div>
            </Col>
            <Col lg="2" className="mb-3">
              <div className="input-style" >
                <label>Выбрать диапазон</label>
                <input id="calendar" autoComplete="off" ref={calendarSearch} name="date" />
              </div>
            </Col>
            <Col lg="1" className="mb-3">
              <div className="input-style" >
                <label>Поиск</label>
                <Button style={style.buttonSend} type="submit" variant="warning">Найти</Button>
              </div>
            </Col>
            <Col lg="1" className="mb-3">
              <div className="input-style" >
                <label>Текущий м</label>
                <Button style={style.buttonSend} onClick={getNowMonth} type="button" variant="success">&#8659;&#8659;&#8659;&#8659;&#8659;&#8659;</Button>
              </div>
            </Col>
            <Col lg="1" className="mb-3">
              <div className="input-style" >
                <label>Все долги</label>
                <Button style={style.buttonSend} onClick={getBlock} type="button" variant="danger">&#8659;&#8659;&#8659;&#8659;&#8659;&#8659;</Button>
              </div>
            </Col>
            <Col lg="1" className="mb-3">
              <div className="input-style" >
                <label>Удаленные</label>
                <Button style={style.buttonSend} onClick={getDelete} type="button" variant="dark">&#8659;&#8659;&#8659;&#8659;&#8659;&#8659;</Button>
              </div>
            </Col>
            <Col lg="1" className="mb-3">
              <div className="input-style">
                <label>Добавить</label>
                <Button style={style.button} className={hostingForm ? "btn-secondary" : "btn-primary"} onClick={addHostingForm}>{hostingForm ? "-" : "+"}</Button>
              </div>
            </Col>

          </Row>
        </Form>

      </Container>
      <Container className="edit-box">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {
          newArrHosting.length ?
            <HostingList clients={clients} hosting={newArrHosting} />
            : (<h2 style={style.h2}>По данному запросу ничего не найдено!</h2>)
        }
      </Container>

    </section >
  );
};

export default Hosting;