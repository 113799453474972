import React, { useCallback } from "react";

import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { removePostal } from '../../store/postalItemsSlice'
import SelectSearch from '../UI/SelectSearch/SelectSearch';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { dateTransform } from '../../components/functions'
import { editPostal } from '../../store/postalItemsSlice'
const style = {
  button: {
    width: '100%',
    maxWidth: '80px'
  },
  span: {
    color: '#8d8d8d',
    fontSize: '10px',
    transform: 'translateY(-40%) translateX(3px)',
  },
  span2: {
    minWidth: 'max-content',
    fontSize: '12px',
    color: '#000',
  },
  div: {
    overflow: 'auto',
  },
  num: {
    justifyContent: 'center',
  },
}

function PostalsList({ postals }) {

  const dispatch = useDispatch()

  const deleteClient = useCallback((id, name) => {
    Swal.fire({
      title: `Удалить ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removePostal(id))
      }
    })
  }, [dispatch])

  function saveStatus(idx, status) {
    const formData = new FormData()
    formData.append('status', status)
    Swal.fire({
      title: `Изменить статус на «${status}»?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(editPostal({ formData, idx }))
      }
    })

  }
  return (
    <>
      <div className="listHead">
        <Row>
          <Col lg="1" className="mb-2">№</Col>
          <Col lg="3" className="mb-2">Клиент</Col>
          <Col lg="2" className="mb-2">Дата отправки</Col>
          <Col lg="2" className="mb-2">Что там</Col>
          <Col lg="2" className="mb-2">Статус</Col>
          <Col lg="1" className="mb-2"></Col>
        </Row>
      </div>
      <TransitionGroup>
        {[...postals].sort((a, b) => parseInt(a.date) - parseInt(b.date)).map((elm, i) => (
          <CSSTransition
            key={elm.id}
            timeout={500}
            classNames="formanimation"
          >
            <div className="listBody">
              <Row>
                <Col lg="1" style={style.num}><b>{i + 1}</b></Col>
                <Col lg="3">{elm.client}</Col>
                <Col lg="2" style={style.div}><span style={style.span2}> {dateTransform(elm.date)}</span> </Col>
                <Col lg="2">{elm.what}</Col>
                <Col lg="2">
                  <SelectSearch onChange={(e) => saveStatus(elm.id, e.value)} name="status" id="status" options={[
                    { name: 'status', value: 'Не определен' },
                    { name: 'status', value: '+' },
                    { name: 'status', value: '-' },
                    { name: 'status', value: 'Возврат' },
                  ]} defaultValue={{ name: 'status', value: elm.status }} />
                </Col>
                <Col lg="1">
                  <span onClick={() => deleteClient(elm.id, elm.client)} className="close-button">&times;</span>
                </Col>
              </Row>
            </div>
          </CSSTransition>
        ))}

      </TransitionGroup >
    </>
  );
};

export default React.memo(PostalsList);