import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchClient = createAsyncThunk(
  'clients/fetchClient',
  async function (obj, { rejectWithValue }) {
    let key = '';
    let param = '';
    if (obj) {
      key = Object.keys(obj)[0]
      param = Object.values(obj)[0]
    }
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients`, {
      const response = await fetch(`https://payment.mediarama.by/core/clients/get/${key ? `?${key}=${param}` : ''}`, {
        method: 'GET'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const createClient = createAsyncThunk(
  'clients/createClient',
  async function (formData, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients`, {
      const response = await fetch(`https://payment.mediarama.by/core/clients/create`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()
      dispatch(addClient(data.data))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const removeClient = createAsyncThunk(
  'clients/removeClient',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients/${id}`, {
      const response = await fetch(`https://payment.mediarama.by/core/clients/delete/${id}`, {
        method: 'POST',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');
      dispatch(deleteClient(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const editClient = createAsyncThunk(
  'clients/editClient',
  async function ({ formData, idx }, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/clients/${idx}`, {
      const response = await fetch(`https://payment.mediarama.by/core/clients/edit/${idx}`, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()
      dispatch(modifyClient(data.data))

      const form = document.getElementById(idx)
      const button = form.querySelector('button')
      button.setAttribute('disabled', 'disabled')
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    status: null,
    error: null,
  },
  reducers: {
    clearClients(state) {
      state.clients = []; // Очищаем данные клиента
    },
    addClient(state, action) {
      state.clients.unshift(action.payload)
    },
    deleteClient(state, action) {
      state.clients = state.clients.filter(client => client.id !== action.payload)
      success.fire({
        text: "Клиент удален!",
      });
    },
    modifyClient(state, action) {
      const inx = state.clients.findIndex(client => client.id === action.payload.id)
      state.clients.splice(inx, 0, action.payload)
      success.fire({
        text: "Изменение сохранено!",
      });
    },
  },
  extraReducers: {
    [fetchClient.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    // [fetchOneTrail.pending]: (state) => {
    //   state.status = 'loading'
    //   state.error = null
    // },
    [createClient.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editClient.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchClient.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.clients = action.payload
    },
    // [fetchOneTrail.fulfilled]: (state, action) => {
    //   state.status = 'resolved'
    //   state.trail = action.payload
    // },
    [fetchClient.rejected]: setError,
    // [fetchOneTrail.rejected]: setError,
    [createClient.rejected]: setError,
    [editClient.rejected]: setError,
    [removeClient.rejected]: setError,
  }
})
const { addClient, deleteClient, modifyClient } = clientsSlice.actions
export const { clearClients } = clientsSlice.actions;
export default clientsSlice.reducer