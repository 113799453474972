import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const createEditor = createAsyncThunk(
  'editor/createEditor',
  async function (formData, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://payment.mediarama.by/core/seo/create`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()
      const { id, editor } = data.data;
      const { date, authName, descriptions, editor: editorContent } = JSON.parse(editor);
      const transformedObj = { id, date, authName, descriptions, editor: editorContent };

      return dispatch(addEditor(transformedObj))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const fetchEditor = createAsyncThunk(
  'editor/fetchEditor',
  async function (id, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/seo/get/${id}`, {
        method: 'GET'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      if (!data.status) return []
      const transformedArr = Object.values(data.data).map(item => ({
        id: item.id,
        ...JSON.parse(item.editor)
      }));

      return transformedArr

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const seoActive = createAsyncThunk(
  'editor/seoActive',
  async function ({ id, formData }, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/seo/block/${id}`, {
        method: 'POST',
        body: formData,
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      if (data.data.active === 'true') {
        success.fire({
          text: 'Клиент активен.',
        })
      }
      else {
        success.fire({
          text: 'Клиент не активен.',
        })
      }
      return data.data
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось пометить клиента.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const fetchAllEditors = createAsyncThunk(
  'editor/fetchAllEditors',
  async function (_, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/seo/get`, {
        method: 'POST'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data.data

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const deleteEditors = createAsyncThunk(
  'editor/deleteEditors',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/seo/delete/${id}`, {
        method: 'GET'
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      dispatch(delEditor(String(data.data)))

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const editEditor = createAsyncThunk(
  'editor/editEditor',
  async function (formData, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/hosting`, {
      const response = await fetch(`https://payment.mediarama.by/core/seo/edit`, {
        method: 'POST',
        body: formData
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const { id, editor } = data.data;
      const { date, authName, descriptions, editor: editorContent } = JSON.parse(editor);
      const transformedObj = { id, date, authName, descriptions, editor: editorContent };
      return dispatch(modifyEditor(transformedObj))

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const SEOSlice = createSlice({
  name: 'editor',
  initialState: {
    editor: [],
    seoClients: [],
    status: null,
    error: null,
  },
  reducers: {
    addEditor(state, action) {
      state.editor.push(action.payload);

      success.fire({
        text: "Добавлено!",
      });
    },
    modifyEditor(state, action) {
      state.editor = state.editor.filter(el => el.id !== action.payload.id);
      state.editor.push(action.payload)
      success.fire({
        text: "Изменение сохранено!",
      });
    },
    delEditor(state, action) {
      state.editor = state.editor.filter(el => el.id !== action.payload);
      success.fire({
        text: "Отчет удален!",
      });
    },
  },
  extraReducers: {
    [createEditor.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchAllEditors.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [deleteEditors.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [seoActive.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editEditor.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchEditor.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchAllEditors.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchEditor.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.editor = action.payload
    },
    [fetchAllEditors.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.seoClients = Object.values(action.payload).filter(item => item.active === 'true').map(item => ({ id: item.client_id, active: item.active }))

    },
    [seoActive.fulfilled]: (state, action) => {
      state.status = 'resolved'

      // Проверяем, есть ли объект с нужным client_id в массиве
      const exists = state.seoClients.some(item => item.id === action.payload.client_id.toString());

      let newArr;

      if (exists) {
        // Если есть, обновляем его значение active
        newArr = state.seoClients.map(item => item.id === action.payload.client_id.toString() ? { ...item, active: action.payload.active } : item);
      } else {
        // Если нет, добавляем новый объект в массив
        newArr = [...state.seoClients, { id: action.payload.client_id.toString(), active: action.payload.active }];
      }
      state.seoClients = newArr
    },
    [fetchAllEditors.rejected]: setError,
    [createEditor.rejected]: setError,
    [editEditor.rejected]: setError,
    [deleteEditors.rejected]: setError,
    [fetchEditor.rejected]: setError,
    [seoActive.rejected]: setError,
  }
})
const { addEditor, modifyEditor, delEditor } = SEOSlice.actions
export default SEOSlice.reducer