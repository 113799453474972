import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from "./PDFComponentStyle";
import { rubles } from "rubles";

function PDFPointSum({ price, page, commission, firma }) {
  let found = ''
  if (firma) {
    found = firma.includes("ХитГрупп");
  }
  const pricetSum = price.reduce((akum, el) => +akum + +el)
  let costSum = ''
  // 1 Создать простой счет 
  // 2 Создать счет на пополнения контекста
  // 3 Создать счет на пополнения instagram
  // 4 Создать счет на оплату RBot, RQuiz
  if (page === "1" || page === "2" || page === "4" || page === "5") costSum = Number(pricetSum).toFixed(2)
  if (page === "3") costSum = (Number(commission) + Number(pricetSum)).toFixed(2)

  return (
    <>
      <View style={styles.rowBody} wrap={false}>
        <View style={styles.row1}><Text>Итого:</Text></View>
        <View style={[styles.row1, styles.row2]}><Text></Text></View>
        <View style={[styles.row1, styles.row2]}><Text></Text></View>
        <View style={[styles.row1, styles.row3]}><Text></Text></View>
        <View style={[styles.row1, styles.row3]}><Text>{costSum}</Text></View>
        <View style={[styles.row1, styles.row3]}><Text></Text></View>
        <View style={[styles.row1, styles.row3]}><Text></Text></View>
        <View style={[styles.row1, styles.row3]}><Text>{costSum}</Text></View>
      </View>
      <View style={styles.rowBody} wrap={false}>
        <View style={styles.rowFull}>
          <Text>Всего к оплате: {rubles(costSum).charAt(0).toUpperCase() + rubles(costSum).slice(1)}. Без НДС.</Text>
          {found && <Text>На основании абзаца 3 пункта 27 Положения о Парке высоких технологий, утвержденного Декретом Президента Республики Беларусь от 22.09.2005 № 12 «О Парке высоких технологий» (с изменениями и дополнениями).</Text>}
        </View>
      </View>
    </>
  )
}
export default PDFPointSum