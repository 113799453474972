export function dateTransform(params, time = false) {
  const date = new Date(params * 1000)
  const dataTransform = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
  const dataTransformTime = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' - ' + date.getHours() + ':' + date.getMinutes()
  if (!time) return dataTransform
  else return dataTransformTime
}

export function dateUnTransform(params) {
  const d = params.split('.')
  const newDate = Math.floor(new Date(d[2] + '.' + d[1] + '.' + d[0]).getTime() / 1000)
  return newDate
}
export function dateTransformNewDate(params) {
  let [day, month, year] = params.split(".");
  let formattedDateString = `${year}-${month}-${day}`;
  let date = new Date(formattedDateString);
  return date;  // Output: 2023-06-04T22:00:00.000Z
}

export function getDateDay(params) {
  const unixDate = new Date(dateUnTransform(params) * 1000)
  const dayName = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']
  return dayName[unixDate.getDay()]
}

export function getNowDate() {
  const date = new Date()
  const nowDate = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
  return nowDate
}

export function toFixed(sum) {
  return parseFloat(sum).toFixed(2)
}

export function sortName(arr) {
  const sortedArr = [...arr].sort((a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  });
  return sortedArr
}

export function removeTags(text) {
  return text.replace(/(<([^>]+)>|\r?\n|\r)/gi, ' ');
}
export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  }
  return text;
}
export function sortFromDate(arr) {
  let arrCopy = [...arr];  // Создание копии массива
  arrCopy.sort((a, b) => {
    let dateA = new Date(a.date.split(".").reverse().join("-"));
    let dateB = new Date(b.date.split(".").reverse().join("-"));

    return dateA - dateB;  // For ascending order. Use `dateB - dateA` for descending order.
  });

  return arrCopy;  // Возвращает отсортированный массив
}
export function plusMonths(date, month) {
  let dateParts = date.split(".");
  let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); // создаем объект Date (месяцы считаются с нуля)

  dateObject.setMonth(dateObject.getMonth() + month); // прибавляем 6 месяцев

  // Форматируем дату обратно в строковый формат "дд.мм.гггг"
  let newDate = ("0" + dateObject.getDate()).slice(-2) + "." + ("0" + (dateObject.getMonth() + 1)).slice(-2) + "." + dateObject.getFullYear();

  return newDate

}

export function thisDateYers() {
  const date = new Date()
  return date.getFullYear()
}


export function cutFromAString(originalString, searchString) {
  if (originalString.startsWith(searchString)) {
    let resultString = originalString.slice(searchString.length);
    return resultString
  } else {
    return originalString; // Выводит исходную строку, если условие не выполнено
  }

}