export default function ViberMessages(mess) {
  const token = process.env.REACT_APP_VIBER_TOKEN
  const chat_id = process.env.REACT_APP_CHAT_ID_PAYMENT_GROUP;
  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${mess}`;
  const oReq = new XMLHttpRequest();
  oReq.open("GET", url, true);
  oReq.send();
}

export function ViberMessagesPhoto(ids, message, file) {
  const formData = new FormData();

  formData.append('ids', JSON.stringify(ids));
  if (message) formData.append('message', message);
  if (file) formData.append('file', file);

  fetch('https://payment.mediarama.by/core/viberSendMessage.php', {
    method: 'POST',
    body: formData
  })
    .then(response => response.text())
    .then(data => console.log('Success:', data))
    .catch(error => console.error('Error:', error));

}