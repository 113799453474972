import React, { useEffect, useState, useCallback, useRef } from "react";

import { Container, Col, Row, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from './styles.module.sass'
import SelectSearch from '../UI/SelectSearch/SelectSearch'

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import { filterParishes } from '../../store/parishesSlice'
import { useDispatch } from 'react-redux';

function TableFilter({ options, managers }) {

  const dispatch = useDispatch()
  const filterCalendar1 = useRef(null)

  const [disabledField, setDisabledField] = useState([]);
  const disabledFieldFunc = (field) => {
    if (field)
      return ["client", "code", "manager",].filter(f => f !== field.replace(/\d|(-)/gi, ""))
    else return []
  }

  const [isFilterData, setIsFilterData] = useState({
    client: '',
    dateFrom: '',
    dateTo: '',
    field: '',
    code: '',
    manager: '',
  })

  function enterClient(params) {
    setIsFilterData(isFilterData => ({
      ...isFilterData,
      client: params.name
    }));
    if (params.name) setDisabledField(disabledFieldFunc("client"))
    else setDisabledField(disabledFieldFunc(""))
  }

  function enterField(params) {
    setIsFilterData(isFilterData => ({
      ...isFilterData,
      field: params.name
    }));

  }

  function enterFirma(params) {
    setIsFilterData(isFilterData => ({
      ...isFilterData,
      code: params.name
    }));
    if (params.name) setDisabledField(disabledFieldFunc("code"))
    else setDisabledField(disabledFieldFunc(""))
  }

  function enterManager(params) {
    setIsFilterData(isFilterData => ({
      ...isFilterData,
      manager: params.value
    }));
    if (params.name) setDisabledField(disabledFieldFunc("manager"))
    else setDisabledField(disabledFieldFunc(""))
  }

  function runFilter() {
    dispatch(filterParishes(isFilterData));
  }

  function closeCalendar(params) {
    params.hide();
  }

  const startCalendar = useCallback((clear) => {
    let butSeeCalendar = {
      content: "Показать",
      onClick: (dp) => {
        closeCalendar(dp);
      },
    };
    const datepicker = new AirDatepicker(filterCalendar1.current, {
      dateFormat: "dd.MM.yyyy",
      autoClose: true,
      range: true,
      buttons: ['clear', butSeeCalendar],
      multipleDatesSeparator: ' - ',
      onSelect({ date, formattedDate, datepicker }) {
        setIsFilterData(isFilterData => ({
          ...isFilterData,
          dateFrom: formattedDate[0] ? formattedDate[0] : '',
          dateTo: formattedDate[1] ? formattedDate[1] : ''
        }));
      }
    })
    if (clear) datepicker.clear();
  }, [])

  useEffect(() => {
    startCalendar()
  }, [filterCalendar1, startCalendar])

  const [isClear, setIsClear] = useState(false)

  function clearFilter() {
    setDisabledField(disabledFieldFunc(""))
    startCalendar(true)
    setIsClear(true);
    setTimeout(() => {
      setIsClear(false);
    }, 200);
    setIsFilterData({
      client: '',
      dateFrom: '',
      dateTo: '',
      field: '',
      code: '',
    })
  }

  const tablefilterContainer = useRef(null)
  const tablefilterBut = useRef(null)

  function openTableFilter() {
    tablefilterContainer.current.classList.toggle('tablefilterOpen')
    tablefilterBut.current.classList.toggle('tablefilterButOpen')
  }

  return (
    <>
      <div className="tablefilterBut" onClick={openTableFilter} ref={tablefilterBut}><span>&lArr;</span></div>
      <Container className={styles.tableFilter} ref={tablefilterContainer}>
        <Row>
          <Col lg="12" className="mb-3">
            <label className={styles.label} htmlFor="calendar">По дате</label>
            <input id="calendar" className={styles.input + " " + styles.simpleInput} autoComplete="off" ref={filterCalendar1} />
          </Col>
          <Col lg="12" className="mb-3">
            <label className={styles.label}>По клиент</label>
            <SelectSearch isDisabled={disabledField.find(el => el === "client")} clear={isClear} search={true} options={options} onChange={enterClient} />
          </Col>
          <Col lg="12" className="mb-3">
            <label className={styles.label} htmlFor="field">По полю</label>
            <SelectSearch clear={isClear} search={true} options={[
              { name: '', value: '' },
              { name: "refill_g", value: "Пополнение Google" },
              { name: "refill_y", value: "Пополнение Яндекс" },
              { name: "refill_i", value: "Пополнение Инстаграм" },
              { name: "dev_site", value: "Разработка Сайт" },
              { name: "dev_context", value: "Разработка Контекст" },
              { name: "dev_complex", value: "Комплекс" },
              { name: "dev_seo", value: "Seo" },
              { name: "host", value: "Хостинг" },
              { name: "map", value: "Карты" },
              { name: "dev_i", value: "Разработка Инстаграм" },
              { name: "maint_i", value: "Ведение Инстаграм" },
              { name: "maint_context", value: "Контекст ведение" },
              { name: "maint_site", value: "Сайт ведение" },
              { name: "products", value: "Продукты" },
              { name: "other", value: "Прочее" },
            ]} onChange={enterField} />
          </Col>
          <Col lg="12" className="mb-3">
            <label className={styles.label} htmlFor="field">По фирме</label>
            <SelectSearch isDisabled={disabledField.find(el => el === "code")} clear={isClear} search={true} options={[
              { name: '', value: '' },
              { name: "X", value: "ООО «ХитГрупп»" },
              { name: "M", value: "ИП Холопов М.С." },
              { name: "-", value: "-" },
            ]} onChange={enterFirma} />
          </Col>
          <Col lg="12" className="mb-3">
            <label className={styles.label} htmlFor="field">По менеджеру</label>
            <SelectSearch isDisabled={disabledField.find(el => el === "manager")} clear={isClear} search={true} options={[{ name: '', value: '' }, ...managers]} onChange={enterManager} />
          </Col>
          <Col lg="6" className="mb-3">
            <Button className={styles.tableFilterButton} variant="success" onClick={runFilter}>Вывести</Button>
          </Col>
          <Col lg="6" className="mb-3">
            <Button className={styles.tableFilterButton} variant="warning" onClick={clearFilter}>Очистить</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(TableFilter);