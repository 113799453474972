import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchContractsDetails } from '../../store/contractsDetailsSlice'
import { filterParishes } from '../../store/parishesSlice'
import { Bar } from 'react-chartjs-2'
function ContractsAnalytics() {
  const dispatch = useDispatch()
  const { contractsDetails, status, error } = useSelector(state => state.contractsdetails)
  const parishes = useSelector(state => state.parishes.parishes)

  // преобразуем массив с приходами текущего месяца в нужную форму по полям maint_i, maint_context, maint_site, dev_complex
  const [newParishes, setNewParishes] = useState([])
  function createParishes(data) {
    const result = {
      dates: [],
      summa: []
    };
    data.forEach(item => {
      const dateParts = item.date.split(".");
      const itemMonth = parseInt(dateParts[1]);
      const itemYear = parseInt(dateParts[2]);

      const currentMonthYear = `${itemMonth}.${itemYear}`;

      if (!result.dates.includes(currentMonthYear)) {
        result.dates.push(currentMonthYear);
        result.summa.push(0);
      }

      // Проверка, что дата принадлежит текущему месяцу
      if (itemYear === currentYear && itemMonth === currentMonth) {
        const maint_i = JSON.parse(item.maint_i || '{"value": "0"}').value || 0;
        const maint_context = JSON.parse(item.maint_context || '{"value": "0"}').value || 0;
        const maint_site = JSON.parse(item.maint_site || '{"value": "0"}').value || 0;
        const dev_complex = JSON.parse(item.dev_complex || '{"value": "0"}').value || 0;
        const totalSum = +maint_i + +maint_context + +maint_site + +dev_complex;

        const currentIndex = result.dates.indexOf(currentMonthYear);
        result.summa[currentIndex] += totalSum;
      }
    });
    // Искусственно создаем следующий месяц с такой же суммой

    if (result.hasOwnProperty('dates')) {
      if (result.dates.length > 0) {
        const lastDate = result.dates[result.dates.length - 1];
        const [month, year] = lastDate.split(".");
        const nextMonth = parseInt(month) + 1;
        const nextYear = nextMonth > 12 ? parseInt(year) + 1 : year;
        const nextDate = `${nextMonth}.${nextYear}`;

        const lastSum = result.summa[result.summa.length - 1];

        result.dates.push(nextDate);
        result.summa.push(lastSum);
        setNewParishes(result)
      }
    }

  }

  useEffect(() => createParishes(parishes), [dispatch, parishes])
  //--------------

  //  Получаем приходы за текущий месяц
  useEffect(() => {
    dispatch(fetchContractsDetails())
    dispatch(filterParishes({
      client: '',
      dateFrom: '',
      dateTo: '',
      field: '',
      code: '',
      manager: '',
    }))
  }, [dispatch])
  // ------------------

  const plans = [...Array(12)].map(e => 15000) // План по договорам
  const plansVidenije = [...Array(12)].map(e => 10000) // План по договорам

  // Получить текущую дату
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  // Функция для объединения данных по месяцам и расчета суммы для текущего и будущих месяцев по договорам
  const [indicators, setIndicators] = useState([])
  function mergeAndSumByMonth(data) {

    const result = {};

    data.forEach(item => {
      const newEl = JSON.parse(JSON.stringify(item));

      const paymentLength = newEl.payment.length;
      const priceLength = newEl.price.length;

      if (paymentLength < priceLength) {
        const lastPaymentDate = newEl.payment[paymentLength - 1];
        const [day, month, year] = lastPaymentDate.split(".");
        const nextDate = new Date(`${year}-${month}-${day}`);
        nextDate.setMonth(nextDate.getMonth() + 1);

        const nextPaymentDate = `${nextDate.getDate()}.${nextDate.getMonth() + 1}.${nextDate.getFullYear()}`;
        newEl.payment.push(nextPaymentDate);
      }

      for (let i = 0; i < newEl.payment.length; i++) {
        const paymentDate = newEl.payment[i];
        const price = parseInt(newEl.price[i]);

        const [day, month, year] = paymentDate.split(".");

        if (year > currentYear || (year == currentYear && month >= currentMonth)) {
          const monthYear = `${month}.${year}`;
          if (result[monthYear]) {
            result[monthYear] += price;
          } else {
            result[monthYear] = price;
          }
        }
      }
    });

    const dates = Object.keys(result);
    const summa = dates.map(date => result[date].toString());

    return { dates, summa };
  }
  useEffect(() => setIndicators(mergeAndSumByMonth(contractsDetails.filter(el => el.pay))), [contractsDetails])
  // --------------------------------------
  // Получаем цвета для окрвшивания
  const [colorsArr, setColorsArr] = useState([])
  useEffect(() => {

    if (indicators.summa) {
      const colors = indicators.summa.map((el, i) => {
        const p1 = plans[i] / 3
        const p2 = plans[i] / 2
        const p3 = plans[i] / 1.2
        if (el < p1) return "rgba(233, 0, 0, 1)"
        if (el > p1 && el < p2) return "rgba(233, 140, 0, 1)"
        if (el > p2 && el < p3) return "rgba(233, 190, 0, 1)"
        if (el > p3) return "rgba(5, 233, 0, 1)"
        return "rgba(233, 0, 0, 1)"
      })
      setColorsArr(colors)
    }
  }, [indicators])
  // -------------------------

  // Обьеденяем массив приходов с массивом договоров
  const [newIndicators, setNewIndicators] = useState([])
  function mergeObjects(obj1, obj2) {
    if (indicators.hasOwnProperty('dates') && newParishes.hasOwnProperty('dates')) {
      if (indicators.dates.length > 0 && newParishes.dates.length > 0) {
        const mergedDates = [...new Set([...obj1.dates, ...obj2.dates])];
        const mergedSumma = [];

        mergedDates.forEach(date => {
          const index1 = obj1.dates.indexOf(date);
          const index2 = obj2.dates.indexOf(date);
          const sum1 = index1 !== -1 ? parseInt(obj1.summa[index1]) : 0;
          const sum2 = index2 !== -1 ? parseInt(obj2.summa[index2]) : 0;
          mergedSumma.push((sum1 + sum2).toString());
        });

        return { dates: mergedDates, summa: mergedSumma };
      }
    }
  }

  useEffect(() => { setNewIndicators(mergeObjects(indicators, newParishes)) }, [indicators, newParishes])
  // -------------------------


  const barChartData = {
    labels: newIndicators?.dates,
    datasets: [
      {
        data: newIndicators?.summa,
        label: "Общая",
        borderColor: "#3333ff",
        backgroundColor: "rgba(23, 100, 0, 0.5)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: newParishes?.summa,
        label: "Приходы ведения",
        borderColor: "#3333ff",
        backgroundColor: colorsArr,
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: plansVidenije,
        label: "План по ведению",
        borderColor: "#ff3333",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: indicators?.summa,
        label: "Договора",
        borderColor: "#3333ff",
        backgroundColor: colorsArr,
        fill: true,
        // stack: 'Stack 0',
      },
      {
        data: plans,
        label: "План по договорам",
        borderColor: "#ff3333",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        fill: true,
        // stack: 'Stack 0',
      },
    ]
  };

  return (
    <Bar
      type="bar"
      width={130}
      height={50}

      options={{
        plugins: {
          title: {
            display: true,
            text: "Приходы по оплаченным договорам",
          }
        },
      }}
      data={barChartData}
    />
  )
};

export default ContractsAnalytics;