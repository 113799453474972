import React, { useEffect, useState } from "react";
import PDFComponent from '../../PDFComponentsInvoice/PDFComponent'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { fetchInvoicesDetails, removeInvoicesDetails } from '../../../store/invoicesDetailsSlice'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Loading from '../../../components/UI/Loading'

const style = {
  input: {
    border: 'none',
    width: '100%',
  },
  invoiceBut: {
    cursor: "pointer"
  }
}

function truncateString(str, num) {
  if (str.length > num) return str.slice(0, num) + "...";
  else return str;
}
function Invoices() {

  const dispatch = useDispatch()

  const { invoicesDetails, status, error } = useSelector(state => state.invoicesdetails)

  const params = useParams()
  useEffect(() => { dispatch(fetchInvoicesDetails(params.id)) }, [dispatch, params.id])

  const [dataPayment, setDataPayment] = useState('')

  function deletePayment(id, name, price) {
    Swal.fire({
      title: `Точно удалить счет ${name} на ${price} руб?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeInvoicesDetails(id))
      }
    })

  }
  return (
    <>
      <div className="invoicesHead">
        <Row>
          <Col lg="1" className="mb-2">Номер</Col>
          <Col lg="4" className="mb-2">Предмет</Col>
          <Col lg="2" className="mb-2">Фирма</Col>
          <Col lg="1" className="mb-2">Дата</Col>
          <Col lg="1" className="mb-2">Сумма</Col>
          <Col lg="2" className="mb-2">Выписал</Col>
          <Col lg="1" className="mb-2"></Col>
        </Row>
      </div>
      <div className="invoicesBody">
        {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>}
        {invoicesDetails?.map(el =>
          <div key={el.id_payment} className="invoice">
            <Row>
              {el.id_payment === dataPayment.id_payment ?
                <Col lg="1"><PDFDownloadLink document={<PDFComponent data={dataPayment} page={dataPayment?.page} activeSing={dataPayment.activeSing} />} fileName={"Счет " + el.name + ' ' + el.date + '.pdf'}>
                  <span onClick={() => setTimeout(() => setDataPayment(''), 1000)}>№ {el.id_payment} ✅</span>
                </PDFDownloadLink> </Col>
                :
                <Col style={style.invoiceBut} lg="1" onClick={() => setDataPayment(el)}>№{el.id_payment} 💾</Col>
              }
              <Col lg="4"><input style={style.input} type="text" value={el.appoint} readOnly /></Col>
              <Col lg="2">{truncateString(el.firmaName || el.firma, 15)}</Col>
              <Col lg="1">{el.date}</Col>
              <Col lg="1">{el.price} р</Col>
              <Col lg="2">{el.authName}</Col>
              <Col lg="1"><span onClick={() => deletePayment(el.id_payment, el.name, el.price)} className="close-button">&times;</span></Col>
            </Row>
          </div>
        ).reverse()}
      </div>
    </>
  );
};
export default Invoices;