import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchInvoicesDetails = createAsyncThunk(
  'invoicesdetails/fetchInvoicesDetails',
  async function (id, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/invoicesdetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/invoicesdetails/get/${id}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const arr = []
      for (const key in data) {
        const newObject = {
          id_payment: data[key].id,
          date: data[key].date,
          ...JSON.parse(data[key].value)
        }
        arr.push(newObject)
      }
      return arr

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const createInvoicesDetails = createAsyncThunk(
  'invoicesdetails/createInvoicesDetails',
  async function (object, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/invoicesdetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/invoicesdetails/create`, {
        method: 'POST',
        body: JSON.stringify(object)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось создать запись о счете в базе. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const removeInvoicesDetails = createAsyncThunk(
  'invoicesdetails/removeInvoicesDetails',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/invoicesdetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/invoicesdetails/delete/${id}`, {
        method: 'POST',
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      dispatch(deletePayment(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось удалить счет в базе. Обновите страницу и повторите попытку.',
      })
      return rejectWithValue(error.message)
    }
  }
)

const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const InvoicesDetailsSlice = createSlice({
  name: 'invoicesdetails',
  initialState: {
    invoicesDetails: [],
    status: null,
    error: null,
  },
  reducers: {
    deletePayment(state, action) {
      state.invoicesDetails = state.invoicesDetails.filter(accountDetail => accountDetail.id_payment !== action.payload)
      success.fire({
        text: "Счет удален!",
      });
    },
  },
  extraReducers: {
    [fetchInvoicesDetails.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [createInvoicesDetails.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchInvoicesDetails.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.invoicesDetails = action.payload
    },
    [fetchInvoicesDetails.rejected]: setError,
    [createInvoicesDetails.rejected]: setError,
  }
})
const { deletePayment } = InvoicesDetailsSlice.actions
export default InvoicesDetailsSlice.reducer