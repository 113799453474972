import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Textarea from "../components/UI/Textarea"
import { useSelector, useDispatch } from 'react-redux';
import "../components/Briefs/styles.sass"
import logo from '../images/logo.png'
import { fetchClient } from '../store/clientsSlice'
import { createBriefs, fetchBriefs } from "../store/BriefSlice"
import CryptoJS from 'crypto-js';
import BriefSiteForm from "../components/Briefs/BriefSiteForm"
import BriefMapForm from "../components/Briefs/BriefMapForm"
import BriefContextForm from "../components/Briefs/BriefContextForm"
import BriefContextSiteForm from "../components/Briefs/BriefContextSiteForm"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import style from '../components/Briefs/style.module.sass'

function BriefSite() {
  const dispatch = useDispatch()

  const { briefs } = useSelector(state => state.briefs)

  const getURL = useCallback(() => {
    const strGET = window.location.search.replace('?', '');
    const str = strGET.split('&').filter(el => el.split('=')[0] === 'client')
    const str2 = strGET.split('&').filter(el => el.split('=')[0] === 'brief')
    const client = str.join().split('=')[1]
    const brief = str2.join().split('=')[1]

    return { 'client': client, 'brief': brief }
  }, [])
  const [dataClient, setDataClient] = useState(null)

  useEffect(() => {
    const key = process.env.REACT_APP_CRYPTO_SECRET_KEY;
    const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(getURL().client), key);
    const decryptedPlaintext = decryptedBytes.toString(CryptoJS.enc.Utf8).split("|");
    setDataClient({ id: decryptedPlaintext[0], key: decryptedPlaintext[1], title: decryptedPlaintext[2] })
  }, [getURL])



  const { clients } = useSelector(state => state.clients)
  const [thisClient, setThisClient] = useState(null)

  useEffect(() => {
    if (dataClient) {
      dispatch(fetchBriefs(dataClient.id))
    }
  }, [dispatch, dataClient])

  useEffect(() => {
    if (dataClient) {
      dispatch(fetchClient({ id: dataClient.id }))
    }
  }, [dispatch, dataClient])

  useEffect(() => {
    setThisClient(clients[0]);
  }, [clients])


  const [editing, setEditing] = useState(false)

  function saveBrief(e) {
    e.preventDefault()
    const fields = e.target.querySelectorAll('.p-title')

    const object = {}


    fields.forEach(el => {
      const title = el.innerHTML
      const field = el.parentElement.nextElementSibling.querySelector('[data-name]')
      const fieldUl = el.parentElement.nextElementSibling.querySelectorAll('ul input')
      if (field.type === 'checkbox') {
        object[field.dataset.name] = { title: title, checked: field.checked, value: field.value }
      } else {
        object[field.dataset.name] = { style: field.style.height, title: title, value: field.value }
      }
      if (fieldUl.length > 0) {
        const result = {
          names: [],
          values: [],
          checked: []
        };
        fieldUl.forEach(input => {
          result.names.push(input.name);    // Добавляем name в массив names
          result.values.push(input.value);  // Добавляем value в массив values
          result.checked.push(input.checked); // Добавляем состояние checked в массив checked
        });
        object[field.dataset.name] = { title: title, fieldul: result }
      }
    });
    Swal.fire({
      title: `Точно сохранить бриф? После сохранения брифа Вы больше не сможете его редактировать!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {

      if (result.isConfirmed) {
        const brief = JSON.parse(briefs[dataClient.key])
        brief.brief = object
        brief.editing = false
        setEditing(false);
        dispatch(createBriefs(brief));
        Swal.fire('Сохранено!', '', 'success')
      }

    })

  }

  const [thisBrief, setThisBrief] = useState({})
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (dataClient)
      if (briefs && briefs[dataClient.key] && dataClient) {
        const edit = JSON.parse(briefs[dataClient.key]);
        setEditing(edit.editing);
        if (edit.brief) setThisBrief(edit.brief);
      } else {
        setTimeout(() => {
          setShowError(true)
        }, 1000);
      }
  }, [briefs, dataClient]);
  return (
    <section className="pages pages-brief">
      {thisClient && Object.keys(briefs).length > 0 ?
        <Container>
          <img className='logo' src={logo} alt="" />
          <h1 className='h1'>{dataClient.title}</h1>
          <p>Уважаемый клиент! Просим Вас ответить на вопросы данного брифа. Любая информация, полученная в рамках данного документа, является конфиденциальной и не подлежит разглашению. Данный бриф является вашим техническим заданием для нас и будет приложен к договору.</p>
          <Form onSubmit={(e) => saveBrief(e)}>
            <Row>
              {Object.keys(thisBrief).length > 0 ?
                Object.entries(thisBrief)?.map(([key, value]) => (
                  <React.Fragment key={key}>
                    <Col lg="4" className="mb-3">
                      <p className='p p-title' dangerouslySetInnerHTML={{ __html: value.title }}></p>
                    </Col>
                    <Col lg="8" className="mb-3">
                      {value.checked !== undefined ?
                        <label htmlFor={key}>
                          <p>Есть</p>
                          <input className='checkbox' type="checkbox" id={key} data-name={key} value={value.value} defaultChecked={value.checked} />
                        </label>
                        :
                        (!value.fieldul && <Textarea name={key} height={value.style} value={value.value} />)
                      }
                      {value.fieldul && <ul className={style.ul}>
                        {

                          value.fieldul && value.fieldul?.values.map((el, i) => (
                            <li key={el}>
                              <label htmlFor={value.fieldul?.names[i]}>
                                <input className='checkbox' type="checkbox" id={value.fieldul?.names[i]} name={value.fieldul?.names[i]} data-name={value.fieldul?.names[i]} value={el} defaultChecked={value.fieldul?.checked[i]} />
                                <p>{el}</p>
                              </label>
                            </li>
                          ))
                        }

                      </ul>}
                    </Col>
                  </React.Fragment>
                ))

                :
                (getURL().brief === "brief_map" ? <BriefMapForm thisClient={thisClient} editing={editing} /> :
                  getURL().brief === "brief_site" ? <BriefSiteForm thisClient={thisClient} editing={editing} /> :
                    getURL().brief === "brief_context" ? <BriefContextForm thisClient={thisClient} editing={editing} /> :
                      getURL().brief === "brief_site_or_context" ? <BriefContextSiteForm thisClient={thisClient} editing={editing} /> : null)
              }
            </Row>
            {editing &&
              <Col lg="8" className="mb-3">
                <Button className="button-submit" variant="success" type="submit">
                  Сохранить и отправить
                </Button>
              </Col>
            }
          </Form>
        </Container>
        :
        <Container>
          {showError && (
            <Row className='justify-content-center'>
              <Col lg="2" className="mb-3">
                <div className="error">
                  <div className="error-fon">404</div>
                  <div className="error-text-wrap">
                    <div className="error-text-top">404 Not Found</div>
                    <div className="error-test-separate"></div>
                    <div className="error-text-bottom">не найдено</div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>}
    </section >
  );
};
export default BriefSite;