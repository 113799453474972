import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import clientsReducer from "./clientsSlice";
import firmasReducer from "./firmasSlice";
import paymentPurposeReducer from "./paymentPurposeSlice";
import invoicesDetailsReducer from "./invoicesDetailsSlice";
import parishesReducer from "./parishesSlice";
import settingsReducer from "./settingsSlice";
import indicatorsReducer from "./indicatorsSlice";
import hostingReducer from "./clientsHostingSlice";
import telegramBaseReducer from "./telegramBaseSlice";
import viberBaseReducer from "./viberBaseSlice";
import recruitManagersReducer from "./recruitManagersSlice";
import clientsSEOReducer from "./clientsSEOSlice";
import contractsDetailsReducer from "./contractsDetailsSlice";
import purchasedServicesReducer from "./purchasedServicesSlice";
import briefsReducer from "./BriefSlice";
import postalItemsReducer from "./postalItemsSlice";
import widgetsReducer from "./widgetsSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    clients: clientsReducer,
    firmas: firmasReducer,
    paymentpurpose: paymentPurposeReducer,
    invoicesdetails: invoicesDetailsReducer,
    parishes: parishesReducer,
    settings: settingsReducer,
    indicators: indicatorsReducer,
    hosting: hostingReducer,
    telegramBase: telegramBaseReducer,
    viberBase: viberBaseReducer,
    recruits: recruitManagersReducer,
    seo: clientsSEOReducer,
    contractsdetails: contractsDetailsReducer,
    purchasedservices: purchasedServicesReducer,
    briefs: briefsReducer,
    postalitems: postalItemsReducer,
    widgets: widgetsReducer,
  }
})