
function Textarea({ title, name, value = '', className = '', type = "text", height = "40px" }) {
  const style = {
    height: height,
  }
  return (
    <div className="input-style">
      <label>{title}</label>
      <textarea style={style} autoComplete="off" className={className} type={type} data-name={name} defaultValue={value}></textarea>
    </div>
  )
}
export default Textarea