import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectSearch from "../../UI/SelectSearch/SelectSearch";
import CryptoJS from 'crypto-js';
import { getNowDate } from "../../functions"
import { createBriefs, fetchBriefs, removeBriefs } from "../../../store/BriefSlice"
import { useDispatch, useSelector } from 'react-redux';
import "../../Briefs/styles.sass"
import { success, alert } from '../../UI/alert'


function Briefs({ data, authName }) {
  const dispatch = useDispatch()

  const params = useParams()

  const { briefs } = useSelector(state => state.briefs)

  const [briefsMenu, setBriefsMenu] = useState([])

  useEffect(() => {
    dispatch(fetchBriefs(params?.id))
  }, [dispatch, params?.id])

  useEffect(() => {
    if (briefs) {
      const site = briefs.brief_site ? JSON.parse(briefs.brief_site) : null;
      const context = briefs.brief_context ? JSON.parse(briefs.brief_context) : null;
      const siteOrContext = briefs.brief_site_or_context ? JSON.parse(briefs.brief_site_or_context) : null;
      const map = briefs.brief_map ? JSON.parse(briefs.brief_map) : null;

      setBriefsMenu([
        site,
        context,
        siteOrContext,
        map,
      ].filter(Boolean))

    }
  }, [briefs])

  const [isClear, setIsClear] = useState(null)
  const [briefsSelectSearch, setBriefsSelectSearch] = useState([])
  function getBriefsSelectSearch() {
    const result = briefsSelectSearch.reduce((acc, el) => {
      if (!briefsMenu.some((item) => item.key === el.name)) {
        acc.push(el);
      }
      return acc;
    }, []);
    setIsClear(new Date())
    setBriefsSelectSearch(result)
  }

  useEffect(() => {
    setBriefsSelectSearch([
      { id: 1, name: "brief_site", value: "Бриф на разработку сайта" },
      { id: 2, name: "brief_map", value: "Бриф на разработку карт" },
      { id: 3, name: "brief_context", value: "Бриф на контекстную рекламу" },
      { id: 4, name: "brief_site_or_context", value: "Бриф на разработку сайта и контекста" },
    ])
    if (Object.keys(briefsMenu).length > 0) getBriefsSelectSearch()
  }, [briefsMenu])


  function createBriefLink(e) {
    e.preventDefault()
    const input = e.target.querySelector('input')
    const urlObject = new URL(window.location);
    // Собираем базовый URL
    const baseUrl = `${urlObject.protocol}//${urlObject.host}/`;

    const linksArr = [
      // { name: input.dataset.name, link: `http://localhost:3002/briefclient` },
      { name: input.dataset.name, link: `${baseUrl}briefclient` },
    ]
    let object = {
      client_id: params.id,
      name: '',
      date: getNowDate(),
      link: '',
      authName: authName,
      key: input.dataset.name,
      brief: '',
      editing: true,
    };
    if (input.value) {
      const client = data.id + "|" + input.dataset.name + "|" + input.value;
      const key = process.env.REACT_APP_CRYPTO_SECRET_KEY;
      const ciphertext = CryptoJS.AES.encrypt(client, key).toString();

      linksArr.forEach(el => {
        if (el.name === input.dataset.name) {
          object.link = el.link + '?brief=' + el.name + '&client=' + encodeURIComponent(ciphertext)
          object.name = input.value
        }
      })
      dispatch(createBriefs(object));
    }
  }

  function goBuffer(e) {
    e.target.select();
    navigator.clipboard.writeText(e.target.value);
    success.fire({
      text: "Скопировано в буфер обмена!",
    });
  }
  function deleteBriefLink(client_id, key, name) {
    alert.fire({
      title: `Точно удалить «${name}»?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeBriefs({ client_id: client_id, key: key }));
      }
    })
  }


  return (
    <section className='pages '>
      <Container>
        <Form onSubmit={(e) => createBriefLink(e)}>
          <Row>
            <Col lg='6' className='mb-3'>
              <SelectSearch options={briefsSelectSearch} clear={isClear} />
            </Col>
            <Col lg='6' className='mb-3'>
              <Button type="submit" variant="secondary">Добавить</Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <div className="invoicesHead">
        <Row>
          <Col lg="4" className="mb-2">Бриф</Col>
          <Col lg="1" className="mb-2">Дата</Col>
          <Col lg="4" className="mb-2">Ссылка</Col>
          <Col lg="1" className="mb-2"></Col>
          <Col lg="1" className="mb-2">Выписал</Col>
          <Col lg="1" className="mb-2"></Col>
        </Row>
      </div>
      <div className="invoicesBody">
        {/* {status === 'loading' && <Loading />}
        {error && <h2>{error}</h2>} */}
        {Object.keys(briefsMenu).length > 0 ? briefsMenu.map(el =>
          <div key={el.name} className="invoice">
            <Row >

              <Col lg="4">№ {el.client_id} {el.name}</Col>
              <Col lg="1">{el.date}</Col>
              <Col lg="4"><input className='linkField' type="text" value={el.link} readOnly onClick={(e) => goBuffer(e)} /></Col>
              {el.brief ?
                <Col lg="1" className='linkEdit'><Link to={{ pathname: '/briefsiteedit/' + el.client_id }} state={{ key: el.key }}>✏️</Link></Col>
                :
                <Col lg="1" className='linkEdit'>🕑</Col>
              }

              <Col lg="1">{el.authName}</Col>
              <Col lg="1"><span className="close-button" onClick={() => deleteBriefLink(el.client_id, el.key, el.name)}>&times;</span></Col>
            </Row>
          </div>
        ) : <></>}
      </div >
    </section >
  );
};
export default Briefs;