import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr } from '../components/UI/alert'

export const fetchIndicators = createAsyncThunk(
  'indicators/fetchIndicators',
  async function (_, { rejectWithValue }) {
    try {
      // const response = await fetch(`http://mediarama-payment-app/InvoicesDetails`, {
      const response = await fetch(`https://payment.mediarama.by/core/indicators`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)


const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState: {
    indicators: [],
    status: null,
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchIndicators.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchIndicators.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.indicators = action.payload
    },
    [fetchIndicators.rejected]: setError,
  }
})
// const {} = indicatorsSlice.actions
export default indicatorsSlice.reducer