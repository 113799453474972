import React, { useState, useEffect } from "react";
import { Configuration, OpenAIApi } from 'openai';
import { Row, Col, Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const style = {
  img: {
    width: "500px",
    height: "500px",
  }
}
function OpenAi() {
  const [message, setMessage] = useState('');
  const [responses, setResponses] = useState([]);
  const sendMessage = async () => {
    const responseMessage = await chatWithOpenAI(message);
    setResponses([...responses, responseMessage]);
    setMessage('');
  };
  const openAIKey = process.env.REACT_APP_OPENAI_API_KEY;
  const chatWithOpenAI = async (prompt) => {
    try {
      const response = await fetch('https://payment.mediarama.by/open_ai/open_ai.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: "text-davinci-003", // Используйте актуальное название модели
          prompt: prompt,
          max_tokens: 150
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  return (
    <section className="pages">
      <Container>
        <h1>OpenAi</h1>
        <Row>
          <Col>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            />
            <button onClick={sendMessage}>Send</button>
          </Col>
          <Col>
            {responses.map((response, index) => (
              <p key={index}>{response}</p>
            ))}
          </Col>
        </Row>
      </Container>

    </section >
  );
};

export default OpenAi;