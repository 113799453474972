import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from "react-router-dom";
import SimpleInput from '../../UI/SimpleInput';
import Textarea from '../../UI/Textarea';
import SelectSearch from '../../UI/SelectSearch/SelectSearch';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputMask from 'react-input-mask';
import { fetchOneHosting, blockingHosting, removeHostingOne, editHosting, blockingSite } from '../../../store/clientsHostingSlice'
import { dateUnTransform, dateTransform } from '../../../components/functions'
import stringify from 'json-stable-stringify';
import { useSelector, useDispatch } from 'react-redux';
import { getNowDate } from '../../functions'
import load from '../../../images/1.svg'
import loadOk from '../../../images/2.svg'
import PDFComponent from '../../PDFComponentsInvoice/PDFComponent'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { createInvoicesDetails } from '../../../store/invoicesDetailsSlice'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const style = {
  label: {
    display: "block",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#7B7B7B",
    marginBottom: "5px",
  },
  buttonMin: {
    width: '100%',
    maxWidth: '55px'
  },
  button: {
    width: '100%',
    maxWidth: '80px'
  },
}
function Hosting({ client, authName, paymentpurpose, firmas }) {
  const params = useParams()
  const dispatch = useDispatch()
  const { hostingOne } = useSelector(state => state.hosting)
  useEffect(() => {
    dispatch(fetchOneHosting(client?.id))
  }, [dispatch, client?.id])

  const saveButton = useRef(null)

  const [domain, setDomain] = useState([])

  useEffect(() => {
    if (hostingOne?.domain) setDomain(JSON.parse(hostingOne.domain));
  }, [hostingOne])

  function addDomainAndWay() {
    setDomain(prevArray => [...prevArray, { id: prevArray.length, domain: '', way: '' }]);
  }
  function delDomainAndWay(id) {
    saveButton.current.removeAttribute('disabled')
    const lastDomain = domain.filter(d => d.id !== id)
    setDomain(lastDomain);
    dispatch(removeHostingOne(JSON.stringify(lastDomain)));
    saveButton.current.removeAttribute('disabled')
  }

  const editClientSend = useCallback((e) => {
    e.preventDefault()
    const targets = e.target.querySelectorAll('input, textarea');
    const formData = new FormData()

    const originalArray = []
    targets.forEach(inp => {
      if (inp.dataset.name === 'date') {
        formData.append('unix', String(dateUnTransform(inp.value)))
        formData.append(inp.dataset.name, inp.value)

      } else if (inp.dataset.name === 'domain' || inp.dataset.name === 'way') {
        if (inp.value) originalArray.push({ [inp.dataset.name]: inp.value })
      }
      else {
        formData.append('client_id', hostingOne.client_id)
        formData.append(inp.dataset.name, inp.value)
      }

    });


    let idx = ''
    formData.forEach((value, key) => {
      if (key === 'id') idx = value
    });


    const resultArray = originalArray.reduce((acc, cur) => {
      if (cur.domain) {
        acc.push({ domain: cur.domain });
      } else {
        const obj = acc[acc.length - 1];
        const key = Object.keys(cur)[0];
        obj[key] = cur[key];
      }
      return acc;
    }, []).map((el, id) => ({ ...el, id }));

    formData.append('domain', JSON.stringify(resultArray))

    dispatch(editHosting({ formData, idx }))

  }, [dispatch, hostingOne])

  const getFormData = useCallback((id) => {
    const form = document.getElementById(id)
    const formValues = form.querySelectorAll('input, textarea')

    const object = {
      id: id,
      client_id: params.id,
      unix: '',
      date: '',
      price: '',
      domain: domain,
      access: '',
      info: '',
      wher: '',
      way: '',
      firma: '',
      block: '',
      del: '',
    }

    formValues.forEach((key) => {
      if (key.dataset.name === 'id') object.id = key.value
      if (key.dataset.name === 'date') {
        object.unix = String(dateUnTransform(key.value))
        object.date = key.value
      }
      if (key.dataset.name === 'price') object.price = key.value
      if (key.dataset.name === 'access') object.access = key.value
      if (key.dataset.name === 'info') object.info = key.value
      if (key.dataset.name === 'wher') object.wher = key.value
      if (key.dataset.name === 'way') object.way = key.value
      if (key.dataset.name === 'firma') object.firma = key.value
      if (key.dataset.name === 'block') object.block = key.value
      if (key.dataset.name === 'del') object.del = key.value
    });

    if (stringify(hostingOne) !== stringify(object)) {
      saveButton.current.removeAttribute('disabled')
    } else {
      saveButton.current.setAttribute('disabled', 'disabled')
    }

  }, [hostingOne, params.id, domain])

  const clickForm = useCallback((id) => {
    getFormData(id)
  }, [getFormData])

  function fieldDomain(e) {
    window.open('https://' + e.value, '_blank')
  };
  const [dataPayment6, setDataPayment6] = useState({})
  const [dataPayment12, setDataPayment12] = useState({})
  const [dataPayment24, setDataPayment24] = useState({})

  const createPDF = useCallback((id, purposeObj, appoint) => {

    setDataPayment6({})
    setDataPayment12({})
    setDataPayment24({})
    let cost = hostingOne.price
    if (appoint === 6) cost = (hostingOne.price / 2 * 1.2).toFixed(1)
    if (appoint === 12) cost = hostingOne.price
    if (appoint === 24) cost = (hostingOne.price * 1.8).toFixed(1)

    const form = document.getElementById(id)
    const formValues = form.querySelectorAll('input, textarea')
    const object = {
      client_id: client.id,
      authName: authName,
      activeSing: false,
      appoint: [],
      client: client.data,
      clientNumber: client.date,
      cod: "",
      date: getNowDate(),
      email: client.email,
      firma: "",
      firmaName: "",
      marker: "",
      name: client.name,
      page: "5",
      price: [cost],
      sing: "",
    }
    object.cod = purposeObj.cod
    object.marker = purposeObj.marker
    object.appoint = [purposeObj.value]


    formValues.forEach((key) => {
      if (key.dataset.name === 'firma') {
        const firma = firmas.find(f => f.name === key.value)

        if (firma) {
          object.firma = firma.data
          object.firmaName = firma.name
          object.sing = firma.sing
        }
      }
    });

    if (appoint === 6) setDataPayment6(object);
    if (appoint === 12) setDataPayment12(object);
    if (appoint === 24) setDataPayment24(object);
    dispatch(createInvoicesDetails(object))
  }, [client, authName, hostingOne, firmas, dispatch])

  function cleanDataPayment() {
    setTimeout(() => {
      setDataPayment6({})
      setDataPayment12({})
      setDataPayment24({})
    }, 500);
  }

  function toggleButSave() {
    saveButton.current.removeAttribute('disabled')
  }

  const blockHosting = useCallback((id, field, flag, domain = '', wher = '') => {
    const hosterUrl = "https://payment.mediarama.by/core/controlpanel.php"
    const domainUrl = "https://xn--c1ajpcpgs.xn--80aaanetzf8a.xn--90ais/control.php"
    const reversFlag = flag === "false" ? true : false

    const createMess = () => {
      if (field === "block" && !reversFlag) return 'разблокировать '
      if (field === "block" && reversFlag) return 'блокировать'
      if (field === "debt" && !reversFlag) return 'убрать из должников '
      if (field === "debt" && reversFlag) return 'добавить в должники'
      if (field === "del" && !reversFlag) return 'убрать пометку на удаление с '
      if (field === "del" && reversFlag) return 'пометить на удаление '
    }

    const goBlockingSite = (thisDomain, thisWay) => {
      formData.append('block', reversFlag)
      formData.append('domain', thisDomain)
      formData.append('way', thisWay)
      if (wher === 'hoster.by' && field !== "debt") dispatch(blockingSite({ url: hosterUrl, formData }))
      if (wher === 'domain.by' && field !== "debt") dispatch(blockingSite({ url: domainUrl, formData }))
      dispatch(blockingHosting({ id, field, reversFlag, thisDomain }))
    }

    const formData = new FormData()
    Swal.fire({
      title: `Точно ${createMess()} ${client.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет'
    }).then((result) => {
      const domainsArr = JSON.parse(domain)

      if (result.isConfirmed) {

        domainsArr.forEach(el => {
          setTimeout(() => { goBlockingSite(el.domain, el.way) }, 250)

        })
        return
      }
      Swal.mixin({
        icon: 'error',
        iconHtml: "!",
        confirmButtonText: "ОК!",
      });
    })
  }, [dispatch, client])

  const HostingBut = (name, keys, appoint, month) => {
    return (
      <Col lg="1" md="2" sm="2" className="mb-2">
        <div className="input-style">
          <label>{name}</label>
          {
            Object.keys(keys).length ?
              <PDFDownloadLink document={<PDFComponent data={keys} />} fileName={`Счет ${name} ${client.name}.pdf`}>
                {({ loading }) => (loading ?
                  <Button className="buttonAnimation" style={style.buttonSend} type="button"><img style={style.img} src={load} alt="" /></Button>
                  : <Button style={style.buttonSend} onClick={cleanDataPayment} variant="success"><img style={style.img} src={loadOk} alt="" /></Button>
                )}
              </PDFDownloadLink>
              :
              <Button style={style.buttonMin} type="button" variant="secondary" onClick={(e) => createPDF(hostingOne.id, paymentpurpose.find(p => p.name === appoint), month)}>+</Button>
          }
        </div>
      </Col>
    )
  }

  return (
    <section className='pages'>
      {hostingOne ?
        <Container >
          <Form key={hostingOne.id} id={hostingOne.id} onSubmit={(e) => editClientSend(e)} onKeyUp={() => clickForm(hostingOne.id)}>
            <Row>
              <input type="hidden" data-name="id" value={hostingOne.id} readOnly />
              <input type="hidden" data-name="block" value={hostingOne.block} readOnly />
              <input type="hidden" data-name="del" value={hostingOne.del} readOnly />
              <Col lg="2" md="6" className="mb-2">
                <label style={style.label} htmlFor="calendar">Дата*</label>
                <div className="input-style" >
                  <InputMask mask="99.99.9999" maskChar=" " className={'calendar-' + hostingOne.id} defaultValue={dateTransform(hostingOne.unix)} data-name={'date'} />
                </div>
              </Col>
              <Col lg="1" md="6" className="mb-2">
                <SimpleInput title="Цена*" type="number" name="price" value={hostingOne.price} />
              </Col>
              <Col lg="2" md="6" className="mb-2">
                <label style={style.label} htmlFor="wher">Где*</label>
                <SelectSearch id="wher" options={[
                  { name: 'wher', value: 'domain.by' },
                  { name: 'wher', value: 'hoster.by' },
                ]} defaultValue={{ name: 'wher', value: hostingOne.wher }} />
              </Col>

              <Col lg="2" md="6" className="mb-2">
                <label style={style.label} htmlFor="firma">Фирме*</label>
                <SelectSearch id="firma" options={[
                  { name: "firma", value: "ООО «ХитГрупп»" },
                  { name: "firma", value: "ИП Холопов М.С." },
                ]} defaultValue={{ name: "firma", value: hostingOne.firma }} onChange={() => toggleButSave()} />
              </Col>
              <Col lg="1" md="2" sm="2" className="mb-2">
                {hostingOne.del !== "true" &&
                  <div className="input-style">
                    <label>Блокировка</label>
                    <Button style={style.button} type="button" variant={hostingOne.block === "true" ? 'danger' : 'light'} onClick={() => blockHosting(hostingOne.id, "block", hostingOne.block, hostingOne.domain, hostingOne.wher)}>{hostingOne.block === "true" ? '🔒' : '🔓'}</Button>
                  </div>
                }
              </Col>
              <Col lg="1" md="2" sm="2" className="mb-2">
                <div className="input-style">
                  <label>Жду оплату</label>
                  <Button style={style.button} type="button" variant={hostingOne.debt === "true" ? 'dark' : 'light'} onClick={() => blockHosting(hostingOne.id, "debt", hostingOne.debt, hostingOne.domain, hostingOne.wher)}>❗️</Button>
                </div>
              </Col>
              <Col lg="1" md="2" sm="2" className="mb-2">
                <div className="input-style">
                  <label>Удаление</label>
                  <Button style={style.button} type="button" variant={hostingOne.del === "true" ? 'dark' : 'light'} onClick={() => blockHosting(hostingOne.id, "del", hostingOne.del, hostingOne.domain, hostingOne.wher)}>🔥</Button>
                </div>
              </Col>
              {
                domain && domain.map(d =>
                  <Col key={d.domain} lg="11" md="5" className="mb-2">
                    <Row>
                      <Col lg="6" md="3" className="mb-2">
                        <div className="input-style" >
                          <label>Домен*</label>
                          <input autoComplete="off" type="text" data-name="domain" defaultValue={d.domain} onDoubleClick={(e) => fieldDomain(e.target)} />
                        </div>
                      </Col>
                      <Col lg="5" md="3" className="mb-2">
                        <div className="input-style" >
                          <label>Путь*</label>
                          <input autoComplete="off" type="text" data-name="way" defaultValue={d.way} />
                        </div>
                      </Col>
                      <Col lg="1" md="1" className="mb-2">
                        <div className="input-style">
                          <label>Удалить</label>
                          <Button variant="danger" style={style.buttonMin} onClick={() => delDomainAndWay(d.id)}>-</Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
              <Col lg="1" md="1" className="mb-2">
                <div className="input-style">
                  <label>Добавить</label>
                  <Button style={style.buttonMin} onClick={addDomainAndWay}>+</Button>
                </div>

              </Col>
              <Col lg="6" md="6" className="mb-2">
                <Textarea height={'100px'} title="Доступы" name="access" value={hostingOne.access} />
              </Col>
              <Col lg="6" md="6" className="mb-2">
                <Textarea height={'100px'} title="Информация" name="info" value={hostingOne.info} />
              </Col>
              <Col lg="1" md="2" sm="2" className="mb-2">
                <div className="input-style">
                  <label>Сохранить</label>
                  <Button ref={saveButton} disabled style={style.button} type="submit" variant="success" className={'button-save'}>&#9998;</Button>
                </div>
              </Col>
              {/* <Col lg="1" md="2" sm="2" className="mb-2">
                <div className="input-style">
                  <label>6 Мес</label>
                  {
                    Object.keys(dataPayment6).length ?
                      <PDFDownloadLink document={<PDFComponent data={dataPayment6} />} fileName={`Счет 6мес ${client.name}.pdf`}>
                        {({ loading }) => (loading ?
                          <Button className="buttonAnimation" style={style.buttonSend} type="button"><img style={style.img} src={load} alt="" /></Button>
                          : <Button style={style.buttonSend} onClick={cleanDataPayment} variant="success"><img style={style.img} src={loadOk} alt="" /></Button>
                        )}
                      </PDFDownloadLink>
                      :
                      <Button style={style.buttonMin} type="button" variant="secondary" onClick={(e) => createPDF(hostingOne.id, paymentpurpose.find(p => p.name === 'appoint-16'), 6)}>+</Button>
                  }
                </div>
              </Col> */}
              {HostingBut('6 мес Б', dataPayment6, 'appoint-16', 6)}
              {HostingBut('12 мес Б', dataPayment12, 'appoint-17', 12)}
              {HostingBut('24 мес Б', dataPayment24, 'appoint-18', 24)}
              {HostingBut('6 мес Р', dataPayment6, 'appoint-56', 6)}
              {HostingBut('12 мес Р', dataPayment12, 'appoint-57', 12)}
              {HostingBut('24 мес Р', dataPayment24, 'appoint-58', 24)}
              {HostingBut('6 мес П', dataPayment6, 'appoint-59', 6)}
              {HostingBut('12 мес П', dataPayment12, 'appoint-60', 12)}
              {HostingBut('24 мес П', dataPayment24, 'appoint-61', 24)}
              {/* <Col lg="1" md="2" sm="2" className="mb-2">
                <div className="input-style">
                  <label>12 Мес</label>
                  {
                    Object.keys(dataPayment12).length ?
                      <PDFDownloadLink document={<PDFComponent data={dataPayment12} />} fileName={`Счет 12мес ${client.name}.pdf`}>
                        {({ loading }) => (loading ?
                          <Button className="buttonAnimation" style={style.buttonSend} type="button"><img style={style.img} src={load} alt="" /></Button>
                          : <Button style={style.buttonSend} onClick={cleanDataPayment} variant="success"><img style={style.img} src={loadOk} alt="" /></Button>
                        )}
                      </PDFDownloadLink>
                      :
                      <Button style={style.buttonMin} type="button" variant="secondary" onClick={(e) => createPDF(hostingOne.id, paymentpurpose.find(p => p.name === 'appoint-17'), 12)}>+</Button>
                  }
                </div>
              </Col>
              <Col lg="1" md="2" sm="2" className="mb-2">
                {console.log(dataPayment24)}
                <div className="input-style">
                  <label>24 Мес</label>
                  {
                    Object.keys(dataPayment24).length ?
                      <PDFDownloadLink document={<PDFComponent data={dataPayment24} />} fileName={`Счет 24мес ${client.name}.pdf`}>
                        {({ loading }) => (loading ?
                          <Button className="buttonAnimation" style={style.buttonSend} type="button"><img style={style.img} src={load} alt="" /></Button>
                          : <Button style={style.buttonSend} onClick={cleanDataPayment} variant="success"><img style={style.img} src={loadOk} alt="" /></Button>
                        )}
                      </PDFDownloadLink>
                      :
                      <Button style={style.buttonMin} type="button" variant="secondary" onClick={(e) => createPDF(hostingOne.id, paymentpurpose.find(p => p.name === 'appoint-18'), 24)}>+</Button>
                  }
                </div>
              </Col> */}
            </Row>
          </Form>
        </Container>
        : <Container>
          <Row className="justify-content-md-center">
            <Col lg="6"><h3>У данного клиента хостинга пока нет</h3></Col>
          </Row>
        </Container>}
    </section >
  );
};
export default Hosting;

