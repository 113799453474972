import { StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import OpenSansLight from "../../assets/fonts/OpenSans-Light.ttf";
import OpenSansSemiBold from "../../assets/fonts/OpenSans-SemiBold.ttf";
import OpenSansMediumItalic from "../../assets/fonts/OpenSans-MediumItalic.ttf";
Font.register({
  family: "OpenSans",
  fonts: [
    { src: OpenSansBold, fontStyle: 'bold' }, // font-style: normal, font-weight: normal
    { src: OpenSansLight, fontStyle: 'light' },
    { src: OpenSansSemiBold, fontStyle: 'normal' },
    { src: OpenSansMediumItalic, fontStyle: 'italic' },
  ]
});
const styles = StyleSheet.create({
  logo: {
    width: '183px',
    height: '45px',
    marginBottom: '30px',
  },
  link: {
    width: '100%',
    marginTop: '60px',
    textAlign: 'center',
    color: '#FF5353',
    fontFamily: "OpenSans",
    fontStyle: 'bold',
    fontSize: '8px',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  mt: {
    marginTop: '20px',
  },
  mb: {
    marginBottom: '20px',
  },
  recTitle: {
    fontFamily: "OpenSans",
    fontStyle: 'normal',
    fontSize: '9px',
    maxWidth: '250px',
  },
  recText: {
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
    maxWidth: '250px',
  },
  recTextM: {
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '7px',
  },
  text: {
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
  },
  textBold: {
    fontFamily: "OpenSans",
    fontStyle: 'bold',
    fontSize: '9px',
  },
  headTitle: {
    fontFamily: "OpenSans",
    fontStyle: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    margin: '20px 0',
  },
  clentRec: {
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
  },
  table: {
    width: '100%',
  },
  rowHeadRec: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
    height: 'auto',
  },
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: '180px',
    height: 'auto',
    position: 'absolute',
    left: '40px',
    top: '-35px'

  },
  rowSing: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
    height: '30px',
    marginTop: '60px',
    width: "480px"
  },
  rowHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #C6C6C6',
    borderBottom: '1px solid #C6C6C6',
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
    height: '30px',
  },

  rowHeader1: {
    width: '30%',
    fontFamily: "OpenSans",
    fontStyle: 'normal',
    fontSize: '6px',
    borderLeft: '1px solid #C6C6C6',
    borderRight: '1px solid #C6C6C6',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  rowHeader2: {
    width: '5%',
    borderLeft: '0px solid #C6C6C6',
  },
  rowHeader3: {
    width: '12%',
    borderLeft: '0px solid #C6C6C6',
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #C6C6C6',
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
  },

  row1: {
    width: '30%',
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
    borderLeft: '1px solid #C6C6C6',
    borderRight: '1px solid #C6C6C6',
    padding: '5px',
    height: '100%',
  },
  row2: {
    width: '5%',
    borderRight: '1px solid #C6C6C6',
    borderLeft: '0px solid #C6C6C6',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  row3: {
    width: '12%',
    borderLeft: '0px solid #C6C6C6',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rowFull: {
    width: '100%',
    fontFamily: "OpenSans",
    fontStyle: 'light',
    fontSize: '9px',
    borderLeft: '1px solid #C6C6C6',
    borderRight: '1px solid #C6C6C6',
    padding: '5px',
    height: '100%',
  },
});

export { styles }