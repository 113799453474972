import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { styles } from "./PDFComponentStyle";
import { cutFromAString } from "../functions";

function PDFFooterSing({ data }) {
  const sing = require(`../../images/${data.sing}`)
  return (
    <><Text style={[styles.headTitle, styles.mt]}>ПОДПИСИ СТОРОН:</Text>
      <View style={[styles.row, styles.mt40, styles.mb]}>
        <View>
          <Text style={[styles.recTitle]}>Исполнитель:</Text>
          <Image
            style={styles.image2}
            src={sing}
          />
          <Text style={[styles.recText, styles.mt]}>М.П. ____________________</Text>
          <Text style={[styles.recText]}>({cutFromAString(data.firmaInFace, "директора ")})</Text>
        </View>
        <View>
          <Text style={[styles.recTitle]}>Заказчик:</Text>
          <Text style={[styles.recText, styles.mt]}>М.П. ____________________</Text>
        </View>
      </View></>)
}
export default PDFFooterSing